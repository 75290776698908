import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@src/store/rootReducer';

import { INITIAL_PAGINATION } from './constants';
import { DebtsState } from './types';

export const initialState: DebtsState = {
  list: [],
  detailStatus: [],
  pagination: INITIAL_PAGINATION,
  madeInitialFetchDebts: false,
  debtIdToDelete: undefined,
  debtIdToAddEvent: undefined,
  debtIdsToConfirm: undefined,
  debtIdsToCancel: undefined,
  debtIdsToConfirmWithBillet: undefined,
  debtIdToSeeMore: undefined,
  debtInfoToCall: undefined,
  debtIdToSendSMS: undefined,
  isFiltersOpen: false,
  isAllRegistersSelected: false,
  mapStatus: {},
  mapSummary: {},
  madeInitialFetchSummary: false,
  initialTabIndexDetailsDrawer: undefined,
  refetchDebts: false,
  isGeneratingBilletBatch: false,
  hasActivePaymentMethod: false,
};

const debtsSlice = createSlice({
  name: 'debts',
  initialState,
  reducers: {
    updateDebts: (state, action: PayloadAction<Partial<DebtsState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    selectRow: (state, action: PayloadAction<number>) => {
      state.list = state.list.map((debt) =>
        Number(debt.id) === Number(action.payload)
          ? { ...debt, selected: !debt.selected }
          : debt
      );
    },
    selectAllRows: (state) => {
      state.list = state.list.map((debt) => ({
        ...debt,
        selected: true,
      }));
    },
    unselectAllRows: (state) => {
      state.list = state.list.map((debt) => ({
        ...debt,
        selected: false,
      }));
      state.isAllRegistersSelected = false;
    },
    selectAllRegisters: (state) => {
      state.isAllRegistersSelected = true;
    },
    callbackCallWebphone: (
      state,
      action: PayloadAction<{ idDebt: string; idDebtor: string }>
    ) => {
      state.debtInfoToCall = {
        idDebt: action.payload.idDebt,
        idDebtor: action.payload.idDebtor,
      };
      state.list = state.list.map((row) => ({
        ...row,
        selected: row.id === action.payload.idDebt,
      }));
    },
    resetMadeInitialFetchDebtsPage: (state) => ({
      ...state,
      madeInitialFetchDebts: false,
      madeInitialFetchSummary: false,
      list: [],
      mapSummary: {},
    }),
    resetDebts: (_) => {
      return initialState;
    },
  },
});

export const {
  updateDebts,
  callbackCallWebphone,
  selectAllRows,
  selectAllRegisters,
  selectRow,
  unselectAllRows,
  resetMadeInitialFetchDebtsPage,
  resetDebts,
} = debtsSlice.actions;

export const selectDebts = (state: RootState) => state.debts;

export const selectDebtIdToAddEvent = (state: RootState) =>
  state.debts.debtIdToAddEvent;

export const selectIsGeneratingBilletBatch = (state: RootState) =>
  state.debts.isGeneratingBilletBatch;

export default debtsSlice.reducer;
