import { styled } from '@material-ui/core/styles';

export const StyledCardText = styled('div')(({ theme }) => ({
  width: '50%',
  '& > *': {
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    display: '-webkit-box',
    lineHeight: '1.2',
  },
}));
