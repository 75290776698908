import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from '@src/store';

interface DebtsTableState {
  shouldReRender: boolean;
}

const initialState: DebtsTableState = {
  shouldReRender: false,
};

export const debtsTableSlice = createSlice({
  name: 'debtsTable',
  initialState,
  reducers: {
    setInitialState: () => initialState,
    handleShouldReRender(state, action: PayloadAction<boolean>) {
      state.shouldReRender = action.payload;
    },
  },
});

export const { setInitialState, handleShouldReRender } =
  debtsTableSlice.actions;

export const selectShouldReRender = (state: RootStateType) =>
  state.debtsTable.shouldReRender;
export default debtsTableSlice.reducer;
