import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import _isNumber from 'lodash/isNumber';
import _isUndefined from 'lodash/isUndefined';

import { AUNotifier, ErrorUtils } from '@assertiva/assertiva-ui';

import { Nullish } from '@src/constants/types';
import WebphoneService from '@src/services/WebphoneService';
import { RootState } from '@src/store/rootReducer';
import { selectHasNBA } from '../wallet/walletSlice';
import {
  selectStrategyHasExpiredStatus,
  selectStrategyHasNBA,
  selectUserHasStrategy,
} from '../strategyDebt/strategyDebtSlice';

import { WebphoneCampaignStatus, WebphoneCampaignsState } from './types';
import { isFetchingWebphoneCampaign } from './utils';

export const initialState: WebphoneCampaignsState = {
  lastCampaignStatus: undefined,
  lastCampaign: undefined,
  campaignSummary: undefined,
  isOpenDialingDrawer: false,
  isOpenCreateCampaignDrawer: false,
};

export const fetchLastWebphoneCampaign = createAsyncThunk(
  'webphoneCampaigns/fetchLastWebphoneCampaign',
  async (_, { dispatch }) => {
    try {
      dispatch(updateState({ lastCampaign: undefined }));

      const response = await WebphoneService.getLastCampaign();

      const {
        campaignid,
        campaigname,
        id_purpose,
        script,
        campaignprogress,
        cancall,
      } = response.data.body;

      return {
        cancall,
        script,
        campaignId: campaignid,
        campaignPercent: campaignprogress,
        idPurpose: id_purpose,
        campaignName: campaigname,
      };
    } catch (err: any) {
      if (err?.response?.status === 404) {
        dispatch(fetchLastWebphoneCampaignStatus());
      } else {
        AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
      }

      throw new Error();
    }
  }
);

export const fetchLastWebphoneCampaignStatus = createAsyncThunk(
  'webphoneCampaigns/fetchLastWebphoneCampaignStatus',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const response = await WebphoneService.getLastWebphoneCampaignStatus();
      const { status } = response.data.body;

      if (
        _isNumber(state.webphoneCampaigns.lastCampaignStatus) &&
        status === WebphoneCampaignStatus.Success
      ) {
        await dispatch(fetchLastWebphoneCampaign()).unwrap();
        dispatch(setIsOpenDialingDrawer(true));
      }

      if (
        !_isUndefined(state.webphoneCampaigns.lastCampaignStatus) &&
        status === WebphoneCampaignStatus.Error
      ) {
        AUNotifier.error(
          'Erro ao criar fila de discagem. Verifique se existem dívidas vencidas e telefones ativos na carteira.'
        );
      }

      if (isFetchingWebphoneCampaign(status)) {
        setTimeout(() => dispatch(fetchLastWebphoneCampaignStatus()), 4000);
      }

      return status;
    } catch (err: any) {
      if (![404, 400].includes(err?.response?.status))
        AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));

      throw new Error();
    }
  }
);

export const fetchWebphoneCampaignSummary = createAsyncThunk(
  'webphoneCampaigns/fetchWebphoneCampaignSummary',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const isStrategyPage = selectUserHasStrategy(state);
      const hasNBASelector = isStrategyPage
        ? selectStrategyHasNBA
        : selectHasNBA;
      const hasNBA = hasNBASelector(state);

      if (!hasNBA) return state.webphoneCampaigns.campaignSummary;

      const response = await WebphoneService.getCampaignSummary();
      return response.data.body;
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    }
  }
);

export const postWebphoneCampaign = createAsyncThunk(
  'webphoneCampaigns/postWebphoneCampaign',
  async (
    payload: {
      idExpirationOption: Nullish<number>;
      idPurpose: number;
      script: string;
    },
    { dispatch }
  ) => {
    try {
      await WebphoneService.postWebphoneCampaign(payload);
      dispatch(fetchLastWebphoneCampaignStatus());
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    }
  }
);

const webphoneCampaignsSlice = createSlice({
  name: 'webphoneCampaigns',
  initialState,
  reducers: {
    setIsOpenDialingDrawer: (state, action: PayloadAction<boolean>) => {
      state.isOpenDialingDrawer = action.payload;
    },
    setIsOpenCreateCampaignDrawer: (state, action: PayloadAction<boolean>) => {
      state.isOpenCreateCampaignDrawer = action.payload;
    },
    updateState: (
      state,
      action: PayloadAction<Partial<WebphoneCampaignsState>>
    ) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLastWebphoneCampaign.fulfilled, (state, action) => {
      state.lastCampaign = action.payload;
      state.lastCampaignStatus = null;
    });
    builder.addCase(fetchLastWebphoneCampaign.rejected, (state) => {
      state.lastCampaign = null;
      state.lastCampaignStatus = null;
    });
    builder.addCase(
      fetchLastWebphoneCampaignStatus.fulfilled,
      (state, action) => {
        state.lastCampaignStatus = action.payload;
      }
    );
    builder.addCase(fetchLastWebphoneCampaignStatus.rejected, (state) => {
      state.lastCampaignStatus = null;
    });
    builder.addCase(fetchWebphoneCampaignSummary.fulfilled, (state, action) => {
      state.campaignSummary = action.payload;
    });
  },
});

export const {
  updateState,
  setIsOpenCreateCampaignDrawer,
  setIsOpenDialingDrawer,
} = webphoneCampaignsSlice.actions;

export const selectLastWebphoneCampaign = (state: RootState) =>
  state.webphoneCampaigns.lastCampaign;

export const selectLastWebphoneCampaignStatus = (state: RootState) =>
  state.webphoneCampaigns.lastCampaignStatus;

export const selectLastWebphoneCampaignProgress = (state: RootState) =>
  state.webphoneCampaigns.lastCampaign?.campaignPercent;

export const selectLastWebphoneCampaignSummary = (state: RootState) =>
  state.webphoneCampaigns.campaignSummary;

export const selectIsOpenDialingDrawer = (state: RootState) =>
  state.webphoneCampaigns.isOpenDialingDrawer;

export const selectIsOpenCreateCampaignDrawer = (state: RootState) =>
  state.webphoneCampaigns.isOpenCreateCampaignDrawer;

export const selectWebphoneCampaignSummaryTotal = (state: RootState) =>
  Number(state.webphoneCampaigns.campaignSummary?.totalDebts);

export const selectIsFetchingWebphoneCampaign = createSelector(
  [
    (state: RootState) => {
      const isStrategyPage = selectUserHasStrategy(state);
      const hasNBASelector = isStrategyPage
        ? selectStrategyHasNBA
        : selectHasNBA;
      return hasNBASelector(state);
    },
    (state: RootState) => {
      const isStrategyPage = selectUserHasStrategy(state);
      if (isStrategyPage) {
        return selectStrategyHasExpiredStatus(state);
      }

      return true;
    },
    (state: RootState) =>
      isFetchingWebphoneCampaign(state.webphoneCampaigns.lastCampaignStatus),
  ],
  (hasNBA, hasExpiredStatus, isFetchingCampaign) =>
    Boolean(hasNBA && hasExpiredStatus && isFetchingCampaign)
);

export default webphoneCampaignsSlice.reducer;
