import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { useCursorPosition } from '../MessageField/CursorPositionContext';
import { StringUtils } from '@assertiva/assertiva-ui';

interface Props {
  label: string;
  isUsed: boolean;
  handleAddVariable: (cursorPosition: number) => void;
  handleRemoveVariable: () => void;
}

/**
 * Variável simples, uma chip que chama `handleAddVariable` ao ser clicada para adicionar a variável na mensagem.
 *
 * Precisa ser passado na prop `messageVariables` do componente `MessageField` para acessar o `CursorPositionContext`
 *
 */
const Variable = ({
  label,
  isUsed,
  handleAddVariable,
  handleRemoveVariable,
}: Props) => {
  const cursorPosition = useCursorPosition();

  const handleClick = () => !isUsed && handleAddVariable(cursorPosition);

  const id = `${StringUtils.formatToLowerCaseHyphen(label).replace(
    ' ',
    '-'
  )}-chip`;

  return (
    <Chip
      size="small"
      clickable={!isUsed}
      onClick={handleClick}
      label={label}
      color="primary"
      variant={isUsed ? 'default' : 'outlined'}
      id={id}
      data-testid={id}
      deleteIcon={<CancelIcon data-testid={`${id}-delete`} />}
      onDelete={isUsed ? handleRemoveVariable : undefined}
    />
  );
};

export default Variable;
