import { DrawerModes, StrategyDrawerSteps, StrategyState } from './types';
import InitialConfigStep from './components/InitialConfigStep';
import FeaturesStep from './components/FeaturesStep';
import UsersStep from './components/UsersStep';

export const initialPagination = { page: 0, rowsPerPage: 10, count: 0 };

export const initialState: StrategyState = {
  list: [],
  pagination: initialPagination,
  isLoadingTable: false,
  drawerMode: DrawerModes.Closed,
  strategyDrawerStep: StrategyDrawerSteps.InitialConfig,
  strategyConfig: undefined,
  hasChangedStrategyConfig: false,
  hasAvailableUsers: undefined,
  lastCreatedStrategy: undefined,
};

export const strategySteps = [
  {
    label: 'Configuração inicial',
    component: <InitialConfigStep />,
  },
  {
    label: 'Características',
    component: <FeaturesStep />,
  },
  {
    label: 'Usuários',
    component: <UsersStep />,
  },
];
