import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';
import DeleteSweep from '@material-ui/icons/DeleteSweep';
import * as filtersUtils from './utils';

interface Props {
  handleClick: () => void;
  numberOfFilters?: number;
  filtersToPreserveOnEraseAll?: Record<string, any>;
  eraseAllFilters?: typeof filtersUtils.eraseAllFilters;
  buttonText?: string;
  buttonIcon?: JSX.Element;
}

export const FilterButtons = ({
  handleClick,
  numberOfFilters = filtersUtils.getFiltersKeys().length,
  filtersToPreserveOnEraseAll,
  eraseAllFilters = filtersUtils.eraseAllFilters,
  buttonText = 'FILTROS',
  buttonIcon = <FilterIcon />,
}: Props) => {
  const hasFilters = numberOfFilters > 0;

  return (
    <Box display="flex" alignItems="center" gridGap={4}>
      <Button
        startIcon={buttonIcon}
        color="primary"
        id="btn-filtros"
        onClick={handleClick}
        data-testid="btn-filtros"
      >
        {buttonText} {hasFilters ? `(${numberOfFilters})` : null}
      </Button>
      {hasFilters ? (
        <IconButton
          onClick={() => eraseAllFilters(filtersToPreserveOnEraseAll)}
          id="btn-limpar-tudo-filtros"
          color="primary"
        >
          <DeleteSweep color="primary" />
        </IconButton>
      ) : null}
    </Box>
  );
};
