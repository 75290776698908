import { useState, memo } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { limitChars, EMAIL_PLACEHOLDERS } from '../constants';
import {
  StyledActionsContainer,
  StyledMessageField,
  StyledLogoContainer,
  StyledVariablesContainer,
} from './MessageField.styles';
import { CursorPositionProvider } from './CursorPositionContext';

interface Props {
  message: string;
  setMessage: (message: string) => void;
  maxLength?: number;
  infoMessage?: string;
  messageVariables?: JSX.Element | JSX.Element[];
  logo?: JSX.Element;
}

const MessageField = ({
  message,
  setMessage,
  maxLength = limitChars,
  infoMessage,
  messageVariables,
  logo,
}: Props) => {
  const [cursorPosition, setCursorPosition] = useState(0);

  const handleBlur = (evt) => setCursorPosition(evt.target.selectionStart);

  return (
    <CursorPositionProvider value={cursorPosition}>
      <StyledMessageField>
        <TextField
          value={message}
          onChange={(evt) => setMessage(evt.target.value)}
          onBlur={handleBlur}
          inputProps={{
            maxLength,
          }}
          variant="outlined"
          placeholder={EMAIL_PLACEHOLDERS.MESSAGE}
          InputProps={{ classes: { root: 'message-input-base' } }}
          classes={{ root: 'message-input' }}
          multiline
          minRows={10}
          maxRows={10}
          fullWidth
          id="email-message-input"
        />
        <StyledActionsContainer>
          <span>
            Clique abaixo nos itens (variáveis) que desejar, para inserir na
            mensagem:
          </span>
          <StyledVariablesContainer>
            {messageVariables}
          </StyledVariablesContainer>
          <StyledLogoContainer>{logo}</StyledLogoContainer>
        </StyledActionsContainer>
        <Typography
          color="textSecondary"
          classes={{ root: 'info-message' }}
          variant="caption"
        >
          {infoMessage}
        </Typography>
      </StyledMessageField>
    </CursorPositionProvider>
  );
};

export default memo(MessageField);
