import { AUConfirmDialog } from '@assertiva/assertiva-ui';
import comparators from '@src/constants/comparators';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const LinkValidatorDialog = ({ isOpen, handleClose }: Props) => {
  return (
    <AUConfirmDialog
      title="É necessário cadastrar e depois validar seu link"
      subtitle="Todo o link precisa ser cadastrado e validado, de acordo com políticas internas de segurança. Entre em contato com nosso suporte caso queira saber mais ou clique em CONTINUAR para prosseguir."
      open={isOpen}
      onDeny={handleClose}
      onConfirm={handleClose}
      denyButtonText="Cancelar"
      confirmButtonText="Continuar"
      actionsWrapperProps={comparators.DEFAULT_ACTION_PROPS_DIALOG}
      data-testid="link-validator-dialog"
      confirmButtonProps={
        {
          id: 'btn-confirm',
          'data-testid': 'btn-confirm',
        } as any
      }
      denyButtonProps={{
        style: { display: 'none' },
      }}
    />
  );
};

export default LinkValidatorDialog;
