import { createContext, useContext } from 'react';
import { useFinancial } from './useFinancial';

const FinancialContext = createContext<
  ReturnType<typeof useFinancial> | undefined
>(undefined);

const FinancialProvider = (props) => <FinancialContext.Provider {...props} />;

function useFinancialContext() {
  const context = useContext(FinancialContext);
  if (context === undefined) {
    throw new Error(
      'useFinancialContext must be used within a FinancialProvider'
    );
  }
  return context;
}

export { FinancialProvider, useFinancialContext };
