const messages = {
  ERRORS: {
    DEBT_HAS_PAID_BILLET: 'Essa dívida possui boleto pago associado a ela',
    INACTIVE_USER:
      'Este usuário não está ativo no recupere. Entre em contato com o administrador do sistema.',
    INVALID_FIELD: (fieldName: string) => `${fieldName} inválido.`,
    MIN_LENGTH: (fieldName: string, length: number) =>
      `${fieldName} deve ter no mínimo ${length} caracteres.`,
    MIN_VALUE: (fieldName: string, value: number) =>
      `Ao menos ${value} ${fieldName} é obrigatório.`,
    ONLY_ADMIN_HAS_PERMISSION:
      'Apenas usuários com perfil "Administrador" podem realizar esta ação.',
    REPEATED_FIELD: (fieldName: string) => `${fieldName} repetido.`,
    REQUIRED_FIELD: (fieldName: string) => `${fieldName} é obrigatório.`,
    WEBPHONE_INACTIVE: 'Você não tem o produto Webphone habilitado',
  },
};

export default messages;
