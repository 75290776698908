import { styled } from '@material-ui/core/styles';

export const StyledEmailContainer = styled('div')({
  width: 684,
  maxWidth: '100%',
  fontSize: '0.875rem',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: 400,
  lineHeight: '1.3rem',
  letterSpacing: '0.01071em',
  color: '#212121',
  margin: 'auto',
});

export const StyledEmailHeader = styled('header')({
  borderBottom: '1px solid #000',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  padding: '4px 8px 24px 16px',
  lineHeight: '0.875rem',
  margin: '0 24px',
  gap: 8,
});

export const StyledLogoHeader = styled('div')({
  display: 'flex',
  alignItems: 'end',
});

export const StyledEmailBody = styled('div')({
  padding: '30px 24px 24px 32px',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
});
