import BaseService from './BaseService';

class BadCreditService extends BaseService {
  postBadCredit(params) {
    return this.axiosInstance.post(`${this.BASE_URL}/negative`, params);
  }

  deleteBadCredit(idBadCredit, body?) {
    return this.axiosInstance.delete(
      `${this.BASE_URL}/negative/${idBadCredit}`,
      { data: body }
    );
  }

  getOperationTypeNegative() {
    return this.axiosInstance.get(`${this.BASE_URL}/negative/operation-type`);
  }

  getBadCreditData(idBadCredit: string) {
    return this.axiosInstance.get(`${this.BASE_URL}/negative/${idBadCredit}`);
  }

  getBureausDebt(idDebt: string) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/negative/bureaus/debt/${idDebt}`
    );
  }

  getBureaus() {
    return this.axiosInstance.get(`${this.BASE_URL}/negative/bureaus`);
  }

  getDimissReason() {
    return this.axiosInstance.get(`${this.BASE_URL}/negative/dimiss-reason`);
  }

  getDebtorOverdueDebts(idDebtor, params?) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debt/negative-available/debtor/${idDebtor}`,
      {
        params,
      }
    );
  }

  getDebtorBadCredits(idDebtor: string, params?) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/negative/debtor/${idDebtor}`,
      {
        params,
      }
    );
  }

  getBadCreditResume(idBadCredit) {
    return this.axiosInstance.get(`${this.BASE_URL}/negative/${idBadCredit}`);
  }

  getBadCreditStatus(idBadCredit, params?) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/negative/${idBadCredit}/status`,
      { params }
    );
  }

  getBadCreditCommunication(idBadCredit) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/negative/${idBadCredit}/communication`
    );
  }
}

export default new BadCreditService();
