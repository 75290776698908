import Box, { BoxProps } from '@material-ui/core/Box';

import { DrawerProps } from '@material-ui/core/Drawer';
import Card from '@material-ui/core/Card';
import Button, { ButtonProps } from '@material-ui/core/Button';

import { AUSimpleDrawer, AUPreventMissClick } from '@assertiva/assertiva-ui';
import { DefaultDrawerProvider, useDefaultDrawer } from './useDefaultDrawer';

const { PreventMissClickDialog, usePreventMissClick } = AUPreventMissClick;

type Props = DrawerProps & {
  onClose?: (event: any, options?: any) => void;
  open?: boolean;
  title: string;
  containerBoxProps?: BoxProps;
  contentBoxProps?: BoxProps;
  cardBoxProps?: BoxProps;
  children?: any;
  isValidToCloseWithoutWarn?: () => boolean;
  handleSubmit?: () => void;
  isSubmitDisabled?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
  submitBtnProps?: ButtonProps;
  showSubmitBtn?: boolean;
};

const DefaultDrawer: React.FC<Props> = ({
  onClose,
  open,
  title,
  containerBoxProps,
  contentBoxProps,
  cardBoxProps,
  children,
  isValidToCloseWithoutWarn = () => true,
  handleSubmit,
  isSubmitDisabled,
  cancelButtonText = 'CANCELAR',
  submitButtonText = 'SALVAR',
  submitBtnProps,
  showSubmitBtn,
  ...props
}) => {
  const { shouldSlideLeft } = useDefaultDrawer();
  const {
    isPreventMissClickDialogOpen,
    preventMissClick,
    closeAnyway,
    keepOpen,
  } = usePreventMissClick(onClose as () => void, isValidToCloseWithoutWarn);
  const hasSubmitBtn = showSubmitBtn || handleSubmit;
  return (
    <AUSimpleDrawer
      onClose={preventMissClick}
      title={title}
      open={Boolean(open)}
      boxProps={{
        width: '60vw',
        minWidth: 700,
        maxWidth: 900,
        style: {
          transition: 'margin-right 225ms 0.035s cubic-bezier(0, 0, 0.2, 1)',
          marginRight: shouldSlideLeft ? 400 : 0,
        },
        ...containerBoxProps,
      }}
      PaperProps={{
        style: {
          overflowX: 'hidden',
        },
      }}
      {...(props as any)}
    >
      <Box paddingY={2} paddingX={3} {...contentBoxProps}>
        <Card>
          <Box padding={3} {...cardBoxProps}>
            {children}
            {hasSubmitBtn && (
              <Box
                display="flex"
                justifyContent="flex-end"
                gridGap={24}
                marginTop={6}
              >
                <Button
                  variant="text"
                  color="primary"
                  id="btn-cancel"
                  data-testid="btn-cancel"
                  onClick={preventMissClick}
                >
                  {cancelButtonText}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  id="btn-submit"
                  data-testid="btn-submit"
                  disabled={isSubmitDisabled}
                  onClick={handleSubmit}
                  {...submitBtnProps}
                >
                  {submitButtonText}
                </Button>
              </Box>
            )}
          </Box>
        </Card>
      </Box>
      <PreventMissClickDialog
        open={isPreventMissClickDialogOpen}
        closeAnyway={closeAnyway}
        keepOpen={keepOpen}
      />
    </AUSimpleDrawer>
  );
};

const WrappedDefaultDrawer: React.FC<Props> = (props) => (
  <DefaultDrawerProvider>
    <DefaultDrawer {...props} />
  </DefaultDrawerProvider>
);

export default WrappedDefaultDrawer;
