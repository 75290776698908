import { ComponentProps, useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { AuthUtils, StringUtils } from '@assertiva/assertiva-ui';
import { StyledTabs, StyledTab } from './SimpleTabs.styles';

export interface Tab {
  label: string;
  description?: string | JSX.Element;
  component: JSX.Element;
  permission?: string[];
  onClick?: () => void;
}

interface Props {
  tabs: Tab[];
  tabsProps?: ComponentProps<typeof StyledTabs>;
  tabProps?: ComponentProps<typeof StyledTab>;
  initialTabsIndex?: number;
}

const SimpleTabs = ({
  tabs,
  initialTabsIndex = 0,
  tabsProps,
  tabProps,
}: Props) => {
  const [activeTab, setActiveTab] = useState(initialTabsIndex);

  const treatedTabs = tabs.filter(
    (tab) =>
      (tab.permission &&
        AuthUtils.getUser().hasPermission(...tab.permission)) ||
      !tab.permission
  );

  if (treatedTabs.length === 0) {
    console.error(
      'SimpleTabs should have at least 1 tab passed through `tabs` prop. If you are using permissioned tabs, make sure users will have permission to at least one tab.'
    );

    return <></>;
  }

  const hasCustomTabs = tabsProps?.value !== undefined;

  const { description, component } =
    treatedTabs[hasCustomTabs ? tabsProps?.value : activeTab];

  return (
    <>
      <StyledTabs
        indicatorColor="primary"
        textColor="primary"
        value={activeTab}
        onChange={(_, newActiveTab) => setActiveTab(newActiveTab)}
        variant="fullWidth"
        {...tabsProps}
      >
        {treatedTabs.map(({ label, onClick }, index) => (
          <StyledTab
            key={index}
            label={label}
            id={`${StringUtils.formatToLowerCaseHyphen(label)}-tab`}
            data-testid={`${StringUtils.formatToLowerCaseHyphen(label)}-tab`}
            onClick={onClick}
            {...tabProps}
          />
        ))}
      </StyledTabs>

      <Box paddingTop={2}>
        {typeof description === 'undefined' ? null : (
          <Box marginBottom={3}>
            {typeof description === 'string' ? (
              <Typography color="textSecondary">{description}</Typography>
            ) : (
              description
            )}
          </Box>
        )}

        {component}
      </Box>
    </>
  );
};

export default SimpleTabs;
