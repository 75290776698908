import { AUBoxSkeleton } from '@assertiva/assertiva-ui';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {
  StyledChangeLogoContent,
  StyledChangeLogoImg,
  StyledChangeLogoText,
} from './UploadLogo.styles';

interface Props {
  handleRemoveLogo?: () => void;
  handleChangeLogo?: () => void;
  imgSrc?: string;
  loadedImg: boolean;
  helperText: JSX.Element | string;
}

const ChangeLogoCard = ({
  handleRemoveLogo,
  handleChangeLogo,
  imgSrc,
  loadedImg,
  helperText,
}: Props) => {
  const isLoadingLogo = !loadedImg;

  return (
    <StyledChangeLogoContent>
      <Box marginLeft={4}>
        {isLoadingLogo ? (
          <AUBoxSkeleton width={130} height={105} />
        ) : (
          <StyledChangeLogoImg
            src={imgSrc}
            width={130}
            height={105}
            crossOrigin="anonymous"
            alt="logo-preview"
          />
        )}
      </Box>
      <StyledChangeLogoText>
        <span>{helperText}</span>
        <Box display="flex" justifyContent="flex-end" gridGap={24}>
          <Button
            variant="text"
            color="primary"
            onClick={handleRemoveLogo}
            disabled={isLoadingLogo}
          >
            EXCLUIR
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleChangeLogo}
            disabled={isLoadingLogo}
          >
            ALTERAR LOGO
          </Button>
        </Box>
      </StyledChangeLogoText>
    </StyledChangeLogoContent>
  );
};

export default ChangeLogoCard;
