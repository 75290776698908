interface FormField {
  /**
   * Deve ser o mesmo nome da propriedade que vem do back `getAddress`
   */
  name: any;
  label: string;
}

type FormConfig = Record<string, FormField>;

export const FORM_CONFIG: FormConfig = {
  zipCode: {
    name: 'zipCode',
    label: 'CEP',
  },
  publicPlaceType: {
    name: 'publicPlaceType',
    label: 'Tipo de logradouro',
  },
  street: {
    name: 'street',
    label: 'Logradouro',
  },
  number: {
    name: 'number',
    label: 'Número',
  },
  complement: {
    name: 'complement',
    label: 'Complemento',
  },
  neighborhood: {
    name: 'neighborhood',
    label: 'Bairro',
  },
  state: {
    name: 'state',
    label: 'UF',
  },
  city: {
    name: 'city',
    label: 'Cidade',
  },
};
