import { useEffect, useState } from 'react';
import { AULoadingManager } from '@assertiva/assertiva-ui';

const LINEAR_LOADING = 'LINEAR';

function showLinearLoading() {
  AULoadingManager.show({ name: LINEAR_LOADING });
}

function closeLinearLoading() {
  AULoadingManager.close({ name: LINEAR_LOADING });
}

function useLinearLoading() {
  const [isLinearLoadingOpen, setLinearLoading] = useState(false);

  useEffect(() => {
    const loadingSub = AULoadingManager.subscribe(({ isLoading, name }) => {
      if (name === LINEAR_LOADING) {
        setLinearLoading(isLoading);
      }
    });

    return () => {
      AULoadingManager.unsubscribe(loadingSub);
    };
  }, []);

  return isLinearLoadingOpen;
}

export { showLinearLoading, closeLinearLoading, useLinearLoading };
