import { useState, useEffect } from 'react';
import { BoxProps } from '@material-ui/core/Box';
import { browserHistory } from '@assertiva/assertiva-ui';
import Alert from './Alert';
import AppointmentsService from '@src/services/AppointmentsService';
import comparators from '@src/constants/comparators';
import { StyledAlertLink } from './Alert.styles';

const CLOSE_ALERT_COOKIE = 'close-appointment-alert';

const TodayAppointmentsAlert = (props: BoxProps) => {
  const [todayAppointments, setTodayAppointments] = useState<number | null>(
    null
  );

  useEffect(() => {
    AppointmentsService.getTodayAppointments()
      .then((res) => {
        const { schedulings } = res.data?.body;
        if (schedulings && Number(schedulings)) {
          setTodayAppointments(schedulings);
        }
      })
      .catch(() => {
        // do nothing in case of error
      });
  }, []);

  if (!todayAppointments) return null;

  return (
    <Alert cookieName={CLOSE_ALERT_COOKIE} id="AGENDAMENTO" {...props}>
      Você tem {todayAppointments} agendamento
      {Number(todayAppointments) === 1 ? '' : 's'} para hoje.{' '}
      <StyledAlertLink
        onClick={() => browserHistory.push(comparators.PATH_APPOINTMENTS)}
        color="primary"
      >
        Acessar agendamentos
      </StyledAlertLink>
    </Alert>
  );
};

export default TodayAppointmentsAlert;
