import styled from '@material-ui/styles/styled';

export const StyledImage = styled('img')({
  width: '100%',
  maxWidth: 110,
});

export const StyledLogoCard = styled('div')({
  border: '1px solid rgba(35, 47, 52, 0.12)',
  minHeight: '170px',
  width: '100%',
  borderRadius: '4px',
  position: 'relative',
});

export const StyledUploadLogoContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gridGap: '12px',
  alignItems: 'center',
  minHeight: 'inherit',
  padding: '20px 8px',
});

export const StyledChangeLogoContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridGap: '50px',
  minHeight: 'inherit',
});

export const StyledChangeLogoImg = styled('img')({
  objectFit: 'contain',
  objectPosition: 'center',
});

export const StyledChangeLogoText = styled('div')({
  flex: 1,
  minHeight: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '35px 18px 18px 0',
  maxWidth: '470px',

  '& > span': {
    color: '#646464',
    fontSize: 14,
    fontWeight: 400,
  },
});
