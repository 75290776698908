import URL_IMAGES from '@src/constants/images';
import {
  AboutSimplificaBox,
  ConfigExplainerBox,
  PurpleBox,
  StatusExplainerBox,
} from './ExplainerBanners.styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StatusExplainer, { IStatusExplainer } from './StatusExplainer';

const status: IStatusExplainer[] = [
  {
    statusName: 'Não iniciadas',
    statusDescription:
      'São as dívidas cadastradas que ainda não estão em processo de cobrança pois tem data antes do vencimento (cobrança preventiva).',
    chipColors: {
      backgroundColor: '#CE8EE3',
      textColor: '#212121',
    },
  },
  {
    statusName: 'Vencidas',
    statusDescription:
      'Ficam no status vencidas as dívidas que passaram do prazo prefixado para pagamento.',
    chipColors: {
      backgroundColor: '#EB5757',
      textColor: '#FFFFFF',
    },
  },
  {
    statusName: 'Próximas do vencimento',
    statusDescription:
      'Dívidas que estão entre 1 e 10 dias antes do vencimento (cobrança preventiva).',
    chipColors: {
      backgroundColor: '#34D0D1',
      textColor: '#212121',
    },
  },
  {
    statusName: 'Finalizadas',
    statusDescription:
      'Dívidas com pagamento confirmado ou que tiveram o acordo realizado e iniciou-se um novo processo de cobrança.',
    chipColors: {
      backgroundColor: '#27AE60',
      textColor: '#FFFFFF',
    },
  },
  {
    statusName: 'Vencendo hoje',
    statusDescription:
      'Nesse status ficam todas as dívidas que vencem no dia atual.',
    chipColors: {
      backgroundColor: '#E18838',
      textColor: '#FFFFFF',
    },
  },
];

const ExplainerBanners = () => (
  <Grid container>
    <Grid item xs={12} md={6}>
      <AboutSimplificaBox>
        <Typography>
          Sobre o <br /> ASSERTIVA RECUPERE
        </Typography>
        <Typography>
          Facilite seu processo de <br /> cobrança
        </Typography>
      </AboutSimplificaBox>
      <ConfigExplainerBox>
        <img
          height={385}
          src={URL_IMAGES.CONFIG_EXPLAINER}
          alt="Explicação sobre configuração"
        />
      </ConfigExplainerBox>
    </Grid>
    <Grid item xs={12} md={6}>
      <PurpleBox>
        <Typography>
          Faça a <span>inclusão de dívidas</span>, acompanhe em quais etapas
          estão suas cobranças, visualize os cards com informações de cada etapa
          e faça{' '}
          <span>edição, envio de SMS unitário e confirmação de pagamento</span>{' '}
          das dívidas.
        </Typography>
      </PurpleBox>
      <StatusExplainerBox>
        <Typography>Etapas do processo de cobrança</Typography>
        <Grid container spacing={2}>
          {status.map((status, index) => (
            <Grid item xs={6} key={index}>
              <StatusExplainer
                statusName={status.statusName}
                statusDescription={status.statusDescription}
                chipColors={status.chipColors}
                showBottonLine={index < 3}
              />
            </Grid>
          ))}
        </Grid>
        <img
          height={55}
          src={URL_IMAGES.ASSERTIVA_ICON}
          alt="Icone assertiva"
        />
      </StatusExplainerBox>
    </Grid>
  </Grid>
);

export default ExplainerBanners;
