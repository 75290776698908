import { useState, useEffect, useMemo } from 'react';
import _isEqual from 'lodash/isEqual';
import Box from '@material-ui/core/Box';
import { AUInputWithRemainingChars, AUNotifier } from '@assertiva/assertiva-ui';
import Title from '@src/features/trackConfig/components/Title';

import DefaultDrawer from '@components/DefaultDrawer';
import {
  IEmailConfig,
  TYPE_MESSAGE_ENUM,
} from '@src/features/trackConfig/types';
import { TYPE_MESSAGE_LABEL } from '@src/features/trackConfig/constants';
import { isEmptyString } from '@src/utils/functionUtils';
import { initialEmailConfig } from './constants';
import { Nullish } from '@src/constants/types';
import {
  EMAIL_PLACEHOLDERS,
  isVariableInMessage,
  defaultMessageVariables,
  MessageField,
  PreviewEmail,
  Variable,
  LinkVariable,
  useMessage,
  replaceVariableForValue,
  LINK_KEY,
  useLinkValidator,
  LinkValidatorDialog,
  Logo,
  getEmailLogoUrl,
  emailConfigWithoutSender,
  removeSenderFromMessage,
  LINK_BILLET_KEY,
  CODE_BILLE_KEY,
} from './EmailMessage';

interface Props {
  open: boolean;
  handleClose: () => void;
  typeMessage?: TYPE_MESSAGE_ENUM;
  emailConfig?: Nullish<IEmailConfig>;
  showBilletVariable: boolean;
  handleInsertConfig: (
    typeMessage: TYPE_MESSAGE_ENUM,
    emailConfig: IEmailConfig
  ) => void;
}

const WriteEmailDrawer = ({
  open,
  handleClose,
  typeMessage,
  emailConfig,
  showBilletVariable,
  handleInsertConfig,
}: Props) => {
  const {
    message,
    setMessage,
    link,
    setLink,
    remainingChars,
    onRemoveVariable,
    onAddVariable,
    maxLengthInputMessage,
    infoMessage,
  } = useMessage();
  const [subject, setSubject] = useState('');
  const [logo, setLogo] = useState(initialEmailConfig.logo);
  const {
    isLinkDialogOpen,
    handleCloseDialog,
    validateIfThereAreInvalidLinks,
  } = useLinkValidator({ message, setMessage });

  const finalMessage = useMemo(() => {
    if (link.url) return replaceVariableForValue(message, LINK_KEY, link.url);
    return message;
  }, [link, message]);

  const newEmailConfig: IEmailConfig = {
    message: finalMessage,
    subject,
    logo,
  };

  const emailConfigPreview = {
    ...newEmailConfig,
    logoUrl: getEmailLogoUrl(logo?.idFileS3),
  };

  const validateBillet = () => {
    if (
      showBilletVariable &&
      !isVariableInMessage({
        message,
        variableKey: LINK_BILLET_KEY,
      }) &&
      !isVariableInMessage({
        message,
        variableKey: CODE_BILLE_KEY,
      })
    ) {
      AUNotifier.error(
        //TODO: Quando houver código do boleto no email adicionar a label no texto abaixo
        `É obrigatório selecionar a variável "${defaultMessageVariables[LINK_BILLET_KEY].label}" para esse modelo de mensagem`
      );
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const isLinkOk = await validateIfThereAreInvalidLinks();
    const isBilletOk = validateBillet();
    if (!isLinkOk || !isBilletOk) return;
    handleInsertConfig(typeMessage as TYPE_MESSAGE_ENUM, newEmailConfig);
    handleClose();
  };

  const isValidToCloseWithoutWarn = () => {
    if (emailConfig)
      return _isEqual(
        emailConfigWithoutSender(newEmailConfig),
        emailConfigWithoutSender(emailConfig)
      );
    return _isEqual(
      emailConfigWithoutSender(newEmailConfig),
      emailConfigWithoutSender(initialEmailConfig)
    );
  };

  const isSubmitDisabled = () =>
    isValidToCloseWithoutWarn() ||
    isEmptyString(message) ||
    isEmptyString(subject);

  const messageVariablesMap = [
    <LinkVariable
      key={'Link'}
      handleRemoveVariable={onRemoveVariable}
      handleAddVariable={onAddVariable}
      message={message}
      remainingChars={remainingChars}
      link={link}
      setLink={setLink}
    />,
    ...Object.keys(defaultMessageVariables).map((key) => {
      const { label, length } = defaultMessageVariables[key];
      if (
        (key === LINK_BILLET_KEY && !showBilletVariable) ||
        key === CODE_BILLE_KEY //TODO: Ajustar validação quando for exibir a variável de Código do Boleto
      )
        return <></>;
      return (
        <Variable
          key={key}
          label={label}
          handleAddVariable={(cursorPosition) =>
            onAddVariable(key, length, cursorPosition)
          }
          handleRemoveVariable={() => onRemoveVariable(key)}
          isUsed={isVariableInMessage({
            message,
            variableKey: key,
          })}
        />
      );
    }),
  ];

  useEffect(() => {
    const setNewEmailConfig = (emailConfig: IEmailConfig) => {
      const emailMessage = emailConfig.message
        ? removeSenderFromMessage(emailConfig.message)
        : '';
      const emailSubject = emailConfig.subject || '';
      setMessage(emailMessage);
      setSubject(emailSubject);
      setLogo(emailConfig.logo);
    };

    if (emailConfig) {
      setNewEmailConfig(emailConfig);
    } else {
      setNewEmailConfig(initialEmailConfig);
    }
  }, [emailConfig, setMessage]);

  return (
    <DefaultDrawer
      open={open}
      onClose={handleClose}
      title={`Mensagem ${typeMessage ? TYPE_MESSAGE_LABEL[typeMessage] : ''}`}
      isValidToCloseWithoutWarn={isValidToCloseWithoutWarn}
      isSubmitDisabled={isSubmitDisabled()}
      handleSubmit={handleSubmit}
      submitButtonText="INSERIR"
    >
      <Box>
        <Title title="Assunto do e-mail" description="Digite um assunto" />

        <AUInputWithRemainingChars
          placeholder={EMAIL_PLACEHOLDERS.SUBJECT}
          value={subject}
          chars={subject.length}
          onChange={(evt) => setSubject(evt.target.value)}
          maxChars={40}
          id="input-subject"
        />
      </Box>

      <Box>
        <Title title="Escrever mensagem" description="Digite uma mensagem" />

        <MessageField
          message={message}
          setMessage={setMessage}
          messageVariables={messageVariablesMap}
          infoMessage={infoMessage}
          maxLength={maxLengthInputMessage}
          logo={<Logo logo={logo} setLogo={setLogo} />}
        />
      </Box>

      <Box marginTop={1.5}>
        <PreviewEmail emailConfigPreview={emailConfigPreview} />
      </Box>

      <LinkValidatorDialog
        isOpen={isLinkDialogOpen}
        handleClose={handleCloseDialog}
      />
    </DefaultDrawer>
  );
};
export default WriteEmailDrawer;
