import _deburr from 'lodash/deburr';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { AUEmptyData } from '@assertiva/assertiva-ui';

import SearchAdornment from '@src/components/SearchAdornment';
import { useState } from 'react';

const filterOptions = <T, K extends keyof T>(
  options: T[],
  { inputValue },
  filterBy?: K[]
) => {
  if (typeof options[0] === 'object') {
    if (filterBy) {
      return options.filter((option) =>
        filterBy.some((key) =>
          _deburr(
            (option[key] as any)?.toString()?.toLowerCase()?.trim()
          )?.includes(_deburr(inputValue?.toLowerCase()?.trim()))
        )
      );
    }
    return options.filter((option) =>
      Object.values(option as object).some((value) =>
        value?.toString()?.toLowerCase()?.includes(inputValue?.toLowerCase())
      )
    );
  }
  return options.filter((option: any) =>
    option?.toString()?.toLowerCase()?.includes(inputValue?.toLowerCase())
  );
};

interface Props<T, K extends keyof T> {
  optionsList: T[];
  selectedItem?: T;
  placeholder?: string;
  renderOption?: (option: T) => React.JSX.Element;
  getOptionLabel?: (option: T) => string;
  filterBy?: K[];
  noOptionsText: string;
  onChange?: (option: T | null) => void;
}

export const AutocompleteCheckbox: <T>(
  p: Props<T, keyof T>
) => React.ReactElement<Props<T, keyof T>> = ({
  optionsList,
  selectedItem,
  placeholder = 'Pesquisar',
  renderOption,
  getOptionLabel,
  noOptionsText,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('');
  return (
    <>
      <Grid item>
        <Autocomplete
          data-testid="autocomplete-models"
          disableListWrap
          disablePortal
          options={optionsList}
          filterOptions={filterOptions}
          getOptionLabel={getOptionLabel}
          inputValue={inputValue}
          onInputChange={(evt: any) => setInputValue(evt.target.value || '')}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: <SearchAdornment />,
                style: { paddingRight: 10 },
              }}
              variant="outlined"
            />
          )}
          onChange={(evt, option, reason) => {
            if (reason === 'select-option') {
              setInputValue((inputValue) => inputValue || '');
              onChange?.(option);
            }
          }}
          openOnFocus
          forcePopupIcon={false}
          closeIcon={<span />}
          onClose={() => setInputValue('')}
          renderOption={renderOption}
          noOptionsText={
            <AUEmptyData
              subtitle={noOptionsText}
              spacing={1}
              boxProps={{ pt: 2, pb: 3 }}
            />
          }
          disableCloseOnSelect
          value={selectedItem || null}
          ListboxProps={{
            style: {
              maxHeight: '32vh',
              listStyle: 'none',
              margin: 0,
              padding: '8px 0',
              overflow: 'auto',
            },
          }}
          id="input-pesquisar"
        />
      </Grid>
    </>
  );
};
