import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTrackConfigContext } from '../context';
import { State } from '../types';

import { StyledRulesContainer } from './TriggerRules.styles';
import BillingExpiration from './components/BillingExpiration';
import ExpirationDayForm from './components/ExpirationDayForm';
import PreventiveForm from './components/PreventiveForm';
import ReactiveForm from './components/ReactiveForm';

import { sendAnalytics } from '@assertiva/assertiva-ui';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useGlobalDrawersContext } from '@src/contexts/GlobalDrawersContext';
import Title from '@src/features/trackConfig/components/Title';
import Alert24Hours from './components/Alert24Hours';

interface Props {
  handleCloseDrawer: () => void;
}

const TriggerRules = ({ handleCloseDrawer }: Props) => {
  const methods = useForm();
  const { goNext, state } = useTrackConfigContext();
  const {
    setCanCloseDrawerWithoutWarn,
    state: { canCloseDrawerWithoutWarn },
  } = useGlobalDrawersContext();

  const { handleSubmit, formState } = methods;

  const onSubmitForm = (data) => {
    const formValues: Partial<State> = {
      preventiveOptions: {
        ...state.preventiveOptions,
        options: [...data.preventiveOptions],
      },
      expirationDay: { ...state.expirationDay, ...data.expirationDay },
      reactiveOption: {
        ...state.reactiveOption,
        ...data.reactiveOption,
        idBillingExpirationOption: data.billingExpiration,
      },
    };
    sendAnalytics({
      page: 'configurar-cobranca-drawer-configuracao',
      event: 'salvar',
    });
    goNext(formValues);
  };

  useEffect(() => {
    if (canCloseDrawerWithoutWarn) {
      if (!isEmpty(formState.touchedFields))
        setCanCloseDrawerWithoutWarn(false);
    }
  }, [formState, canCloseDrawerWithoutWarn, setCanCloseDrawerWithoutWarn]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Title
              title="Definição das regras de acionamento"
              description="Em regras de acionamento você irá definir prazos e dias específicos para realizar a cobrança do seu cliente, em qualquer etapa da esteira"
            />
          </Grid>
          <Grid item>
            <Box width="100%" marginTop={-2} marginBottom={2}>
              <Alert24Hours />
            </Box>
          </Grid>
          <StyledRulesContainer item>
            <PreventiveForm />
          </StyledRulesContainer>
          <StyledRulesContainer item>
            <ExpirationDayForm />
          </StyledRulesContainer>
          <StyledRulesContainer item>
            <ReactiveForm />
          </StyledRulesContainer>
          <StyledRulesContainer item>
            <BillingExpiration />
          </StyledRulesContainer>
          <Grid container item justify="flex-end" spacing={3}>
            <Grid item>
              <Button
                variant="text"
                color="primary"
                id="btn-cancelar"
                data-testid="btn-cancelar"
                onClick={handleCloseDrawer}
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                id="btn-proximo"
                data-testid="btn-proximo"
                type="submit"
              >
                PRÓXIMO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default TriggerRules;
