import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';

export const StyledWelcomBox = styled(Box)({
  padding: 2,
});

export const StyledPresentationText = styled(Typography)({
  color: '#828282',
  '& > span': {
    fontWeight: 'bold',
  },
});
