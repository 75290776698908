import { Controller, useFormContext } from 'react-hook-form';
import { TYPE_MESSAGE_ENUM } from '../../types';
import Checkbox from '@material-ui/core/Checkbox';
import comparators from '@src/constants/comparators';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { resolveHasPermission } from '../../utils';

const resolveTooltipText = (
  value: boolean,
  label = 'SMS',
  hasPermission: boolean
) => {
  if (!hasPermission)
    return `Você não possui permissão para configurar ${label}.`;

  return value
    ? `Ao desmarcar o campo não será disparado ${label} para essa configuração.`
    : `Ao marcar o campo será disparado ${label} para essa configuração.`;
};

interface Props {
  rule: TYPE_MESSAGE_ENUM;
  index?: number;
}

const ChannelCheckboxes = ({ rule, index }: Props) => {
  const { control } = useFormContext();

  const labels = comparators.LABEL_SENDS_CHANNEL_OPTIONS;
  const names = comparators.NAME_SENDS_CHANNEL_OPTIONS;
  const permissions = comparators.PERMISSION_SENDS_CHANNEL_OPTIONS;
  const channelsKeys = Object.keys(names);

  const resolveName = (key: string) =>
    typeof index === 'number'
      ? `${rule}.${index}.is${names[key]}`
      : `${rule}.is${names[key]}`;

  const returnCheckboxes = () => {
    return channelsKeys.map((key) => {
      const hasPermission = resolveHasPermission(permissions[key]);

      return (
        <Controller
          key={key}
          name={resolveName(key) as ''}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            // TODO: Quando entrar outro canal repensar texto da tooltip
            <Tooltip
              title={resolveTooltipText(
                field.value,
                labels[key],
                hasPermission
              )}
              arrow
            >
              <div>
                <FormControlLabel
                  checked={Boolean(field.value)}
                  {...field}
                  control={
                    <Checkbox
                      color="primary"
                      inputProps={{ 'data-testid': resolveName(key) } as any}
                    />
                  }
                  label={labels[key]}
                  disabled={!hasPermission}
                />
              </div>
            </Tooltip>
          )}
        />
      );
    });
  };

  return <>{returnCheckboxes()}</>;
};

export default ChannelCheckboxes;
