import {
  AULoadingManager,
  AUNotifier,
  ErrorUtils,
  // AUPurposeService,
  // PRODUCT_IDS,
  sendAnalytics,
} from '@assertiva/assertiva-ui';
import { getQueryParams } from '@src/components/filters';
import { useGlobalDrawersContext } from '@src/contexts/GlobalDrawersContext';
import ConfigService from '@src/services/ConfigService';
import { useAppDispatch } from '@src/store/hooks';
import {
  getDiffFromNowInMinutes,
  objectsAreEqual,
} from '@src/utils/functionUtils';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isValidNbaGroup, saveSelectedWallet } from '../wallet/utils';
import {
  selectGlobalWallet,
  selectGlobalWalletDeependenciesStatus,
  selectNBAGroups,
  updateGlobalWallet,
  updateGlobalWalletStatus,
} from '../wallet/walletSlice';
import { initialState } from './constants';
import {
  DefineMessagesTabs,
  IEmailConfig,
  ISMSConfig,
  PossibleConfigs,
  State,
  Step,
  TYPE_MESSAGE_ENUM,
} from './types';
import { createHour, getOptionsData, getSubmitPayload } from './utils';

export function useTrackConfig(defaultInitialState?: State) {
  const finalInitialState = defaultInitialState || initialState;
  const [state, setState] = useState(finalInitialState);
  const [openedTime, setOpenedTime] = useState<any>(null);
  const storeDispatch = useAppDispatch();

  const nbaGroups = useSelector(selectNBAGroups);

  const { idGroupNba } = getQueryParams();

  const handleIdGroupNba: number = useMemo(() => {
    if (!isValidNbaGroup(Number(idGroupNba), nbaGroups)) {
      return nbaGroups?.[0]?.id;
    }

    return idGroupNba;
  }, [idGroupNba, nbaGroups]);

  const {
    onCloseDrawer,
    handleState: handleStateDrawer,
    state: { visibleDrawers, canCloseDrawerWithoutWarn },
    setCanCloseDrawerWithoutWarn,
  } = useGlobalDrawersContext();

  const isDrawerOpen = visibleDrawers.trackConfig;

  const updatedWallet = useSelector(selectGlobalWalletDeependenciesStatus);
  const selectedWallet = useSelector(selectGlobalWallet);

  const handleState = useCallback(
    (changes: Partial<State>) =>
      setState((state) => ({ ...state, ...changes })),
    []
  );

  const optionsData = useMemo(
    () =>
      getOptionsData({
        preventiveOptions: state.preventiveOptions,
        expirationDay: state.expirationDay,
        reactiveOption: state.reactiveOption,
      }),
    [state.preventiveOptions, state.expirationDay, state.reactiveOption]
  );

  const goBack = useCallback(
    () => handleState({ step: Step.triggerRules }),
    [handleState]
  );

  const goNext = useCallback(
    (formValues: Partial<State>) => {
      handleState({
        ...formValues,
        step: Step.defineMessages,
        defineMessagesTab: DefineMessagesTabs.Sms,
      });
    },
    [handleState]
  );

  const handleInsertConfig = (
    typeMessage: TYPE_MESSAGE_ENUM,
    configKey: PossibleConfigs,
    config: ISMSConfig | IEmailConfig
  ) => {
    handleState({
      [typeMessage]: {
        ...state[typeMessage],
        [configKey]: config,
      },
    });
    if (canCloseDrawerWithoutWarn) {
      setCanCloseDrawerWithoutWarn(false);
    }
  };

  const handleCloseDrawer = (
    { closeAfterSubmit } = { closeAfterSubmit: false }
  ) => {
    if (!closeAfterSubmit && state.initialSelectedWallet) {
      storeDispatch(updateGlobalWallet(state.initialSelectedWallet));
      saveSelectedWallet(state.initialSelectedWallet);
    }

    onCloseDrawer('trackConfig');

    const resetState = () => {
      if (state.changedWallet && closeAfterSubmit) {
        storeDispatch(updateGlobalWalletStatus(true));
      }
      setState(initialState);
    };

    setTimeout(resetState, 500);
  };

  const handleSubmit = async () => {
    try {
      AULoadingManager.show();
      const payload = getSubmitPayload(state, handleIdGroupNba);
      await ConfigService.putTrackConfig(payload);
      AUNotifier.success(
        'Configuração da esteira de cobrança concluída com sucesso!'
      );
      sendAnalytics({
        page: 'configurar-cobranca',
        event: 'salvar-tempo',
        value: getDiffFromNowInMinutes(openedTime),
      });
      handleCloseDrawer({ closeAfterSubmit: true });
      handleStateDrawer('successTrackConfig', true);
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      AULoadingManager.close();
    }
  };

  const getTrackConfig = useCallback(async () => {
    try {
      AULoadingManager.show();

      const requestSettings = async () => {
        const settings = await ConfigService.getTrackConfig(handleIdGroupNba);
        return settings.data.body;
      };
      const requestPreventiveLabels = async () => {
        const labels = await ConfigService.getPreventiveOptions();
        return labels.data.body.list;
      };
      const requestReactiveLabels = async () => {
        const labels = await ConfigService.getReactiveOptions();
        return labels.data.body.list;
      };
      const requestBillingExpirationLabels = async () => {
        const labels = await ConfigService.getBillingExpirationOptions();
        return labels.data.body.list;
      };
      // TODO: voltar quando tiver finalidade de uso
      // const requestPurposesOfUse = async () => {
      //   const purposes = await AUPurposeService.getPurposes(PRODUCT_IDS.SMS);
      //   return purposes.data;
      // };
      const requestHasBillet = async () => {
        const hasBillet = await ConfigService.getActivePaymentMethod();
        return hasBillet.data.body.hasActivePaymentMethod;
      };

      const [
        settings,
        preventiveLabels,
        reactiveLabels,
        billingExpirationLabels,
        // purposes,
        hasBillet,
      ] = await Promise.all([
        requestSettings(),
        requestPreventiveLabels(),
        requestReactiveLabels(),
        requestBillingExpirationLabels(),
        // requestPurposesOfUse(),
        requestHasBillet(),
      ]);

      handleState({
        ...finalInitialState,
        ...settings,
        preventiveOptions: {
          ...settings.preventiveOptions,
          options: settings.preventiveOptions.options.map((option) => ({
            ...option,
            hour: createHour(option.hour),
          })),
        },
        expirationDay: {
          ...settings.expirationDay,
          hour: createHour(settings.expirationDay.hour),
        },
        reactiveOption: {
          ...settings.reactiveOption,
          hour: createHour(settings.reactiveOption.hour),
        },
        selectLabels: {
          preventive: preventiveLabels,
          reactive: reactiveLabels,
          billingExpirations: billingExpirationLabels,
        },
        // purposes,
        hasBillet,
      });
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      AULoadingManager.close();
    }
  }, [handleState, handleIdGroupNba, finalInitialState]);

  const reloadTrackConfig = useCallback(async () => {
    try {
      AULoadingManager.show();

      const requestSettings = async () => {
        const settings = await ConfigService.getTrackConfig(handleIdGroupNba);
        return settings.data.body;
      };

      const settings = await requestSettings();

      handleState({
        ...settings,
        preventiveOptions: {
          options: settings.preventiveOptions.options.map((option) => ({
            ...option,
            hour: createHour(option.hour),
          })),
          smsConfig: settings.preventiveOptions.smsConfig,
          emailConfig: settings.preventiveOptions.emailConfig,
        },
        expirationDay: {
          ...settings.expirationDay,
          hour: createHour(settings.expirationDay.hour),
        },
        reactiveOption: {
          ...settings.reactiveOption,
          hour: createHour(settings.reactiveOption.hour),
        },
        changedWallet: true,
      });
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      AULoadingManager.close();
      storeDispatch(updateGlobalWalletStatus(false));
    }
  }, [handleIdGroupNba, handleState, storeDispatch]);

  useEffect(() => {
    if (isDrawerOpen) {
      getTrackConfig();
      setCanCloseDrawerWithoutWarn(true);
    }
  }, [isDrawerOpen, handleState, setCanCloseDrawerWithoutWarn, getTrackConfig]);

  useEffect(() => {
    if (updatedWallet && isDrawerOpen) {
      reloadTrackConfig();
      setCanCloseDrawerWithoutWarn(false);
    }
  }, [
    updatedWallet,
    isDrawerOpen,
    reloadTrackConfig,
    setCanCloseDrawerWithoutWarn,
  ]);

  useEffect(() => {
    if (
      state.purposes.length &&
      !objectsAreEqual(state.purposeOfUse, initialState.purposeOfUse)
    ) {
      const hasPreSelectedPurposeInList = state.purposes.some(
        ({ id, text, description }) =>
          state.purposeOfUse.purposeText === text &&
          state.purposeOfUse.purposeDescription === description &&
          state.purposeOfUse.backofficeId === id
      );

      if (!hasPreSelectedPurposeInList) {
        handleState({ purposeOfUse: initialState.purposeOfUse });
      }
    }
  }, [state.purposes, state.purposeOfUse, handleState]);

  useEffect(() => {
    if (isDrawerOpen) {
      setOpenedTime(moment());
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    if (isDrawerOpen && selectedWallet && !state.initialSelectedWallet) {
      handleState({ initialSelectedWallet: selectedWallet });
    }
  }, [isDrawerOpen, selectedWallet, state.initialSelectedWallet, handleState]);

  return {
    state,
    handleState,
    goBack,
    goNext,
    handleInsertConfig,
    handleSubmit,
    isDrawerOpen,
    handleCloseDrawer,
    canCloseDrawerWithoutWarn,
    optionsData,
  };
}
