import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import URL_IMAGES from '@constants/images';
import { StyledImage, StyledUploadLogoContent } from './UploadLogo.styles';

interface Props {
  handleClickUpload: () => void;
  helperText: JSX.Element | string;
}

const UploadLogoCard = ({ handleClickUpload, helperText }: Props) => {
  return (
    <StyledUploadLogoContent onClick={handleClickUpload}>
      <StyledImage
        src={URL_IMAGES.UPLOAD_ICON}
        width={60}
        height={41}
        alt="Ícone de upload"
      />
      <Typography variant="body2" color="textSecondary" align="center">
        {helperText}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={(evt) => {
          evt.stopPropagation();
          handleClickUpload();
        }}
        style={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        ADICIONAR LOGO
      </Button>
    </StyledUploadLogoContent>
  );
};

export default UploadLogoCard;
