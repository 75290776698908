import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

type StyledRulesFieldsProps = {
  width?: string;
};

export const StyledRulesFields = styled(({ width, ...rest }) => (
  <Box {...rest} />
))(({ width = '100%' }: StyledRulesFieldsProps) => {
  return {
    '& .MuiFormControl-marginDense': {
      marginTop: 0,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0,
    },
    width,
  };
});
