import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Controller } from 'react-hook-form';
import { StepThreeFormValues } from '../../../types';
import { AUInputWithRemainingChars } from '@assertiva/assertiva-ui';

interface FormFieldProps {
  title: string;
  name: keyof StepThreeFormValues;
  control: any;
  errors?: any;
  placeholder?: string;
  rules?: any;
  watch?: any;
  maxChars?: number;
  render?: any;
}

const FormField = ({
  title,
  name,
  control,
  errors,
  placeholder,
  rules,
  watch,
  maxChars,
  render,
}: FormFieldProps) => (
  <Grid item xs={12}>
    <Box marginBottom={1}>
      <Typography variant="subtitle1">{title}</Typography>
    </Box>
    <Controller
      name={name}
      control={control}
      render={
        render ||
        (({ field: { ref, ...rest } }) => (
          <AUInputWithRemainingChars
            {...rest}
            chars={watch(name)?.length || 0}
            inputRef={ref}
            inputProps={{ 'data-testid': 'input-phone' }}
            fullWidth
            maxChars={maxChars as number}
            error={Boolean(errors?.[name])}
            helperText={errors?.[name]?.message}
            placeholder={placeholder}
            id="input-phone"
          />
        ))
      }
      rules={rules}
    />
  </Grid>
);

export default FormField;
