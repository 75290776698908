import { GetResponse } from '@src/constants/types';
import BaseService from './BaseService';
import {
  IMonetaryRestatementConfig,
  IMonetaryRestatementIndex,
  IMonetaryRestatementLastYear,
  IMonetaryRestatementPeriod,
} from '@src/features/settings/tabs/financial/types';

class ConfigService extends BaseService {
  // TRACK CONFIG ENDPOINTS
  getTrackConfig(idGroupNba: number | null) {
    return this.axiosInstance.get(`${this.BASE_URL}/send-config`, {
      params: { idGroupNba },
    });
  }

  putTrackConfig(params) {
    return this.axiosInstance.put(`${this.BASE_URL}/send-config`, params);
  }

  getPreventiveOptions() {
    return this.axiosInstance.get(
      `${this.BASE_URL}/send-config/preventive-options`
    );
  }

  getReactiveOptions() {
    return this.axiosInstance.get(
      `${this.BASE_URL}/send-config/reactive-options`
    );
  }

  getBillingExpirationOptions() {
    return this.axiosInstance.get(
      `${this.BASE_URL}/send-config/billing-expiration-options`
    );
  }

  getContactStatus() {
    return this.axiosInstance.get(`${this.BASE_URL}/config/contact/status`);
  }

  postEmailLogo(params) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/file/logo/email/upload-url`,
      params
    );
  }

  getContactStatusEmail() {
    return this.axiosInstance.get(
      `${this.BASE_URL}/config/contact/status/email`
    );
  }

  // BLACKLIST ENDPOINTS
  getBlocklist(params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/config/blacklists`, {
      params,
      cancelToken: this.cancelTokenSource.token,
    });
  }

  getBlocklistReasons() {
    return this.axiosInstance.get(`${this.BASE_URL}/config/blacklists/reasons`);
  }

  postBlocklist(params) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/config/blacklists`,
      params
    );
  }

  patchBlacklit(id, params) {
    return this.axiosInstance.patch(
      `${this.BASE_URL}/config/blacklists/${id}`,
      params
    );
  }

  // BAD-CREDIT ENDPOINTS
  getSettingsNegative() {
    return this.axiosInstance.get(`${this.BASE_URL}/config/negative`);
  }

  getBadCreditDocCreditor() {
    return this.axiosInstance.get(
      `${this.BASE_URL}/negative/comunication/model/1`
    );
  }

  putBadCreditConfig(params?) {
    return this.axiosInstance.put(`${this.BASE_URL}/config/negative`, params);
  }
  // EVENTS ENDPOINTS
  getEvents(params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/config/events`, {
      params,
    });
  }

  getAllEvents() {
    return this.axiosInstance.get(`${this.BASE_URL}/config/all-events`);
  }

  patchEvent(id, params?) {
    return this.axiosInstance.patch(
      `${this.BASE_URL}/config/events/${id}`,
      params
    );
  }

  postEvent(params?) {
    return this.axiosInstance.post(`${this.BASE_URL}/config/events`, params);
  }

  // FINANCIAL ENDPOINTS
  getDebtsFinancial() {
    return this.axiosInstance.get(`${this.BASE_URL}/config/financial/debts`);
  }

  putDebtsFinancial(params) {
    return this.axiosInstance.put(
      `${this.BASE_URL}/config/financial/debts`,
      params
    );
  }

  getAgreementFinancial() {
    return this.axiosInstance.get(
      `${this.BASE_URL}/config/financial/agreement`
    );
  }

  putAgreementFinancial(params) {
    return this.axiosInstance.put(
      `${this.BASE_URL}/config/financial/agreement`,
      params
    );
  }

  getPaymentAccounts(params) {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/paymentmethod/all`,
      {
        params,
      }
    );
  }

  getPaymentAccountsDefault(params) {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/paymentmethod/all-default`,
      {
        params,
      }
    );
  }

  getPaymentAccountDetails(id) {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/paymentmethod/${id}`
    );
  }

  getAccountsStatus() {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/general/paymentMethodStatus`
    );
  }

  patchPaymentAccount(id, params) {
    return this.axiosInstance.patch(
      `${this.BASE_PAYMENTS_URL}/paymentmethod/${id}`,
      params
    );
  }

  postUploadSocialContractPaymentAccount(params) {
    return this.axiosInstance.post(
      `${this.BASE_PAYMENTS_URL}/paymentmethod/social-contract/upload-url`,
      params
    );
  }

  getDownloadSocialContractPaymentAccount(idFileS3: string) {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/paymentmethod/social-contract/${idFileS3}`
    );
  }

  getTestBillet(idPaymentMethod: string) {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/paymentbillet/test/${idPaymentMethod}`
    );
  }

  postPaymentAccount(params) {
    return this.axiosInstance.post(
      `${this.BASE_PAYMENTS_URL}/paymentmethod`,
      params
    );
  }

  // Termos de uso para criação de contas de recebimento
  getAcceptedTermsOfUse() {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/general/terms-of-use/accepted`
    );
  }

  getTermsOfUse() {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/general/terms-of-use`
    );
  }

  postAcceptedTermsOfUse(params) {
    return this.axiosInstance.post(
      `${this.BASE_PAYMENTS_URL}/general/terms-of-use`,
      params
    );
  }

  getBanks() {
    return this.axiosInstance.get(`${this.BASE_PAYMENTS_URL}/general/banks`);
  }

  postUploadUrlLogo = (params) => {
    return this.axiosInstance.post(
      `${this.BASE_PAYMENTS_URL}/paymentmethod/logo/upload-url`,
      params
    );
  };

  getLogoUrl = (idFileS3) =>
    `${this.BASE_PAYMENTS_URL}/paymentmethod/logo-external/${idFileS3}`;

  getActivePaymentMethod() {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/paymentmethod/has-active-payment-method`
    );
  }

  getBillet(idDebt) {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/paymentbillet/${idDebt}`
    );
  }

  getBilletStatus() {
    return this.axiosInstance.get(
      `${this.BASE_PAYMENTS_URL}/general/billetStatus`
    );
  }

  postBillet(params) {
    return this.axiosInstance.post(
      `${this.BASE_PAYMENTS_URL}/paymentbillet`,
      params
    );
  }

  deleteBillet(debtId) {
    return this.axiosInstance.delete(
      `${this.BASE_PAYMENTS_URL}/paymentbillet/debt/${debtId}`
    );
  }

  postTestBillet(params) {
    return this.axiosInstance.post(
      `${this.BASE_PAYMENTS_URL}/paymentbillet/test`,
      params
    );
  }

  // MONETARY RESTATEMENT ENDPOINTS
  getMonetaryRestatementConfig() {
    return this.axiosInstance.get<GetResponse<IMonetaryRestatementConfig>>(
      `${this.BASE_URL}/monetary-restatement`
    );
  }

  getMonetaryRestatementConfigByIdDebt(idDebt) {
    return this.axiosInstance.get<GetResponse<IMonetaryRestatementConfig>>(
      `${this.BASE_URL}/monetary-restatement/debt/${idDebt}`
    );
  }

  putMonetaryRestatementConfig(params: IMonetaryRestatementConfig) {
    return this.axiosInstance.put(
      `${this.BASE_URL}/monetary-restatement`,
      params
    );
  }

  getMonetaryRestatementIndexes() {
    return this.axiosInstance.get<GetResponse<IMonetaryRestatementIndex[]>>(
      `${this.BASE_URL}/monetary-restatement/indexes`
    );
  }

  getMonetaryRestatementPeriods(idIndex: number) {
    return this.axiosInstance.get<GetResponse<IMonetaryRestatementPeriod[]>>(
      `${this.BASE_URL}/monetary-restatement/periods/${idIndex}`
    );
  }

  getMonetaryRestatementLastYear(idIndex: number) {
    return this.axiosInstance.get<GetResponse<IMonetaryRestatementLastYear[]>>(
      `${this.BASE_URL}/monetary-restatement/last-year/${idIndex}`
    );
  }

  // ALERTS ENDPOINTS
  getAlerts() {
    return this.axiosInstance.get(`${this.BASE_URL}/config/alerts`);
  }

  // MODELS ENDPOINTS
  getModels(params) {
    return this.axiosInstance.get(`${this.BASE_URL}/config/models`, { params });
  }

  getAllModels() {
    return this.axiosInstance.get(`${this.BASE_URL}/config/all-models`);
  }

  postModels(params) {
    return this.axiosInstance.post(`${this.BASE_URL}/config/models`, params);
  }

  patchModels(id, params) {
    return this.axiosInstance.patch(
      `${this.BASE_URL}/config/models/${id}`,
      params
    );
  }

  // USERS ENDPOINTS

  getActionsLog(params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/config/audit/logs`, {
      params,
    });
  }

  getUsers(params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/config/users`, {
      params,
    });
  }

  getSummary() {
    return this.axiosInstance.get(`${this.BASE_URL}/config/users/summary`);
  }

  getUsersActive() {
    return this.axiosInstance.get(`${this.BASE_URL}/config/users/active`);
  }

  getUsersSummarizer() {
    return this.axiosInstance.get(`${this.BASE_URL}/config/users/active`);
  }

  getUserHistory(idUser, params?) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/config/users/${idUser}/history`,
      {
        params,
      }
    );
  }

  patchUser(idUser, params) {
    return this.axiosInstance.patch(
      `${this.BASE_URL}/config/users/${idUser}`,
      params
    );
  }
}

export default new ConfigService();
