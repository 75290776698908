import React, { useState, createContext, useCallback } from 'react';

interface Drawers {
  newDebts: boolean;
  trackConfig: boolean;
}

interface State {
  visibleDrawers: Drawers;
  canCloseDrawerWithoutWarn: boolean;
  successNewDebt: boolean;
  successTrackConfig: boolean;
  batchToggleDebt?: boolean;
  openedTime: any;
  lastAddedDebt?: Record<string, any>;
}

const GlobalDrawersContext = createContext<
  ReturnType<typeof useGlobalDrawers> | undefined
>(undefined);

function useGlobalDrawers(initialState: State) {
  const [state, setState] = useState<State>(initialState);

  const handleState = useCallback((key: keyof State, value: any) => {
    setState((prev) => ({ ...prev, [key]: value }));
  }, []);

  const setCanCloseDrawerWithoutWarn = useCallback(
    (canClose: boolean) => {
      handleState('canCloseDrawerWithoutWarn', canClose);
    },
    [handleState]
  );

  const onCloseDrawer = (drawer: keyof Drawers) => {
    handleState('visibleDrawers', { ...state.visibleDrawers, [drawer]: false });

    setCanCloseDrawerWithoutWarn(true);
  };

  const onOpenDrawer = (drawer: keyof Drawers) =>
    handleState('visibleDrawers', { ...state.visibleDrawers, [drawer]: true });

  return {
    state,
    onCloseDrawer,
    onOpenDrawer,
    setCanCloseDrawerWithoutWarn,
    handleState,
  };
}

interface Props {
  defaultInitialState?: State;
}

export const initialState: State = {
  visibleDrawers: {
    newDebts: false,
    trackConfig: false,
  },
  canCloseDrawerWithoutWarn: true,
  successNewDebt: false,
  batchToggleDebt: false,
  successTrackConfig: false,
  openedTime: null,
  lastAddedDebt: undefined,
};

const GlobalDrawersProvider: React.FC<Props> = ({
  children,
  defaultInitialState = initialState,
}) => {
  const value = useGlobalDrawers(defaultInitialState);

  return (
    <GlobalDrawersContext.Provider value={value}>
      {children}
    </GlobalDrawersContext.Provider>
  );
};

function useGlobalDrawersContext() {
  const context = React.useContext(GlobalDrawersContext);
  if (context === undefined) {
    throw new Error(
      'useGlobalDrawersContext must be used within a GlobalDrawersContext'
    );
  }
  return context;
}

export { GlobalDrawersProvider, useGlobalDrawersContext };
