import { Pagination } from '@src/constants/types';

export interface WalletSelect {
  id: number;
  disabled: boolean;
  name: string;
}

export interface WalletUsers {
  id: number;
  name: string;
  email: string;
  active?: boolean;
}

export interface Wallet {
  id: number;
  name: string;
  default: boolean;
  active: boolean;
  users: WalletUsers[];
  idStrategy?: number | null;
}

export interface WalletList {
  list: Wallet[];
  madeInitialFetch: boolean;
  pagination: Pagination;
}

export interface WalletDrawer {
  isOpen: boolean;
  idSeletedWallet?: number;
  idPaymentMethod?: string;
  hasPaymentMethods: boolean;
  madeInitialFetchPayments: boolean;
}

export enum NBAStatus {
  Created,
  SentToQueue,
  Processing,
  Success,
  Error,
}

export interface NBAGroupType {
  id: number;
  name: string;
}

export interface NBAGroup {
  name: string;
  description: string;
  id: number;
  order: number;
}

export interface WalletSummary {
  totalCountDebts: number;
  totalValueDebts: number;
  totalCountOpenDebts: number;
  totalValueOpenDebts: number;
  nba: {
    createdAt: string;
    groups: NBAGroup[];
  } | null;
}

export interface WalletActive {
  id: number;
  name: string;
}

export interface WalletState {
  table: WalletList;
  drawer: WalletDrawer;
  walletIdToDisable: number | null;
  globalWallet: { value: WalletSelect; shouldUpdateDependencies: boolean };
  dashboard: { walletList: WalletSelect[]; NBAGroupTypes: NBAGroupType[] };
  summary?: WalletSummary | null;
  nbaStatus?: NBAStatus | null;
  isGlobalWalletInStrategy?: boolean;
}
