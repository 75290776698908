import { useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import {
  AUPreventMissClick,
  AUDocumentField,
  AUInputWithRemainingChars,
  AURegex,
  ValidationUtils,
  AUEmptyData,
} from '@assertiva/assertiva-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useFinancialContext } from '../../../context';
import messages from '@src/constants/messages';
import { ACCOUNT_TYPE, Bank, StepOneFormValues } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextAddAccountStep,
  selectAddAccountData,
  selectBanks,
  selectInteractedWithAddAccountDrawer,
  updateAddAccountData,
} from '../../../financialSlice';
import { sendInternalAnalytics } from '@src/utils/internalAnalytics';
import { EVENTS } from '@src/constants/analytics';

const { PreventMissClickDialog, usePreventMissClick } = AUPreventMissClick;

const REGEX_BANK_AGENCY = /(^[0-9]{0,6})$|(^[0-9]{0,4}([-]\w{1})?)$/;

const filterOptions = (options: Bank[], { inputValue }) =>
  options.filter((option) =>
    Object.values(option).some((value) =>
      value?.toString()?.toLowerCase()?.includes(inputValue?.toLowerCase())
    )
  );

const StepOne = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    reset,
    watch,
    trigger,
  } = useForm<StepOneFormValues>();
  const dispatch = useDispatch();
  const { handleState } = useFinancialContext();
  const banks = useSelector(selectBanks);
  const addAccountData = useSelector(selectAddAccountData);
  const interactedWithAddAccountDrawer = useSelector(
    selectInteractedWithAddAccountDrawer
  );

  const onCloseDrawer = () => {
    handleState({ addAccountDrawerOpen: false });
  };

  const handleNextStep = (data: StepOneFormValues) => {
    sendInternalAnalytics(EVENTS.PAYMENTS.CLICK_ADD_ACCOUNT('ETAPA 1'));
    dispatch(updateAddAccountData(data));
    dispatch(nextAddAccountStep());
  };

  const formFields = watch();

  const hasNotTouchedForm = () => !interactedWithAddAccountDrawer;

  useEffect(() => {
    if (isDirty) {
      handleState({ interactedWithAddAccountDrawer: true });
    }
  }, [isDirty, handleState]);

  useEffect(() => {
    if (!_isEmpty(addAccountData)) {
      reset({
        doc: addAccountData.doc,
        companyName: addAccountData.companyName,
        tradingName: addAccountData.tradingName,
        bankAccount: addAccountData.bankAccount,
        bankAccountDigit: addAccountData.bankAccountDigit,
        bankAgency: addAccountData.bankAgency,
        bankNumber: addAccountData.bankNumber,
        bank: addAccountData.bank,
        bankAccountType: addAccountData.bankAccountType,
      });
    }
  }, [addAccountData, reset]);

  const {
    isPreventMissClickDialogOpen,
    preventMissClick,
    closeAnyway,
    keepOpen,
  } = usePreventMissClick(onCloseDrawer, hasNotTouchedForm);

  return (
    <form onSubmit={handleSubmit(handleNextStep)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box marginBottom={1}>
            <Typography variant="subtitle1">Dados bancários</Typography>
          </Box>
          <Controller
            name="doc"
            control={control}
            rules={{
              required: messages.ERRORS.REQUIRED_FIELD('CNPJ'),
              pattern: {
                value: AURegex.cnpjWithAndWithoutMask,
                message: messages.ERRORS.INVALID_FIELD('CNPJ'),
              },
              validate: (document) =>
                ValidationUtils.isValidDocument(document) ||
                messages.ERRORS.INVALID_FIELD('CNPJ'),
            }}
            render={({ field: { ref, ...rest } }) => (
              <AUDocumentField
                {...rest}
                inputRef={ref}
                fullWidth
                label="CNPJ*"
                type="CNPJ"
                helperText={errors?.doc?.message}
                error={Boolean(errors?.doc)}
                onBlurEvent={(_, maskedValue) => setValue('doc', maskedValue)}
                id="input-doc"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="companyName"
            control={control}
            rules={{
              required: messages.ERRORS.REQUIRED_FIELD('Razão social'),
              validate: (v) =>
                Boolean(v.replace(/\s/g, '')) ||
                messages.ERRORS.INVALID_FIELD('Razão social'),
            }}
            render={({ field: { ref, ...rest } }) => (
              <AUInputWithRemainingChars
                {...rest}
                inputRef={ref}
                label="Razão social*"
                chars={watch('companyName')?.length || 0}
                maxChars={80}
                helperText={errors?.companyName?.message}
                error={Boolean(errors?.companyName)}
                id="input-razao-social"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="tradingName"
            control={control}
            rules={{
              required: messages.ERRORS.REQUIRED_FIELD('Nome fantasia'),
              validate: (v) =>
                Boolean(v.replace(/\s/g, '')) ||
                messages.ERRORS.INVALID_FIELD('Nome fantasia'),
            }}
            render={({ field: { ref, ...rest } }) => (
              <AUInputWithRemainingChars
                {...rest}
                inputRef={ref}
                label="Nome fantasia*"
                chars={watch('tradingName')?.length || 0}
                maxChars={80}
                helperText={errors?.tradingName?.message}
                error={Boolean(errors?.tradingName)}
                id="input-nome-fantasia"
              />
            )}
          />
        </Grid>
        <Grid item xs={10} sm={6}>
          <Controller
            name="bankAccount"
            control={control}
            rules={{
              required: messages.ERRORS.REQUIRED_FIELD('Conta'),
              pattern: AURegex.integer,
            }}
            render={({ field: { ref, onChange, ...rest } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.includes('-')) {
                    const [bankAccount, bankAccountDigit] = value.split('-');
                    onChange(bankAccount.replace(AURegex.notNumbers, ''));
                    setValue(
                      'bankAccountDigit',
                      bankAccountDigit.replace(AURegex.notNumbers, '')
                    );
                    trigger('bankAccountDigit');
                  } else {
                    onChange(value.replace(AURegex.notNumbers, ''));
                  }
                }}
                inputRef={ref}
                inputProps={{
                  maxLength: 30,
                }}
                label="Conta*"
                fullWidth
                helperText={errors?.bankAccount?.message}
                error={Boolean(errors?.bankAccount)}
                id="conta"
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={3}>
          <Controller
            name="bankAccountDigit"
            control={control}
            defaultValue={formFields.bankAccountDigit || ''}
            rules={{
              required: messages.ERRORS.REQUIRED_FIELD('Dígito verificador'),
              maxLength: 2,
              validate: (v) =>
                Boolean(v.replace(/\s/g, '')) ||
                messages.ERRORS.INVALID_FIELD('Dígito verificador'),
            }}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                {...rest}
                inputRef={ref}
                label="Dígito verificador*"
                inputProps={{
                  maxLength: 2,
                }}
                helperText={errors?.bankAccountDigit?.message}
                error={Boolean(errors?.bankAccountDigit)}
                id="input-digito-verificador"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="bankAgency"
            control={control}
            rules={{
              required: messages.ERRORS.REQUIRED_FIELD('Agência'),
              minLength: {
                value: 2,
                message: messages.ERRORS.INVALID_FIELD('Agência'),
              },
              validate: (v) =>
                (Boolean(v.replace(/\s/g, '')) && REGEX_BANK_AGENCY.test(v)) ||
                messages.ERRORS.INVALID_FIELD('Agência'),
            }}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                {...rest}
                inputRef={ref}
                label="Agência*"
                inputProps={{
                  maxLength: 7,
                  'data-testid': 'input-agencia',
                }}
                helperText={errors?.bankAgency?.message}
                error={Boolean(errors?.bankAgency)}
                id="input-agencia"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="bank"
            control={control}
            rules={{
              required: messages.ERRORS.REQUIRED_FIELD('Banco'),
            }}
            defaultValue={formFields.bank}
            render={({ field: { ref, onChange, ...rest } }) => (
              <Autocomplete
                {...rest}
                disabled={_isEmpty(banks)}
                options={banks}
                disablePortal
                autoHighlight
                filterOptions={filterOptions}
                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                value={
                  typeof rest.value === 'string'
                    ? filterOptions(banks, { inputValue: rest.value })[0]
                    : rest.value || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={ref}
                    label="Banco*"
                    fullWidth
                    helperText={(errors?.bank as any)?.message}
                    error={Boolean(errors?.bank)}
                  />
                )}
                noOptionsText={
                  <AUEmptyData
                    subtitle="Nenhum banco encontrado."
                    spacing={1}
                    boxProps={{ pt: 2, pb: 3 }}
                  />
                }
                onChange={(_, option) => onChange(option || undefined)}
                id="autocomplete-banco"
                ListboxProps={{
                  style: {
                    maxHeight: 200,
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="bankAccountType"
            control={control}
            rules={{
              required: messages.ERRORS.REQUIRED_FIELD('Tipo de conta'),
            }}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <TextField
                {...rest}
                inputRef={ref}
                label="Tipo de conta*"
                helperText={errors?.bankAccountType?.message}
                error={Boolean(errors?.bankAccountType)}
                id="select-tipo-conta"
                select
                fullWidth
              >
                <MenuItem value="" disabled>
                  Tipo de conta*
                </MenuItem>
                {Object.keys(ACCOUNT_TYPE).map((key) => (
                  <MenuItem key={key} value={key}>
                    {ACCOUNT_TYPE[key]}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>
      <Box height={32} />
      <Grid item container justify="flex-end" spacing={3}>
        <Grid item>
          <Button
            onClick={preventMissClick}
            color="primary"
            id="btn-cancelar"
            data-testid="btn-cancel"
          >
            CANCELAR
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            id="btn-proximo-step-1"
            data-testid="btn-proximo-step-1"
            disabled={hasNotTouchedForm()}
            type="submit"
          >
            PRÓXIMO
          </Button>
        </Grid>
      </Grid>
      <PreventMissClickDialog
        open={isPreventMissClickDialogOpen}
        closeAnyway={closeAnyway}
        keepOpen={keepOpen}
      />
    </form>
  );
};

export default StepOne;
