import BaseService from './BaseService';
import {
  IGeneratePreSignedURLRequest,
  ICalculateFineFee,
} from '@constants/interfaces';

let cancelPostBatchFileDebts: any;
let cancelPostProcessBatchDebts: any;

let cancelPostBatchFilePayments: any;
let cancelPostProcessBatchPayments: any;

class DebtsService extends BaseService {
  getDebts(idStatus, params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/debt/track/${idStatus}`, {
      params,
    });
  }

  getDebtsType() {
    return this.axiosInstance.get(`${this.BASE_URL}/debt/types`);
  }

  postDebt(debt) {
    return this.axiosInstance.post(`${this.BASE_URL}/debt`, debt);
  }

  delDebt(debt) {
    return this.axiosInstance.delete(`${this.BASE_URL}/debt`, {
      data: debt,
    });
  }

  postDebtCalculateFineFee(data: ICalculateFineFee) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/debt/calculate-fine-fee`,
      data
    );
  }

  getDebtById(id: string | undefined, config?) {
    return this.axiosInstance.get(`${this.BASE_URL}/debt/${id}`, config);
  }

  patchDebt(params) {
    return this.axiosInstance.patch(
      `${this.BASE_URL}/debt/${params.id}`,
      params
    );
  }

  postAttachmentsFilesDebt(data: IGeneratePreSignedURLRequest) {
    return this.axiosInstance.post(`${this.BASE_URL}/file/upload-url`, data);
  }

  putDebtCellphones(id: string | undefined, params) {
    return this.axiosInstance.put(`${this.BASE_URL}/debt/${id}/cellphone`, {
      cellPhones: params,
    });
  }

  // Debts functions
  postBatchFileDebts(data: IGeneratePreSignedURLRequest) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/file/bulk/debts/upload-url`,
      data,
      {
        cancelToken: new this.cancelToken(function executor(c) {
          cancelPostBatchFileDebts = c;
        }),
      }
    );
  }

  cancelPostBatchFileDebts() {
    return cancelPostBatchFileDebts();
  }

  getDebtsModel(typeModel: string) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/file/bulk/debts/model/${typeModel}`
    );
  }

  postProcessBatchDebts(idS3: string) {
    return this.axiosInstance.post(`${this.BASE_URL}/file/bulk/debts/${idS3}`, {
      cancelToken: new this.cancelToken(function executor(c) {
        cancelPostProcessBatchDebts = c;
      }),
    });
  }

  getPendingProcessDebts = () => {
    return this.axiosInstance.get(
      `${this.BASE_URL}/file/bulk/debts/pending-process`
    );
  };

  getPendingProcessPayments = () => {
    return this.axiosInstance.get(
      `${this.BASE_URL}/file/bulk/payments/pending-process`
    );
  };

  getStatusBatchDebts = (idS3: string) => {
    return this.axiosInstance.get(
      `${this.BASE_URL}/file/bulk/debts/status/${idS3}`
    );
  };

  getLastProcessDebts() {
    return this.axiosInstance.get(
      `${this.BASE_URL}/file/bulk/debts/last-processed`
    );
  }

  deleteLastProcessDebts() {
    return this.axiosInstance.delete(
      `${this.BASE_URL}/file/bulk/debts/undo-last-processed`
    );
  }

  getLastProcessPayments() {
    return this.axiosInstance.get(
      `${this.BASE_URL}/file/bulk/payments/last-processed`
    );
  }

  cancelPostProcessBatchDebts() {
    return cancelPostProcessBatchDebts();
  }

  // Payments functions
  postBatchFilePayments(data: IGeneratePreSignedURLRequest) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/file/bulk/payments/upload-url`,
      data,
      {
        cancelToken: new this.cancelToken(function executor(c) {
          cancelPostBatchFilePayments = c;
        }),
      }
    );
  }

  cancelPostBatchFilePayments() {
    return cancelPostBatchFilePayments();
  }

  getPaymentsModel(typeModel: string) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/file/bulk/payments/model/${typeModel}`
    );
  }

  postProcessBatchPayments(idS3: string) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/file/bulk/payments/${idS3}`,
      {
        cancelToken: new this.cancelToken(function executor(c) {
          cancelPostProcessBatchDebts = c;
        }),
      }
    );
  }

  getStatusBatchPayments = (idS3: string) => {
    return this.axiosInstance.get(
      `${this.BASE_URL}/file/bulk/payments/status/${idS3}`
    );
  };

  cancelPostProcessBatchPayments() {
    return cancelPostProcessBatchPayments();
  }

  getFile(id) {
    return this.axiosInstance.get(`${this.BASE_URL}/file/${id}`);
  }

  getChildDebts(params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/debt/child`, {
      params,
    });
  }

  deleteParentDebt(idGeneric: string) {
    return this.axiosInstance.delete(
      `${this.BASE_URL}/debt/parent/${idGeneric}`
    );
  }

  getDebtStatus() {
    return this.axiosInstance.get(`${this.BASE_URL}/debt/status`);
  }

  getDebtDetailStatus() {
    return this.axiosInstance.get(`${this.BASE_URL}/debt/status/detail`);
  }

  getDebtSummary(params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/debt/summary`, {
      params,
    });
  }

  getDebtHistoric(idDebt, params) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debt/${idDebt}/contact/historic`,
      { params }
    );
  }

  getReportWebphoneByDebt(idDebt, params) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debt/${idDebt}/webphone/historic`,
      {
        params,
      }
    );
  }

  confirmPaymentDebt(data, params?, idStatus?) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/debt/confirm-payment${idStatus ? '/' + idStatus : ''}`,
      data,
      {
        params,
      }
    );
  }

  cancelPaymentDebt(data, params?, idStatus?) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/debt/cancel-payment${idStatus ? '/' + idStatus : ''}`,
      data,
      {
        params,
      }
    );
  }

  // Agreements
  getUnpaidDebts(idDebtor, params?) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debt/agreement-available/debtor/${idDebtor}`,
      {
        params,
      }
    );
  }

  getEventsHistoric(idDebt, params) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debt/${idDebt}/events/historic`,
      { params }
    );
  }

  postEventsHistoric = (idDebt, params) => {
    return this.axiosInstance.post(
      `${this.BASE_URL}/debt/${idDebt}/events/historic`,
      params
    );
  };

  deleteEventHistoric = (idDebt, idEvent) => {
    return this.axiosInstance.delete(
      `${this.BASE_URL}/debt/${idDebt}/events/historic/${idEvent}`
    );
  };

  // Bad Credit
  getOverdueDebts(idDebtor, params?) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debt/negative-available/debtor/${idDebtor}`,
      {
        params,
      }
    );
  }

  //Generate Billet Batch
  postGenerateBilletBatch(data, params?, idStatus?) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/debt/billet-batch${idStatus ? `/${idStatus}` : ''}`,
      data,
      {
        params,
      }
    );
  }

  getHasBilletBatchPendingProcess = () => {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debt/billet-batch/pending-process`
    );
  };

  getBilletBatchGenerationStatus = (idImport) => {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debt/billet-batch/status/${idImport}`
    );
  };
}

export default new DebtsService();
