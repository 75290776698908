/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, useCallback, useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';

export interface ItemUploadFile {
  name: string;
  contentType?: string;
  fileSize?: number | string;
  uploaded?: boolean;
  success?: boolean;
  type: TYPE_FEATURE;
  idFileS3?: string | number;
  // propriedades da geração de boleto em lote
  idImport?: string;
  idUser?: string;
  totalBillets?: number;
}

export enum TYPE_FEATURE {
  addDebt,
  confirmPayment,
  billetBatch,
}

export enum STATUS_BATCH_FILE {
  empty,
  starting,
  uploading,
  processing,
}

export enum STATUS_PROCESS_BATCH_FILE {
  WAITING,
  PROCESSING,
  PROCESSED_WITH_ERRORS,
  ERROR,
  UNDONE,
  QUEUED,
}

export interface UploadResumeBillet {
  status: STATUS_PROCESS_BATCH_FILE;
  billetsSuccess: number;
  billetsError: number;
  description?: string | null;
  s3KeyErrors?: string;
}

export interface UploadResume {
  status: STATUS_PROCESS_BATCH_FILE;
  rowsSuccess: number;
  rowsError: number;
  description: string | null;
  s3KeyErrors?: string;
}

export type StatusUpload = {
  addDebt: {
    status: STATUS_BATCH_FILE;
  };
  confirmPayment: {
    status: STATUS_BATCH_FILE;
  };
  billetBatch: {
    status: STATUS_BATCH_FILE;
  };
};

interface State {
  isNotifierBatchUploadOpen: boolean;
  isExpanded: boolean;
  uploadingFiles: Array<ItemUploadFile>;
  statusUpload: StatusUpload;
  timeoutProcessKey: any;
  uploadResume?: UploadResume;
  listUploadResume: UploadResume[] | [];
  listDeleteResume: UploadResume[] | [];
  listBilletResume: UploadResumeBillet[] | [];
  currentType: number | null;
  rerenderDebtsFetch: boolean;
}

const NotifyBatchUploadContext = createContext<
  ReturnType<typeof useNotifyBatchUpload> | undefined
>(undefined);

function useNotifyBatchUpload(initialState: State) {
  const [state, setState] = useState<State>(initialState);

  const handleState = useCallback((key: keyof State, value: any) => {
    setState((prev) => ({ ...prev, [key]: value }));
  }, []);

  const closeNotifyBatchUpload = useCallback(
    async (typeImport?: TYPE_FEATURE) => {
      if (typeImport === TYPE_FEATURE.addDebt) {
        handleState('statusUpload', {
          ...state.statusUpload,
          addDebt: {
            status: STATUS_BATCH_FILE.empty,
          },
        });
      }

      if (typeImport === TYPE_FEATURE.confirmPayment) {
        handleState('statusUpload', {
          ...state.statusUpload,
          confirmPayment: {
            status: STATUS_BATCH_FILE.empty,
          },
        });
      }

      if (typeImport === TYPE_FEATURE.billetBatch) {
        handleState('statusUpload', {
          ...state.statusUpload,
          billetBatch: {
            status: STATUS_BATCH_FILE.empty,
          },
        });
      }

      handleState('timeoutProcessKey', null);
      handleState('currentType', typeImport);
    },
    [handleState]
  );

  const disabledWhenProcessDebt = () =>
    state.uploadingFiles.some((item) => item.type === 0);
  const disabledWhenProcessPayments = () =>
    state.uploadingFiles.some((item) => item.type === 1);

  useEffect(() => {
    if (
      state.currentType === TYPE_FEATURE.addDebt ||
      state.currentType === TYPE_FEATURE.confirmPayment ||
      state.currentType === TYPE_FEATURE.billetBatch
    ) {
      handleState('uploadingFiles', [
        ...state.uploadingFiles.filter(
          (x: any) => x.type !== state.currentType
        ),
      ]);
      handleState('currentType', null);
    }
  }, [handleState, state.currentType, state.uploadingFiles]);

  useEffect(() => {
    if (_isEmpty(state.uploadingFiles)) {
      handleState('isNotifierBatchUploadOpen', false);
    }
  }, [handleState, state.uploadingFiles]);

  useEffect(() => {
    if (!_isEmpty(state.uploadResume)) {
      handleState('listUploadResume', [
        ...state.listUploadResume,
        state.uploadResume,
      ]);
    }
  }, [state.uploadResume]);

  return {
    state,
    handleState,
    closeNotifyBatchUpload,
    disabledWhenProcessDebt,
    disabledWhenProcessPayments,
  };
}

interface Props {
  defaultInitialState?: State;
}

export const initialState: State = {
  uploadingFiles: [],
  isNotifierBatchUploadOpen: false,
  isExpanded: true,
  statusUpload: {
    addDebt: {
      status: STATUS_BATCH_FILE.empty,
    },
    confirmPayment: {
      status: STATUS_BATCH_FILE.empty,
    },
    billetBatch: {
      status: STATUS_BATCH_FILE.empty,
    },
  },
  currentType: null,
  timeoutProcessKey: null,
  uploadResume: undefined,
  listUploadResume: [],
  listDeleteResume: [],
  listBilletResume: [],
  rerenderDebtsFetch: false,
};

const NotifyBatchUploadProvider: React.FC<Props> = ({
  children,
  defaultInitialState = initialState,
}) => {
  const value = useNotifyBatchUpload(defaultInitialState);

  return (
    <NotifyBatchUploadContext.Provider value={value}>
      {children}
    </NotifyBatchUploadContext.Provider>
  );
};

function useNotifyBatchUploadContext() {
  const context = React.useContext(NotifyBatchUploadContext);
  if (context === undefined) {
    throw new Error(
      'useNotifyBatchUploadContext must be used within a NotifyBatchUploadContext'
    );
  }
  return context;
}

export { NotifyBatchUploadProvider, useNotifyBatchUploadContext };
