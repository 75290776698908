import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface INBAModalContentProps {
  nbaParams: { img: string; name: string }[];
}

const NBAModalContent: React.FC<INBAModalContentProps> = ({ nbaParams }) => {
  const isLastParam = (index: number) => nbaParams.length - 1 === index;

  return (
    <Grid
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-evenly',
      }}
      container
      spacing={2}
    >
      {nbaParams.map(({ img, name }, index) => (
        <Grid item xs={6} sm={4} key={name}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              minHeight={70}
            >
              <img
                alt={name}
                fetchPriority="high"
                src={img}
                width={isLastParam(index) ? 120 : 60}
              />
            </Box>
            <Typography
              color={isLastParam(index) ? 'primary' : 'textPrimary'}
              variant="subtitle2"
              align="center"
            >
              {name}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default NBAModalContent;
