import StepperFooter from '@src/components/StepperFooter';
import FieldTitle from '@src/components/FieldTitle';
import { useUsersStep } from './useUsersStep';
import { AUBoxSkeleton } from '@assertiva/assertiva-ui';
import {
  AutocompleteCheckbox,
  AutocompleteCheckboxItem,
  AutocompleteTable,
  ListSubtitle,
  ListTitle,
} from '@src/components/AutocompleteTable';
import { DrawerModes } from '../../types';

const UsersStep = () => {
  const {
    handleSubmit,
    handleBack,
    isFetchingUsers,
    usersListWithActives,
    handleChangeUserStatus,
    selectedUsers,
    isSubmitDisabled,
    drawerMode,
  } = useUsersStep();

  return (
    <form onSubmit={handleSubmit}>
      <FieldTitle
        title="Defina os usuários"
        subtitle="Adicione os usuários que trabalharão nessa estratégia."
      >
        {isFetchingUsers ? (
          <AUBoxSkeleton width="100%" height={40} />
        ) : (
          <>
            <AutocompleteCheckbox
              noOptionsText="Nenhum usuário encontrado"
              optionsList={usersListWithActives}
              filterBy={['name', 'email']}
              getOptionLabel={(option) => option.name}
              placeholder="Pesquisar usuários"
              renderOption={(item) => {
                return (
                  <AutocompleteCheckboxItem
                    item={item}
                    onChangeItemStatus={handleChangeUserStatus}
                    strongNameKey="name"
                    subNameKey="email"
                    testId={`autocomplete-option-${item.id}`}
                  />
                );
              }}
              onChange={(user) =>
                user && handleChangeUserStatus(user.id, !user.active)
              }
            />
            <AutocompleteTable
              list={selectedUsers}
              onChangeItemStatus={handleChangeUserStatus}
            >
              {(item) => (
                <>
                  <ListTitle>{item.name}</ListTitle>
                  <ListSubtitle>{item.email}</ListSubtitle>
                </>
              )}
            </AutocompleteTable>
          </>
        )}
      </FieldTitle>
      <StepperFooter
        handleBack={handleBack}
        nextBtnText={
          drawerMode === DrawerModes.Add ? 'CRIAR ESTRATÉGIA' : 'SALVAR'
        }
        nextBtnProps={{ disabled: isSubmitDisabled }}
      />
    </form>
  );
};

export default UsersStep;
