import {
  AUNotifier,
  AuthUtils,
  browserHistory,
  createAxiosInstance,
  Envs,
} from '@assertiva/assertiva-ui';
import messages from '@constants/messages';
import { mountInitialWallet } from '@features/wallet/utils';

const instance = createAxiosInstance({
  timeout: 300000,
  onNotAuthenticate: () => {
    localStorage.clear();
    window.open(`${Envs().PAINEL_URL}/login`, '_self');
  },
  onResponseReject: (error) => {
    const message = error.response?.data?.message;

    if (message === messages.ERRORS.INACTIVE_USER) {
      AUNotifier.error(message);

      if (AuthUtils.getUser().isAdminProfile()) {
        browserHistory.push('/usuarios/controle-acessos');
      } else {
        setTimeout(
          () => window.open(`${Envs().PAINEL_URL}/inicio`, '_self'),
          3000
        );
      }
    }
  },
});

instance.interceptors.request.use((request) => {
  request.headers.common['ASTVRECUPEREWALLET'] = mountInitialWallet().id;
  return request;
});

export default instance;
