import { lazy, Suspense, useEffect, useState } from 'react';
import { AuthUtils, browserHistory } from '@assertiva/assertiva-ui';
import { useStrategyInfo } from '@src/features/strategyDebt/components/StrategyInfo/useStrategyInfo';
import { useAppDispatch } from '@src/store/hooks';
import {
  fetchUserStrategy,
  resetState,
} from '@src/features/strategyDebt/strategyDebtSlice';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const LazyWithoutStrategy = lazy(
  () => import('@features/debt/list/pages/Debts')
);
const LazyWithStrategy = lazy(
  () => import('@features/strategyDebt/page/StrategyDebt')
);

interface PermissionedRoutesHandlerProps {
  children: React.JSX.Element;
  redirect?: string;
  handleAdmin?: boolean;
  handlePermissions?: string | string[];
}

export const PermissionedRoutesHandler = ({
  children,
  handleAdmin,
  handlePermissions,
  redirect = '/inicio',
}: PermissionedRoutesHandlerProps) => {
  const [renderChildren, setRenderChildren] = useState(false);
  useEffect(() => {
    const user = AuthUtils.getUser();
    const redirectByAdminHandler = handleAdmin && !user.isAdminProfile();
    const redirectByPermissionsHandler =
      handlePermissions && !user.hasPermission(handlePermissions);

    if (redirectByAdminHandler || redirectByPermissionsHandler) {
      browserHistory.push(redirect);
      return;
    }
    setRenderChildren(true);
  }, [redirect, handleAdmin, handlePermissions]);

  return renderChildren ? children : null;
};

const LoadingComponent = () => (
  <Box
    display="flex"
    width="100%"
    justifyContent="center"
    data-testid="loading-debts-page"
  >
    <Box
      position="absolute"
      top="50%"
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      <CircularProgress value={0} variant={'indeterminate'} size={50} />
    </Box>
  </Box>
);
export const DebtPageHandler = (props) => {
  const { userHasStrategy, madeInitialFetch } = useStrategyInfo();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUserStrategy());

    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const handleRender = () => {
    if (!madeInitialFetch) return <LoadingComponent />;

    if (userHasStrategy) return <LazyWithStrategy {...props} />;

    return <LazyWithoutStrategy {...props} />;
  };

  return <Suspense fallback={<LoadingComponent />}>{handleRender()}</Suspense>;
};
