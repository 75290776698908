import { AUConfirmDialog, Envs, sendAnalytics } from '@assertiva/assertiva-ui';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import comparators from '@src/constants/comparators';
import { useState } from 'react';
import {
  StyledAlert,
  StyledCloseButton,
  StyledDialogTitle,
} from './Alert24Hours.styles';

const KnowMoreDialogContent = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <StyledDialogTitle variant="h6" color="primary">
        Saiba Mais - Configurações
      </StyledDialogTitle>

      <StyledCloseButton
        color="primary"
        onClick={onClose}
        id="btn-icone-fechar-saiba-mais"
      >
        <CloseIcon />
      </StyledCloseButton>
      <Box marginBottom={3} marginTop={5.5}>
        <Typography variant="body2" color="textSecondary">
          As configurações da régua de cobrança entram em vigor no dia seguinte
          às alterações. Isso acontece pois o sistema precisa deste tempo para
          processar essas novas configurações salvas.
        </Typography>
      </Box>

      <Box marginBottom={1}>
        <Typography variant="body2" color="textPrimary">
          Precisa fazer um envio de SMS imediatamente? Utilize nossa ferramenta
          de SMS.
        </Typography>
      </Box>
    </>
  );
};

const Alert24Hours = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledAlert>
        <Typography variant="caption" color="textPrimary">
          As configurações da régua de cobrança entram em vigor no dia seguinte
          às alterações.
        </Typography>
        <Box minWidth={120}>
          <Button
            color="primary"
            onClick={() => {
              sendAnalytics({
                page: 'configurar-cobranca-drawer-configuracao',
                event: 'saiba-mais',
              });
              setOpen(true);
            }}
            id="btn-saiba-mais"
            fullWidth
          >
            SAIBA MAIS
          </Button>
        </Box>
      </StyledAlert>
      <AUConfirmDialog
        title=""
        subtitle=""
        open={open}
        onConfirm={() => {
          sendAnalytics({
            page: 'configurar-cobranca-drawer-configuracao',
            event: 'ir-para-sms',
          });
          window.open(Envs().SMS_URL, '_blank');
          setOpen(false);
        }}
        onDeny={() => setOpen(false)}
        confirmButtonText="Ir para o assertiva sms"
        denyButtonText="Fechar"
        actionsWrapperProps={comparators.DEFAULT_ACTION_PROPS_DIALOG}
      >
        <KnowMoreDialogContent onClose={() => setOpen(false)} />
      </AUConfirmDialog>
    </>
  );
};

export default Alert24Hours;
