import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';

import {
  AUAnalyticsUtils,
  AuthUtils,
  browserHistory,
  sendAnalytics,
} from '@assertiva/assertiva-ui';

import { getQueryParams } from '@src/components/filters';
import comparators from '@src/constants/comparators';
import URL_IMAGES from '@src/constants/images';
import { TRACK_CONFIG } from '@src/constants/permissions';
import { useGlobalDrawersContext } from '@src/contexts/GlobalDrawersContext';
import { isValidNbaGroup } from '@src/features/wallet/utils';
import { selectNBAGroups } from '@src/features/wallet/walletSlice';
import initialTrackConfig from '@src/mocks/data/trackConfig.json';
import ConfigService from '@src/services/ConfigService';
import { objectsAreEqual } from '@src/utils/functionUtils';
import { StyledPresentationText, StyledWelcomBox } from './Welcome.styles';

const Welcome = () => {
  const [hasTrackConfig, setHasTrackConfig] = useState<boolean | undefined>();
  const {
    onOpenDrawer,
    state: { successTrackConfig, successNewDebt },
    handleState: handleStateDrawer,
  } = useGlobalDrawersContext();

  const nbaGroups = useSelector(selectNBAGroups);

  const { idGroupNba } = getQueryParams();

  const handleIdGroupNba: number = useMemo(() => {
    if (!isValidNbaGroup(Number(idGroupNba), nbaGroups)) {
      return nbaGroups?.[0]?.id;
    }

    return idGroupNba;
  }, [idGroupNba, nbaGroups]);

  useEffect(() => {
    const requestSettings = async () => {
      try {
        const trackConfig = await ConfigService.getTrackConfig(
          handleIdGroupNba
        );

        const hasTrackConfig = !objectsAreEqual(
          trackConfig.data.body,
          initialTrackConfig.body
        );

        setHasTrackConfig(hasTrackConfig);
      } catch (err: any) {
        if (err?.response?.status === 400) {
          setHasTrackConfig(true);
        }
      }
    };

    requestSettings();
  }, [handleIdGroupNba]);

  useEffect(() => {
    if (successTrackConfig || successNewDebt) {
      browserHistory.push('/cobranca');
      handleStateDrawer('successNewDebt', false);
      handleStateDrawer('successTrackConfig', false);
    }
  }, [successTrackConfig, successNewDebt, handleStateDrawer]);

  const renderButton = () => {
    if (hasTrackConfig || !AuthUtils.getUser().hasPermission(TRACK_CONFIG)) {
      return (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          onClick={() =>
            AUAnalyticsUtils.sendAnalytics({
              eventCategory: '[INICIO][VISUALIZAR COBRANÇAS]',
              eventAction: '[CLICAR]',
            })
          }
          to={comparators.PATH_DEBTS_ROUTE}
          data-testid="see-debts-btn"
        >
          VISUALIZAR COBRANÇAS
        </Button>
      );
    }
    if (hasTrackConfig === undefined) {
      return <Box height={36.5} data-testid="empty-btn" id="bem-vindo-btn" />;
    }

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          onOpenDrawer('trackConfig');
          sendAnalytics({ page: 'telainicial', event: 'configurarcobranca' });
        }}
        startIcon={<SettingsIcon />}
        data-testid="config-btn"
      >
        CONFIGURAR COBRANÇA
      </Button>
    );
  };

  return (
    <Grow in timeout={450}>
      <StyledWelcomBox boxShadow={3}>
        <Box display="flex" justifyContent="center" width="100%" mt={5}>
          <img
            height={140}
            src={URL_IMAGES.LOGO_SOLO}
            alt="Simplifica"
            data-testid="simplifica-icon"
          />
        </Box>
        <Box mt={4} mb={4}>
          <Typography align="center" variant="h5">
            Bem-vindo(a) ao Assertiva Recupere!
          </Typography>
        </Box>
        <Box maxWidth="860px" margin="0 auto" px={2}>
          <StyledPresentationText align="center" data-testid="welcome-text">
            Facilite o processo de cobrança de clientes. Atue do momento{' '}
            <span>da inclusão da dívida até a recuperação</span> da mesma, por
            meio de insumos para o melhor momento e contato com o cliente de
            forma efetiva.
          </StyledPresentationText>
        </Box>
        <Box display="flex" justifyContent="center" width="100%" mt={4} mb={5}>
          {renderButton()}
        </Box>
      </StyledWelcomBox>
    </Grow>
  );
};

export default Welcome;
