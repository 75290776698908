import { EXPIRED_ID_STATUS } from '@src/features/debt/list/constants';
import { useFeaturesStep } from './useFeaturesStep';

import StepperFooter from '@src/components/StepperFooter';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FieldTitle from '@src/components/FieldTitle';
import {
  AUBoxSkeleton,
  AUCurrencyField,
  StringUtils,
} from '@assertiva/assertiva-ui';
import SummarizerCard from '@src/features/debt/list/components/SummarizerCard';
import AgingChip from './AgingChip';
import { emptyObjectArray } from '@src/utils/functionUtils';

const FeaturesStep = () => {
  const {
    isSubmitDisabled,
    handleSubmit,
    handleBack,
    strategyConfig,
    handleChangeStrategyConfig,
    isInvalidRange,
    state,
    handleState,
    statusListWithActives,
    handleSelectStatus,
    agingListWithActives,
    handleSelectAging,
  } = useFeaturesStep();

  const isOverdueStatusSelected = statusListWithActives.find(
    (status) => status.id === EXPIRED_ID_STATUS
  )?.active;

  return (
    <form onSubmit={handleSubmit}>
      <FieldTitle
        title="Defina o(s) status(s)"
        subtitle="Adicione os status que aparecerão na tela de cobrança."
      >
        <Box display="flex" flexWrap="wrap" gridGap={16}>
          {state.isFetchingData
            ? emptyObjectArray(5).map((element) => (
                <SummarizerCard
                  loading
                  key={element.key}
                  hideDebtsInfo
                  smallCard
                  width="140px"
                />
              ))
            : statusListWithActives.map((status) => (
                <SummarizerCard
                  key={`card-${status.cardDescription}`}
                  card={{
                    description: status.cardDescription,
                    backgroundColor: status.backgroundColor,
                    borderColor: status.borderColor,
                    idStatus: status.id,
                    tooltip: status.tooltip,
                  }}
                  onSelect={() => handleSelectStatus(status.id, !status.active)}
                  selected={status.active}
                  showCheckbox
                  width="140px"
                  smallCard
                  hideDebtsInfo
                />
              ))}
        </Box>
      </FieldTitle>

      {isOverdueStatusSelected ? (
        <FieldTitle
          title="Defina o tempo de vencimento"
          subtitle="Adicione opcionalmente o tempo de vencimento das dívidas (aging) que serão apresentadas no card vencidas."
          mt={4}
        >
          <Box display="flex" justifyContent="flex-start" gridGap={16}>
            {agingListWithActives.map((aging) => (
              <AgingChip
                key={`chip-${aging.name}`}
                label={aging.name}
                isSelected={aging.active}
                toggleSelect={() => handleSelectAging(aging.id, !aging.active)}
              />
            ))}
          </Box>
        </FieldTitle>
      ) : null}

      <FieldTitle
        title="Defina o intervalo de valor"
        subtitle="Adicione opcionalmente um intervalo de valores para as dívidas dessa estratégia."
        mt={3}
      >
        <Box display="flex" gridGap={16} width="100%">
          <Box width="40%">
            <AUCurrencyField
              fullWidth
              label="De:"
              placeholder="R$"
              helperText={isInvalidRange ? 'Valor inválido' : undefined}
              error={isInvalidRange}
              id="input-min-value-strategy"
              value={strategyConfig?.minValue ?? undefined}
              key={`${state.reloadMinField}`}
              onBlurEvent={(target) => {
                const onlyNumbers = StringUtils.formatCurrencyToFloatPtBr(
                  target.value
                );
                if (!onlyNumbers) {
                  handleState({ reloadMinField: !state.reloadMinField });
                }
                handleChangeStrategyConfig({
                  minValue: !onlyNumbers ? undefined : target.value,
                });
              }}
              onChangeEvent={(target) => {
                handleChangeStrategyConfig({
                  minValue: target.value,
                });
              }}
            />
          </Box>
          <Box width="40%">
            <AUCurrencyField
              fullWidth
              label="Até:"
              placeholder="R$"
              helperText={isInvalidRange ? 'Valor inválido' : undefined}
              error={isInvalidRange}
              id="input-max-value-strategy"
              value={strategyConfig?.maxValue ?? undefined}
              key={`${state.reloadMaxField}`}
              onBlurEvent={(target) => {
                const onlyNumbers = StringUtils.formatCurrencyToFloatPtBr(
                  target.value
                );
                if (!onlyNumbers) {
                  handleState({ reloadMaxField: !state.reloadMaxField });
                }
                handleChangeStrategyConfig({
                  maxValue: !onlyNumbers ? undefined : target.value,
                });
              }}
              onChangeEvent={(target) => {
                handleChangeStrategyConfig({
                  maxValue: target.value,
                });
              }}
            />
          </Box>
        </Box>
      </FieldTitle>

      <FieldTitle
        title="Defina as características das dívidas"
        subtitle="Adicione opcionalmente as características das dívidas."
        mt={4}
      >
        <Box display="flex" gridGap={16} width="100%">
          <Box width="40%">
            {state.isFetchingData ? (
              <AUBoxSkeleton height={40} width="100%" />
            ) : (
              <TextField
                label="Acordos"
                select
                fullWidth
                variant="outlined"
                id="select-agreements"
                value={
                  strategyConfig?.idAgreementOption ||
                  state.agreementFilterOptions?.[0]?.id
                }
                onChange={(evt) =>
                  handleChangeStrategyConfig({
                    idAgreementOption: Number(evt.target.value),
                  })
                }
              >
                {state.agreementFilterOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
          <Box width="40%">
            {state.isFetchingData ? (
              <AUBoxSkeleton height={40} width="100%" />
            ) : (
              <TextField
                label="Negativações"
                select
                fullWidth
                variant="outlined"
                id="select-negatives"
                value={
                  strategyConfig?.idNegativeOption ||
                  state.negativeFilterOptions?.[0]?.id
                }
                onChange={(evt) =>
                  handleChangeStrategyConfig({
                    idNegativeOption: Number(evt.target.value),
                  })
                }
              >
                {state.negativeFilterOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
        </Box>
      </FieldTitle>

      <StepperFooter
        handleBack={handleBack}
        nextBtnProps={{ disabled: isSubmitDisabled }}
      />
    </form>
  );
};

export default FeaturesStep;
