import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { Envs } from '@assertiva/assertiva-ui';
import instance from './instanceAxios';

class BaseService {
  BASE_URL = '';
  BASE_PAYMENTS_URL = '';
  BASE_WEBPHONE_URL = '';
  BASE_EVENTS_URL = '';
  BASE_ADDRESS_URL = '';
  axiosInstance: AxiosInstance;
  cancelToken: any;
  cancelTokenSource: CancelTokenSource;

  constructor() {
    this.BASE_URL = `${Envs().RECUPERE_API_URL}simplifica-api/api/v1`;
    this.BASE_WEBPHONE_URL = process.env.REACT_APP_WEBPHONE_API_URL || '';
    this.BASE_EVENTS_URL = `${Envs().RECUPERE_API_URL}simplifica-notification`;
    this.BASE_ADDRESS_URL = process.env.REACT_APP_ADDRESS_API || '';
    this.BASE_PAYMENTS_URL = process.env.REACT_APP_PAYMENTS_API_URL || '';
    this.axiosInstance = instance;
    this.cancelToken = axios.CancelToken;
    this.cancelTokenSource = axios.CancelToken.source();
  }

  generateNewCancelTokenSource() {
    this.cancelTokenSource = axios.CancelToken.source();
  }

  finishPendingRequests() {
    this.cancelTokenSource.cancel();
    this.generateNewCancelTokenSource();
  }
}

export default BaseService;
