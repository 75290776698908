import { Pagination } from '@src/constants/types';
import { useCallback, useEffect, useState } from 'react';

interface State<T> {
  paginatedList: T[];
  pagination: Pagination;
}

export function useAutocompleteTable<T>(list: T[]) {
  const [state, setState] = useState<State<T>>({
    paginatedList: [],
    pagination: { page: 0, rowsPerPage: 5, count: 0 },
  });

  const handleState = useCallback(
    (changes: Partial<State<T>>) =>
      setState((state) => {
        return { ...state, ...changes };
      }),
    []
  );

  const handleStatePagination = useCallback(
    (changes: Partial<Pagination>) =>
      setState((state) => {
        return { ...state, pagination: { ...state.pagination, ...changes } };
      }),
    []
  );

  const handlePagination = useCallback(
    (attr: keyof Pagination, value) => {
      if (attr === 'rowsPerPage') {
        handleStatePagination({ rowsPerPage: value, page: 0 });
      } else {
        handleStatePagination({ page: value });
      }
    },
    [handleStatePagination]
  );

  useEffect(() => {
    const paginatedList = list.slice(
      state.pagination.page * state.pagination.rowsPerPage,
      (state.pagination.page + 1) * state.pagination.rowsPerPage
    );
    handleState({ paginatedList });
    handleStatePagination({ count: list.length });
  }, [
    list,
    handleState,
    handleStatePagination,
    state.pagination.page,
    state.pagination.rowsPerPage,
  ]);

  return { state, handlePagination };
}
