import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { AUConfirmDialog, useLazyLoadImg } from '@assertiva/assertiva-ui';

import SimpleTabs from '@src/components/SimpleTabs';
import { Tab } from '@src/components/SimpleTabs/SimpleTabs';
import comparators from '@src/constants/comparators';
import IMAGES from '@src/constants/images';
import { postWalletNBA } from '@src/features/wallet/walletSlice';
import { useAppDispatch } from '@src/store/hooks';
import { pfNBAParams, pjNBAParams } from '../constants';
import NBAModalContent from './NBAModalContent';

const tabs: Tab[] = [
  {
    label: 'Pessoa física',
    component: <NBAModalContent nbaParams={pfNBAParams} />,
  },
  {
    label: 'Pessoa jurídica',
    component: <NBAModalContent nbaParams={pjNBAParams} />,
  },
];

const NBAParams = () => (
  <Box
    display="flex"
    flexDirection="column"
    gridGap={24}
    alignItems="center"
    paddingBottom={2}
    marginTop="-13px"
    width="100%"
  >
    <Typography variant="body1" color="textPrimary">
      Combinamos critérios para definir a melhor recomendação de cobrança para o
      seu negócio. <strong>Veja alguns exemplos:</strong>
    </Typography>
    <SimpleTabs
      tabs={tabs}
      tabsProps={{
        style: {
          width: '100%',
        },
      }}
      tabProps={{
        fullWidth: true,
      }}
    />
  </Box>
);

const NBATrack = () => (
  <Box
    display="flex"
    flexDirection="column"
    gridGap={34}
    alignItems="center"
    paddingBottom={5.5}
    marginTop="-13px"
    width="100%"
  >
    <Box paddingRight={4}>
      <Typography variant="body1" color="textPrimary">
        A régua de comunicação de e-mail e SMS será pré-definida com nossa
        recomendação mas você pode alterá-la a qualquer momento:
      </Typography>
    </Box>
    <Box marginTop={3} display="flex" justifyContent="center" width="100%">
      <img
        src={IMAGES.NBA_TRACK_CONFIG}
        alt="Régua de cobrança customizada por grupo"
        fetchPriority="high"
        draggable="false"
        width="100%"
        height={157}
      />
    </Box>
    <Box>
      <Typography variant="body1" color="textSecondary">
        Em caso do produto SMS não estar habilitado, então não serão disparados
        SMS.
      </Typography>
    </Box>
  </Box>
);

interface Props {
  open: boolean;
  handleClose: () => void;
}

const NBAModal = ({ open, handleClose }: Props) => {
  const [next, setNext] = useState(false);
  const dispatch = useAppDispatch();

  useLazyLoadImg(IMAGES.NBA_TRACK_CONFIG);

  const handleNext = async () => {
    try {
      if (!next) {
        setNext(true);
        return;
      }
      await dispatch(postWalletNBA()).unwrap();
      handleClose();
    } catch (err) {}
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => setNext(false), 250);
    }
  }, [open]);

  return (
    <AUConfirmDialog
      onEnabledClear={handleClose}
      onConfirm={handleNext}
      onDeny={handleClose}
      onClose={handleClose}
      confirmButtonText="Continuar"
      denyButtonText="Cancelar"
      open={open}
      title=" "
      subtitle=""
      PaperProps={{
        style: {
          width: '540px',
        },
      }}
      actionsWrapperProps={comparators.DEFAULT_ACTION_PROPS_DIALOG}
    >
      {next ? <NBATrack /> : <NBAParams />}
    </AUConfirmDialog>
  );
};

export default NBAModal;
