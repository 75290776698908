import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { StyledTypographyWrapper } from '@src/assets/styles/Typography.styles';
import { ListItem } from './types';

interface Props<T, K extends keyof T> {
  item: ListItem<T>;
  onChangeItemStatus: (id: number, active: boolean) => void;
  strongNameKey: K;
  subNameKey?: K;
  testId?: string;
}

export const AutocompleteCheckboxItem: <T>(
  p: Props<T, keyof T>
) => React.ReactElement<Props<T, keyof T>> = ({
  item,
  onChangeItemStatus,
  strongNameKey,
  subNameKey,
  testId,
}) => {
  const { id, active } = item;
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      data-testid={testId}
      onClick={(e) => {
        e.stopPropagation();
        onChangeItemStatus(id, !active);
      }}
    >
      <Box display="flex" alignItems="center" gridGap={4}>
        <StyledTypographyWrapper weight={500} color="#212121">
          {item[strongNameKey]}
        </StyledTypographyWrapper>
        {subNameKey && (
          <StyledTypographyWrapper
            size={14}
            color="#666666"
            style={{ whiteSpace: 'nowrap' }}
          >
            {`- ${item[subNameKey]}`}
          </StyledTypographyWrapper>
        )}
      </Box>
      <Checkbox
        color="primary"
        value={Boolean(active)}
        checked={Boolean(active)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(_, checked) => {
          onChangeItemStatus(id, checked);
        }}
      />
    </Box>
  );
};
