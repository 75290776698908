import { useState } from 'react';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PreviewEmailDialog from './PreviewEmailDialog';
import { IEmailConfigPreview } from '@src/features/trackConfig/types';
import { isEmptyString } from '@src/utils/functionUtils';

interface Props {
  emailConfigPreview: IEmailConfigPreview;
}

const PreviewEmail = ({ emailConfigPreview }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const isDisabled =
    isEmptyString(emailConfigPreview.message) ||
    isEmptyString(emailConfigPreview.subject);

  return (
    <>
      <Button
        color="primary"
        variant="text"
        onClick={() => setIsOpen(true)}
        endIcon={<PlayArrowIcon />}
        disabled={isDisabled}
        id="btn-preview-email"
        data-testid="btn-preview-email"
      >
        PRÉ-VISUALIZAR E-MAIL
      </Button>
      <PreviewEmailDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        emailConfigPreview={emailConfigPreview}
      />
    </>
  );
};

export default PreviewEmail;
