/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { IVariant } from '@unleash/proxy-client-react';

import _get from 'lodash/get';

import {
  AUAnalyticsUtils,
  AuthUtils,
  useFeatureFlag,
} from '@assertiva/assertiva-ui';

import {
  RECUPERE_UI_AB_TESTING_NBA,
  RECUPERE_UI_NBA,
} from '@src/constants/featureFlags';
import { SIMPLIFICA_COBRANCA_INTELIGENTE } from '@src/constants/permissions';
import {
  selectCanPostNBA,
  selectWalletSummary,
} from '@src/features/wallet/walletSlice';

interface IUseNBAReturn {
  canPostNBA: boolean;
  cardText: string;
  disableNBAButton: boolean;
  FEATURE_FLAG_NBA: boolean;
  flagsError: boolean;
  flagsReady: boolean;
  handleAnalyticsTracking: () => void;
  hasNoDebts: boolean;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  toggleIsModalOpen: () => void;
  tooltipTitle: string;
  variant: IVariant;
}

interface IVariantValue {
  value?: boolean;
}

export function useNBA(): IUseNBAReturn {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const user = AuthUtils.getUser();

  const { flagsError, flagsReady, getFlag, getVariant, updateContext } =
    useFeatureFlag();

  const canPostNBA = useSelector(selectCanPostNBA);

  const walletSummary = useSelector(selectWalletSummary);

  const hasNoDebts = !walletSummary?.totalCountDebts;

  const userId = user.id?.toString();

  const FEATURE_FLAG_NBA = getFlag(RECUPERE_UI_NBA) && flagsReady;

  const hasNBAPermission = user.hasPermission(SIMPLIFICA_COBRANCA_INTELIGENTE);

  const toggleIsModalOpen = () => setIsModalOpen(!isModalOpen);

  const variant = getVariant(RECUPERE_UI_AB_TESTING_NBA);

  const variantValue = JSON.parse(
    _get(variant, 'payload.value', '{}')
  ) as IVariantValue;

  const disableNBAButton = useMemo(
    () => FEATURE_FLAG_NBA || !hasNBAPermission || hasNoDebts,
    [FEATURE_FLAG_NBA, hasNBAPermission, hasNoDebts]
  );

  const cardText = useMemo(
    () =>
      variantValue.value
        ? 'Aumente a sua recuperação usando a Cobrança Inteligente que priorizará os devedores com maior probabilidade de pagamento.'
        : 'Veja quais dívidas têm maior probabilidade de recuperação e saiba como priorizar!',
    [variantValue]
  );

  const tooltipTitle = useMemo(() => {
    if (FEATURE_FLAG_NBA)
      return 'Entre em contato com nosso time de relacionamento para liberar a cobrança inteligente';

    if (!hasNBAPermission)
      return 'Você não tem permissão para realizar essa ação.';

    if (hasNoDebts) return 'Esta carteira não possui dívidas';

    return '';
  }, [FEATURE_FLAG_NBA, hasNBAPermission, hasNoDebts]);

  const handleAnalyticsTracking = () =>
    AUAnalyticsUtils.sendAnalytics({
      eventAction: '[CLICK]',
      eventCategory: '[NBA][COMECE_AGORA]',
      eventLabel: `[${variant?.name?.toUpperCase()}]`,
    });

  useEffect(() => {
    updateContext({ userId });
  }, [updateContext, userId]);

  return {
    canPostNBA,
    cardText,
    disableNBAButton,
    FEATURE_FLAG_NBA,
    flagsError,
    flagsReady,
    handleAnalyticsTracking,
    hasNoDebts,
    isModalOpen,
    setIsModalOpen,
    toggleIsModalOpen,
    tooltipTitle,
    variant,
  };
}
