import { AUCustomChip } from '@assertiva/assertiva-ui';
import { styled } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

interface AgingChipProps {
  isSelected: boolean;
  label: string;
  toggleSelect: () => void;
}

interface StyledChipProps {
  BgColorHover?: string;
}

const StyledChip = styled(AUCustomChip)(
  ({ BgColorHover }: StyledChipProps) => ({
    transition: 'all .2s',
    '&:focus': {
      backgroundColor: '#D44E4E',
    },
    '&:hover': {
      backgroundColor: BgColorHover,
    },
  })
);

const StyledCancelIcon = styled(CancelIcon)({
  color: '#FFFFFF',
  opacity: 0.7,
  '&:hover': {
    color: '#FFFFFF',
    opacity: 1,
  },
});

const AgingChip = ({ isSelected, label, toggleSelect }: AgingChipProps) => {
  return (
    <StyledChip
      size="small"
      labelColor={isSelected ? '#FFFFFF' : '#EB5757'}
      bgColor={isSelected ? '#EB5757' : '#FFFFFF'}
      BgColorHover={isSelected ? '#D44E4E' : '#F7F7F7'}
      border={!isSelected ? '1px solid #EB5757' : undefined}
      label={label}
      onDelete={isSelected ? toggleSelect : undefined}
      deleteIcon={<StyledCancelIcon />}
      onClick={!isSelected ? toggleSelect : undefined}
    />
  );
};

export default AgingChip;
