export enum LAYOUT_STEP_ENUM {
  EMPTY = 'EMPTY',
  DOWNLOAD = 'DOWNLOAD',
  UPLOAD = 'UPLOAD',
  PROGRESS = 'UPLOAD_PROGRESS',
  COMPLETED = 'UPLOAD_COMPLETED',
}

export enum TYPE_DOC {
  CPF = 'CPF',
  CNPJ = 'CNPJ',
}

export enum SOLICITATION_TAB_ENUM {
  EM_ANDAMENTO,
  PROCESSADO,
  CANCELADO,
  USUARIO_LIMITADO,
  SEM_RETORNO,
  FALHA_DE_ENTRADA,
}

export enum TREATMENT_ACTION_ENUM {
  DOWNLOAD,
  REPROCESS,
  CANCEL,
  LOG,
}

export enum SENDS_CHANNEL_OPTIONS_ENUM {
  SMS,
}

export enum FormModes {
  Edit = 'Edit',
  Add = 'Add',
  Closed = 'Closed',
}
