import {
  FileMetaData,
  ImagePreview,
  PreviewContainer,
} from '@components/FileUpload/FileUpload.styles';
import Box from '@material-ui/core/Box';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { StyledTypographyWrapper } from '@assets/styles/Typography.styles';
import { convertBytesToKB } from '@components/FileUpload/FileUploadUtils';
import DebtsService from '@src/services/DebtsService';
import { AULoadingManager } from '@assertiva/assertiva-ui';
import Tooltip from '@material-ui/core/Tooltip';

interface Props {
  modeImage: boolean;
  isImageFile?: boolean;
  file?: any;
  progress?: any;
  index?: number;
  download?: any;
  href?: any;
  children?: any;
  isLoading?: boolean;
  hideCursorPointer?: boolean;
}

const AuxiliaryFilesAddDebt = ({
  modeImage,
  isImageFile,
  file,
  index,
  progress,
  download,
  href,
  children,
  isLoading,
  hideCursorPointer,
}: Props) => {
  const readOnlyAttr = {
    onClick: async () => {
      if (isLoading) return;

      if (file?.handleClick) {
        file?.handleClick?.();
        return;
      }

      if (file.readonly) {
        AULoadingManager.show();
        const response = await DebtsService.getFile(file.idFileS3);

        const {
          data: {
            body: { url },
          },
        } = response;
        AULoadingManager.close();
        window.open(url, '_blank');
      } else {
        //TODO: Por algum motivo, na hora de adicionar arquivos na criação de dividas o objeto vem apenas como file
        // analisar o pq disso acontecer apenas lá
        const url = window.URL.createObjectURL(file?.file ?? file);
        window.open(url, '_blank');
      }
    },
  };

  return (
    <PreviewContainer mode={modeImage}>
      <div>
        {isImageFile && modeImage && (
          <ImagePreview
            src={URL.createObjectURL(file)}
            alt={`file preview ${index}`}
          />
        )}
        <FileMetaData isImageFile={isImageFile} mode={modeImage}>
          <Box height={10} width="100%" position="absolute" overflow="hidden">
            {progress}
          </Box>
          <Box
            display="flex"
            alignItems="space-between"
            padding={1}
            paddingLeft={0}
            justifyContent="space-between"
            gridColumnGap={6}
            borderRadius={6}
            position="relative"
            paddingTop="10px"
          >
            <Box
              display="flex"
              height="100%"
              alignItems="center"
              overflow="hidden"
              flexDirection={modeImage ? `column` : 'row'}
              gridGap={8}
              style={{ cursor: hideCursorPointer ? 'auto' : 'pointer' }}
              {...readOnlyAttr}
            >
              {!modeImage && <AttachFileIcon color="primary" />}

              <StyledTypographyWrapper
                size={14}
                weight={400}
                color={!modeImage ? '#8A00C1' : '#fff'}
              >
                {modeImage ? (
                  <Box maxWidth={100} overflow="hidden" textOverflow="ellipsis">
                    {file?.name}
                  </Box>
                ) : (
                  <Tooltip
                    title={
                      !file?.hideTooltip && file?.name?.length > 40
                        ? file?.name
                        : ''
                    }
                    placement="top-start"
                  >
                    <Box
                      maxWidth={290}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {file?.name}
                    </Box>
                  </Tooltip>
                )}
              </StyledTypographyWrapper>

              {modeImage && (
                <Box
                  marginRight="auto"
                  pt={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  <StyledTypographyWrapper size={12} weight={400} color="#fff">
                    {convertBytesToKB(file.size)} kb
                  </StyledTypographyWrapper>
                </Box>
              )}
            </Box>
            <Box mt={1}>{children}</Box>
          </Box>
        </FileMetaData>
      </div>
    </PreviewContainer>
  );
};

export default AuxiliaryFilesAddDebt;
