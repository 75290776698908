import { Nullish } from '@src/constants/types';
import { resolveMomentToString } from '@src/utils/functionUtils';
import moment from 'moment';
import { initialState } from './constants';
import {
  handleSender,
  sanitizeMessage,
} from './defineMessages/components/WriteEmailDrawer/EmailMessage';
import {
  ConfigOptions,
  DefineMessagesTabs,
  IEmailConfig,
  IExpirationDay,
  IPreventiveOptions,
  IReactiveOptions,
  ISMSConfig,
  PossibleChannels,
  PossibleConfigs,
  State,
  TYPE_MESSAGE_ENUM,
} from './types';
import { AuthUtils } from '@assertiva/assertiva-ui';

export const removeUnusedSMSConfigProps = (smsConfig: Nullish<ISMSConfig>) => {
  if (!smsConfig) return null;
  const { message, idRoute } = smsConfig;
  return { message, idRoute };
};

export const handleEmailConfig = (emailConfig: Nullish<IEmailConfig>) => {
  if (!emailConfig) return null;
  return {
    ...emailConfig,
    message: emailConfig?.message
      ? handleSender(sanitizeMessage(emailConfig.message))
      : emailConfig?.message,
    logo: { idFileS3: emailConfig?.logo?.idFileS3 || null },
  };
};

export const formatHour = (hour: string | any) => {
  if (!hour) return null;
  return typeof hour === 'string'
    ? hour
    : resolveMomentToString(hour, { format: 'HH:mm' });
};

export const createHour = (hour: string | null) =>
  hour ? moment(hour, 'HH:mm') : null;

export const getSubmitPayload = (
  { preventiveOptions, expirationDay, reactiveOption }: State,
  idGroupNba: null | number
) => {
  const optionsArray = preventiveOptions.options
    .filter((option) => option.idPreventiveOption || option.hour)
    .map(({ hour, ...option }) => ({
      ...option,
      hour: formatHour(hour),
    }));

  return {
    preventiveOptions: {
      options: optionsArray.length
        ? optionsArray
        : [...initialState.preventiveOptions.options],
      smsConfig: removeUnusedSMSConfigProps(preventiveOptions.smsConfig),
      smsBilletConfig: removeUnusedSMSConfigProps(
        preventiveOptions.smsBilletConfig
      ),
      emailConfig: handleEmailConfig(preventiveOptions.emailConfig),
      emailBilletConfig: handleEmailConfig(preventiveOptions.emailBilletConfig),
    },
    expirationDay: {
      ...expirationDay,
      hour: formatHour(expirationDay.hour),
      smsConfig: removeUnusedSMSConfigProps(expirationDay.smsConfig),
      smsBilletConfig: removeUnusedSMSConfigProps(
        expirationDay.smsBilletConfig
      ),
      emailConfig: handleEmailConfig(expirationDay.emailConfig),
      emailBilletConfig: handleEmailConfig(expirationDay.emailBilletConfig),
    },
    reactiveOption: {
      ...reactiveOption,
      hour: formatHour(reactiveOption.hour),
      smsConfig: removeUnusedSMSConfigProps(reactiveOption.smsConfig),
      smsBilletConfig: removeUnusedSMSConfigProps(
        reactiveOption.smsBilletConfig
      ),
      emailConfig: handleEmailConfig(reactiveOption.emailConfig),
      emailBilletConfig: handleEmailConfig(reactiveOption.emailBilletConfig),
    },
    idGroupNba: Number(idGroupNba),
  };
};

export const getOptionsData = (options: Partial<ConfigOptions>) => {
  const getChannelDataSMS = (typeMessage) => ({
    default: getChannelData('isSms', 'smsConfig', typeMessage, options),
    withBillet: getChannelData(
      'isSms',
      'smsBilletConfig',
      typeMessage,
      options
    ),
  });

  const getChannelDataEmail = (typeMessage) => ({
    default: getChannelData('isEmail', 'emailConfig', typeMessage, options),
    withBillet: getChannelData(
      'isEmail',
      'emailBilletConfig',
      typeMessage,
      options
    ),
  });

  const getChannelDataBy = (getChannelFn) => {
    const data: Partial<
      Record<TYPE_MESSAGE_ENUM, ReturnType<typeof getChannelDataEmail>>
    > = {};
    Object.keys(TYPE_MESSAGE_ENUM).forEach((key) => {
      data[key] = getChannelFn(key);
    });
    return data;
  };

  return {
    [DefineMessagesTabs.Sms]: getChannelDataBy(getChannelDataSMS),
    [DefineMessagesTabs.Email]: getChannelDataBy(getChannelDataEmail),
  };
};

export const getChannelData = (
  channel: PossibleChannels,
  config: PossibleConfigs,
  typeMessage: TYPE_MESSAGE_ENUM,
  options: Partial<ConfigOptions>
) => {
  const option = options[typeMessage];
  const optionConfig = option?.[config];
  const message = optionConfig?.message || undefined;
  const selected = hasSelectedChannel(channel, typeMessage, option);
  const isCorrect = isCorrectChannel(
    channel,
    selected,
    config.includes('Billet'),
    optionConfig
  );
  return {
    message,
    selected,
    isCorrect,
  };
};

export const hasSelectedChannel = (
  channel: PossibleChannels,
  typeMessage: TYPE_MESSAGE_ENUM,
  option?: IPreventiveOptions | IExpirationDay | IReactiveOptions
) =>
  typeMessage === TYPE_MESSAGE_ENUM.preventiveOptions
    ? hasPreventiveChannel(channel, option as IPreventiveOptions)
    : option?.[channel];

export const hasPreventiveChannel = (
  channel: PossibleChannels,
  preventiveOptions: IPreventiveOptions
) => preventiveOptions?.options?.some((o) => o?.[channel]);

export const isCorrectChannel = (
  channel: PossibleChannels,
  selected: boolean,
  isBilletConfig: boolean,
  config?: Nullish<ISMSConfig | IEmailConfig>
) =>
  channel === 'isSms'
    ? isCorrectSMSChannel(
        selected,
        config as Nullish<ISMSConfig>,
        isBilletConfig
      )
    : isCorrectEmailChannel(
        selected,
        config as Nullish<IEmailConfig>,
        isBilletConfig
      );

export const isCorrectSMSChannel = (
  selected: boolean,
  smsConfig: ISMSConfig | null,
  isBilletConfig: boolean
) => {
  if (!selected) return true;

  return isBilletConfig ? true : Boolean(smsConfig?.message);
};

export const isCorrectEmailChannel = (
  selected: boolean,
  emailConfig: IEmailConfig | null,
  isBilletConfig: boolean
) => {
  if (!selected) return true;

  if (isBilletConfig && !emailConfig?.message && !emailConfig?.subject)
    return true;

  return Boolean(emailConfig?.message && emailConfig?.subject);
};

export const areOptionsCorrect = (
  tab: DefineMessagesTabs,
  optionsData: ReturnType<typeof getOptionsData>,
  hasBillet: boolean
) => {
  const options = optionsData[tab];

  if (!hasBillet)
    return (
      options.preventiveOptions?.default?.isCorrect &&
      options.expirationDay?.default?.isCorrect &&
      options.reactiveOption?.default?.isCorrect
    );

  return (
    options.preventiveOptions?.default?.isCorrect &&
    options.preventiveOptions?.withBillet?.isCorrect &&
    options.expirationDay?.default?.isCorrect &&
    options.expirationDay?.withBillet?.isCorrect &&
    options.reactiveOption?.default?.isCorrect &&
    options.reactiveOption?.withBillet?.isCorrect
  );
};

export const areAllOptionsCorrect = (
  optionsData: ReturnType<typeof getOptionsData>,
  hasBillet: boolean
) =>
  areOptionsCorrect(DefineMessagesTabs.Sms, optionsData, hasBillet) &&
  areOptionsCorrect(DefineMessagesTabs.Email, optionsData, hasBillet);

export const resolveHasPermission = (permission: string | null) => {
  if (!permission) return true;

  return AuthUtils.getUser().hasPermission(permission);
};
