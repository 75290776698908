import { useEffect, useRef, useState, useCallback } from 'react';
import { convertNestedObjectToArray } from '@components/FileUpload/FileUploadUtils';
import _isEmpty from 'lodash/isEmpty';

export const useFileUpload = ({
  maxFileSizeInBytes,
  onUpdateFiles,
  otherProps,
  items = {},
}) => {
  const fileInputField = useRef<any>(null);
  const [files, setFiles] = useState({});

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles: any): any => {
    for (const file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }

        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const currentFiles = (newFiles: any) => {
    const files2: any = [];
    for (const file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        if (!files[file.name]) {
          files2.push(file);
        }
      }
    }
    return [...files2];
  };
  const renderPreview = (cb) =>
    !_isEmpty(files) &&
    Object.keys(files).map((fileName, index) => {
      const file = files[fileName];
      const isImageFile = !files[fileName]['readonly']
        ? file.type.split('/')[0] === 'image'
        : 'image';
      return cb({ file, fileName, isImageFile, index });
    });

  const callUpdateFilesCb = (files, action, currentFiles?) => {
    const filesAsArray = convertNestedObjectToArray(files);
    onUpdateFiles && onUpdateFiles(filesAsArray, action, currentFiles);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      const currentDiffFiles = currentFiles(newFiles);
      const updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);

      callUpdateFilesCb(updatedFiles, 'added', currentDiffFiles);
    }
  };

  const removeFile = (fileName) => {
    const filesCopy = { ...files };
    delete filesCopy[fileName];
    setFiles({ ...filesCopy });
    callUpdateFilesCb({ ...filesCopy }, 'removed');
  };
  let objectURL;
  const getObjectURL = (fileName) => {
    try {
      objectURL = URL.createObjectURL(files[fileName]);
      return {
        objectURL,
        name: files[fileName].name,
      };
    } catch (err) {
      return {
        objectURL,
        name: files?.[fileName]?.name,
      };
    }
  };

  const defaultItems = useCallback(() => setFiles(items), [items]);

  useEffect(() => {
    !_isEmpty(items) && defaultItems();
  }, [defaultItems, items]);

  return {
    fileInputField,
    files,
    handleUploadBtnClick,
    addNewFiles,
    callUpdateFilesCb,
    handleNewFileUpload,
    removeFile,
    getObjectURL,
    renderPreview,
  };
};
