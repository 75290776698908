import { GetResponse, Nullish } from '@src/constants/types';
import {
  IWebphoneCampaignExpirationOption,
  WebphoneCampaign,
  WebphoneCampaignStatus,
  WebphoneCampaignSummary,
} from '@src/features/webphoneCampaigns/types';
import BaseService from './BaseService';

class WebphoneService extends BaseService {
  postDebtCall(data) {
    return this.axiosInstance.post(
      `${this.BASE_WEBPHONE_URL}/call/simplifica`,
      data
    );
  }

  getLastCampaign() {
    return this.axiosInstance.get<GetResponse<WebphoneCampaign>>(
      `${this.BASE_URL}/webphone-campaign`
    );
  }

  getLastWebphoneCampaignStatus() {
    return this.axiosInstance.get<
      GetResponse<{ status: Nullish<WebphoneCampaignStatus> }>
    >(`${this.BASE_URL}/webphone-campaign/status`);
  }

  getCampaignSummary() {
    return this.axiosInstance.get<GetResponse<WebphoneCampaignSummary>>(
      `${this.BASE_URL}/webphone-campaign/summary`
    );
  }

  postWebphoneCampaign(data) {
    return this.axiosInstance.post(`${this.BASE_URL}/webphone-campaign`, data);
  }

  getWebphoneCampaignExpirationOptions() {
    return this.axiosInstance.get<
      GetResponse<IWebphoneCampaignExpirationOption>
    >(`${this.BASE_URL}/webphone-campaign/expiration-option`);
  }
}

export default new WebphoneService();
