import { AUIPurpose } from '@assertiva/assertiva-ui';
import { SMSTypes } from '@assertiva/business-ui';
import { IPurposeOfUse } from '@src/constants/interfaces';
import { Nullish } from '@src/constants/types';
import { Moment } from 'moment';
import { WalletSelect } from '../wallet/type';
import { IEmailLogo } from './defineMessages/components/WriteEmailDrawer/EmailMessage';

export enum Step {
  triggerRules,
  defineMessages,
}

export interface ISMSConfig {
  message: Nullish<string>;
  idRoute: Nullish<number>;
  selectedSender?: SMSTypes.SendersRegister;
  selectedLink?: SMSTypes.LinkRegister;
  messageWithoutParse?: string;
}

export interface IEmailConfig {
  message: Nullish<string>;
  subject: Nullish<string>;
  logo: Nullish<IEmailLogo>;
}

export interface IEmailConfigPreview extends IEmailConfig {
  logoUrl?: string;
}

export interface IDefaultConfig {
  hour: Nullish<Moment>;
  isSms: boolean;
  isPhone: boolean;
  isEmail: boolean;
}

export interface IDefaultChannelConfigs {
  smsConfig: Nullish<ISMSConfig>;
  smsBilletConfig: Nullish<ISMSConfig>;
  emailConfig: Nullish<IEmailConfig>;
  emailBilletConfig: Nullish<IEmailConfig>;
}

export type PossibleChannels = keyof Pick<IDefaultConfig, 'isSms' | 'isEmail'>;

export type PossibleConfigs = keyof IDefaultChannelConfigs;

export interface IPreventiveConfig extends IDefaultConfig {
  idPreventiveOption: Nullish<string>;
}

export interface IPreventiveOptions extends IDefaultChannelConfigs {
  options: IPreventiveConfig[];
}

export interface IExpirationDay
  extends IDefaultConfig,
    IDefaultChannelConfigs {}

export interface IReactiveOptions
  extends IDefaultConfig,
    IDefaultChannelConfigs {
  idReactiveRecurrence: Nullish<string>;
  idBillingExpirationOption: Nullish<string>;
}

export enum TYPE_MESSAGE_ENUM {
  preventiveOptions = 'preventiveOptions',
  expirationDay = 'expirationDay',
  reactiveOption = 'reactiveOption',
}

export enum DefineMessagesTabs {
  Sms = 'SMS',
  Email = 'E-MAIL',
}

export enum ChannelsTrackConfig {
  Sms,
  Email,
}

export interface ISelectLabels {
  id: string;
  description: string;
}
export interface State {
  step: Step;
  defineMessagesTab: DefineMessagesTabs;
  preventiveOptions: IPreventiveOptions;
  expirationDay: IExpirationDay;
  reactiveOption: IReactiveOptions;
  selectLabels: {
    preventive: ISelectLabels[];
    reactive: ISelectLabels[];
    billingExpirations: ISelectLabels[];
  };
  purposeOfUse: IPurposeOfUse;
  purposes: AUIPurpose[];
  changedWallet: boolean;
  initialSelectedWallet: WalletSelect | null;
  hasBillet: boolean;
}

export type ConfigOptions = Pick<
  State,
  'preventiveOptions' | 'expirationDay' | 'reactiveOption'
>;
