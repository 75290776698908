import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import DebtorsService from '@src/services/DebtorsService';
import { RootStateType } from '@src/store';
import {
  showLinearLoading,
  closeLinearLoading,
} from '@src/hooks/useLinearLoading';
import { AUNotifier, ErrorUtils } from '@assertiva/assertiva-ui';
import {
  formatDebtorInfoValues,
  handleDebtorContactValues,
} from './badCreditUtils';
import ConfigService from '@src/services/ConfigService';

export enum DestinationBadCredit {
  ALL_DEBTS_BY_DEBTOR = 0,
  SINGLE_DEBT = 1,
}

export interface BadCreditDebt {
  id: string;
  installment: string;
  finalDate: string;
  valueFraction: number;
  idContract?: string;
}

export interface BadCreditCreditor {
  loaded: boolean;
  cnpj: string;
  name: string;
  socialName: string;
  operationType: number;
  tel1: string;
  tel2: string;
  callCenterTime: string;
  email: string;
  site: string;
}

interface SentBadCredit {
  debt: boolean;
  debtor: boolean;
}

interface BadCreditState {
  isOpen: boolean;
  isBilletAlertOpen: boolean;
  activeStep: number;
  enabledConfirm: boolean;
  isOpenDrawerSettings: boolean;
  selectedDebts: BadCreditDebt[];
  creditorInfo: BadCreditCreditor;
  debtorInfo: Record<string, any>;
  sentBadCredit: SentBadCredit;
  origin: DestinationBadCredit;
}

export const initialState: BadCreditState = {
  isOpen: false,
  isBilletAlertOpen: false,
  activeStep: 0,
  enabledConfirm: false,
  isOpenDrawerSettings: false,
  selectedDebts: [],
  sentBadCredit: {
    debt: false,
    debtor: false,
  },
  creditorInfo: {
    loaded: false,
    cnpj: '',
    name: '',
    socialName: '',
    operationType: 1,
    tel1: '',
    tel2: '',
    callCenterTime: '',
    email: '',
    site: '',
  },
  debtorInfo: {
    contact: {
      loaded: false,
      cellPhones: [{ number: '' }],
      phones: [{ number: '' }],
      emails: [{ email: '' }],
    },
    address: {
      cep: '',
      street: '',
      neighborhood: '',
      city: '',
      state: '',
      publicPlaceType: '',
      complement: '',
      number: '',
    },
  },
  origin: 0,
};

export const fetchDebtorInfo = createAsyncThunk(
  'badCredit/fetchDebtorInfo',
  async (debtorID: string) => {
    showLinearLoading();
    try {
      const response = await DebtorsService.getDebtorData(debtorID);
      return response.data.body;
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      closeLinearLoading();
    }
  }
);

export const fetchCreditorInfo = createAsyncThunk(
  'badCredit/fetchCreditorInfo',
  async () => {
    showLinearLoading();
    try {
      const response = await ConfigService.getSettingsNegative();
      return response.data.body;
    } catch (err) {
      // AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      closeLinearLoading();
    }
  }
);

export const badCreditSlice = createSlice({
  name: 'badCredit',
  initialState,
  reducers: {
    setInitialState(
      state,
      action: PayloadAction<Partial<typeof state> | undefined>
    ) {
      Object.assign(state, action?.payload ?? initialState);
    },
    handleOpenModalCreditBad(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    handleOpenBilletAlert(state, action: PayloadAction<boolean>) {
      state.isBilletAlertOpen = action.payload;
    },
    nextStep: (state: BadCreditState) => {
      state.activeStep = state.activeStep + 1;
    },
    prevStep: (state: BadCreditState) => {
      state.activeStep = state.activeStep - 1;
    },
    handleSentBadCredit: (
      state,
      action: PayloadAction<Partial<SentBadCredit>>
    ) => {
      state.sentBadCredit = { ...state.sentBadCredit, ...action.payload };
    },
    handleSelectedDebts(state, action: PayloadAction<any[]>) {
      state.selectedDebts = action.payload;
    },
    handleDebtorInfo(state, action: PayloadAction<Record<string, any>>) {
      state.debtorInfo = action.payload;
    },
    handleCreditorInfo(state, action: PayloadAction<BadCreditCreditor>) {
      state.creditorInfo = { ...action.payload, loaded: true };
    },
    handleOrigin(state, action: PayloadAction<DestinationBadCredit>) {
      state.origin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreditorInfo.fulfilled, (state, action) => {
      state.creditorInfo = { ...action.payload, loaded: true };
    });
    builder.addCase(fetchCreditorInfo.rejected, (state) => {
      state.creditorInfo = { ...state.creditorInfo, loaded: true };
    });

    builder.addCase(fetchDebtorInfo.fulfilled, (state, action) => {
      const onlyNotBlocklist = (phones) =>
        phones.filter((p) => !p.blacklist?.active);
      const foundCellPhones = formatDebtorInfoValues(
        onlyNotBlocklist(action.payload.cellPhones)
      );
      const foundPhones = formatDebtorInfoValues(
        onlyNotBlocklist(action.payload.phones)
      );
      const foundEmails = formatDebtorInfoValues(action.payload.emails);

      state.debtorInfo = {
        ...state.debtorInfo,
        contact: {
          cellPhones: handleDebtorContactValues(foundCellPhones, 'phone'),
          phones: handleDebtorContactValues(foundPhones, 'phone'),
          emails: handleDebtorContactValues(foundEmails, 'email'),
          loaded: true,
        },
      };
    });
  },
});

export const {
  nextStep,
  prevStep,
  setInitialState,
  handleOpenModalCreditBad,
  handleOpenBilletAlert,
  handleSelectedDebts,
  handleCreditorInfo,
  handleDebtorInfo,
  handleSentBadCredit,
  handleOrigin,
} = badCreditSlice.actions;

export const selectActiveStep = (state: RootStateType) =>
  state.badCredit.activeStep;
export const selectIsOpen = (state: RootStateType) => state.badCredit.isOpen;
export const selectIsBilletAlertOpen = (state: RootStateType) =>
  state.badCredit.isBilletAlertOpen;
export const selectSelectedDebts = (state: RootStateType) =>
  state.badCredit.selectedDebts;
export const selectSentBadCredit = (state: RootStateType) =>
  state.badCredit.sentBadCredit;
export const selectCreditorInfo = (state: RootStateType) =>
  state.badCredit.creditorInfo;
export const selectDebtorInfo = (state: RootStateType) =>
  state.badCredit.debtorInfo;
export const selectOrigin = (state: RootStateType) => state.badCredit.origin;
export default badCreditSlice.reducer;
