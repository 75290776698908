export const EDIT_PROFILE = [
  'BACKOFFICE:PERFIL:EDITAR:USUARIO',
  'ADMINISTRATIVO:PERFIL:EDITAR:USUARIO',
];

export const CONSULT_ENRICHMENT = ['LOCALIZE:CNPJ', 'LOCALIZE:CPF'];
export const CONSULT_SCORE = [
  'ASSERTIVASCORE:RECUPERE:PF',
  'ASSERTIVASCORE:RECUPERE:PJ',
];

export const EDIT_DEBT = [
  'SIMPLIFICA:DIVIDA:ALTERAR:EMPRESA',
  'SIMPLIFICA:DIVIDA:ALTERAR:GRUPO',
];

export const ENRICH_DEBTOR_SCORE = ['SIMPLIFICA:DEVEDOR:SCORE'];
export const ENRICH_DEBTOR = ['SIMPLIFICA:DEVEDOR:ENRIQUECER'];

export const SEE_DEBTS = [
  'SIMPLIFICA:DIVIDA:VISUALIZAR:USUARIO',
  'SIMPLIFICA:DIVIDA:VISUALIZAR:GRUPO',
  'SIMPLIFICA:DIVIDA:VISUALIZAR:EMPRESA',
];

export const ADD_DEBT = ['SIMPLIFICA:DIVIDA:INDIVIDUAL:INCLUIR'];
export const ADD_DEBT_BATCH = ['SIMPLIFICA:DIVIDA:LOTE:INCLUIR'];

export const DELETE_DEBT = [
  'SIMPLIFICA:DIVIDA:EXCLUIR:EMPRESA',
  'SIMPLIFICA:DIVIDA:EXCLUIR:GRUPO',
];

export const TRACK_CONFIG = [
  'SIMPLIFICA:REGUA:CONFIGURAR:USUARIO',
  'SIMPLIFICA:REGUA:CONFIGURAR:GRUPO',
  'SIMPLIFICA:REGUA:CONFIGURAR:EMPRESA',
];

export const EDIT_DEBTOR = [
  'SIMPLIFICA:DEVEDOR:ALTERAR:GRUPO',
  'SIMPLIFICA:DEVEDOR:ALTERAR:EMPRESA',
];

export const DELETE_DEBTOR = [
  'SIMPLIFICA:DEVEDOR:EXCLUIR:GRUPO',
  'SIMPLIFICA:DEVEDOR:EXCLUIR:EMPRESA',
];

export const SEE_DEBTORS = [
  'SIMPLIFICA:DEVEDOR:VISUALIZAR:EMPRESA',
  'SIMPLIFICA:DEVEDOR:VISUALIZAR:GRUPO',
  'SIMPLIFICA:DEVEDOR:VISUALIZAR:USUARIO',
];

export const CONFIRM_PAYMENT = ['SIMPLIFICA:DIVIDA:DARBAIXA'];

export const INSERT_BLACKLIST_CONFIG = [
  'SIMPLIFICA:CONFIGURAR:BLACKLIST:INCLUIR:EMPRESA',
];

export const EDIT_BLACKLIST = [
  'SIMPLIFICA:CONFIGURAR:BLACKLIST:EDITAR:EMPRESA',
];

export const SEE_BLACKLIST = [
  'SIMPLIFICA:CONFIGURAR:BLACKLIST:VISUALIZAR:EMPRESA',
  'SIMPLIFICA:CONFIGURAR:BLACKLIST:VISUALIZAR:GRUPO',
  'SIMPLIFICA:CONFIGURAR:BLACKLIST:VISUALIZAR:USUARIO',
];

export const SEE_BAD_CREDIT = ['NEGATIVACAO:QUOD'];

export const CALL_WEBPHONE = 'WEBPHONE:FAZER:LIGACAO';

export const SEE_DASHBOARD = [
  'SIMPLIFICA:DASHBOARD:VISUALIZAR:EMPRESA',
  'SIMPLIFICA:DASHBOARD:VISUALIZAR:GRUPO',
  'SIMPLIFICA:DASHBOARD:VISUALIZAR:USUARIO',
];

export const FILTER_DASHBOARD_GROUPS = [
  'SIMPLIFICA:DASHBOARD:VISUALIZAR:EMPRESA',
];

export const FILTER_DASHBOARD_USERS = [
  'SIMPLIFICA:DASHBOARD:VISUALIZAR:EMPRESA',
  'SIMPLIFICA:DASHBOARD:VISUALIZAR:GRUPO',
];

export const SIMPLIFICA_COBRANCA_INTELIGENTE =
  'SIMPLIFICA:COBRANCA:INTELIGENTE';

export const SIMPLIFICA_EMAIL_AUTOMATICO = 'SIMPLIFICA:EMAIL:AUTOMATICO';

export const SEE_STRATEGY_LIST = ['SIMPLIFICA:ESTRATEGIA'];
