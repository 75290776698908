import { styled } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { AUEmptyData } from '@assertiva/assertiva-ui';

type StyledTypographyWrapperProps = {
  isImageFile?: boolean;
  mode?: boolean;
};

export const StyledAUEmptyDataWrapper = styled(
  ({ emptyDataSubtitleStyles, ...rest }) => <AUEmptyData {...rest} />
)(({ emptyDataSubtitleStyles }: any) => {
  return {
    '& .empty-data-image': {
      minHeight: '41px',
      minWidth: '60px',
      transition: 'all  .25s linear',
      margin: '0px 10px 10px 10px',
    },
    '& .empty-data-subtitle': {
      color: '#646464',
      transition: 'all  .25s linear',
      fontSize: 14,
      fontWeight: 400,
      width: 400,
      ...emptyDataSubtitleStyles,
    },
  };
});

export const StyledIconButton = styled(({ isImageFile, mode, ...rest }) => (
  <IconButton {...rest} />
))(({ mode, isImageFile }: StyledTypographyWrapperProps) => ({
  ...(mode
    ? { color: '#FFF', marginTop: -9, marginRight: -9 }
    : { marginTop: -5, marginRight: 0 }),
  cursor: 'pointer',
  padding: 0,
}));

export const StyledAttachFileButton = styled('a')(() => {
  return {
    cursor: 'pointer',
    color: 'rgba(180, 180, 180, 1)',
  };
});

export const FileUploadContainer = styled('section')(() => {
  return {
    position: 'relative',
    margin: '25px 0 15px',
    border: '1px solid rgba(35, 47, 52, 0.12)',
    padding: '35px 20px',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  };
});

export const FileField = styled('input')(() => {
  return {
    fontSize: '18px',
    display: 'block',
    width: '100%',
    border: 'none',
    textTransform: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    '&:focus': {
      outline: 'none',
    },
  };
});

export const FilePreviewContainer = styled('article')(() => {
  return {
    marginBottom: '35px',
    span: {
      fontSize: '14px',
    },
  };
});

export const PreviewList = styled('section')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});

export const FileMetaData = styled(({ isImageFile, mode, ...rest }) => (
  <div {...rest} />
))(({ isImageFile, mode }: StyledTypographyWrapperProps) => {
  return {
    ...(isImageFile || mode ? { display: 'flex' } : undefined),
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    borderRadius: '6px',
    color: 'white',
    fontWeight: 'bold',
    ...(!isImageFile || !mode ? { opacity: 1 } : { opacity: 0 }),
    transition: 'opacity .2s ease-in-out',
    ...(mode ? { backgroundColor: 'rgba(138, 0, 193, 0.55)' } : undefined),

    '&:hover': {
      opacity: '1',
    },
  };
});

export const PreviewContainer = styled(({ isImageFile, mode, ...rest }) => (
  <section {...rest} />
))(({ isImageFile, mode }: StyledTypographyWrapperProps) => {
  return {
    padding: '0.25rem',
    paddingLeft: 0,
    ...(mode
      ? { width: '20%', height: '100px' }
      : {
          width: '50%',
          height: '45px',
        }),

    borderRadius: '6px',

    boxSizing: 'border-box',
    '& > div:first-of-type': {
      height: '100%',
      position: 'relative',
    },
    '@media only screen and (max-width: 750px)': {
      width: '25%',
    },
    '@media only screen and (max-width: 500px)': {
      width: '50%',
    },
    '@media only screen and (max-width: 400px)': {
      width: '100%',
      padding: '0 0 0.4em',
    },
  };
});

export const ImagePreview = styled('img')(() => {
  return {
    borderRadius: '6px',
    width: '100%',
    height: '100%',
  };
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const UploadFileBtn = styled('button')(() => {
  return {
    boxSizing: 'border-box',
    appearance: 'none',
    backgroundColor: 'transparent',
    border: '2px solid #3498db',
    cursor: 'pointer',
    fontSize: '1rem',
    lineHeight: 1,
    padding: '1.1em 2.8em',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: '700',
    borderRadius: '6px',
    color: '#3498db',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    transition: 'color 250ms ease-in-out',
    fontFamily: 'Open Sans, sans-serif',
    width: '45%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 0,
    justifyContent: 'center',
    '&:after': {
      content: '',
      position: 'absolute',
      display: 'block',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: 0,
      height: '100%',
      background: '#3498db',
      zIndex: '-1',
      transition: 'width 250ms ease-in-out',
    },
    i: {
      fontSize: '22px',
      marginRight: '5px',
      borderRight: '2px solid',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: '20%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '@media only screen and (max-width: 500px)': {
      width: '70%',
    },
    '@media only screen and (max-width: 350px)': {
      width: '100%',
    },
    '&:hover': {
      color: '#fff',
      outline: 0,
      background: 'transparent',
      '&:after': {
        width: '110%',
      },
    },
    '&:focus': {
      outline: 0,
      background: 'transparent',
    },
    '&:disabled': {
      opacity: '0.4',
      filter: 'grayscale(100%)',
      pointerEvents: 'none',
    },
  };
});
