import { AUAnalyticsUtils } from '@assertiva/assertiva-ui';
import { IGAEvent } from '@assertiva/assertiva-ui/core/utils/analytics.v2';

interface Options {
  sendExternalToo?: boolean;
}

export const sendInternalAnalytics = (
  event: Partial<IGAEvent>,
  options: Options = { sendExternalToo: true }
) => AUAnalyticsUtils.sendAnalytics(event, options.sendExternalToo, true);
