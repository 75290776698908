import axios from 'axios';
import BaseService from './BaseService';

class AddressService extends BaseService {
  getStates() {
    return axios.get(
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
    );
  }

  getCities(uf) {
    return axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`
    );
  }

  async getAddress(zipCode) {
    return axios.get(`${this.BASE_ADDRESS_URL}${zipCode}/json/`);
  }
}

export default new AddressService();
