import { getOnlyPropertiesWithValue } from '@src/utils/functionUtils';
import { StrategyNBAGroup } from './types';
import { DEFAULT_ID_STATUS } from '../debt/list/constants';
import _orderBy from 'lodash/orderBy';
import { AUCookiesUtils } from '@assertiva/assertiva-ui';
import { STRATEGY_DEBTS_COOKIE } from './constants';
import {
  fetchStrategyDebts,
  fetchStrategyDebtsSummary,
  fetchUserStrategy,
} from './strategyDebtSlice';
import { fetchWebphoneCampaignSummary } from '../webphoneCampaigns/webphoneCampaignsSlice';

export const isValidNbaGroupType = (
  idGroupTypeNba: number,
  nbaGroups?: StrategyNBAGroup[]
) => nbaGroups?.some((g) => Number(g.idGroupTypeNba) === idGroupTypeNba);

export const getOnlyValidFilters = (filters): Record<string, any> =>
  getOnlyPropertiesWithValue({
    page: filters?.page,
    size: filters?.size,
    debtorName: filters?.debtorName,
    typeDoc: filters?.typeDoc,
    doc: filters?.doc,
    phone: filters?.phone,
    minValueDebt: filters?.minValueDebt,
    maxValueDebt: filters?.maxValueDebt,
    minFinalDateDebt: filters?.minFinalDateDebt,
    maxFinalDateDebt: filters?.maxFinalDateDebt,
    minFinalDateEvent: filters?.minFinalDateEvent,
    maxFinalDateEvent: filters?.maxFinalDateEvent,
    contract: filters?.contract,
    reference: filters?.reference,
    onlyAgreement: filters?.onlyAgreement,
    onlyNegativated: filters?.onlyNegativated,
    idGroupTypeNba: filters?.idGroupTypeNba,
    idStatus: filters?.idStatus,
    hasBillet: filters?.hasBillet,
  });

export const getDefaultIdStatusDebts = (mapSummary) =>
  mapSummary[DEFAULT_ID_STATUS]
    ? DEFAULT_ID_STATUS
    : Object.keys(mapSummary)[0];

export const orderedStrategyNBAGroups = (groups?: StrategyNBAGroup[]) => {
  if (groups) {
    return _orderBy(groups, ['order'], ['asc']);
  }
};

export const getFiltersCookies = () =>
  JSON.parse(AUCookiesUtils.getItem(STRATEGY_DEBTS_COOKIE) || '{}');

export const refetchAllStrategyRequests = (dispatch) =>
  Promise.all([
    dispatch(fetchUserStrategy()),
    dispatch(fetchWebphoneCampaignSummary()),
    dispatch(fetchStrategyDebtsSummary({ refetch: true })),
    dispatch(fetchStrategyDebts({ refetch: true })),
  ]);
