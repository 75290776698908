import { useSelector } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';

import { AUBoxSkeleton } from '@assertiva/assertiva-ui';

import { selectGlobalWallet } from '../../walletSlice';
import { StyledSelect } from './style';
import { useWalletSelect } from './useWalletSelect';

const WalletSelect = () => {
  const { state, handleFetchWalletsAvailable, handleOnChangeSelectedWallet } =
    useWalletSelect();

  const walletGlobal = useSelector(selectGlobalWallet);
  return (
    <>
      <StyledSelect
        inputProps={{
          'data-testid': 'wallet-select-input-value',
          id: 'wallet-select-input-value',
        }}
        value={state.list.length > 0 ? walletGlobal.id : ''}
        fullWidth
        disableUnderline
        style={{ maxWidth: '350px', minWidth: '300px' }}
        onOpen={() => {
          handleFetchWalletsAvailable();
        }}
        id="wallet-select"
      >
        {state.list.map((item) => (
          <MenuItem
            disabled={item.disabled}
            key={item.id}
            value={item.id}
            onClick={() => {
              handleOnChangeSelectedWallet(item);
            }}
          >
            {item.name}
          </MenuItem>
        ))}
        {state.isLoading && state.list.length <= 1 && (
          <MenuItem>
            <AUBoxSkeleton width="100%" height={24} />
          </MenuItem>
        )}
      </StyledSelect>
    </>
  );
};

export default WalletSelect;
