import { AUNotifier, ErrorUtils } from '@assertiva/assertiva-ui';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ConfigService from '@src/services/ConfigService';
import { RootState } from '@src/store/rootReducer';
import { BilletStatus } from './types';

export interface IBilletState {
  possibleStatus: BilletStatus[];
  debtor?: any;
}

export const initialState: IBilletState = {
  possibleStatus: [],
  debtor: undefined,
};

export const fetchPossibleStatus = createAsyncThunk(
  'billet/fetchPossibleStatus',
  async () => {
    try {
      const res = await ConfigService.getBilletStatus();
      return res.data.body;
    } catch (error) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
    }
  }
);

const billetSlice = createSlice({
  name: 'billet',
  initialState,
  reducers: {
    setState(state, { payload }: PayloadAction<Partial<IBilletState>>) {
      return {
        ...state,
        ...payload,
      };
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPossibleStatus.fulfilled, (state, action) => {
      state.possibleStatus = action.payload;
    });
  },
});

export const { resetState, setState } = billetSlice.actions;

export const selectBilletPossibleStatus = (state: RootState) =>
  state.billet.possibleStatus;

export const selectDebtorBillet = (state: RootState) => state.billet.debtor;

export default billetSlice.reducer;
