import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pagination } from '@src/constants/types';
import { RootState } from '@src/store/rootReducer';
import { STEPS } from './components/AddAccountDrawer/constants';
import {
  AccountStatusContent,
  AddAcccountFormValues,
  Bank,
  EditDrawerName,
  IMonetaryRestatementConfig,
  IMonetaryRestatementIndex,
  IMonetaryRestatementLastYear,
  IMonetaryRestatementPeriod,
  ITermsOfUse,
  PaymentAccount,
} from './types';
import { UNDEFINED_INDEX } from './constants';
import { AUNotifier, ErrorUtils } from '@assertiva/assertiva-ui';
import ConfigService from '@src/services/ConfigService';

export interface IFinancialState {
  debtsConfig: {
    feeRate: number;
    fineRate: number;
  };
  agreementConfig: {
    feeRate: number;
    fineRate: number;
    maxDiscount: number;
    honorary: number;
  };
  monetaryRestatementConfig: IMonetaryRestatementConfig | null;
  mapMonetaryRestatementIndexes: IMonetaryRestatementIndex[];
  mapMonetaryRestatementPeriods: IMonetaryRestatementPeriod[];
  monetaryRestatementIndexLastYear: IMonetaryRestatementLastYear[];
  paymentAccountsList: PaymentAccount[];
  paymentAccountsPagination: Pagination;
  editDrawerOpen?: EditDrawerName;
  addAccountDrawerOpen: boolean;
  interactedWithAddAccountDrawer: boolean;
  addAccountData: Partial<AddAcccountFormValues>;
  addAccountStep: number;
  madeInitialFetch: boolean;
  mapStatusAccounts: AccountStatusContent[];
  banks: Bank[];
  accountToSeeMore: PaymentAccount | null;
  lastAddedAccount: PaymentAccount | null;
  termsOfUse: ITermsOfUse;
}

export const INITIAL_PAGINATION = { page: 0, rowsPerPage: 5, count: 0 };

export const initialState: IFinancialState = {
  debtsConfig: {
    feeRate: 0,
    fineRate: 0,
  },
  agreementConfig: {
    feeRate: 0,
    fineRate: 0,
    honorary: 0,
    maxDiscount: 0,
  },
  monetaryRestatementConfig: {
    idIndex: null,
    idPeriod: null,
    ignoreNegativeValue: null,
  },
  mapMonetaryRestatementIndexes: [],
  mapMonetaryRestatementPeriods: [],
  monetaryRestatementIndexLastYear: [],
  editDrawerOpen: undefined,
  addAccountDrawerOpen: false,
  interactedWithAddAccountDrawer: false,
  addAccountData: {},
  addAccountStep: 0,
  madeInitialFetch: false,
  paymentAccountsList: [],
  paymentAccountsPagination: INITIAL_PAGINATION,
  mapStatusAccounts: [],
  banks: [],
  accountToSeeMore: null,
  lastAddedAccount: null,
  termsOfUse: {
    id: null,
    accepted: null,
    html: null,
    urlPdf: null,
  },
};

const financialSlice = createSlice({
  name: 'financial',
  initialState,
  reducers: {
    setState(state, { payload }: PayloadAction<Partial<IFinancialState>>) {
      return {
        ...state,
        ...payload,
      };
    },
    updateAddAccountData(
      state,
      { payload }: PayloadAction<Partial<AddAcccountFormValues>>
    ) {
      state.addAccountData = {
        ...state.addAccountData,
        ...payload,
      };
    },
    updateTermsOfUse(state, { payload }: PayloadAction<Partial<ITermsOfUse>>) {
      state.termsOfUse = {
        ...state.termsOfUse,
        ...payload,
      };
    },
    nextAddAccountStep(state) {
      if (state.addAccountStep === STEPS.length - 1) return state;
      state.addAccountStep += 1;
    },
    prevAddAccountStep(state) {
      if (state.addAccountStep === 0) return state;
      state.addAccountStep -= 1;
    },
    resetAddAcountDrawer(state) {
      state.addAccountData = initialState.addAccountData;
      state.addAccountStep = initialState.addAccountStep;
      state.interactedWithAddAccountDrawer =
        initialState.interactedWithAddAccountDrawer;
    },
    updateMapStatusAccounts(
      state,
      { payload }: PayloadAction<AccountStatusContent[]>
    ) {
      state.mapStatusAccounts = payload;
    },
    resetState: () => initialState,
  },
});

export const {
  resetState,
  setState,
  updateAddAccountData,
  nextAddAccountStep,
  prevAddAccountStep,
  resetAddAcountDrawer,
  updateTermsOfUse,
  updateMapStatusAccounts,
} = financialSlice.actions;

interface FetchMonetaryRestatementOptions {
  idDebt?: number | string;
}

export const fetchMonetaryRestatement = createAsyncThunk(
  'general/handleFetchMonetaryRestatement',
  async (
    options: FetchMonetaryRestatementOptions | undefined,
    { dispatch }
  ) => {
    try {
      const [resConfig, resIndexes] = await Promise.all([
        options?.idDebt
          ? ConfigService.getMonetaryRestatementConfigByIdDebt(options?.idDebt)
          : ConfigService.getMonetaryRestatementConfig(),
        ConfigService.getMonetaryRestatementIndexes(),
      ]);

      dispatch(
        setState({
          monetaryRestatementConfig: resConfig.data.body,
          mapMonetaryRestatementIndexes: resIndexes.data.body,
        })
      );
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    }
  }
);

export const selectAddAccountData = (state: RootState) =>
  state.financial.addAccountData;

export const selectBanks = (state: RootState) => state.financial.banks;

export const selectInteractedWithAddAccountDrawer = (state: RootState) =>
  state.financial.interactedWithAddAccountDrawer;

export const selectMadeInitialFetch = (state: RootState) =>
  state.financial.madeInitialFetch;

export const selectPaymentAccountsList = (state: RootState) =>
  state.financial.paymentAccountsList;

export const selectPaymentAccountsPagination = (state: RootState) =>
  state.financial.paymentAccountsPagination;

export const selectLastAddedAccount = (state: RootState) =>
  state.financial.lastAddedAccount;

export const selectTermsOfUse = (state: RootState) =>
  state.financial.termsOfUse;

export const selectMapStatusAccounts = (state: RootState) =>
  state.financial.mapStatusAccounts;

export const selectMonetaryRestatementConfig = (state: RootState) =>
  state.financial.monetaryRestatementConfig;

export const selectMonetaryRestatementIndexes = (state: RootState) =>
  state.financial.mapMonetaryRestatementIndexes;

export const selectMonetaryRestatementPeriods = (state: RootState) =>
  state.financial.mapMonetaryRestatementPeriods;

export const selectMonetaryRestatementIndexLastYear = (state: RootState) =>
  state.financial.monetaryRestatementIndexLastYear;

export const selectCurrentMonetaryRestatemntIndex = (state: RootState) =>
  state.financial.mapMonetaryRestatementIndexes.find(
    (i) => i.id === state.financial.monetaryRestatementConfig?.idIndex
  )?.name ?? UNDEFINED_INDEX;

export const selectDebtsConfig = (state: RootState) =>
  state.financial.debtsConfig;

export const selectAgreementConfig = (state: RootState) =>
  state.financial.agreementConfig;

export default financialSlice.reducer;
