import {
  AULoadingManager,
  AUNotifier,
  ErrorUtils,
} from '@assertiva/assertiva-ui';
import ConfigService from '@src/services/ConfigService';
import S3Service from '@src/services/S3Service';
import { preloadImage } from '@src/utils/functionUtils';
import { useState, useRef, useEffect } from 'react';
import { TIdFileLogo } from '../../types';

export interface UploadLogoParams {
  setIdFileLogo: (idFileLogo: TIdFileLogo) => void;
  idFileLogo: TIdFileLogo;
  postUploadUrlLogo?: (params) => Promise<any>;
  uploadFileByPreSignedURL?: (params) => Promise<any>;
}

export function useUploadLogo({
  setIdFileLogo,
  idFileLogo,
  postUploadUrlLogo = ConfigService.postUploadUrlLogo,
  uploadFileByPreSignedURL = S3Service.uploadFileByPreSignedURL,
}: UploadLogoParams) {
  const [imgSrc, setImgSrc] = useState<string>();
  const [idFileLogoLocal, setIdFileLogoLocal] = useState<TIdFileLogo>(null);
  const [loadedImg, setLoadedImg] = useState(true);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleRemoveLogo = () => {
    setIdFileLogo(null);
    setImgSrc(undefined);
    setLoadedImg(true);
    if (fileInputRef?.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleClickUpload = () => fileInputRef?.current?.click();

  const handleUpload = async (evt) => {
    try {
      AULoadingManager.show();
      const { files } = evt.target;
      if (files.length) {
        const file = files[0];

        const responsePost = await postUploadUrlLogo({
          name: file.name,
          contentType: file.type,
          fileSize: file.size,
        });

        const {
          data: {
            body: { idFileS3, url: preSignedURL },
          },
        } = responsePost;

        await uploadFileByPreSignedURL({ preSignedURL, file });

        setIdFileLogoLocal(idFileS3);
        setImgSrc(URL.createObjectURL(file));
      }
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      AULoadingManager.close();
    }
  };

  useEffect(() => {
    if (!imgSrc && idFileLogo) {
      const src = ConfigService.getLogoUrl(idFileLogo);
      setLoadedImg(false);
      preloadImage(src, () => {
        setImgSrc(src);
        setLoadedImg(true);
      });
    }
  }, [imgSrc, idFileLogo]);

  useEffect(() => {
    if (idFileLogoLocal) setIdFileLogo(idFileLogoLocal);
  }, [setIdFileLogo, idFileLogoLocal]);

  return {
    imgSrc,
    handleRemoveLogo,
    fileInputRef,
    handleClickUpload,
    handleUpload,
    loadedImg,
  };
}
