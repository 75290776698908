import { styled } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

export const StyledCardWrapper = styled(Card)(({ theme }) => {
  return {
    position: 'fixed',
    zIndex: 100000,
    boxShadow: theme.shadows[3],
    bottom: 0,
    right: 12,
    width: 355,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    '& .cardActions': {
      backgroundColor: 'rgba(138, 0, 193, 1)',
      justifyContent: 'space-between',
      padding: theme.spacing(1.5, 2),
    },
    '& .collapse': {
      padding: 0,

      '& .fileContainer': {
        padding: '10px 16px 16px',
      },
    },
    '& .icons': {
      marginLeft: 'auto',
    },
    '& .expand': {
      color: '#fff',
      padding: '6px',
      transform: 'rotate(180deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '& .expandOpen': {
      color: '#fff',
      padding: '6px',
      transform: 'rotate(0deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  };
});
