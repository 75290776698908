import { styled, Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

interface StyledCardProps {
  selected: boolean;
  loading: boolean;
  backgroundColor?: string;
  borderColor?: string;
  maxWidth?: string | number;
  width?: string;
  minHeight?: string | number;
  justifyContent?: string;
  theme: Theme;
  wrapColumns?: boolean;
}

export const StyledCard = styled(
  ({
    selected,
    backgroundColor,
    borderColor,
    loading,
    maxWidth,
    width,
    minHeight,
    justifyContent,
    wrapColumns,
    ...rest
  }) => <div {...rest} />
)(
  ({
    theme,
    selected,
    backgroundColor,
    loading,
    maxWidth = 240,
    width = '100%',
    minHeight = 128,
    justifyContent = 'space-between',
    borderColor,
    wrapColumns,
  }: StyledCardProps) => {
    const resolveBoxShadowHover = () => {
      if (selected) return 'none';
      if (loading) return theme.shadows[3];
      return theme.shadows[5];
    };
    const wrapColumnsStyles = {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gridColumnGap: 40,
      gridRowGap: 16,
      minHeight: 82,
      flexWrap: 'wrap',
    } as any;

    return {
      backgroundColor: selected ? backgroundColor : theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      height: '100%',
      minHeight,
      maxWidth,
      width,
      display: 'flex',
      flexDirection: 'column',
      justifyContent,
      borderLeft: `12px solid ${loading ? 'transparent' : borderColor}`,
      userSelect: 'none',
      cursor: loading ? undefined : 'pointer',
      position: 'relative',
      transition: 'box-shadow .1s ease-in, transform .1s ease-in',
      boxShadow: selected && !loading ? 'none' : theme.shadows[3],
      transform: selected && !loading ? 'translateY(2px)' : 'none',

      ...(wrapColumns ? wrapColumnsStyles : {}),

      '&:hover': {
        boxShadow: resolveBoxShadowHover(),
      },

      '& .value-debts': {
        wordBreak: 'break-all',
      },
    };
  }
);

interface StyledCardTitleProps {
  theme: Theme;
  fontSize?: string;
  wrapColumns?: boolean;
}

export const StyledCardTitle = styled(({ fontSize, wrapColumns, ...props }) => (
  <Typography {...props} />
))(({ theme, fontSize = '16px', wrapColumns }: StyledCardTitleProps) => ({
  paddingRight: theme.spacing(2),
  fontWeight: 700,
  lineHeight: 1.2,
  textTransform: 'uppercase',
  fontSize,
  maxWidth: 130,
  minWidth: wrapColumns ? 130 : 'unset',
}));

export const StyledCheckIcon = styled(CheckCircleIcon)({
  position: 'absolute',
  top: 14,
  right: 12,
});

export const StyledCheckbox = styled(Checkbox)({
  position: 'absolute',
  top: 0,
  right: 0,
});
