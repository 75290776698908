import { DiscadoraTypes } from '@assertiva/business-ui';
import { Image, Nullish } from '@src/constants/types';

export interface ICampaingTopic {
  id?: number;
  image: Image;
  text: string;
  title: string;
}

export enum WebphoneCampaignStatus {
  Created,
  SentToQueue,
  Processing,
  Success,
  Error,
}

export interface WebphoneCampaign {
  campaignid: number;
  campaigname: string;
  id_purpose: number;
  script: string;
  created_at: string;
  campaignownername: string;
  totallines: string;
  campaignprogress: number;
  cancall: boolean;
}

export interface WebphoneCampaignSummary {
  totalDebts: number;
}

export interface WebphoneCampaignsState {
  lastCampaignStatus?: Nullish<WebphoneCampaignStatus>;
  lastCampaign?: Nullish<DiscadoraTypes.IListDialing>;
  campaignSummary?: WebphoneCampaignSummary;
  isOpenDialingDrawer: boolean;
  isOpenCreateCampaignDrawer: boolean;
}

export interface IWebphoneCampaignExpirationOption {
  id: number;
  description: string;
}
