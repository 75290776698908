import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@src/store/rootReducer';

import {
  DrawerModes,
  StrategyConfig,
  StrategyDrawerSteps,
  StrategyId,
  StrategyState,
} from './types';
import { initialState } from './constants';
import StrategyService from '@src/services/StrategyService';

import { getQueryParams, setQueryParams } from '@src/components/filters';
import {
  AULoadingManager,
  AUNotifier,
  ErrorUtils,
  StringUtils,
} from '@assertiva/assertiva-ui';
import {
  closeLinearLoading,
  showLinearLoading,
} from '@src/hooks/useLinearLoading';

const strategySlice = createSlice({
  name: 'strategy',
  initialState,
  reducers: {
    setStrategyState: (
      state,
      action: PayloadAction<Partial<StrategyState>>
    ) => ({
      ...state,
      ...action.payload,
    }),
    setStategyActive: (
      state,
      action: PayloadAction<HandleActiveStrategyParams>
    ) => {
      const strategyToSet = state.list.find(
        (strategy) => strategy.id === action.payload.id
      );

      if (strategyToSet) strategyToSet.isActive = action.payload.isActive;
    },
    setStrategyConfig: (
      state,
      action: PayloadAction<Partial<StrategyConfig>>
    ) => {
      state.strategyConfig = {
        ...state.strategyConfig,
        ...action.payload,
      };
      state.hasChangedStrategyConfig = true;
    },
    resetStrategyConfig: (state) => {
      state.strategyConfig = undefined;
      state.hasChangedStrategyConfig = false;
      state.strategyDrawerStep = StrategyDrawerSteps.InitialConfig;
    },
    openAddStrategy: (state) => {
      state.drawerMode = DrawerModes.Add;
    },
    openEditStrategy: (state) => {
      state.drawerMode = DrawerModes.Edit;
    },
    closeStrategyDrawer: (state) => {
      state.drawerMode = DrawerModes.Closed;
    },
    nextStep: (state) => {
      state.strategyDrawerStep = state.strategyDrawerStep + 1;
    },
    prevStep: (state) => {
      state.strategyDrawerStep = state.strategyDrawerStep - 1;
    },
  },
});

export const {
  setStrategyState,
  setStategyActive,
  setStrategyConfig,
  resetStrategyConfig,
  openAddStrategy,
  openEditStrategy,
  closeStrategyDrawer,
  nextStep,
  prevStep,
} = strategySlice.actions;

export const fetchStrategyList = createAsyncThunk(
  'strategy/fetchStrategyList',
  async (_, { dispatch }) => {
    dispatch(setStrategyState({ isLoadingTable: true }));

    try {
      const { page, size } = getQueryParams();

      const res = await StrategyService.getStrategyList({ page, size });

      if (res.data?.body) {
        const { list, count, page, size } = res.data.body;

        if (!list.length && page > 0) {
          setQueryParams({ page: page - 1, size });
          dispatch(fetchStrategyList());
          return;
        }

        dispatch(
          setStrategyState({
            list,
            pagination: { count, page, rowsPerPage: size },
          })
        );
        dispatch(setStrategyState({ isLoadingTable: false }));
      }
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
      dispatch(setStrategyState({ isLoadingTable: false }));
    }
  }
);

export const fetchHasAvailableUsers = createAsyncThunk(
  'strategy/fetchHasAvailableUsers',
  async (_, { dispatch }) => {
    try {
      const res = await StrategyService.getStrategyUsersAvailable();
      dispatch(
        setStrategyState({
          hasAvailableUsers: res.data.body?.length > 0,
        })
      );
    } catch (err) {
      console.error(err);
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    }
  }
);

interface HandleActiveStrategyParams {
  id: StrategyId;
  isActive: boolean;
}

export const handleActiveStrategy = createAsyncThunk(
  'strategy/handleActiveStrategy',
  async ({ id, isActive }: HandleActiveStrategyParams, { dispatch }) => {
    try {
      showLinearLoading();
      const res = await StrategyService.patchStrategy(id, { isActive });

      if (res.data.success) {
        dispatch(setStategyActive({ id, isActive }));
      }
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      closeLinearLoading();
    }
  }
);

export const handleDeleteStrategy = createAsyncThunk(
  'strategy/handleDeleteStrategy',
  async (id: StrategyId, { dispatch }) => {
    try {
      AULoadingManager.show();
      const res = await StrategyService.deleteStrategy(id);

      if (res.data.success) {
        AUNotifier.success('Estratégia de cobrança excluída com sucesso!');
        dispatch(fetchStrategyList());
        dispatch(fetchHasAvailableUsers());
      }
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      AULoadingManager.close();
    }
  }
);

export const handleSubmitStrategy = createAsyncThunk(
  'strategy/handleSubmitStrategy',
  async (strategyConfig: StrategyConfig, { dispatch }) => {
    try {
      AULoadingManager.show();
      const { id, ...payload } = strategyConfig;
      const minValueNumber = StringUtils.formatCurrencyToFloatPtBr(
        `${payload?.minValue}`
      );
      const maxValueNumber = StringUtils.formatCurrencyToFloatPtBr(
        `${payload?.maxValue}`
      );
      const resolvedPayload: StrategyConfig = {
        ...payload,
        idAgingOptions: payload?.idAgingOptions ?? [],
        minValue: minValueNumber || null,
        maxValue: maxValueNumber || null,
      };
      let res;
      if (id) {
        res = await StrategyService.putStrategy(id, resolvedPayload);

        if (res.data.success) {
          AUNotifier.success('Estratégia de cobrança alterada com sucesso!');
        }
      } else {
        res = await StrategyService.postStrategy(resolvedPayload);

        if (res.data.success) {
          dispatch(setStrategyState({ lastCreatedStrategy: res.data.body }));
        }
      }

      if (res.data.success) {
        dispatch(fetchStrategyList());
        dispatch(fetchHasAvailableUsers());
        dispatch(closeStrategyDrawer());
        setTimeout(() => {
          dispatch(resetStrategyConfig());
        }, 500);
      }
    } catch (err) {
      console.error(err);
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      AULoadingManager.close();
    }
  }
);

export const handleOpenEditStrategy = createAsyncThunk(
  'strategy/handleOpenEditStrategy',
  async (id: StrategyId, { dispatch }) => {
    try {
      AULoadingManager.show();
      const res = await StrategyService.getStrategyConfig(id);

      if (res.data.success) {
        const strategyConfig = res.data.body;
        const minValueNumber = Number(strategyConfig.minValue);
        const maxValueNumber = Number(strategyConfig.maxValue);

        // usando o set geral pra não marcar o hasChangedStrategyConfig e ativar de cara o preventMissClick
        dispatch(
          setStrategyState({
            strategyConfig: {
              ...strategyConfig,
              minValue: minValueNumber
                ? StringUtils.formatFloatToCurrency(minValueNumber)
                : undefined,
              maxValue: maxValueNumber
                ? StringUtils.formatFloatToCurrency(maxValueNumber)
                : undefined,
            },
          })
        );
        dispatch(openEditStrategy());
      }
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      AULoadingManager.close();
    }
  }
);

export const selectStrategyTable = (state: RootState) => ({
  list: state.strategy.list,
  isLoading: state.strategy.isLoadingTable,
  pagination: state.strategy.pagination,
  hasAvailableUsers: state.strategy.hasAvailableUsers,
});

export const selectStrategyDrawerMode = (state: RootState) =>
  state.strategy.drawerMode;

export const selectStrategyDrawerStep = (state: RootState) =>
  state.strategy.strategyDrawerStep;

export const selectStrategyConfig = (state: RootState) =>
  state.strategy.strategyConfig;

export const selectHasChangedStrategyConfig = (state: RootState) =>
  state.strategy.hasChangedStrategyConfig;

export const selectLastCreatedStrategy = (state: RootState) =>
  state.strategy.lastCreatedStrategy;

export default strategySlice.reducer;
