import { useState, useCallback } from 'react';
import { AUNotifier, ErrorUtils } from '@assertiva/assertiva-ui';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import _keyBy from 'lodash/keyBy';
import _values from 'lodash/values';
import { getUrlPreSignedByFile } from '@components/FileUpload/FileUploadUtils';
import { ACTION_UPLOAD } from '@components/FileUpload/FileUpload';
import S3Service from '@src/services/S3Service';
import ConfigService from '@src/services/ConfigService';
import { Nullish } from '@src/constants/types';
import { IEmailLogo } from '../types';
import { defaultLogo } from '../constants';

interface Params {
  setLogo: (logo: Nullish<IEmailLogo>) => void;
}

export function useLogo({ setLogo }: Params) {
  const [uploadFiles, setUploadFiles] = useState<Array<any>>([]);
  const [filesNotLoaded, setFilesNotLoaded] = useState<boolean>(true);

  // TODO: refatorar essa função para ficar mais fácil de entender e
  // tentar encontrar uma forma de extrair lógica e reutilizar no src/feature/debt/add/.../useStepThree
  const onGenerateS3PreSignedURL = useCallback(
    async (files, action) => {
      setFilesNotLoaded(false);
      if (_isEmpty(files) && action === ACTION_UPLOAD.ADDED) {
        AUNotifier.error('Tamanho do arquivo não pode ser superior à 10mb.');
        setFilesNotLoaded(true);
        return;
      }

      if (action === ACTION_UPLOAD.REMOVED) {
        const completedFilesArray: any = files.map((item, index) => {
          return {
            file: item,
            id: item.name,
            idFileS3: uploadFiles[index].idFileS3,
            progress: uploadFiles[index].progress,
          };
        });

        setUploadFiles(completedFilesArray);
        setFilesNotLoaded(true);
        setLogo(defaultLogo);
      }

      if (action === ACTION_UPLOAD.ADDED) {
        if (uploadFiles.length) {
          AUNotifier.error('É possível importar apenas 1 arquivo.');
          setUploadFiles([...uploadFiles]);
          return;
        }
        let addItemFile: any = [];

        files.map((item) => {
          (async (dataGetUrl, file) => {
            try {
              const response = await ConfigService.postEmailLogo(
                dataGetUrl as any
              );
              const {
                data: {
                  body: { idFileS3, url: preSignedURL },
                },
              } = response;

              await S3Service.uploadFileByPreSignedURL({ preSignedURL, file });

              setLogo(response.data.body);

              const newObj = {
                file,
                id: file.name,
                idFileS3,
                progress: 100,
              };

              addItemFile = [...addItemFile, newObj];
              const merged = _merge(
                _keyBy([...uploadFiles, ...addItemFile], 'file.name')
              );
              const values = _values(merged);

              setUploadFiles(values);
            } catch (err) {
              AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
              setUploadFiles([]);
            } finally {
              setFilesNotLoaded(true);
            }
          })(getUrlPreSignedByFile(item), item);
          return item;
        });
      }
    },
    [uploadFiles, setLogo]
  );

  return {
    onGenerateS3PreSignedURL,
    uploadFiles,
    filesNotLoaded,
    isShowingPreviewFiles: uploadFiles.length > 0 || !filesNotLoaded,
  };
}
