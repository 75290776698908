import { useMemo, useCallback, useState } from 'react';
import {
  LINK_KEY,
  defaultLinkVariable,
  defaultMessageVariables,
  limitChars,
} from './constants';
import { MessageVariable } from './types';
import { handleAddVariable, handleRemoveVariable } from './messageHandlers';

interface Params {
  message: string;
  setMessage: (message: string) => void;
}

export function useMessageVariables({ message, setMessage }: Params) {
  const [link, setLink] = useState<MessageVariable>(defaultLinkVariable);

  const messageVariables = useMemo(
    () => ({
      [LINK_KEY]: link,
      ...defaultMessageVariables,
    }),
    [link]
  );

  const onAddVariable = useCallback(
    (variableKey: string, variableLength: number, cursorPosition: number) =>
      handleAddVariable({
        limitChars,
        messageVariables,
        message,
        variableKey,
        variableLength,
        cursorPosition,
        setMessage,
      }),
    [message, setMessage, messageVariables]
  );

  const onRemoveVariable = useCallback(
    (variableKey: string) =>
      handleRemoveVariable({
        message,
        setMessage,
        variableKey,
      }),
    [message, setMessage]
  );

  return {
    messageVariables,
    onAddVariable,
    onRemoveVariable,
    link,
    setLink,
  };
}
