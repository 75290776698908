import React from 'react';
import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';

interface StepperFooterProps {
  handleBack?: () => void;
  backBtnProps?: ButtonProps;
  backBtnText?: string | React.JSX.Element;
  nextBtnProps?: ButtonProps;
  nextBtnText?: string | React.JSX.Element;
}

const StepperFooter = ({
  handleBack,
  backBtnProps,
  backBtnText = 'VOLTAR',
  nextBtnProps,
  nextBtnText = 'PRÓXIMO',
}: StepperFooterProps) => {
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-end"
      mt={3}
      gridColumnGap={24}
    >
      {handleBack && (
        <Button
          variant="text"
          color="primary"
          onClick={handleBack}
          id="btn-voltar"
          data-testid="btn-voltar"
          {...backBtnProps}
        >
          {backBtnText}
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        id="btn-proximo"
        data-testid="btn-proximo"
        {...nextBtnProps}
      >
        {nextBtnText}
      </Button>
    </Box>
  );
};

export default StepperFooter;
