import { Controller, useFormContext } from 'react-hook-form';
import Title from '../../components/Title';
import { StyledRulesFields } from './rulesFields/RulesFields.styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Clear from '@material-ui/icons/Clear';
import MenuItem from '@material-ui/core/MenuItem';
import { useTrackConfigContext } from '../../context';
import { useEffect } from 'react';

const BillingExpiration = () => {
  const { control, setValue } = useFormContext();

  const {
    state: {
      selectLabels: { billingExpirations },
      reactiveOption: { idBillingExpirationOption },
    },
  } = useTrackConfigContext();

  useEffect(() => {
    setValue('billingExpiration', idBillingExpirationOption);
  }, [idBillingExpirationOption, setValue]);

  return (
    <Grid container direction="column">
      <Grid container item wrap="nowrap" alignItems="center">
        <Grid item xs={12}>
          <Title
            title="Expiração da cobrança"
            description="As comunicações de cobrança devem ser pausadas após:"
          />
        </Grid>
      </Grid>
      <Grid item>
        <StyledRulesFields width="258px">
          <Controller
            name="billingExpiration"
            control={control}
            defaultValue={idBillingExpirationOption}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ''}
                fullWidth
                select
                variant="outlined"
                id="select-billing-expiration"
                SelectProps={{ displayEmpty: true }}
                InputProps={{
                  endAdornment: field.value && (
                    <IconButton
                      onClick={() => {
                        setValue('billingExpiration', null, {
                          shouldTouch: true,
                        });
                      }}
                      style={{
                        position: 'absolute',
                        right: 0,
                        marginRight: '32px',
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  ),
                }}
              >
                <MenuItem value="" disabled>
                  Selecione
                </MenuItem>
                {billingExpirations.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </StyledRulesFields>
      </Grid>
    </Grid>
  );
};

export default BillingExpiration;
