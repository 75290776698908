import { createContext, useContext } from 'react';

import { useTrackConfig } from './useTrackConfig';

const TrackConfigContext = createContext<
  ReturnType<typeof useTrackConfig> | undefined
>(undefined);

const TrackConfigProvider = (props) => (
  <TrackConfigContext.Provider {...props} />
);

function useTrackConfigContext() {
  const context = useContext(TrackConfigContext);
  if (context === undefined) {
    throw new Error(
      'useTrackConfigContext must be used within a TrackConfigProvider'
    );
  }
  return context;
}

export { TrackConfigProvider, useTrackConfigContext };
