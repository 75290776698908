import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';

export const PurpleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#FFFFFF',
  border: '2px solid #8A00C1',
  borderRadius: '27px',
  padding: theme.spacing(2),
  marginTop: theme.spacing(7),
  '& > p > span': {
    fontWeight: 'bold',
  },
}));

export const AboutSimplificaBox = styled(Box)(({ theme }) => ({
  borderLeft: '2px solid #8A00C1',
  padding: theme.spacing(2),
  marginTop: theme.spacing(7),
  '& > p': {
    fontWeight: 'bold',
    letterSpacing: '0.15 px',
    '&:first-child': {
      fontSize: '40px',
      lineHeight: '46.88px',
      color: theme.palette.primary.main,
    },
    '&:last-child': {
      fontSize: '20px',
      lineHeight: '24px',
      color: theme.palette.text.primary,
    },
  },
}));

export const ConfigExplainerBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

export const StatusExplainerBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#F7F7F7',
  border: '2px solid #F7F7F7',
  borderRadius: '27px',
  padding: theme.spacing(2),
  marginTop: theme.spacing(5),
  boxShadow: '0px 4px 4px #00000040',
  position: 'relative',
  '& > img': {
    position: 'absolute',
    right: '24px',
    bottom: '34px',
  },
  '& > p ': {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
}));

export const StatusContentContainer = styled(Box)(({ theme }) => ({
  '& > div:first-child': {
    marginBottom: theme.spacing(1),
  },
  '& > hr': {
    backgroundColor: '#9E9E9E',
    height: '1px',
    border: 'none',
    marginTop: theme.spacing(3),
  },
}));
