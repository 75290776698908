import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect } from 'react-router-dom';
import './assets/styles/index.scss';

import { WebphoneProvider } from '@assertiva/business-ui';

import routes from '../src/routes';
import AppLayout from './AppLayout';
import store from './store';

import {
  AUAcceptCookiesMessage,
  AUProvider,
  AURoutesGenerator,
} from '@assertiva/assertiva-ui';

import { AppContextProvider } from '@src/contexts/AppContextProvider';

import NotifyFileBatch from '@components/FileUpload/Views/NotifyFileBatch/NotifyFileBatch';

// Iniciando service worker para mockar back-end,
// útil para desenvolvimento e testes.
// saiba mais: https://mswjs.io/docs/
// if (
//   process.env.REACT_APP_APP_ENV === 'development' &&
//   window.location.hostname === 'localhost.assertivasolucoes.com.br'
// ) {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const { worker } = require('./mocks/dev-browser');
//   worker.start({
//     onUnhandledRequest: 'bypass',
//   });
// }

ReactDOM.render(
  <AUProvider featureFlagProvider>
    <AUAcceptCookiesMessage />
    <WebphoneProvider>
      <Provider store={store}>
        <AppContextProvider>
          <AURoutesGenerator
            routes={routes()}
            defaultPrivateRedirect="/"
            defaultPublicRedirect="/"
            NotFoundComponent={() => <Redirect to="/" />}
            PrivateLayout={(props) => <AppLayout {...props} />}
            loadingBeforeRenderRoutes={false}
          />
          <NotifyFileBatch />
        </AppContextProvider>
      </Provider>
    </WebphoneProvider>
  </AUProvider>,
  document.getElementById('root')
);
