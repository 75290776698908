import { useEffect } from 'react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { useTrackConfigContext } from '../../context';
import { TYPE_MESSAGE_ENUM } from '../../types';

import RulesFields from './rulesFields/RulesFields';

import Title from '@src/features/trackConfig/components/Title';
import ChannelLabel from '../../components/ChannelLabel';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import { sendAnalytics } from '@assertiva/assertiva-ui';

const rule = TYPE_MESSAGE_ENUM.preventiveOptions;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginTop: '-6px',
}));

const PreventiveForm = () => {
  const { control, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: rule,
  });

  const {
    state: { preventiveOptions, selectLabels },
  } = useTrackConfigContext();

  const preventiveOptionsWatch = useWatch({
    control,
    name: TYPE_MESSAGE_ENUM.preventiveOptions,
  });

  const usedOptions =
    preventiveOptionsWatch
      ?.filter((option) => (option as any)?.idPreventiveOption)
      .map((option) => (option as any)?.idPreventiveOption) || [];

  useEffect(() => {
    setValue(rule, preventiveOptions.options);
  }, [preventiveOptions.options, setValue]);

  return (
    <Grid container direction="column">
      <Grid container item wrap="nowrap" alignItems="center">
        <Grid item xs={6}>
          <Title
            title="Preventiva"
            description="Dias antes do vencimento"
            tooltip="Dívidas que podem ser recuperadas antes do vencimento."
          />
        </Grid>
        <Grid item>
          <ChannelLabel />
        </Grid>
      </Grid>
      <Grid container item direction="column" spacing={2}>
        {fields.map((item, index) => {
          const options = selectLabels.preventive.filter(
            (label) =>
              !usedOptions.includes(label.id) ||
              label.id === preventiveOptionsWatch[index]?.idPreventiveOption
          );
          return (
            <Grid key={item.id} container item wrap="nowrap">
              <RulesFields
                rule={rule}
                index={index}
                firstSelectItems={options}
                firstSelectAccessor="idPreventiveOption"
              >
                {fields.length > 1 && (
                  <StyledIconButton
                    color="primary"
                    onClick={() => remove(index)}
                    id={`btn-remover-acionamento-${index}`}
                  >
                    <DeleteOutlinedIcon />
                  </StyledIconButton>
                )}
              </RulesFields>
            </Grid>
          );
        })}
      </Grid>
      <Grid item>
        <Box marginTop={1}>
          <Button
            onClick={() => {
              sendAnalytics({
                page: 'configurar-cobranca-drawer-configuracao',
                event: 'adicionar-acionamento',
              });
              append({
                idPreventiveOption: null,
                hour: null,
                isSms: false,
                isPhone: false,
                isEmail: false,
              });
            }}
            startIcon={<AddIcon />}
            disabled={Boolean(fields.length >= selectLabels.preventive.length)}
            variant="text"
            color="primary"
            size="large"
            id={'btn-adicionar-acionamento'}
          >
            ADICIONAR ACIONAMENTO
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PreventiveForm;
