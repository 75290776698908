/* eslint-disable react-hooks/exhaustive-deps */
import styles from './DotPulse.module.scss';
import { useEffect } from 'react';

import _isEmpty from 'lodash/isEmpty';

import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from '@material-ui/core/styles';

import { StyledTypographyWrapper } from '@assets/styles/Typography.styles';
import { StyledCardWrapper } from '@components/FileUpload/Views/NotifyFileBatch/NotifyFileBatch.styles';

import {
  TYPE_FEATURE,
  useNotifyBatchUploadContext,
} from '@src/contexts/NotifyBatchUploadContext';

import ResumeUploadDialog from './ResumeUploadDialog';

import { useNotifyFileBatch } from '@components/FileUpload/Views/NotifyFileBatch/useNotifyFileBatch';
import { useSelector } from 'react-redux';
import { selectIsGeneratingBilletBatch } from '@src/features/debt/list/debtsSlice';
import { selectStrategyIsGeneratingBilletBatch } from '@src/features/strategyDebt/strategyDebtSlice';

export const FileNameText = styled(Typography)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '80%',
});

const NotifyFileBatch = () => {
  const { state } = useNotifyBatchUploadContext();

  const { isNotifierBatchUploadOpen, isExpanded, uploadingFiles } = state;

  const {
    fetchPendingProcess,
    toggleExpanded,
    statusFile,
    onCloseResumeUploadDialog,
    isOnlyGeneratingBillets,
    fetchAllPendingProcesses,
  } = useNotifyFileBatch();

  const isGeneratingBilletBatchDebts = useSelector(
    selectIsGeneratingBilletBatch
  );
  const isGeneratingBilletBatchStrategy = useSelector(
    selectStrategyIsGeneratingBilletBatch
  );

  const isGeneratingBilletBatch =
    isGeneratingBilletBatchDebts || isGeneratingBilletBatchStrategy;

  useEffect(() => {
    fetchAllPendingProcesses();
  }, []);

  useEffect(() => {
    if (
      isGeneratingBilletBatch &&
      uploadingFiles.some((value) => value.type === TYPE_FEATURE.billetBatch)
    ) {
      fetchPendingProcess(TYPE_FEATURE.billetBatch);
    }
  }, [isGeneratingBilletBatch, uploadingFiles]);

  const isResumeOpen =
    !_isEmpty(state.listUploadResume) ||
    !_isEmpty(state.listDeleteResume) ||
    !_isEmpty(state.listBilletResume);

  const itemPlural = uploadingFiles.length === 1 ? 'item' : 'itens';

  const snackbarTitle = isOnlyGeneratingBillets
    ? `Gerando ${uploadingFiles[0].totalBillets} boletos`
    : `Fazendo importação de ${uploadingFiles.length ?? ''} ${itemPlural}`;

  return (
    <>
      <Slide
        direction="up"
        in={isNotifierBatchUploadOpen && !_isEmpty(uploadingFiles)}
        mountOnEnter
        unmountOnExit
      >
        <StyledCardWrapper>
          <CardActions className="cardActions">
            <Box
              display="flex"
              gridColumnGap={27}
              justifyContent="center"
              alignItems="center"
            >
              <StyledTypographyWrapper
                size={16}
                weight={400}
                lineHeight={18}
                color="white"
              >
                {snackbarTitle}
              </StyledTypographyWrapper>
            </Box>

            <div className="icons">
              <IconButton
                aria-label="Expandir"
                className={isExpanded ? 'expandOpen' : 'expand'}
                onClick={toggleExpanded}
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
          </CardActions>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            {uploadingFiles.map((file) => {
              return (
                <Paper key={file?.name} className="collapse" square>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="fileContainer"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <StyledTypographyWrapper
                        size={15}
                        className={styles.textAnimationOpacity}
                        weight={400}
                        lineHeight={23}
                        color="#666666"
                      >
                        {statusFile(file.type)}
                      </StyledTypographyWrapper>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <FileNameText variant="body1" className="fileName">
                        {file.name}
                      </FileNameText>

                      <CircularProgress
                        className="fileStatus"
                        value={0}
                        variant={'indeterminate'}
                        size={24}
                      />
                    </Box>
                  </Grid>
                </Paper>
              );
            })}
          </Collapse>
        </StyledCardWrapper>
      </Slide>

      <ResumeUploadDialog
        open={isResumeOpen}
        listUploadResume={state.listUploadResume}
        listDeleteResume={state.listDeleteResume}
        listBilletResume={state.listBilletResume}
        onClose={onCloseResumeUploadDialog}
      />
    </>
  );
};

export default NotifyFileBatch;
