import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTrackConfigContext } from '../../context';
import { TYPE_MESSAGE_ENUM } from '../../types';

import RulesFields from './rulesFields/RulesFields';
import Title from '@src/features/trackConfig/components/Title';
import ChannelLabel from '../../components/ChannelLabel';
import Grid from '@material-ui/core/Grid';

const rule = TYPE_MESSAGE_ENUM.reactiveOption;

const ReactiveForm = () => {
  const { setValue, resetField } = useFormContext();

  const {
    state: { selectLabels, reactiveOption },
  } = useTrackConfigContext();

  useEffect(() => {
    setValue(rule, reactiveOption);
  }, [reactiveOption, setValue]);

  useEffect(() => {
    resetField(`${rule}.hour` as '', { defaultValue: reactiveOption.hour });
  }, [reactiveOption.hour, resetField]);

  return (
    <Grid container direction="column">
      <Grid container item wrap="nowrap" alignItems="center">
        <Grid item xs={6}>
          <Title
            title="Reativa"
            description="Depois do dia do vencimento"
            tooltip="Dívidas que precisam ser recuperadas após vencimento."
          />
        </Grid>
        <Grid item>
          <ChannelLabel />
        </Grid>
      </Grid>
      <Grid item>
        <RulesFields
          rule={rule}
          firstSelectAccessor="idReactiveRecurrence"
          firstSelectItems={selectLabels.reactive}
          defaultValueHour={reactiveOption.hour}
        />
      </Grid>
    </Grid>
  );
};

export default ReactiveForm;
