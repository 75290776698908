import { AUNotifier, ErrorUtils } from '@assertiva/assertiva-ui';
import WalletService from '@src/services/WalletService';
import { AppDispatch } from '@src/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_WALLET_NAME, defaultWallet } from '../../constants';
import { WalletSelect } from '../../type';
import {
  getSelectedWallet,
  mountInitialWallet,
  saveSelectedWallet,
} from '../../utils';
import {
  updateGlobalWallet,
  updateGlobalWalletStatus,
} from '../../walletSlice';

interface State {
  list: WalletSelect[];
  isLoading: boolean;
}

const mountInitialWalletList = () => {
  const selectWallet = mountInitialWallet();
  if (selectWallet.id === defaultWallet.id) {
    return [defaultWallet];
  } else if (selectWallet.name === DEFAULT_WALLET_NAME) {
    return [selectWallet];
  } else {
    return [defaultWallet, selectWallet];
  }
};

const initialState: State = {
  list: [],
  isLoading: false,
};

export const useWalletSelect = () => {
  const [state, setState] = useState(initialState);

  const dispatch: AppDispatch = useDispatch();

  const handleState = useCallback(
    (changes: Partial<State>) =>
      setState((state) => {
        return { ...state, ...changes };
      }),
    []
  );

  const handleOnChangeSelectedWallet = (wallet: WalletSelect) => {
    dispatch(updateGlobalWallet(wallet));
    dispatch(updateGlobalWalletStatus(true));
    saveSelectedWallet(wallet);
  };

  const handleFetchWalletsAvailable = useCallback(async () => {
    try {
      handleState({ isLoading: true });
      const res = await WalletService.getWalletsAvailable();
      const { body } = res.data;
      const cookieWallet = getSelectedWallet();

      const actualWallet = body.some((item) => item.id === cookieWallet?.id);

      if (!actualWallet && cookieWallet) {
        body.push({ ...cookieWallet, disabled: true });
      }

      if (!cookieWallet) {
        const findCompanyDefaultWallet = body.find(
          (e) => e.name === defaultWallet.name
        );
        dispatch(updateGlobalWallet(findCompanyDefaultWallet));
        saveSelectedWallet(findCompanyDefaultWallet);
      }
      handleState({ list: body });
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      handleState({ isLoading: false });
    }
  }, [handleState, dispatch]);

  const assertivaTrialWallet = useMemo(() => {
    const wallet = state?.list?.filter(
      (wallet) => wallet?.name !== DEFAULT_WALLET_NAME
    );

    if (wallet) return wallet[0];
  }, [state]) as WalletSelect;

  useEffect(() => {
    handleState({ list: mountInitialWalletList() });
    handleFetchWalletsAvailable();
  }, [handleFetchWalletsAvailable, handleState]);

  return {
    assertivaTrialWallet,
    state,
    handleFetchWalletsAvailable,
    handleOnChangeSelectedWallet,
  };
};
