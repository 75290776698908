import { createContext, useState, useContext, useCallback } from 'react';
import * as filtersUtils from './utils';

const FiltersContext = createContext<ReturnType<typeof useFilters> | undefined>(
  undefined
);

const useFilters = () => {
  const [searchString, setSearchString] = useState('');

  const getQueryParams = useCallback(
    (options?) => filtersUtils.getQueryParams({ ...options, searchString }),
    [searchString]
  );

  const setQueryParams = useCallback(
    (queryParams, options?) =>
      filtersUtils.setQueryParams(queryParams, {
        ...options,
        getQueryParams,
        setSearchString,
      }),
    [getQueryParams, setSearchString]
  );

  const getFilters = useCallback(
    () => filtersUtils.getFilters(getQueryParams),
    [getQueryParams]
  );

  const eraseFilter = useCallback(
    (filterKey: string) =>
      filtersUtils.eraseFilter(filterKey, { getQueryParams, setQueryParams }),
    [getQueryParams, setQueryParams]
  );

  const eraseAllFilters = useCallback(
    (filtersToPreserve?) =>
      filtersUtils.eraseAllFilters(filtersToPreserve, {
        setQueryParams,
      }),
    [setQueryParams]
  );

  return {
    searchString,
    getQueryParams,
    setQueryParams,
    getFilters,
    eraseFilter,
    eraseAllFilters,
  };
};

export const FiltersProvider = (props) => {
  const value = useFilters();

  return <FiltersContext.Provider value={value} {...props} />;
};

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error(`useFiltersContext must be used within a FiltersProvider`);
  }

  return context;
};
