import * as DOMPurify from 'dompurify';
import { AuthUtils, Envs } from '@assertiva/assertiva-ui';
import { Nullish } from '@src/constants/types';
import { IEmailConfig } from '@src/features/trackConfig/types';

export const getEmailLogoUrl = (idFileS3?: Nullish<string>) => {
  if (!idFileS3) return undefined;
  return `${
    Envs().RECUPERE_API_URL
  }simplifica-api/api/v1/file/logo/email/external/${idFileS3}`;
};

export const getSender = () => {
  if (
    AuthUtils.getUser().getCompanyId() ===
    Number(process.env.REACT_APP_FUEL_COMPANY_ID)
  )
    return 'SINDICATO DAS EMPRESAS DE TRANSPORTE DE COMBUSTIVEIS, DERIVADOS DE PETROLEO, MATERIAIS INFLAMAVEIS E CARGAS PERIGOSAS NO ESTADO DE GOIAS-STPP';

  if (
    AuthUtils.getUser().getCompanyId() ===
    Number(process.env.REACT_APP_CASTELO_COMPANY_ID)
  )
    return 'ASSERTIVA TECNOLOGIA DA INFORMACAO LTDA';

  return AuthUtils.getUser().getCompanyName().toUpperCase();
};

const senderString = () => `\n\n&hairsp;${getSender()}&hairsp;`;

export const addSenderToMessage = (message?: Nullish<string>) =>
  message ? `${message}${senderString()}` : '';

export const removeSenderFromMessage = (message?: Nullish<string>) =>
  message ? message.replace(senderString(), '') : '';

export const addBoldToSenderInMessage = (message?: Nullish<string>) =>
  message ? message.replace(senderString(), `<b>${senderString()}</b>`) : '';

interface HandleSenderOptions {
  addBold?: boolean;
}

export const handleSender = (
  message: string,
  options?: HandleSenderOptions
) => {
  let messageWithOneSender = addSenderToMessage(
    removeSenderFromMessage(message)
  );

  if (options?.addBold) {
    messageWithOneSender = addBoldToSenderInMessage(messageWithOneSender);
  }

  return messageWithOneSender;
};

export const emailConfigWithoutSender = (
  emailConfig: Nullish<IEmailConfig>
) => {
  if (!emailConfig) return null;
  return {
    ...emailConfig,
    subject: emailConfig.subject || '',
    message: removeSenderFromMessage(emailConfig?.message),
  };
};

export const converteUrlToHref = (message: string): string => {
  const urlRegex =
    /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\]{};:'".,<>?«»“”‘’]))/g;
  return message.replace(urlRegex, function (url) {
    const newUrl = url.indexOf('http') === -1 ? 'http://' + url : url;
    return (
      '<a href="' +
      newUrl +
      '" target="_blank" style="color: #15c;">' +
      url +
      '</a>'
    );
  });
};

export const sanitizeMessage = (message: string): string =>
  DOMPurify.sanitize(message, { ALLOWED_TAGS: [] });
