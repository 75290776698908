import PanelBox from '@src/components/PanelBox/PanelBox';
import Welcome from '../components/Welcome';
import ExplainerBanners from '../components/ExplainerBanners';
import TodayAppointmentsAlert from '@src/components/Alert/TodayAppointmentsAlert';
import WarningAlerts from '@src/components/Alert/WarningAlert';

const HomePage = () => (
  <PanelBox>
    <Welcome />
    <TodayAppointmentsAlert marginTop={2} />
    <WarningAlerts marginTop={2} />
    <ExplainerBanners />
  </PanelBox>
);

export default HomePage;
