import { AUPagination } from '@assertiva/assertiva-ui';
import Box, { BoxProps } from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useAutocompleteTable } from './useAutocompleteTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ListItem } from './types';

interface Props<T> {
  list: ListItem<T>[];
  onChangeItemStatus: (id: number, active: boolean) => void;
  children: (item: ListItem<T>) => React.JSX.Element;
  cardBoxProps?: BoxProps;
}

export const AutocompleteTable: <T>(
  p: Props<T>
) => React.ReactElement<Props<T>> = ({
  list,
  onChangeItemStatus,
  children,
  cardBoxProps,
}) => {
  const {
    handlePagination,
    state: { paginatedList, pagination },
  } = useAutocompleteTable(list);

  return (
    <Box>
      {paginatedList.map((item) => {
        return (
          <Box data-testid="list-item" key={item.id}>
            <CardActionArea>
              <Box
                display="flex"
                width="98%"
                alignItems="center"
                justifyContent="space-between"
                mt={1.5}
                ml={0.5}
                mb={1.5}
                {...cardBoxProps}
              >
                <Box>{children(item)}</Box>

                <IconButton
                  data-testid={`delete-item-active-${item.id}`}
                  color="primary"
                  component="div"
                  onClick={(e) => {
                    e.stopPropagation();
                    onChangeItemStatus(item.id, false);
                  }}
                  style={{ padding: 8 }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Box>
              <Divider />
            </CardActionArea>
          </Box>
        );
      })}

      <AUPagination
        count={pagination.count}
        rowsPerPage={pagination.rowsPerPage}
        onChangePage={(_, newPage) => handlePagination('page', newPage)}
        onChangeRowsPerPage={(event) =>
          handlePagination('rowsPerPage', Number(event.target.value))
        }
        labelRowsPerPage="Linhas por página:"
        page={pagination.page}
        rowsPerPageOptions={[5, 10, 25, 50]}
        hideIfOnePage
        onPageChange={() => {}}
      />
    </Box>
  );
};

export const ListTitle = ({ children }) => (
  <Typography variant="subtitle2">{children}</Typography>
);

export const ListSubtitle = ({ children }) => (
  <Typography variant="subtitle2" style={{ color: '#666666' }}>
    {children}
  </Typography>
);
