import axios from 'axios';
import BaseService from './BaseService';
import { IUploadRequest } from '@constants/interfaces';

let cancel: any;

class S3Service extends BaseService {
  constructor() {
    super();

    this.uploadFileByPreSignedURL = this.uploadFileByPreSignedURL.bind(this);
    this.cancelUploadProgress = this.cancelUploadProgress.bind(this);
  }
  uploadFileByPreSignedURL = ({ preSignedURL, file }: IUploadRequest, cb?) => {
    return axios.put(preSignedURL, file, {
      cancelToken: new this.cancelToken(function executor(c) {
        cancel = c;
      }),
      headers: {
        'Content-Type': file.type,
      },
    });
  };

  cancelUploadProgress = () => {
    cancel();
  };
}

export default new S3Service();
