import IMAGES from '@src/constants/images';

export const pfNBAParams = [
  {
    img: IMAGES.NBA_REGION_ICON,
    name: 'Região',
  },
  {
    img: IMAGES.NBA_AGE_ICON,
    name: 'Idade',
  },
  {
    img: IMAGES.NBA_SALARY_ICON,
    name: 'Renda estimada',
  },
  {
    img: IMAGES.NBA_TIME_ICON,
    name: 'Tempo da dívida',
  },
  {
    img: IMAGES.NBA_OTHER_CRITERIA,
    name: 'E vários outros critérios',
  },
];

export const pjNBAParams = [
  {
    img: IMAGES.NBA_COMPANY_TYPE,
    name: 'Tipo de empresa',
  },
  {
    img: IMAGES.NBA_COMPANY_AGE,
    name: 'Idade da empresa',
  },
  {
    img: IMAGES.NBA_DEBTS_AMOUNT,
    name: 'Valor da dívida',
  },
  {
    img: IMAGES.NBA_INVOICING,
    name: 'Faturamento presumido',
  },
  {
    img: IMAGES.NBA_OTHER_CRITERIA,
    name: 'E vários outros critérios',
  },
];
