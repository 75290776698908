import { useAppDispatch } from '@src/store/hooks';
import { useSelector } from 'react-redux';
import {
  closeStrategyDrawer,
  resetStrategyConfig,
  selectHasChangedStrategyConfig,
  selectStrategyConfig,
  selectStrategyDrawerMode,
  setStrategyConfig,
} from './strategySlice';
import { DrawerModes, StrategyConfig } from './types';

export const useStrategyDrawer = () => {
  const dispatch = useAppDispatch();

  const strategyConfig = useSelector(selectStrategyConfig);

  const drawerMode = useSelector(selectStrategyDrawerMode);

  const isOpen = drawerMode !== DrawerModes.Closed;

  const titleDrawer =
    drawerMode === DrawerModes.Add
      ? 'Criar estratégia de cobrança'
      : 'Editar estratégia de cobrança';

  const handleClose = () => {
    dispatch(closeStrategyDrawer());

    setTimeout(() => {
      dispatch(resetStrategyConfig());
    }, 500);
  };

  const hasChangedStrategyConfig = useSelector(selectHasChangedStrategyConfig);

  const isValidToCloseWithoutWarn = () => !hasChangedStrategyConfig;

  const handleChangeStrategyConfig = (changes: Partial<StrategyConfig>) =>
    dispatch(setStrategyConfig(changes));

  return {
    isOpen,
    titleDrawer,
    strategyConfig,
    isValidToCloseWithoutWarn,
    handleClose,
    handleChangeStrategyConfig,
    drawerMode,
  };
};
