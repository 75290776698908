import { DefineMessagesTabs, State, Step } from './types';

export const TYPE_MESSAGE_LABEL = {
  preventiveOptions: 'Preventiva',
  expirationDay: 'Dia do Vencimento',
  reactiveOption: 'Reativa',
};

export const hoursRegex = /(0?[6-9]:[0-5]\d|1\d:[0-5]\d|2[0-1]:[0-5]\d|22:00)/;

export const INVALID_HOUR_MESSAGE = 'Deve ser entre 06:00 e 22:00';

export const initialState: State = {
  step: Step.triggerRules,
  defineMessagesTab: DefineMessagesTabs.Sms,
  preventiveOptions: {
    options: [
      {
        idPreventiveOption: null,
        hour: null,
        isEmail: false,
        isPhone: false,
        isSms: false,
      },
    ],
    smsConfig: null,
    smsBilletConfig: null,
    emailConfig: null,
    emailBilletConfig: null,
  },
  expirationDay: {
    hour: null,
    isEmail: false,
    isPhone: false,
    isSms: false,
    smsConfig: null,
    smsBilletConfig: null,
    emailConfig: null,
    emailBilletConfig: null,
  },
  reactiveOption: {
    idReactiveRecurrence: null,
    idBillingExpirationOption: null,
    hour: null,
    isEmail: false,
    isPhone: false,
    isSms: false,
    smsConfig: null,
    smsBilletConfig: null,
    emailConfig: null,
    emailBilletConfig: null,
  },
  selectLabels: {
    preventive: [],
    reactive: [],
    billingExpirations: [],
  },
  purposeOfUse: {
    backofficeId: null,
    purposeText: '',
    purposeDescription: '',
  },
  purposes: [],
  changedWallet: false,
  initialSelectedWallet: null,
  hasBillet: false,
};
