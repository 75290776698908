import Box, { BoxProps } from '@material-ui/core/Box';
import { AUText } from '@assertiva/assertiva-ui';

interface FieldTitleProps extends BoxProps {
  title: string;
  subtitle?: string | React.JSX.Element;
}

const FieldTitle = ({
  title,
  subtitle,
  children,
  ...props
}: FieldTitleProps) => (
  <Box display="flex" flexDirection="column" gridGap={16} {...props}>
    <Box display="flex" flexDirection="column" gridGap={4}>
      <AUText
        fontSize="18px"
        fontWeight={400}
        lineHeight="21px"
        color="#000000"
      >
        {title}
      </AUText>
      {subtitle && (
        <AUText
          fontSize="14px"
          fontWeight={400}
          color="#666666"
          lineHeight="24px"
        >
          {subtitle}
        </AUText>
      )}
    </Box>
    {children}
  </Box>
);

export default FieldTitle;
