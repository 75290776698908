import { DebtsSortBy, OverdueDebtsTimeFilter } from './types';

export const NOT_INITIATED_ID_STATUS = 1;
export const NEXT_TO_DUE_DATE_STATUS = 2;
export const DUE_TODAY_STATUS = 3;
export const DEFAULT_ID_STATUS = 4;
export const EXPIRED_ID_STATUS = 4;
export const DEFAULT_ID_SORT = DebtsSortBy.FinalDateAsc;
export const FINISHED_ID_STATUS = 10;

export const FILTERS_LABELS = {
  debtorName: 'Nome do cliente',
  typeDoc: 'Tipo de documento',
  doc: 'CPF/CNPJ',
  phone: 'Número de telefone',
  contract: 'Número de contrato',
  reference: 'ID da cobrança',
  minValueDebt: 'Valores - De',
  maxValueDebt: 'Valores - Até',
  minFinalDateDebt: 'Data - De',
  maxFinalDateDebt: 'Data - Até',
  minFinalDateEvent: 'Evento - De',
  maxFinalDateEvent: 'Evento - Até',
  onlyAgreement: 'Somente dívidas de acordo',
  onlyNegativated: 'Somente dívidas negativadas',
  hasBillet: 'Boletos',
};

const sortByDesc = 'DESC';
const sortByAsc = 'ASC';

export const SORT_BY = {
  [DebtsSortBy.FinalDateAsc]: {
    label: 'Data do vencimento: do menor para o maior',
    sortBy: 'finalDate',
    sortDirection: sortByAsc,
  },
  [DebtsSortBy.FinalDateDesc]: {
    label: 'Data do vencimento: do maior para o menor',
    sortBy: 'finalDate',
    sortDirection: sortByDesc,
  },
  [DebtsSortBy.EventDateAsc]: {
    label: 'Último evento: do mais antigo para o mais novo',
    sortBy: 'eventDate',
    sortDirection: sortByAsc,
  },
  [DebtsSortBy.EventDateDesc]: {
    label: 'Último evento: do mais novo para o mais antigo',
    sortBy: 'eventDate',
    sortDirection: sortByDesc,
  },
  [DebtsSortBy.ValueAsc]: {
    label: 'Valor: do menor para o maior',
    sortBy: 'value',
    sortDirection: sortByAsc,
  },
  [DebtsSortBy.ValueDesc]: {
    label: 'Valor: do maior para o menor',
    sortBy: 'value',
    sortDirection: sortByDesc,
  },
  [DebtsSortBy.NbaPercentageAsc]: {
    label: 'Propensão de pagamento: da menor para a maior',
    sortBy: 'nbaPercentage',
    sortDirection: sortByAsc,
  },
  [DebtsSortBy.NbaPercentageDesc]: {
    label: 'Propensão de pagamento: da maior para a menor',
    sortBy: 'nbaPercentage',
    sortDirection: sortByDesc,
  },
};

export const OVERDUE_DEBTS_TIME = {
  [OverdueDebtsTimeFilter.BetweenThirtyDays]: {
    label: 'Até 30 dias',
    initialDateDays: 1,
    finalDateDays: 30,
  },
  [OverdueDebtsTimeFilter.MoreThanThirtyDays]: {
    label: '+30 dias',
    finalDateDays: 30,
  },
  [OverdueDebtsTimeFilter.MoreThanSixtyDays]: {
    label: '+60 dias',
    finalDateDays: 60,
  },
  [OverdueDebtsTimeFilter.MoreThanNinetyDays]: {
    label: '+90 dias',
    finalDateDays: 90,
  },
};

export const INITIAL_PAGINATION = { page: 0, rowsPerPage: 10, count: 0 };
