import { Controller, useFormContext, useWatch } from 'react-hook-form';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';

import { ISelectLabels, TYPE_MESSAGE_ENUM } from '../../../types';
import { resolveMomentToString } from '@src/utils/functionUtils';
import {
  hoursRegex,
  INVALID_HOUR_MESSAGE,
} from '@src/features/trackConfig/constants';

import ChannelCheckboxes from '../ChannelCheckboxes';
import { StyledRulesFields } from './RulesFields.styles';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import EventIcon from '@material-ui/icons/Event';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

interface Props {
  rule: TYPE_MESSAGE_ENUM;
  firstSelectItems: ISelectLabels[];
  firstSelectAccessor: string;
  defaultValueHour?: Moment | null;
  index?: number;
}

const RulesFields: React.FC<Props> = ({
  rule,
  firstSelectItems,
  firstSelectAccessor,
  defaultValueHour,
  index,
  children,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const resolveName = (fieldAccessor: string) =>
    typeof index === 'number'
      ? `${rule}.${index}.${fieldAccessor}`
      : `${rule}.${fieldAccessor}`;

  const resolveError = (fieldAccessor: string) =>
    typeof index === 'number'
      ? errors?.[rule]?.[index]?.[fieldAccessor]
      : errors?.[rule]?.[fieldAccessor];

  const isSmsWatch = useWatch({
    control,
    name: resolveName('isSms') as '',
  });

  const isEmailWatch = useWatch({
    control,
    name: resolveName('isEmail') as '',
  });

  const isSmsOrEmail = isSmsWatch || isEmailWatch;

  return (
    <Grid container spacing={2} data-testid={`rules-fields-${rule}`}>
      <Grid container item spacing={1} xs={6}>
        <Grid item xs={7}>
          <StyledRulesFields>
            <Controller
              name={resolveName(firstSelectAccessor) as ''}
              control={control}
              rules={{ required: isSmsOrEmail }}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value || ''}
                  fullWidth
                  select
                  variant="outlined"
                  id={`select-days-${rule}`}
                  data-testid={`select-days-${rule}`}
                  SelectProps={{ displayEmpty: true }}
                  error={Boolean(resolveError(firstSelectAccessor))}
                  InputProps={{
                    endAdornment: field.value && (
                      <IconButton
                        onClick={() => {
                          setValue(
                            resolveName(firstSelectAccessor) as '',
                            null,
                            {
                              shouldTouch: true,
                            }
                          );
                        }}
                        style={{
                          position: 'absolute',
                          right: 0,
                          marginRight: '32px',
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    ),
                  }}
                >
                  <MenuItem value="" disabled>
                    Selecione*
                  </MenuItem>
                  {firstSelectItems.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      data-testid="option"
                    >
                      {item.description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </StyledRulesFields>
        </Grid>

        <Grid item xs={5}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <StyledRulesFields>
              <Controller
                name={resolveName('hour') as ''}
                control={control}
                rules={{
                  validate: (value) =>
                    hoursRegex.test(
                      resolveMomentToString(value, { format: 'HH:mm' })
                    ) ||
                    !value ||
                    INVALID_HOUR_MESSAGE,
                  required: isSmsOrEmail,
                }}
                defaultValue={defaultValueHour}
                render={({ field: { onChange, onBlur, value } }) => (
                  <KeyboardTimePicker
                    value={value ? moment(value, 'HH:mm') : null}
                    onChange={(date) => {
                      if (date) {
                        onChange(date);
                      } else onChange(null);
                    }}
                    onBlur={onBlur}
                    onAccept={(date) => {
                      if (date) {
                        setValue(resolveName('hour') as '', date, {
                          shouldTouch: true,
                        });
                      }
                    }}
                    fullWidth
                    clearable
                    clearLabel="LIMPAR"
                    cancelLabel="CANCELAR"
                    id={`select-hour-${rule}`}
                    label="Hora*"
                    invalidDateMessage="Hora inválida"
                    ampm={false}
                    size="small"
                    helperText={resolveError('hour')?.message}
                    error={Boolean(resolveError('hour'))}
                    keyboardIcon={<EventIcon color={'primary'} />}
                  />
                )}
              />
            </StyledRulesFields>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <Grid item>
        <StyledRulesFields display="flex">
          <ChannelCheckboxes rule={rule} index={index} />
        </StyledRulesFields>
      </Grid>

      <Grid item>
        <StyledRulesFields>{children}</StyledRulesFields>
      </Grid>
    </Grid>
  );
};

RulesFields.defaultProps = {
  defaultValueHour: null,
};

export default RulesFields;
