import comparators from '@src/constants/comparators';
import { WalletSelect } from './type';

export const DEFAULT_WALLET_ID = 1;
export const DEFAULT_WALLET_NAME = 'Padrão';

export const defaultWallet: WalletSelect = {
  id: DEFAULT_WALLET_ID,
  disabled: false,
  name: DEFAULT_WALLET_NAME,
};

export const hasPermissionToSeeWalletSelection = (
  userHasStrategy: boolean,
  madeInitialFetchUserStrategies: boolean
) => {
  const path = window.location.pathname;

  if (
    (!madeInitialFetchUserStrategies || userHasStrategy) &&
    path === comparators.PATH_DEBTS_ROUTE
  ) {
    return false;
  }

  return [
    comparators.PATH_DEBTS_ROUTE,
    comparators.PATH_DEBTORS_ROUTE,
  ].includes(path);
};
