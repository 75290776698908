import { ParsedQs } from 'qs';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { eraseFilter, getFilters, getFiltersKeys } from './utils';

interface Props {
  filters?: ParsedQs;
  filtersKeys?: string[];
  handleDelete?: (filterKey: string) => void;
  resolveFilterLabel?: (filterKey: string, filters: Record<string, any>) => any;
  resolveFilterValue?: (filterKey: string, filters: Record<string, any>) => any;
}

export const FilterChips = ({
  filters = getFilters(),
  handleDelete = (filterKey) => eraseFilter(filterKey),
  resolveFilterLabel,
  resolveFilterValue,
}: Props) => {
  const filtersKeys = getFiltersKeys(filters);
  const hasFilters = filtersKeys.length > 0;

  if (!hasFilters) return null;

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-end"
      marginTop={-2}
      marginBottom={2}
    >
      <Box
        width="80%"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexWrap="wrap"
        gridGap={8}
      >
        <span>
          <b>Filtros: </b>
        </span>
        {filtersKeys.map((key, index) => (
          <Chip
            key={key}
            size="small"
            color="primary"
            label={
              <span>
                <b>{resolveFilterLabel?.(key, filters) || key}:</b>{' '}
                <span>
                  {resolveFilterValue?.(key, filters) || filters[key]}
                </span>
              </span>
            }
            onDelete={() => handleDelete(key)}
            deleteIcon={<CancelIcon id={`btn-remover-${index}-filtro`} />}
          />
        ))}
      </Box>
    </Box>
  );
};
