import Box, { BoxProps } from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';

const NewBadge = (props: BoxProps) => {
  const theme = useTheme();

  return (
    <Box
      py="4px"
      px="4px"
      color="#fff"
      borderRadius={4}
      fontSize="12px"
      fontWeight={500}
      lineHeight="12px"
      bgcolor={theme.palette.primary.main}
      {...props}
    >
      NOVO
    </Box>
  );
};

export default NewBadge;
