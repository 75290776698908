import { styled } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export const StyledPaperCard = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gridGap: theme.spacing(2.5),
  maxWidth: 565,
  background: 'linear-gradient(90deg, #52B1B5 54.31%, #82E0E2 99.85%)',
}));

export const StyledCardText = styled(Typography)(({ theme }) => ({
  lineHeight: 'normal',
  color: theme.palette.common.white,

  '& span': {
    fontWeight: 600,
  },
}));
