import React, { Suspense } from 'react';
import {
  AUClientLogin,
  Envs,
  PRODUCT_IDS,
  User,
  browserHistory,
  sendAnalytics,
} from '@assertiva/assertiva-ui';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DebtorsIcon from '@material-ui/icons/Group';
import DebtsIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import { StrategyIcon } from './assets/images/StrategyIcon';
import { WalletIcon } from '@assets/images/WalletIcon';

import HomePage from './features/home/page';

import comparators from '@constants/comparators';
import Box from '@material-ui/core/Box';
import {
  SEE_DASHBOARD,
  SEE_DEBTORS,
  SEE_DEBTS,
  SEE_STRATEGY_LIST,
} from './constants/permissions';
import {
  PermissionedRoutesHandler,
  DebtPageHandler,
} from './utils/routerUtils';
import NewBadge from './components/NewBadge';

const Debtors = React.lazy(() => import('@features/debtors/pages/Debtors'));
const Dashboard = React.lazy(() => import('./features/dashboard/page'));
const Settings = React.lazy(() => import('./features/settings/page'));
const UsersSettings = React.lazy(
  () => import('./features/usersSettings/page/UsersSettings')
);
const StrategyList = React.lazy(
  () => import('./features/strategy/pages/StrategyList')
);
const Wallet = React.lazy(() => import('./features/wallet/pages/Wallet'));
const Appointments = React.lazy(
  () => import('./features/appointments/page/Appointments')
);

interface RoutesOptions {
  userInfo?: User;
  shouldShowStrategy?: boolean;
}

const routes = ({ userInfo, shouldShowStrategy }: RoutesOptions = {}) => {
  const isAdmin = userInfo?.isAdminProfile();

  const handleHasPermission = (permissions: string[]) =>
    userInfo?.hasPermission(permissions);

  return [
    {
      id: -1,
      exact: true,
      path: '/',
      redirect: '/inicio',
      isPrivate: false,
      component: () => <AUClientLogin />,
    },
    {
      id: 0,
      path: '/inicio',
      showInMenu: true,
      exact: true,
      label: comparators.LABEL_SIMPLIFICA_ROUTE,
      isPrivate: true,
      startOpen: true,
      loadingBeforeRenderRoutes: false,
      component: (props) => <HomePage {...props} />,
      productId: PRODUCT_IDS.RECUPERE,
      linkId: 'simplifica-sidebar',
      nested: [
        {
          id: 1,
          showInMenu: false,
          path: '/inicio',
          exact: true,
          isPrivate: true,
          component: (props) => <HomePage {...props} />,
        },
        {
          id: 2,
          showInMenu: handleHasPermission(SEE_DASHBOARD),
          path: comparators.PATH_DASHBOARD,
          label: comparators.LABEL_DASHBOARD,
          linkId: 'dashboard-sidebar',
          icon: <DashboardIcon />,
          exact: true,
          isPrivate: true,
          permissions: SEE_DASHBOARD,
          component: (props) => (
            <Suspense fallback={<div />}>
              <Dashboard {...props} />
            </Suspense>
          ),
          action: () => {
            sendAnalytics({
              page: 'sidebar',
              event: 'dashboard',
            });
            browserHistory.push(comparators.PATH_DASHBOARD);
          },
        },
        {
          id: 3,
          showInMenu: handleHasPermission(SEE_DEBTS),
          path: comparators.PATH_DEBTS_ROUTE,
          label: comparators.LABEL_DEBTS_ROUTE,
          linkId: 'cobranca-sidebar',
          icon: <DebtsIcon />,
          exact: true,
          isPrivate: true,
          permissions: SEE_DEBTS,
          component: (props) => <DebtPageHandler {...props} />,
          action: () => {
            sendAnalytics({
              page: 'sidebar',
              event: 'cobranca',
            });
            browserHistory.push(comparators.PATH_DEBTS_ROUTE);
          },
        },
        {
          id: 4,
          showInMenu: handleHasPermission(SEE_DEBTORS),
          path: comparators.PATH_DEBTORS_ROUTE,
          label: comparators.LABEL_DEBTORS_ROUTE,
          linkId: 'cliente-sidebar',
          icon: <DebtorsIcon />,
          exact: true,
          isPrivate: true,
          permissions: SEE_DEBTORS,
          component: (props) => (
            <Suspense fallback={<div />}>
              <Debtors {...props} />
            </Suspense>
          ),
          action: () => {
            sendAnalytics({
              page: 'sidebar',
              event: 'cliente',
            });
            browserHistory.push(comparators.PATH_DEBTORS_ROUTE);
          },
        },
        {
          id: 10,
          showInMenu: isAdmin,
          path: comparators.PATH_WALLET_ROUTE,
          label: comparators.LABEL_WALLET_ROUTE,
          linkId: 'carteira-sidebar',
          icon: (
            <Box pl="7px">
              <WalletIcon />
            </Box>
          ),
          exact: true,
          isPrivate: true,
          component: (props) => (
            <Suspense fallback={<div />}>
              <PermissionedRoutesHandler handleAdmin>
                <Wallet {...props} />
              </PermissionedRoutesHandler>
            </Suspense>
          ),
          action: () => {
            browserHistory.push(comparators.PATH_WALLET_ROUTE);
          },
        },
        {
          id: 5,
          label: comparators.LABEL_CHARGE_SETTINGS_ROUTE,
          linkId: 'configuracoes-sidebar',
          path: '/configuracoes/:section',
          showInMenu: true,
          exact: true,
          isPrivate: true,
          icon: <SettingsIcon />,
          component: (props) => (
            <Suspense fallback={<div />}>
              <Settings {...props} />
            </Suspense>
          ),
          action: () => {
            sendAnalytics({
              page: 'sidebar',
              event: 'configuracoes',
            });
            browserHistory.push('/configuracoes/financeiro');
          },
          nested: [
            {
              id: 6,
              path: '/configuracoes/:section',
              showInMenu: false,
              isPrivate: true,
              exact: true,
              component: (props) => (
                <Suspense fallback={<div />}>
                  <Settings {...props} />
                </Suspense>
              ),
            },
          ],
        },
        {
          id: 14,
          showInMenu:
            isAdmin &&
            handleHasPermission(SEE_STRATEGY_LIST) &&
            shouldShowStrategy,
          path: comparators.PATH_STRATEGY_ROUTE,
          label: comparators.LABEL_STRATEGY_ROUTE,
          linkId: 'estrategia-sidebar',
          icon: (
            <>
              <StrategyIcon />
              <NewBadge
                position="absolute"
                left="140px"
                top="50%"
                style={{ transform: 'translateY(-50%)' }}
              />
            </>
          ),
          exact: true,
          isPrivate: true,
          redirect: '/',
          component: (props) => (
            <Suspense fallback={<div />}>
              <PermissionedRoutesHandler
                handleAdmin
                handlePermissions={SEE_STRATEGY_LIST}
              >
                <StrategyList {...props} />
              </PermissionedRoutesHandler>
            </Suspense>
          ),
          action: () => {
            sendAnalytics({
              page: 'sidebar',
              event: 'estrategia',
            });
            browserHistory.push(comparators.PATH_STRATEGY_ROUTE);
          },
        },
        {
          id: 7,
          label: comparators.LABEL_USER_SETTINGS_ROUTE,
          linkId: 'usuarios-sidebar',
          path: '/usuarios/:section',
          showInMenu: isAdmin,
          exact: true,
          isPrivate: true,
          icon: <PersonIcon />,
          component: (props) => (
            <Suspense fallback={<div />}>
              <UsersSettings {...props} />
            </Suspense>
          ),
          action: () => {
            sendAnalytics({
              page: 'sidebar',
              event: 'usuarios',
            });
            browserHistory.push('/usuarios/controle-acessos');
          },
          nested: [
            {
              id: 8,
              path: '/usuarios/:section',
              showInMenu: false,
              isPrivate: true,
              exact: true,
              component: (props) => (
                <Suspense fallback={<div />}>
                  <PermissionedRoutesHandler handleAdmin>
                    <UsersSettings {...props} />
                  </PermissionedRoutesHandler>
                </Suspense>
              ),
            },
          ],
        },
        {
          id: 11,
          showInMenu: false,
          path: comparators.PATH_APPOINTMENTS,
          exact: true,
          isPrivate: true,
          permissions: SEE_DASHBOARD,
          component: (props) => (
            <Suspense fallback={<div />}>
              <Appointments {...props} />
            </Suspense>
          ),
        },
        {
          id: 9,
          path: '/relatorios',
          showInMenu: true,
          action: () => {
            window.location.href = `${
              Envs().PAINEL_URL
            }/gestao-da-conta/relatorios/recupere`;
          },
          label: comparators.LABLE_REPORT,
          icon: <DescriptionOutlinedIcon />,
          exact: true,
          isPrivate: true,
        },
      ],
    },
  ];
};

export default routes;
