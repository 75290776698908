import React, { useState, createContext, useCallback } from 'react';

interface State {
  idEnrichProgress: number | string | null;
  idScoreProgress: number | string | null;
  valueEnrichProgress: number;
  valueScoreProgress: number;
}

const EnrichScoreBatchContext = createContext<
  ReturnType<typeof useEnrichScoreBatch> | undefined
>(undefined);

function useEnrichScoreBatch(initialState: State) {
  const [state, setState] = useState<State>(initialState);

  const handleState = useCallback((key: keyof State, value: any) => {
    setState((prev) => ({ ...prev, [key]: value }));
  }, []);

  return {
    state,
    handleState,
  };
}

interface Props {
  defaultInitialState?: State;
}

export const initialState: State = {
  idEnrichProgress: null,
  idScoreProgress: null,
  valueEnrichProgress: 0,
  valueScoreProgress: 0,
};

const EnrichScoreBatchProvider: React.FC<Props> = ({
  children,
  defaultInitialState = initialState,
}) => {
  const value = useEnrichScoreBatch(defaultInitialState);

  return (
    <EnrichScoreBatchContext.Provider value={value}>
      {children}
    </EnrichScoreBatchContext.Provider>
  );
};

function useEnrichScoreBatchContext() {
  const context = React.useContext(EnrichScoreBatchContext);
  if (context === undefined) {
    throw new Error(
      'useEnrichScoreBatchContext must be used within a EnrichScoreBatchProvider'
    );
  }
  return context;
}

export { EnrichScoreBatchProvider, useEnrichScoreBatchContext };
