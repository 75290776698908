import BaseService from './BaseService';

class AppointmentsService extends BaseService {
  getAppointments(params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/schedulings`, {
      params,
    });
  }

  getTodayAppointments() {
    return this.axiosInstance.get(`${this.BASE_URL}/schedulings/today`);
  }

  patchAppointment(id, params?) {
    return this.axiosInstance.patch(
      `${this.BASE_URL}/schedulings/${id}`,
      params
    );
  }
}

export default new AppointmentsService();
