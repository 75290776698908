import {
  AULoadingManager,
  AUNotifier,
  ErrorUtils,
} from '@assertiva/assertiva-ui';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _isUndefined from 'lodash/isUndefined';
import comparators from '@src/constants/comparators';
import { FormModes } from '@src/constants/enum';
import {
  closeLinearLoading,
  showLinearLoading,
} from '@src/hooks/useLinearLoading';
import ConfigService from '@src/services/ConfigService';
import { RootState } from '@src/store/rootReducer';
import { FormValues, IBlocklistState } from './types';
import { getQueryParams } from '@src/components/filters';
import axios from 'axios';
import { Phone } from '@src/features/debtors/components/DebtorPhoneCards/DebtorPhoneCards';
import { getOnlyDefaultValidFilters } from '@src/utils/functionUtils';

export const INITIAL_PAGINATION = { page: 0, rowsPerPage: 5, count: 0 };

export const initialState: IBlocklistState = {
  list: [],
  pagination: comparators.DEFAULT_INITIAL_PAGINATION,
  formMode: FormModes.Closed,
  madeInitialFetch: false,
  blocklistToSeeMore: null,
  blocklistReasons: [],
};

export const fetchBlocklistReasons = createAsyncThunk(
  'blocklist/fetchBlocklistReasons',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;

      if (state.blocklist.blocklistReasons.length) {
        return state.blocklist.blocklistReasons;
      }

      const response = await ConfigService.getBlocklistReasons();
      return response.data.body;
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    }
  }
);

export const handleFetchBlocklist = createAsyncThunk(
  'blocklist/handleFetchBlocklist',
  async (_, { dispatch }) => {
    const filters = getOnlyDefaultValidFilters(getQueryParams());

    try {
      showLinearLoading();

      const res = await ConfigService.getBlocklist(filters);
      const { list, page, size, count } = res.data.body;

      dispatch(
        setState({
          list,
          pagination: {
            page,
            rowsPerPage: size,
            count,
          },
        })
      );
    } catch (err) {
      if (axios.isCancel(err)) return;
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      closeLinearLoading();
      dispatch(
        setState({
          madeInitialFetch: true,
        })
      );
    }
  }
);

export const handleCreateBlocklist = createAsyncThunk(
  'blocklist/handleCreateBlocklist',
  async (newBlocklist: FormValues, { dispatch }) => {
    try {
      showLinearLoading();

      const res = await ConfigService.postBlocklist(newBlocklist);

      if (res.data.success) {
        dispatch(resetState());
        AUNotifier.success('Número adicionado com sucesso!');
        if (window.location.pathname.includes('blocklist')) {
          dispatch(handleFetchBlocklist());
        }
      }
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      closeLinearLoading();
    }
  }
);

export const handleEditBlocklist = createAsyncThunk(
  'blocklist/handleEditBlocklist',
  async (
    changes: Partial<{
      id: string;
      idReason: string | number;
      description: string;
      active: boolean;
    }>,
    { getState, dispatch }
  ) => {
    try {
      AULoadingManager.show();

      const state = getState() as RootState;
      const blocklist = state.blocklist;

      const payload = {
        idReason:
          changes.idReason !== blocklist.blocklistToSeeMore?.idReason
            ? changes.idReason
            : undefined,
        description:
          changes.description !== blocklist.blocklistToSeeMore?.description
            ? changes.description
            : undefined,
        active:
          changes.active !== blocklist.blocklistToSeeMore?.active
            ? changes.active
            : undefined,
      };

      const res = await ConfigService.patchBlacklit(changes.id, payload);

      if (res.data.success) {
        if (_isUndefined(payload.active)) {
          AUNotifier.success('Número alterado com sucesso!');
        }
        if (Boolean(blocklist.blocklistToSeeMore)) {
          dispatch(handleCloseDrawer());
        }
        if (!_isUndefined(changes.active)) {
          return blocklist.list.map((row) => {
            if (row.id === changes.id) {
              return { ...row, active: Boolean(changes.active) };
            }
            return row;
          });
        }
        dispatch(resetState());
        if (window.location.pathname.includes('blocklist')) {
          dispatch(handleFetchBlocklist());
        }
      }
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      AULoadingManager.close();
    }
  }
);

export const handleBlockPhoneCard = createAsyncThunk<
  Partial<IBlocklistState> | void,
  Phone
>(
  'blocklist/handleBlockPhoneCard',
  async (
    phone: Phone,
    { dispatch }
  ): Promise<Partial<IBlocklistState> | void> => {
    try {
      AULoadingManager.show();
      await dispatch(fetchBlocklistReasons());

      if (!phone.blacklist) {
        return {
          formMode: FormModes.Add,
          blocklistToSeeMore: { phone: phone.number },
        };
      }

      if (phone.blacklist.active) {
        await dispatch(
          handleEditBlocklist({ id: phone.blacklist.id, active: false })
        );
        return;
      }

      if (phone.blacklist && !phone.blacklist.active) {
        return {
          formMode: FormModes.Add,
          blocklistToSeeMore: {
            ...phone.blacklist,
            phone: phone.number,
          },
        };
      }
    } catch (err) {
    } finally {
      AULoadingManager.close();
    }
  }
);

const blocklistSlice = createSlice({
  name: 'blocklist',
  initialState,
  reducers: {
    setState(state, { payload }: PayloadAction<Partial<IBlocklistState>>) {
      return {
        ...state,
        ...payload,
      };
    },
    handleCloseDrawer(state) {
      return {
        ...state,
        formMode: FormModes.Closed,
        blocklistToSeeMore: null,
      };
    },
    handleOpenDrawer(state, { payload }: PayloadAction<FormModes>) {
      return {
        ...state,
        formMode: payload,
      };
    },
    resetState(state) {
      return {
        ...initialState,
        blocklistReasons: state.blocklistReasons,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBlocklistReasons.fulfilled, (state, action) => {
      state.blocklistReasons = action.payload;
    });
    builder.addCase(handleEditBlocklist.fulfilled, (state, action) => {
      if (action.payload) {
        state.list = action.payload;
      }
    });
    builder.addCase(handleBlockPhoneCard.fulfilled, (state, action) => {
      if (action.payload) {
        state.formMode = action.payload.formMode || state.formMode;
        state.blocklistToSeeMore = action.payload.blocklistToSeeMore || null;
      }
    });
  },
});

export const { resetState, setState, handleOpenDrawer, handleCloseDrawer } =
  blocklistSlice.actions;

export const selectBlocklist = (state: RootState) => state.blocklist;

export const selectBlocklistReasons = (state: RootState) =>
  state.blocklist.blocklistReasons;

export const selectBlocklistList = (state: RootState) => state.blocklist.list;

export default blocklistSlice.reducer;
