export const formatDebtorInfoValues = (value) => {
  return value.map((el) => ({
    ...el,
    disabled: true,
  }));
};

export const handleDebtorContactValues = (
  value: Record<string, any>,
  type: 'phone' | 'email'
) => {
  if (value.length > 0) return value;
  if (type === 'phone') return [{ number: '' }];
  return [{ email: '' }];
};
