import { useState, useEffect, useMemo } from 'react';
import {
  getInfoMessage,
  getMaxLengthInputMessage,
  getRemainingChars,
  removeLastVariable,
} from './messageHandlers';
import { useMessageVariables } from './useMessageVariables';
import { limitChars } from './constants';

export function useMessage() {
  const [message, setMessage] = useState('');

  const { messageVariables, link, setLink, onAddVariable, onRemoveVariable } =
    useMessageVariables({ message, setMessage });

  const remainingChars = useMemo(
    () => getRemainingChars({ message, messageVariables, limitChars }),
    [message, messageVariables]
  );

  const infoMessage = useMemo(
    () => getInfoMessage(remainingChars),
    [remainingChars]
  );

  const maxLengthInputMessage = useMemo(
    () => getMaxLengthInputMessage({ message, limitChars, messageVariables }),
    [message, messageVariables]
  );

  useEffect(() => {
    if (remainingChars < 0) setMessage(removeLastVariable(message));
  }, [message, remainingChars]);

  return {
    message,
    setMessage,
    remainingChars,
    infoMessage,
    maxLengthInputMessage,
    messageVariables,
    link,
    setLink,
    onAddVariable,
    onRemoveVariable,
  };
}
