import { AuthUtils, useFeatureFlag } from '@assertiva/assertiva-ui';
import { RECUPERE_UI_STRATEGY } from '@src/constants/featureFlags';
import { useEffect } from 'react';

export function useStrategyFeatureFlag() {
  const { flagsError, flagsReady, getFlag, updateContext } = useFeatureFlag();

  const user = AuthUtils.getUser();

  const userId = user?.id?.toString();

  const isAssertiva = user?.isAssertiva();

  const isCastelo =
    user?.getCompanyId() === Number(process.env.REACT_APP_CASTELO_COMPANY_ID);

  const hasFeatureFlag =
    getFlag(RECUPERE_UI_STRATEGY) && flagsReady && !flagsError;

  const shouldShowStrategy = isAssertiva || isCastelo || hasFeatureFlag;

  useEffect(() => {
    updateContext({ userId });
  }, [updateContext, userId]);

  return { shouldShowStrategy };
}
