import { StyledChannelLabel } from './ChannelLabel.styles';

const ChannelLabel = () => {
  return (
    <StyledChannelLabel variant="caption" color="textSecondary" gutterBottom>
      Canal
    </StyledChannelLabel>
  );
};

export default ChannelLabel;
