import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { StringUtils, AUBoxSkeleton } from '@assertiva/assertiva-ui';
import {
  StyledCard,
  StyledCardTitle,
  StyledCheckbox,
  StyledCheckIcon,
} from './SummarizerCard.styles';

interface Card {
  description: string;
  backgroundColor: string;
  borderColor: string;
  amountDebts?: number;
  valueDebts?: number;
  tooltip?: string;
  idStatus: number | string;
}

interface Props {
  card?: Card;
  selected?: boolean;
  loading?: boolean;
  onSelect?: () => void;
  showCheckbox?: boolean;
  maxWidth?: string | number;
  width?: string;
  smallCard?: boolean;
  hideDebtsInfo?: boolean;
  wrapColumns?: boolean;
}

const SummarizerCard = ({
  card,
  selected,
  loading,
  showCheckbox,
  maxWidth,
  width,
  smallCard,
  hideDebtsInfo,
  onSelect,
  wrapColumns,
}: Props) => (
  <Tooltip
    title={card?.tooltip ? <Box width={160}>{card?.tooltip}</Box> : ''}
    arrow
    placement="top"
    enterDelay={800}
    enterNextDelay={800}
  >
    <div>
      <StyledCard
        selected={Boolean(selected)}
        loading={Boolean(loading)}
        backgroundColor={card?.backgroundColor}
        borderColor={card?.borderColor}
        onClick={onSelect}
        data-testid={selected ? 'summarizer-card-selected' : 'summarizer-card'}
        id={`summarizer-card-${card?.idStatus}`}
        justifyContent={hideDebtsInfo ? 'center' : undefined}
        maxWidth={maxWidth}
        width={width}
        minHeight={smallCard ? 80 : undefined}
        wrapColumns={wrapColumns}
      >
        {loading ? (
          <AUBoxSkeleton height={20} width="90%" aria-label="loading" />
        ) : (
          <StyledCardTitle
            data-testid="summarizer-card-title"
            fontSize={smallCard ? '14px' : undefined}
            wrapColumns={wrapColumns}
          >
            {card?.description}
          </StyledCardTitle>
        )}
        {showCheckbox ? (
          <StyledCheckbox color="primary" checked={selected} />
        ) : null}
        {selected && !showCheckbox ? (
          <StyledCheckIcon color="primary" data-testid="check-icon" />
        ) : null}
        {!hideDebtsInfo ? (
          <Box minWidth={wrapColumns ? 110 : undefined}>
            {loading ? (
              <>
                <AUBoxSkeleton height={20} width="60%" marginBottom={1} />
                <AUBoxSkeleton height={20} width="75%" />
              </>
            ) : (
              <>
                <Typography color="textSecondary">
                  <strong>{card?.amountDebts?.toLocaleString('pt-BR')}</strong>{' '}
                  <Typography component="span" variant="body2">
                    dívidas
                  </Typography>
                </Typography>
                <Typography color="textSecondary" className="value-debts">
                  <strong
                    id={`valor-total-${card?.description
                      .toLowerCase()
                      .replace(' ', '-')}`}
                  >
                    {StringUtils.formatFloatToCurrency(card?.valueDebts)}
                  </strong>
                </Typography>
              </>
            )}
          </Box>
        ) : null}
      </StyledCard>
    </div>
  </Tooltip>
);

export default SummarizerCard;
