import { styled } from '@material-ui/core/styles';

export const StyledMessageField = styled('div')(({ theme }) => ({
  position: 'relative',

  '& .message-input': {
    marginBottom: 0,
    backgroundColor: '#fff',
  },

  '& .message-input-base': {
    paddingBottom: '24px',
  },

  '& .message-input fieldset': {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },

  '& .info-message': {
    position: 'absolute',
    top: 210,
    right: 8,
  },
}));

export const StyledActionsContainer = styled('div')({
  backgroundColor: '#f5f5f5',
  padding: 12,
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderTopColor: 'transparent',
});

export const StyledVariablesContainer = styled('div')({
  maxHeight: 125,
  display: 'flex',
  gap: '8px',
  marginTop: '8px',
  flexWrap: 'wrap',
});

export const StyledLogoContainer = styled('div')({});
