import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStrategyDrawer } from '../../useStrategyDrawer';
import { useAppDispatch } from '@src/store/hooks';
import { handleSubmitStrategy, prevStep } from '../../strategySlice';
import { AUNotifier, ErrorUtils } from '@assertiva/assertiva-ui';
import { StrategyConfig, UserListItem } from '../../types';
import StrategyService from '@src/services/StrategyService';

export const useUsersStep = () => {
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [usersList, setUsersList] = useState<UserListItem[] | undefined>(
    undefined
  );

  const { isOpen, strategyConfig, handleChangeStrategyConfig, drawerMode } =
    useStrategyDrawer();

  const dispatch = useAppDispatch();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    dispatch(handleSubmitStrategy(strategyConfig as StrategyConfig));
  };

  const handleBack = () => dispatch(prevStep());

  const selectedIdUsers = strategyConfig?.idUsers;

  const selectedUsers = useMemo(
    () =>
      usersList?.filter((wallet) => selectedIdUsers?.includes(wallet.id)) || [],
    [usersList, selectedIdUsers]
  );

  const usersListWithActives = useMemo(
    () =>
      usersList?.map((wallet) => ({
        ...wallet,
        active: selectedIdUsers?.includes(wallet.id),
      })) || [],
    [usersList, selectedIdUsers]
  );

  const isSubmitDisabled = !selectedIdUsers?.length;

  const idStrategy = strategyConfig?.id;

  const handleFetchUsers = useCallback(async () => {
    try {
      setIsFetchingUsers(true);
      const res = await StrategyService.getStrategyUsersAvailable({
        idStrategy,
      });
      const users = res.data.body;
      if (selectedIdUsers?.length) {
        setUsersList(
          users.map((user) => ({
            ...user,
            active: selectedIdUsers?.includes(user.id),
          }))
        );
      } else {
        setUsersList(users);
      }
    } catch (err) {
      console.error(err);
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      setIsFetchingUsers(false);
    }
  }, [selectedIdUsers, idStrategy]);

  const handleChangeUserStatus = (id: number, active: boolean) => {
    const oldIdUsers = strategyConfig?.idUsers ?? [];
    handleChangeStrategyConfig({
      idUsers: active
        ? [...oldIdUsers, id]
        : oldIdUsers.filter((idUser) => idUser !== id),
    });
  };

  useEffect(() => {
    if (isOpen && !usersList) {
      handleFetchUsers();
    }
  }, [isOpen, handleFetchUsers, usersList]);

  return {
    handleFetchUsers,
    isFetchingUsers,
    handleChangeStrategyConfig,
    handleSubmit,
    isSubmitDisabled,
    strategyConfig,
    usersListWithActives,
    selectedUsers,
    handleChangeUserStatus,
    handleBack,
    drawerMode,
  };
};
