import {
  StrategyConfig,
  StrategyDetailed,
  StrategyListDTO,
  StrategyId,
  UserAvailable,
  FilterOption,
  StrategyListItem,
} from '@src/features/strategy/types';
import { GetResponse, SuccessResponse } from '@src/constants/types';
import BaseService from './BaseService';
import { StrategyInfo } from '@src/features/strategyDebt/types';
import { NBAStatus } from '@src/features/wallet/type';

class StrategyService extends BaseService {
  getStrategyList(params?) {
    return this.axiosInstance.get<GetResponse<StrategyListDTO>>(
      `${this.BASE_URL}/strategy/list`,
      {
        params,
      }
    );
  }
  getStrategyConfig(id: StrategyId) {
    return this.axiosInstance.get<GetResponse<StrategyConfig>>(
      `${this.BASE_URL}/strategy/${id}`
    );
  }
  patchStrategy(id: StrategyId, payload: Partial<StrategyDetailed>) {
    return this.axiosInstance.patch<SuccessResponse>(
      `${this.BASE_URL}/strategy/${id}`,
      payload
    );
  }
  putStrategy(id: StrategyId, payload: StrategyConfig) {
    return this.axiosInstance.put<SuccessResponse>(
      `${this.BASE_URL}/strategy/${id}`,
      payload
    );
  }
  postStrategy(payload: StrategyConfig) {
    return this.axiosInstance.post<{
      body: StrategyListItem;
      success: boolean;
    }>(`${this.BASE_URL}/strategy`, payload);
  }
  deleteStrategy(id: StrategyId) {
    return this.axiosInstance.delete<SuccessResponse>(
      `${this.BASE_URL}/strategy/${id}`
    );
  }
  getStrategyUsersAvailable(params?) {
    return this.axiosInstance.get<GetResponse<UserAvailable[]>>(
      `${this.BASE_URL}/strategy/user/available`,
      { params }
    );
  }
  getAgreementFilterOptions() {
    return this.axiosInstance.get<GetResponse<FilterOption[]>>(
      `${this.BASE_URL}/strategy/agreement-filter-option`
    );
  }
  getNegativeFilterOptions() {
    return this.axiosInstance.get<GetResponse<FilterOption[]>>(
      `${this.BASE_URL}/strategy/negative-filter-option`
    );
  }
  getAgingFilterOptions() {
    return this.axiosInstance.get<GetResponse<FilterOption[]>>(
      `${this.BASE_URL}/strategy/aging-filter-option`
    );
  }
  getStrategyInfo() {
    return this.axiosInstance.get<GetResponse<StrategyInfo>>(
      `${this.BASE_URL}/strategy`
    );
  }
  getStrategyDebtsSummary(params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/strategy/debt/summary`, {
      params,
    });
  }
  getStrategyDebts(idStatus, params?) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/strategy/debt/track/${idStatus}`,
      {
        params,
      }
    );
  }
  getStrategyNBAStatus() {
    return this.axiosInstance.get<GetResponse<{ status: NBAStatus }>>(
      `${this.BASE_URL}/strategy/nba/status`
    );
  }
  confirmPaymentDebt(data, params?, idStatus?) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/strategy/confirm-payment${
        idStatus ? '/' + idStatus : ''
      }`,
      data,
      {
        params,
      }
    );
  }
  cancelPaymentDebt(data, params?, idStatus?) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/strategy/cancel-payment${
        idStatus ? '/' + idStatus : ''
      }`,
      data,
      {
        params,
      }
    );
  }
  getHasActivePaymentMethod() {
    return this.axiosInstance.get<
      GetResponse<{ hasActivePaymentMethod: boolean }>
    >(
      `${this.BASE_PAYMENTS_URL}/strategy/paymentmethod/has-active-payment-method`
    );
  }
  postGenerateBilletBatch(data, params?, idStatus?) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/strategy/debt/billet-batch${
        idStatus ? `/${idStatus}` : ''
      }`,
      data,
      {
        params,
      }
    );
  }
}

export default new StrategyService();
