import BaseService from './BaseService';
import { AUPurposeService, AuthUtils } from '@assertiva/assertiva-ui';
import { EventSourcePolyfill } from 'event-source-polyfill';

class DebtorsService extends BaseService {
  getDebtors(params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/debtor`, { params });
  }

  getAllDebtorDebts(idDebtor, params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/debt/debtor/${idDebtor}`, {
      params,
    });
  }

  getDebtorCount(params?) {
    return this.axiosInstance.get(`${this.BASE_URL}/debtor/count/filters`, {
      params,
    });
  }

  postDebtorEnrich(idDebtor, data) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/debtor/${idDebtor}/enrich`,
      data
    );
  }

  SseSend(ids: any, type) {
    const generateRandomString = () =>
      `${new Date().getTime()}-${Math.random() * 100}`;

    const isEnrich = (type) => type === 'enrich';
    const getTypeRequest = (type) => (type ? 'localize' : 'score');

    return new EventSourcePolyfill(
      `${this.BASE_EVENTS_URL}/event?originName=debtor-bulk-${getTypeRequest(
        isEnrich(type)
      )}&idValues=${ids}`,
      {
        heartbeatTimeout: 1800000,
        headers: {
          Authorization: 'Bearer ' + AuthUtils.getToken(),
          memberId: generateRandomString(),
        },
      }
    );
  }

  postDebtorScore(idDebtor, data) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/debtor/${idDebtor}/score`,
      data
    );
  }

  getBatchDebtorEnrichAndScoreStatus() {
    return this.axiosInstance.get(`${this.BASE_URL}/debtor/status/bulk`);
  }
  postBatchDebtorEnrichAndScore(type, data) {
    return this.axiosInstance.post(
      `${this.BASE_URL}/debtor/${type}/bulk`,
      data
    );
  }

  getDebtorParentDebts(idDebtor, params?, cancelToken?) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debt/parent/debtor/${idDebtor}`,
      {
        params,
        cancelToken,
      }
    );
  }

  getPurposesOfUse = (PRODUCT_ID) => {
    return AUPurposeService.getPurposes(PRODUCT_ID);
  };

  patchDebtor(idDebtor: string, params) {
    return this.axiosInstance.patch(
      `${this.BASE_URL}/debtor/${idDebtor}`,
      params
    );
  }

  deleteDebtor(idDebtor: string) {
    return this.axiosInstance.delete(`${this.BASE_URL}/debtor/${idDebtor}`);
  }

  getDebtorData(idDebtor: string, config?) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debtor/${idDebtor}`,
      config
    );
  }

  getDebtorByDoc(doc: string) {
    return this.axiosInstance.get(`${this.BASE_URL}/debtor/doc/${doc}`);
  }

  getDebtorHistoric(idDebtor, params) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debtor/${idDebtor}/contact/historic`,
      { params }
    );
  }

  getReportWebphoneByDebtor(idDebtor, params) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debtor/${idDebtor}/webphone/historic`,
      {
        params,
      }
    );
  }

  getEventsHistoric(idDebtor, params) {
    return this.axiosInstance.get(
      `${this.BASE_URL}/debtor/${idDebtor}/events/historic`,
      { params }
    );
  }

  postEventsHistoric = (idDebtor, params) => {
    return this.axiosInstance.post(
      `${this.BASE_URL}/debtor/${idDebtor}/events/historic`,
      params
    );
  };

  deleteEventHistoric = (idDebtor, idEvent) => {
    return this.axiosInstance.delete(
      `${this.BASE_URL}/debtor/${idDebtor}/events/historic/${idEvent}`
    );
  };
}

export default new DebtorsService();
