import { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import { IEmailConfigPreview } from '@src/features/trackConfig/types';
import { AUBoxSkeleton } from '@assertiva/assertiva-ui';
import { converteUrlToHref, handleSender, sanitizeMessage } from '../utils';
import EmailTemplate from './EmailTemplate';
import { getMessageWithBoldVariables } from '../messageHandlers';

const LogoHandler = ({
  height,
  width,
  styles = {},
  isLoading,
  hasLogo,
  logoSrc,
  alt = 'logo do e-mail',
}) => {
  if (!hasLogo) return null;

  if (hasLogo && isLoading)
    return (
      <AUBoxSkeleton
        width={width}
        height={height}
        component="span"
        display="block"
        data-testid="skeleton-logo"
        style={styles}
      />
    );

  return (
    <img
      src={logoSrc}
      width={width}
      height={height}
      style={{
        objectFit: 'contain',
        objectPosition: 'left',
        ...styles,
      }}
      alt={alt}
    />
  );
};

interface Props {
  open?: boolean;
  onClose?(): void;
  emailConfigPreview: IEmailConfigPreview;
}

const PreviewEmailDialog = ({
  open = true,
  onClose,
  emailConfigPreview,
}: Props) => {
  const [loadedLogo, setLoadedLogo] = useState(false);
  const hasLogo = Boolean(emailConfigPreview.logo?.idFileS3);
  const logoSrc = emailConfigPreview.logoUrl;
  const sanitizedMessage = sanitizeMessage(emailConfigPreview.message || '');
  const messageWithCorrectLinks = converteUrlToHref(sanitizedMessage);
  const messageWithSender = handleSender(messageWithCorrectLinks, {
    addBold: true,
  });
  const messageWithVariablesBold =
    getMessageWithBoldVariables(messageWithSender);

  useEffect(() => {
    if (!open) {
      setTimeout(() => setLoadedLogo(false), 500);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: 732,
        },
      }}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle style={{ paddingTop: '20px', paddingBottom: '12px' }}>
        Assunto: {emailConfigPreview.subject}
      </DialogTitle>
      <DialogContent>
        <EmailTemplate
          logoHeader={
            hasLogo ? (
              <LogoHandler
                logoSrc={logoSrc}
                alt="logo do cabeçalho"
                isLoading={!loadedLogo}
                hasLogo={hasLogo}
                width={90}
                height={45}
                styles={{
                  objectPosition: 'center',
                }}
              />
            ) : null
          }
          logoBody={
            <LogoHandler
              logoSrc={logoSrc}
              alt="logo do e-mail"
              isLoading={!loadedLogo}
              hasLogo={hasLogo}
              width={170}
              height={85}
              styles={{
                marginTop: 16,
              }}
            />
          }
        >
          <div dangerouslySetInnerHTML={{ __html: messageWithVariablesBold }} />
        </EmailTemplate>
        {hasLogo && (
          <img
            src={logoSrc}
            onLoad={() => setLoadedLogo(true)}
            style={{ display: 'none' }}
            alt="logo do e-mail oculto"
          />
        )}
        <Box
          width="100%"
          bgcolor="#EFEEEE"
          padding="15px 50px 25px"
          fontSize={12}
          lineHeight="18px"
        >
          <Box width="95%">
            <Box textAlign="center">
              <b>
                Esse é um e-mail automático. Por favor, não responda esta
                mensagem.
                <br />
              </b>
              Em caso de dúvidas, entre em contato com a empresa credora da
              dívida constante <br />
              no cabeçalho deste e-mail.
            </Box>
          </Box>
          <Box borderBottom="1px solid #000000" mt={2} mb={2} />
          <Box textAlign="center">
            E-mail enviado através da Plataforma Assertiva
            <br />
            Acesse{' '}
            <a
              href="https://plataformaassertiva.com.br/"
              style={{ color: '#000' }}
            >
              <b>www.plataformaassertiva.com.br</b>
            </a>{' '}
            e saiba mais
          </Box>
        </Box>
        <Box
          marginTop={3}
          marginBottom={1}
          display="flex"
          justifyContent="flex-end"
          component="span"
        >
          <Button color="primary" onClick={onClose} variant="text">
            FECHAR
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewEmailDialog;
