import { useCallback, useState } from 'react';
import { useDefaultDrawer } from '@src/components/DefaultDrawer';
import { TYPE_MESSAGE_ENUM } from '../types';

interface State {
  typeMessageToDefine?: TYPE_MESSAGE_ENUM;
  isSubmitDialogOpen?: boolean;
}

const initialState: State = {
  typeMessageToDefine: undefined,
  isSubmitDialogOpen: false,
};

export function useDefineMessages() {
  const [state, setState] = useState<State>(initialState);
  const { setShouldSlideLeft } = useDefaultDrawer();

  const handleState = useCallback(
    (changes: Partial<State>) =>
      setState((state) => ({ ...state, ...changes })),
    []
  );

  const handleOpenDrawer = (typeMessage: TYPE_MESSAGE_ENUM) => {
    handleState({ typeMessageToDefine: typeMessage });
    setShouldSlideLeft(true);
  };

  const handleCloseDrawer = () => {
    handleState({ typeMessageToDefine: undefined });
    setShouldSlideLeft(false);
  };

  return { state, handleOpenDrawer, handleCloseDrawer, handleState };
}
