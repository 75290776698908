import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useTheme } from '@material-ui/core/styles';

const SearchAdornment = () => {
  const theme = useTheme();

  return (
    <InputAdornment disablePointerEvents disableTypography position="end">
      <SearchIcon htmlColor={theme.palette.primary.main} />
    </InputAdornment>
  );
};

export default SearchAdornment;
