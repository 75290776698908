import _isEqual from 'lodash/isEqual';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  StrategyDebtState,
  StrategyInfo,
  StrategyDebtTable,
  StrategyDebtSummary,
} from './types';
import {
  AUCookiesUtils,
  AUNotifier,
  ErrorUtils,
} from '@assertiva/assertiva-ui';
import StrategyService from '@src/services/StrategyService';
import { RootState } from '@src/store/rootReducer';
import { NBAStatus } from '../wallet/type';
import { isFetchingNBA } from '../wallet/utils';
import {
  EXPIRED_ID_STATUS,
  FINISHED_ID_STATUS,
  INITIAL_PAGINATION,
} from '../debt/list/constants';
import DebtsService from '@src/services/DebtsService';
import { getQueryParams } from '@src/components/filters';
import {
  getFiltersCookies,
  getOnlyValidFilters,
  isValidNbaGroupType,
  orderedStrategyNBAGroups,
} from './utils';
import {
  closeLinearLoading,
  showLinearLoading,
} from '@src/hooks/useLinearLoading';
import { STRATEGY_DEBTS_COOKIE } from './constants';

export const initialState: StrategyDebtState = {
  userStrategy: null,
  madeInitialFetchUserStrategy: false,
  nbaStatus: null,
  hasActivePaymentMethod: false,
  table: {
    list: [],
    detailStatus: [],
    pagination: INITIAL_PAGINATION,
    madeInitialFetchDebts: false,
  },
  summary: {
    mapStatus: {},
    mapSummary: {},
    madeInitialFetchSummary: false,
    lastSummaryFilters: undefined,
  },
  debtIdToDelete: undefined,
  debtIdToAddEvent: undefined,
  debtIdsToConfirm: undefined,
  debtIdsToCancel: undefined,
  debtIdsToConfirmWithBillet: undefined,
  debtIdToSeeMore: undefined,
  debtInfoToCall: undefined,
  debtIdToSendSMS: undefined,
  isAllRegistersSelected: false,
  isFiltersOpen: false,
  isGeneratingBilletBatch: false,
};

const strategyDebtSlice = createSlice({
  name: 'strategyDebt',
  initialState,
  reducers: {
    setStrategyDebtState: (
      state,
      action: PayloadAction<Partial<StrategyDebtState>>
    ) => ({
      ...state,
      ...action.payload,
    }),
    setUserStrategy: (state, action: PayloadAction<StrategyInfo>) => ({
      ...state,
      userStrategy: action.payload,
    }),
    setTable: (state, action: PayloadAction<Partial<StrategyDebtTable>>) => ({
      ...state,
      table: {
        ...state.table,
        ...action.payload,
      },
    }),
    setSummary: (
      state,
      action: PayloadAction<Partial<StrategyDebtSummary>>
    ) => ({
      ...state,
      summary: {
        ...state.summary,
        ...action.payload,
      },
    }),
    setHasActivePaymentMethod: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasActivePaymentMethod: action.payload,
    }),
    setMadeInitialFetchUserStrategy: (
      state,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      madeInitialFetchUserStrategy: action.payload,
    }),
    setNBAStatus: (state, action: PayloadAction<NBAStatus>) => ({
      ...state,
      nbaStatus: action.payload,
    }),
    resetState: () => initialState,
    selectRow: (state, action: PayloadAction<number>) => {
      state.table.list = state.table.list.map((debt) =>
        Number(debt.id) === Number(action.payload)
          ? { ...debt, selected: !debt.selected }
          : debt
      );
    },
    selectAllRows: (state) => {
      state.table.list = state.table.list.map((debt) => ({
        ...debt,
        selected: true,
      }));
    },
    unselectAllRows: (state) => {
      state.table.list = state.table.list.map((debt) => ({
        ...debt,
        selected: false,
      }));
      state.isAllRegistersSelected = false;
    },
    selectAllRegisters: (state) => {
      state.isAllRegistersSelected = true;
    },
    callbackCallWebphone: (
      state,
      action: PayloadAction<{ idDebt: string; idDebtor: string }>
    ) => {
      state.debtInfoToCall = {
        idDebt: action.payload.idDebt,
        idDebtor: action.payload.idDebtor,
      };
      state.table.list = state.table.list.map((row) => ({
        ...row,
        selected: row.id === action.payload.idDebt,
      }));
    },
    resetDebtInfoToCall: (state) => {
      state.debtInfoToCall = undefined;
      state.table.list = state.table.list.map((row) => ({
        ...row,
        selected: false,
      }));
    },
  },
});

export const selectUserStrategy = (state: RootState) => ({
  userStrategy: state.strategyDebt.userStrategy,
  madeInitialFetch: state.strategyDebt.madeInitialFetchUserStrategy,
});

export const selectStrategyNBAStatus = (state: RootState) =>
  state.strategyDebt.nbaStatus;

export const selectIsFetchingStrategyNBA = (state: RootState) =>
  isFetchingNBA(state.strategyDebt.nbaStatus);

export const selectStrategyNBA = (state: RootState) =>
  state.strategyDebt.userStrategy?.nba;

export const selectOrderedStrategyNBAGroups = (state: RootState) =>
  orderedStrategyNBAGroups(state.strategyDebt.userStrategy?.nba?.groups);

export const selectStrategyNBAGroups = (state: RootState) =>
  state.strategyDebt.userStrategy?.nba?.groups;

export const selectStrategyHasNBA = (state: RootState) =>
  Boolean(state.strategyDebt.userStrategy?.nba);

export const selectStrategyDebtDetailStatus = (state: RootState) =>
  state.strategyDebt.table.detailStatus;

export const selectUserHasStrategy = (state: RootState) =>
  Boolean(state.strategyDebt.userStrategy);

export const selectStrategyDebtsTable = (state: RootState) =>
  state.strategyDebt.table;

export const selectStrategyDebtsTableList = (state: RootState) =>
  state.strategyDebt.table.list;

export const selectConfirmPaymentData = (state: RootState) => ({
  debtIdsToConfirm: state.strategyDebt.debtIdsToConfirm,
  isAllRegistersSelected: state.strategyDebt.isAllRegistersSelected,
  list: state.strategyDebt.table.list,
  mapSummary: state.strategyDebt.summary.mapSummary,
});

export const selectCancelPaymentData = (state: RootState) => ({
  debtIdsToCancel: state.strategyDebt.debtIdsToCancel,
  isAllRegistersSelected: state.strategyDebt.isAllRegistersSelected,
  list: state.strategyDebt.table.list,
  mapSummary: state.strategyDebt.summary.mapSummary,
});

export const selectStrategyDebtInfoToCall = (state: RootState) =>
  state.strategyDebt.debtInfoToCall;

export const selectStrategyDebtSummary = (state: RootState) =>
  state.strategyDebt.summary;

export const selectStrategyDebtMapSummary = (state: RootState) =>
  state.strategyDebt.summary.mapSummary;

export const selectStrategyDebtMapStatus = (state: RootState) =>
  state.strategyDebt.summary.mapStatus;

export const selectIsFiltersOpen = (state: RootState) =>
  state.strategyDebt.isFiltersOpen;

export const selectDebtIdToSendSMS = (state: RootState) =>
  state.strategyDebt.debtIdToSendSMS;

export const selectDebtIdToSeeMore = (state: RootState) =>
  state.strategyDebt.debtIdToSeeMore;

export const selectDebtToSendWhatsApp = (state: RootState) =>
  state.strategyDebt.table.list.find(
    (debt) => debt.id === state.strategyDebt.debtIdToSendWhatsapp
  );

export const selectDebtIdToAddEvent = (state: RootState) =>
  state.strategyDebt.debtIdToAddEvent;

export const selectDebtIdsToConfirmWithBillet = (state: RootState) =>
  state.strategyDebt.debtIdsToConfirmWithBillet;

export const selectStrategyHasExpiredStatus = (state: RootState) =>
  state.strategyDebt.summary.mapSummary[EXPIRED_ID_STATUS];

export const selectIsAllRowsSelected = (state: RootState) =>
  state.strategyDebt.table.list.length > 0 &&
  !state.strategyDebt.table.list.some((debt) => !debt.selected);

export const selectIsAllRegistersSelected = (state: RootState) =>
  state.strategyDebt.isAllRegistersSelected;

export const selectSelectedRows = (state: RootState) =>
  state.strategyDebt.table.list.filter((debt) => debt.selected);

export const selectShouldShowConfirmPayment = createSelector(
  [selectSelectedRows],
  (selectedRows) =>
    selectedRows.length &&
    selectedRows.some((debt) => Number(debt.idStatus) !== FINISHED_ID_STATUS)
);

export const selectShouldShowCancelPayment = createSelector(
  [selectSelectedRows],
  (selectedRows) =>
    selectedRows.length &&
    selectedRows.every((debt) => Number(debt.idStatus) === FINISHED_ID_STATUS)
);

export const selectShouldShowGenerateBilletBatchButton = createSelector(
  [selectSelectedRows],
  (selectedRows) => Boolean(selectedRows.length)
);

export const selectStrategyIsGeneratingBilletBatch = (state: RootState) =>
  state.strategyDebt.isGeneratingBilletBatch;

export const selectGenerateBilletBatch = createSelector(
  [
    selectStrategyDebtsTableList,
    selectStrategyDebtMapSummary,
    (state: RootState) => ({
      isAllRegistersSelected: state.strategyDebt.isAllRegistersSelected,
      isGeneratingBilletBatch: state.strategyDebt.isGeneratingBilletBatch,
      hasActivePaymentMethod: state.strategyDebt.hasActivePaymentMethod,
    }),
  ],
  (
    list,
    mapSummary,
    { isAllRegistersSelected, isGeneratingBilletBatch, hasActivePaymentMethod }
  ) => ({
    list,
    mapSummary,
    isAllRegistersSelected,
    isGeneratingBilletBatch,
    hasActivePaymentMethod,
  })
);

export const {
  setStrategyDebtState,
  setUserStrategy,
  setTable,
  setSummary,
  setHasActivePaymentMethod,
  callbackCallWebphone,
  resetDebtInfoToCall,
  selectAllRows,
  selectAllRegisters,
  selectRow,
  unselectAllRows,
  setMadeInitialFetchUserStrategy,
  setNBAStatus,
  resetState,
} = strategyDebtSlice.actions;

export const fetchStrategyNBAStatus = createAsyncThunk(
  'strategy/fetchStrategyNBAStatus',
  async (_, { dispatch }) => {
    try {
      const res = await StrategyService.getStrategyNBAStatus();

      if (res.data.success) {
        const { status } = res.data.body;
        dispatch(setNBAStatus(status));

        if (status === NBAStatus.Success) {
          await dispatch(fetchUserStrategy());
          return;
        }

        if (isFetchingNBA(status)) {
          setTimeout(() => dispatch(fetchStrategyNBAStatus()), 4000);
        }
      }
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    }
  }
);

export const fetchUserStrategy = createAsyncThunk(
  'strategyDebt/fetchUserStrategy',
  async (_, { dispatch }) => {
    try {
      const res = await StrategyService.getStrategyInfo();

      if (res.data.success) {
        dispatch(
          setStrategyDebtState({
            userStrategy: res.data.body,
            madeInitialFetchUserStrategy: true,
          })
        );

        if (!res.data.body.nba) {
          dispatch(fetchStrategyNBAStatus());
        }
      }
    } catch (err) {
      const status = (err as any)?.response?.status;
      if (status !== 404 && status !== 403) {
        AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
      }

      dispatch(setMadeInitialFetchUserStrategy(true));
    }
  }
);

export const fetchStrategyDebtsDetailStatus = createAsyncThunk(
  'strategyDebt/fetchStrategyDebtsDetailStatus',
  async (_, { dispatch }) => {
    try {
      const res = await DebtsService.getDebtDetailStatus();
      if (res.data?.body?.length) {
        dispatch(
          setTable({
            detailStatus: res.data.body,
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const fetchStrategyDebtsSummaryStatus = createAsyncThunk(
  'strategyDebt/fetchStrategyDebtsSummaryStatus',
  async (_, { dispatch }) => {
    try {
      const res = await DebtsService.getDebtStatus();
      if (res.data?.body?.length) {
        const mapStatus = {};
        res.data.body.forEach((status) => (mapStatus[status.id] = status));
        dispatch(
          setSummary({
            mapStatus,
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  }
);

interface FetchStrategyDebtsSummaryOptions {
  refetch?: boolean;
}

let isFetchingDebtsSummary = false;

export const fetchStrategyDebtsSummary = createAsyncThunk(
  'strategyDebt/fetchStrategyDebtsSummary',
  async (
    options: FetchStrategyDebtsSummaryOptions | undefined,
    { dispatch, getState }
  ) => {
    if (isFetchingDebtsSummary) return;
    try {
      isFetchingDebtsSummary = true;

      if (options?.refetch) showLinearLoading();

      const state = getState() as RootState;

      const nbaGroups = state.strategyDebt.userStrategy?.nba?.groups;
      const lastSummaryFilters = state.strategyDebt.summary.lastSummaryFilters;
      const { page, size, idStatus, ...filters } = getQueryParams();

      let { idGroupTypeNba } = filters;

      const filtersCookies = getFiltersCookies();

      idGroupTypeNba = idGroupTypeNba || filtersCookies?.idGroupTypeNba;

      if (!isValidNbaGroupType(Number(idGroupTypeNba), nbaGroups)) {
        // precisa ser string para não falhar o _isEqual de baixo
        // da slice vem number, dos filters vem string
        idGroupTypeNba = `${nbaGroups?.[0]?.idGroupTypeNba}`;
      }
      const mapSummary = {};
      const finalFilters = {
        ...getOnlyValidFilters(filters),
        idGroupTypeNba,
      };

      // esse _isEqual
      if (
        window.location.search &&
        _isEqual(finalFilters, lastSummaryFilters) &&
        !options?.refetch
      )
        return;

      const res = await StrategyService.getStrategyDebtsSummary(finalFilters);
      if (res.data?.body?.length) {
        res.data.body.forEach(
          (status) => (mapSummary[status.idStatus] = status)
        );
        dispatch(
          setSummary({
            mapSummary,
            lastSummaryFilters: finalFilters,
          })
        );
      }
    } catch (err) {
      console.error(err);
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      isFetchingDebtsSummary = false;
      dispatch(
        setSummary({
          madeInitialFetchSummary: true,
        })
      );
      closeLinearLoading();
    }
  }
);

interface FetchStrategyDebtsOptions {
  refetch?: boolean;
}

let isFetchingDebts = false;

export const fetchStrategyDebts = createAsyncThunk(
  'strategyDebt/fetchStrategyDebts',
  async (
    options: FetchStrategyDebtsOptions | undefined,
    { dispatch, getState }
  ) => {
    if (isFetchingDebts) return;
    try {
      isFetchingDebts = true;

      const state = getState() as RootState;

      const madeInitialFetchDebts =
        state.strategyDebt.table.madeInitialFetchDebts;

      let { idGroupTypeNba, ...filters } = getQueryParams();

      const { idStatus } = filters;

      const nbaGroups = state.strategyDebt.userStrategy?.nba?.groups;

      if (!isValidNbaGroupType(Number(idGroupTypeNba), nbaGroups)) {
        idGroupTypeNba = `${nbaGroups?.[0]?.idGroupTypeNba}`;
      }

      filters = {
        ...getOnlyValidFilters(filters),
        idGroupTypeNba,
      };

      const filtersCookies = getFiltersCookies();

      if (
        madeInitialFetchDebts &&
        _isEqual(filters, filtersCookies) &&
        !options?.refetch
      )
        return;

      AUCookiesUtils.saveItem(STRATEGY_DEBTS_COOKIE, JSON.stringify(filters), {
        expires: 7,
        path: '',
      });

      showLinearLoading();
      dispatch(unselectAllRows());

      const res = await StrategyService.getStrategyDebts(idStatus, filters);
      const {
        body: { list, page, size, count },
      } = res.data;

      dispatch(
        setTable({
          list,
          pagination: {
            page,
            rowsPerPage: size,
            count,
          },
        })
      );
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      isFetchingDebts = false;
      closeLinearLoading();
      dispatch(
        setTable({
          madeInitialFetchDebts: true,
        })
      );
    }
  }
);

export const fetchStrategyHasActivePaymentMethod = createAsyncThunk(
  'strategyDebt/fetchStrategyHasActivePaymentMethod',
  async (_, { dispatch }) => {
    try {
      const res = await StrategyService.getHasActivePaymentMethod();
      if (res.data.success) {
        dispatch(
          setHasActivePaymentMethod(res.data.body.hasActivePaymentMethod)
        );
      }
    } catch (err) {
      const status = (err as any)?.response?.status;

      if (status !== 404) {
        AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
      }
    }
  }
);

export default strategyDebtSlice.reducer;
