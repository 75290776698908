const URL_IMAGES = {
  WHITE_LOGO: `${process.env.REACT_APP_S3_URL}/images/logo_recupere_navbar.svg`,
  ASSERTIVA_ICON: `${process.env.REACT_APP_S3_URL}/images/logo_assertiva.svg`,
  UPLOAD_ICON: `${process.env.REACT_APP_S3_URL}/images/upload_icon.svg`,
  CONFIG_EXPLAINER: `${process.env.REACT_APP_S3_URL}/images/comunik/onboard_recupere_banner.svg`,
  LOGO_SOLO: `${process.env.REACT_APP_S3_URL}/images/recupere-icone.svg`,
  BG_MODAL_ENGAGE_SMS: `${process.env.REACT_APP_S3_URL}/images/recupere-engage-sms.svg`,
  BG_MODAL_ENGAGE_ENRICH: `${process.env.REACT_APP_S3_URL}/images/recupere-engage-localize.svg`,
  BG_MODAL_ENGAGE_SPECIALIST: `${process.env.REACT_APP_S3_URL}/images/recupere-engage-specialist.svg`,
  ILLUSTRATION_SUCCESS_SMS:
    'https://portalv3-front-images-dev.s3.amazonaws.com/images/comunik/illustration_success_sms.svg',
  COPY_TO_CLIPBOARD_ICON: `${process.env.REACT_APP_S3_URL}/images/horizontal-components/copyClipboardIcon.svg`,
  FAKE_DOOR_NBA_THANKS: `${process.env.REACT_APP_S3_URL}/images/recupere-obrigado-fakedoor-nba.svg`,
  NBA_CARD_ICON: `${process.env.REACT_APP_S3_URL}/images/nba-card-icon.png`,
  NBA_VALUE_ICON: `${process.env.REACT_APP_S3_URL}/images/nba-value-icon.jpg`,
  NBA_JOB_ICON: `${process.env.REACT_APP_S3_URL}/images/nba-job-icon.jpg`,
  NBA_REGION_ICON: `${process.env.REACT_APP_S3_URL}/images/nba-region-icon.jpg`,
  NBA_SALARY_ICON: `${process.env.REACT_APP_S3_URL}/images/nba-salary-icon.jpg`,
  NBA_TIME_ICON: `${process.env.REACT_APP_S3_URL}/images/nba-time-icon.jpg`,
  NBA_AGE_ICON: `${process.env.REACT_APP_S3_URL}/images/nba-age-icon.jpg`,
  NBA_TRACK_CONFIG: `${process.env.REACT_APP_S3_URL}/images/nba-track-config.jpg`,
  NBA_PARTNERS_AMOUNT: `${process.env.REACT_APP_S3_URL}/images/nba-partners-amount-icon.jpg`,
  NBA_EMPLOYEES_AMOUNT: `${process.env.REACT_APP_S3_URL}/images/nba-employees-amount-icon.jpg`,
  NBA_CNPJ: `${process.env.REACT_APP_S3_URL}/images/nba-cnpj-icon.jpg`,
  NBA_DEBTS_AMOUNT: `${process.env.REACT_APP_S3_URL}/images/nba-debts-amount-icon.jpg`,
  NBA_INVOICING: `${process.env.REACT_APP_S3_URL}/images/nba-invoicing-icon.jpg`,
  NBA_DEBTS_AGE: `${process.env.REACT_APP_S3_URL}/images/nba-debts-age-icon.jpg`,
  NBA_COMPANY_AGE: `${process.env.REACT_APP_S3_URL}/images/nba-company-age-icon.jpg`,
  NBA_COMPANY_TYPE: `${process.env.REACT_APP_S3_URL}/images/nba-company-type-icon.jpg`,
  NBA_OTHER_CRITERIA: `${process.env.REACT_APP_S3_URL}/images/other-criteria.jpg`,
  CALL_LOG: `${process.env.REACT_APP_S3_URL}/images/call_log.svg`,
  PAUSE_CIRCLE: `${process.env.REACT_APP_S3_URL}/images/pause_circle.svg`,
  TRENDING_UP: `${process.env.REACT_APP_S3_URL}/images/trending_up.svg`,
  WEBPHONE_ICON: `${process.env.REACT_APP_S3_URL}/images/icon-webphone.svg`,
  CREATED_STRATEGY_ART: `${process.env.REACT_APP_S3_URL}/images/recupere-created-strategy-art.jpg `,
};

export default URL_IMAGES;
