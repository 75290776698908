import { browserHistory } from '@assertiva/assertiva-ui';
import qs from 'qs';
interface GetQueryParamsOptions {
  initialPage?: number;
  initialSize?: number;
  searchString?: string;
}

export const getQueryParams = (options?: GetQueryParamsOptions): any => {
  const searchString =
    typeof options?.searchString === 'string'
      ? options?.searchString
      : window.location.search;

  let queryParams = qs.parse(searchString, {
    ignoreQueryPrefix: true,
  });

  queryParams = {
    ...queryParams,
    page: queryParams?.page || options?.initialPage?.toString() || '0',
    size: queryParams?.size || options?.initialSize?.toString() || '10',
  };

  return queryParams;
};

interface SetQueryParamsOptions {
  forceReset?: boolean;
  getQueryParams?: typeof getQueryParams;
  setSearchString?: (searchString: string) => void;
}

export const setQueryParams = (
  queryParams: any,
  options?: SetQueryParamsOptions
) => {
  const currentQueryParams = options?.getQueryParams?.() || getQueryParams();
  const search = qs.stringify(
    options?.forceReset
      ? {
          ...queryParams,
          page: 0,
          size: currentQueryParams.size,
        }
      : {
          ...currentQueryParams,
          ...queryParams,
        }
  );

  if (options?.setSearchString) {
    options.setSearchString(search);
    return;
  }

  browserHistory.replace({
    pathname: window.location.pathname,
    search,
  });
};

export const getFilters = (getQueryParamsFn = getQueryParams) => {
  const filters = getQueryParamsFn();
  delete filters.page;
  delete filters.size;
  delete filters.sortBy;
  delete filters.sortDirection;
  return filters;
};

export const getFiltersKeys = (filters = getFilters()) => Object.keys(filters);

export const getHasFilters = (filtersKeys = getFiltersKeys()) =>
  filtersKeys.length > 0;

interface EraseFilterOptions {
  getQueryParams?: typeof getQueryParams;
  setQueryParams?: typeof setQueryParams;
}

export const eraseFilter = (
  filterKey: string,
  options?: EraseFilterOptions
) => {
  const queryParams = options?.getQueryParams?.() || getQueryParams();

  if (!queryParams[filterKey]) {
    throw new Error(
      `Erro ao remover filtro ${filterKey}. Verifique se essa chave existe na URL (search).`
    );
  }

  delete queryParams[filterKey];

  const setQueryParamsFn = options?.setQueryParams || setQueryParams;

  setQueryParamsFn(queryParams, { forceReset: true });
};

interface EraseAllFiltersOptions {
  setQueryParams?: typeof setQueryParams;
}

export const eraseAllFilters = (
  filtersToPreserve = {},
  options?: EraseAllFiltersOptions
) => {
  const setQueryParamsFn = options?.setQueryParams || setQueryParams;

  setQueryParamsFn(filtersToPreserve, { forceReset: true });
};
