import { useState } from 'react';
import { useSMSService, SMSService } from '@assertiva/business-ui';
import { AULoadingManager } from '@assertiva/assertiva-ui';
import { findLinksInMessage } from './linkHandlers';

interface Params {
  message: string;
  setMessage: (message: string) => void;
}

export function useLinkValidator({ message, setMessage }: Params) {
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
  const [links, setLinks] = useState<any>([]);
  const smsService = useSMSService();

  const handleCloseDialog = () => setIsLinkDialogOpen(false);

  const fetchLinks = async () => {
    try {
      const result = await smsService.getLinks();
      const links = result.data.rows;
      setLinks(links);
      return links;
    } catch (err) {}
  };

  const validateIfThereAreInvalidLinks = async () => {
    const linksInMessage = findLinksInMessage(message).map(
      (link) => link.split(' ')[0]
    );

    if (linksInMessage.length > 0) {
      AULoadingManager.show();
      const linksList = links.length > 0 ? links : await fetchLinks();
      const shouldFilter = await Promise.all(
        linksInMessage.map(async (link) => {
          if (linksList.find((l) => l.url === link)) {
            return false;
          }

          let isARealLink = false;

          try {
            let linkWithHttp = link;

            if (link.includes('http:')) {
              linkWithHttp = link.replace('http', 'https');
            }

            if (!linkWithHttp.includes('https:')) {
              linkWithHttp = `https://${link}`;
            }

            const res = await SMSService.testLink(linkWithHttp);
            if (res) {
              isARealLink = true;
            }
          } catch (err) {
            isARealLink = false;
          }

          return isARealLink;
        })
      );
      const invalidLinks = linksInMessage.filter(
        (_, index) => shouldFilter[index]
      );
      if (invalidLinks.length > 0) {
        AULoadingManager.close();
        setIsLinkDialogOpen(true);
        let messageWithoutInvalidLinks = message;
        invalidLinks.forEach((link) => {
          messageWithoutInvalidLinks = message.replace(link, '');
        });
        setMessage(messageWithoutInvalidLinks);
        return false;
      }

      AULoadingManager.close();
    }

    return true;
  };

  return {
    isLinkDialogOpen,
    handleCloseDialog,
    validateIfThereAreInvalidLinks,
  };
}
