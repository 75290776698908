import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const PanelBox = ({ children }) => (
  <Paper elevation={3}>
    <Box p={3}>{children}</Box>
  </Paper>
);

export default PanelBox;
