import { AUNotifier } from '@assertiva/assertiva-ui';
import {
  AddVariablePayload,
  GetMaxLengthInputMessagePayload,
  GetMessageWithoutVariablePayload,
  GetRemainingCharsPayload,
  GetTotalMessageVariablesLengthPayload,
  IsVariableInMessagePayload,
  MessageVariables,
  PutMessageVariableInRightPlacePayload,
  RemoveVariablePayload,
} from './types';
import {
  LINK_BILLET_KEY,
  VARIABLE_DONT_FIT,
  defaultMessageVariables,
} from './constants';

const REGEX_ALL_MESSAGE_VARIABLES = /[$]\[([^\]]+)\]/g;

/**
 * Adiciona uma variável `$[variableKey]` na mensagem chamando o `setMessage(messageWithVariable)`
 * @param AddVariablePayload
 * @returns `true` = adicionou com sucesso; `false` = erro
 */
export const handleAddVariable = ({
  message,
  setMessage,
  limitChars,
  messageVariables,
  variableKey,
  variableLength,
  cursorPosition,
}: AddVariablePayload): boolean => {
  const remainingChars = getRemainingChars({
    message,
    limitChars,
    messageVariables,
  });

  if (!handleVariableFitsInMessage(remainingChars, variableLength)) {
    return false;
  }

  const messageWithVariable = _putMessageVariableInRightPlace({
    message: _getMessageWithoutVariable({ message, variableKey }),
    cursorPosition,
    limitChars,
    messageVariables,
    variableKey,
    variableLength,
  });

  setMessage(messageWithVariable);
  return true;
};

/**
 * Verifica se a variável cabe na mensagem e mostra um erro `AUNotifier.error` caso não caiba
 * @param remainingChars number
 * @param variableLength number
 * @returns `true` = cabe; `false` = não cabe
 */
export const handleVariableFitsInMessage = (
  remainingChars: number,
  variableLength: number
) => {
  const variableFits = variableFitsInMessage(remainingChars, variableLength);

  if (!variableFits) {
    AUNotifier.error(VARIABLE_DONT_FIT);
  }

  return variableFits;
};

/**
 * Verifica se a variável cabe na mensagem
 * @param remainingChars number
 * @param variableLength number
 * @returns `true` = cabe; `false` = não cabe
 */
export const variableFitsInMessage = (
  remainingChars: number,
  variableLength: number
) => remainingChars - variableLength >= 0;

/**
 * Retorna quantos caracteres ainda faltam para atingir o limite
 * @param GetRemainingCharsPayload
 * @returns caracteres restantes
 */
export const getRemainingChars = ({
  message,
  limitChars,
  messageVariables,
}: GetRemainingCharsPayload): number => {
  const messageVariablesKeys = _findMessageVariablesKeys(message);

  const messageWithoutVariables = _getMessageWithoutVariables(message);

  if (messageVariablesKeys.length === 0) {
    return limitChars - messageWithoutVariables.length;
  }

  const totalVariablesLength = _getTotalMessageVariablesLength({
    messageVariablesKeys,
    messageVariables,
  });

  return limitChars - (totalVariablesLength + messageWithoutVariables.length);
};

export const getInfoMessage = (remainingChars: number) => {
  let infoMessage = `${remainingChars.toString()} caracteres restantes`;
  if (remainingChars === 0) infoMessage = 'Nenhum caracter restante';
  if (remainingChars === 1) infoMessage = '1 caracter restante';
  return infoMessage;
};

const _findMessageVariablesKeys = (message: string): string[] => {
  let key: string[] | null = null;
  const messageVariablesKeys: string[] = [];
  key = REGEX_ALL_MESSAGE_VARIABLES.exec(message);

  while (key !== null) {
    messageVariablesKeys.push(key[1]);
    key = REGEX_ALL_MESSAGE_VARIABLES.exec(message);
  }

  return messageVariablesKeys;
};

const _getMessageWithoutVariables = (message: string): string =>
  message.replace(REGEX_ALL_MESSAGE_VARIABLES, '');

const _getTotalMessageVariablesLength = ({
  messageVariablesKeys,
  messageVariables,
}: GetTotalMessageVariablesLengthPayload): number => {
  if (messageVariablesKeys.length === 0) {
    return 0;
  }

  const messageVariablesLength = messageVariablesKeys.map((key) =>
    _getMessageVariableLength(key, messageVariables)
  );

  const totalVariablesLength = messageVariablesLength.reduce(
    (acc: number, currentValue: number) => acc + currentValue,
    0
  );

  return totalVariablesLength;
};

const _getMessageVariableLength = (
  variableKey: string,
  messageVariables: MessageVariables
) => {
  const variableDefaultLength = _getVariableDefaultLength(variableKey);

  return messageVariables[variableKey]?.length || variableDefaultLength;
};

/**
 * Coloca uma variável na mensagem,
 * considera a posição do cursor `cursorPosition`,
 * considera que a variável cabe na mensagem
 * e coloca espaços antes e depois da variável caso
 * não ultrapasse o limite de caracteres
 *
 * @param PutMessageVariableInRightPlacePayload
 * @returns mensagem com variável
 */
const _putMessageVariableInRightPlace = ({
  message,
  limitChars,
  messageVariables,
  variableKey,
  variableLength,
  cursorPosition,
}: PutMessageVariableInRightPlacePayload): string => {
  const remainingChars = getRemainingChars({
    message,
    limitChars,
    messageVariables,
  });
  const variableWithMask = _getVariableWithMask(variableKey);

  if (message.length === 0) return `${variableWithMask} `;

  const stringBeforeCursor = message.slice(0, cursorPosition);
  const stringAfterCursor = message
    .slice(cursorPosition, message.length)
    .trimStart();

  const finalLength = limitChars - remainingChars + variableLength;

  let finalMessage = `${stringBeforeCursor} ${variableWithMask} ${stringAfterCursor}`;

  if (finalLength === limitChars) {
    finalMessage = `${stringBeforeCursor}${variableWithMask}${stringAfterCursor}`;
  }

  if (finalLength === limitChars - 1) {
    finalMessage = `${stringBeforeCursor} ${variableWithMask}${stringAfterCursor}`;
  }

  return finalMessage.trimStart();
};

/**
 * Valida se a variável `variableKey` existe na mensagem `message`
 * @param IsVariableInMessagePayload
 * @returns se variável está ou não na mensagem `boolean`
 */
export const isVariableInMessage = ({
  message,
  variableKey,
}: IsVariableInMessagePayload) =>
  _findMessageVariablesKeys(message).includes(variableKey);

/**
 * Chama a função de alterar mensagem `setMessage`
 * com a mensagem `message` sem a variável `variableKey`
 * @param RemoveVariablePayload
 */
export const handleRemoveVariable = ({
  message,
  variableKey,
  setMessage,
}: RemoveVariablePayload) => {
  const messageWithoutVariable = _getMessageWithoutVariable({
    message,
    variableKey,
  });
  setMessage(messageWithoutVariable);
};

const _getMessageWithoutVariable = ({
  message,
  variableKey,
}: GetMessageWithoutVariablePayload) => {
  let finalMessage = message;
  const variableWithMask = _getVariableWithMask(variableKey);

  const removeLastVariable = (pattern: string) =>
    _replaceLast(message, pattern, '');

  if (message.includes(` ${variableWithMask} `)) {
    finalMessage = removeLastVariable(` ${variableWithMask} `);
  } else if (message.includes(`${variableWithMask} `)) {
    finalMessage = removeLastVariable(`${variableWithMask} `);
  } else if (message.includes(` ${variableWithMask}`)) {
    finalMessage = removeLastVariable(` ${variableWithMask}`);
  } else {
    finalMessage = removeLastVariable(`${variableWithMask}`);
  }

  return finalMessage.trimStart();
};

const _getVariableWithMask = (variableKey: string) => `$[${variableKey}]`;

/**
 * Considera o tamanho real das variáveis `messageVariables`
 * e o limite de caracteres `limitChars`
 * para definir quantos caracteres o input deve deixar ser inserido
 * @param GetMaxLengthInputMessagePayload
 * @returns máximo de caracteres que o input deve deixar ser inserido
 */
export const getMaxLengthInputMessage = ({
  message,
  limitChars,
  messageVariables,
}: GetMaxLengthInputMessagePayload): number => {
  const messageVariablesKeys = _findMessageVariablesKeys(message);
  const totalVariablesLength = _getTotalMessageVariablesLength({
    messageVariables,
    messageVariablesKeys,
  });

  if (totalVariablesLength === 0) return limitChars;

  const messageVariablesMaskLength = messageVariablesKeys.map((key) =>
    _getVariableDefaultLength(key)
  );

  const totalVariablesMaskLength = messageVariablesMaskLength.reduce(
    (acc, currentValue) => acc + currentValue,
    0
  );

  return limitChars + totalVariablesMaskLength - totalVariablesLength;
};

const _getVariableDefaultLength = (variableKey: string): number =>
  _getVariableWithMask(variableKey).length;

/**
 * Remove a última variável `$[variableKey]` da mensagem
 * @param message
 * @returns `message` sem a última variável
 */
export const removeLastVariable = (message: string) => {
  const messageVariablesKeys = _findMessageVariablesKeys(message);
  if (messageVariablesKeys.length === 0) return message;
  const variableKey = messageVariablesKeys[messageVariablesKeys.length - 1];
  const variableWithMask = _getVariableWithMask(variableKey);
  return _replaceLast(message, variableWithMask, '');
};

const _replaceLast = (str: string, pattern: string, replacement: string) => {
  if (!pattern) return str;
  const last = str.lastIndexOf(pattern);
  return last !== -1
    ? `${str.slice(0, last)}${replacement}${str.slice(last + pattern.length)}`
    : str;
};

/**
 * Substitui todas as ocorrências da `variableKey` na mensagem `message`
 * pelo valor desejado `value`
 * @param message mensagem que possui a `variableKey`
 * @param variableKey qual variável deve ser alterada
 * @param value por qual valor a variável deve ser substituída
 * @returns `message` com `variableKey` substituída por `value`
 */
export const replaceVariableForValue = (
  message: string,
  variableKey: string,
  value: string
) => {
  const variableWithMask = _getVariableWithMask(variableKey);
  const messageWithValue = message.replace(variableWithMask, value);
  if (messageWithValue.includes(variableWithMask))
    return replaceVariableForValue(messageWithValue, variableKey, value);
  return messageWithValue;
};

export const getMessageWithBoldVariables = (message: string) => {
  let messageWithBoldVariables = message;
  const variablesKeys = _findMessageVariablesKeys(message).filter(
    (variableKey) => Object.keys(defaultMessageVariables).includes(variableKey)
  );

  variablesKeys.forEach((variableKey) => {
    const variableWithMask = _getVariableWithMask(variableKey);
    messageWithBoldVariables = messageWithBoldVariables.replace(
      variableWithMask,
      `<b>${variableWithMask}</b>`
    );
  });

  messageWithBoldVariables = messageWithBoldVariables.replace(
    `$[${LINK_BILLET_KEY}]`,
    `<b><u>Clique para abrir boleto</u></b>`
  );

  return messageWithBoldVariables;
};
