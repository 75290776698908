import { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _isMatch from 'lodash/isMatch';
import {
  AUPreventMissClick,
  AUInputWithRemainingChars,
  AURegex,
  ValidationUtils,
  AUPhoneField,
  AUCheckboxWithTooltip,
} from '@assertiva/assertiva-ui';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddressFields from '@src/components/AddressFields';
import { Controller, useForm } from 'react-hook-form';
import messages from '@src/constants/messages';
import { StepTwoFormValues, TIdFileLogo } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextAddAccountStep,
  prevAddAccountStep,
  selectAddAccountData,
  updateAddAccountData,
} from '../../../financialSlice';
import { sendInternalAnalytics } from '@src/utils/internalAnalytics';
import { EVENTS } from '@src/constants/analytics';
import UploadLogo from '../../UploadLogo';

const { PreventMissClickDialog, usePreventMissClick } = AUPreventMissClick;

const StepTwo = () => {
  const dispatch = useDispatch();
  const addAccountData = useSelector(selectAddAccountData);
  const [idFileLogo, setIdFileLogo] = useState<TIdFileLogo>(null);
  const hookForm = useForm<StepTwoFormValues>();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = hookForm;

  const handlePrevStep = () => {
    dispatch(prevAddAccountStep());
  };

  const handleNextStep = (data: StepTwoFormValues) => {
    sendInternalAnalytics(EVENTS.PAYMENTS.CLICK_ADD_ACCOUNT('ETAPA 2'));
    dispatch(
      updateAddAccountData({
        ...data,
        idFileLogo,
      })
    );
    dispatch(nextAddAccountStep());
  };

  const formFields = watch();

  const hasNotTouchedForm = () => Object.values(formFields).every(_isEmpty);

  const canGoToPrevStep = () =>
    hasNotTouchedForm() || _isMatch(addAccountData, formFields);

  const {
    isPreventMissClickDialogOpen,
    preventMissClick,
    closeAnyway,
    keepOpen,
  } = usePreventMissClick(handlePrevStep, canGoToPrevStep);

  useEffect(() => {
    if (!_isEmpty(addAccountData)) {
      reset({
        phone: addAccountData.phone,
        email: addAccountData.email,
        zipCode: addAccountData.zipCode,
        street: addAccountData.street,
        number: addAccountData.number,
        complement: addAccountData.complement,
        neighborhood: addAccountData.neighborhood,
        city: addAccountData.city,
        state: addAccountData.state,
        default: addAccountData.default,
      });
      setIdFileLogo(addAccountData.idFileLogo || null);
    }
  }, [addAccountData, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(handleNextStep)} data-testid="form-step-2">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Box marginBottom={1}>
              <Typography variant="subtitle1">Dados de contato</Typography>
            </Box>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <AUPhoneField
                  {...rest}
                  inputRef={ref}
                  inputProps={{ 'data-testid': 'input-phone' }}
                  fullWidth
                  error={Boolean(errors?.phone)}
                  helperText={errors?.phone?.message}
                  label="Telefone*"
                  id="input-phone"
                />
              )}
              rules={{
                required: messages.ERRORS.REQUIRED_FIELD('Telefone'),
                validate: (phoneString) => {
                  if (phoneString && !ValidationUtils.isValidPhone(phoneString))
                    return messages.ERRORS.INVALID_FIELD('Telefone');
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              render={({ field: { ref, ...rest } }) => (
                <AUInputWithRemainingChars
                  {...rest}
                  inputRef={ref}
                  chars={watch('email')?.length || 0}
                  maxChars={255}
                  helperText={errors?.email?.message}
                  error={Boolean(errors?.email)}
                  label="E-mail*"
                  id="input-email"
                />
              )}
              rules={{
                required: messages.ERRORS.REQUIRED_FIELD('E-mail'),
                pattern: {
                  value: AURegex.email,
                  message: messages.ERRORS.INVALID_FIELD('E-mail'),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box marginBottom={-1}>
              <Typography variant="subtitle1">Dados de Localização</Typography>
            </Box>
          </Grid>
          <AddressFields
            hookForm={hookForm}
            hidePublicPlaceType
            maxCharsComplement={15}
            maxCharsStreet={50}
          />
          <Grid item xs={12}>
            <Controller
              name="default"
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <AUCheckboxWithTooltip
                  checked={value}
                  onChange={(_, checked) => onChange(checked)}
                  id="checkbox-default"
                  label="Definir como forma de recebimento padrão"
                  tooltipContent={
                    <Typography variant="body2">
                      Ao marcar esta opção, esta conta será definida como meio
                      de pagamento padrão para recebimento.
                    </Typography>
                  }
                  popoverProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadLogo idFileLogo={idFileLogo} setIdFileLogo={setIdFileLogo} />
          </Grid>
        </Grid>
        <Box height={32} />
        <Grid item container justify="flex-end" spacing={3}>
          <Grid item>
            <Button
              onClick={preventMissClick}
              color="primary"
              id="btn-cancelar"
              data-testid="btn-cancel"
            >
              VOLTAR
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              id="btn-proximo-step-2"
              data-testid="btn-proximo-step-2"
              disabled={hasNotTouchedForm()}
              type="submit"
            >
              PRÓXIMO
            </Button>
          </Grid>
        </Grid>
      </form>
      <PreventMissClickDialog
        open={isPreventMissClickDialogOpen}
        closeAnyway={closeAnyway}
        keepOpen={keepOpen}
        closeAnywayButtonText="VOLTAR MESMO ASSIM"
      />
    </>
  );
};

export default StepTwo;
