import StepperFooter from '@src/components/StepperFooter';
import FieldTitle from '@src/components/FieldTitle';
import {
  AUBoxSkeleton,
  AUInputWithRemainingChars,
  AUPreventMissClick,
} from '@assertiva/assertiva-ui';
import { useInitialConfigStep } from './useInitialConfigStep';
import {
  AutocompleteCheckbox,
  AutocompleteCheckboxItem,
  AutocompleteTable,
  ListTitle,
} from '@src/components/AutocompleteTable';

const { PreventMissClickDialog, usePreventMissClick } = AUPreventMissClick;

const InitialConfigStep = () => {
  const {
    handleClose,
    isValidToCloseWithoutWarn,
    handleSubmit,
    handleChangeStrategyConfig,
    strategyConfig,
    isSubmitDisabled,
    selectedWallets,
    handleChangeWalletStatus,
    walletsListWithActives,
    isFetchingWallets,
  } = useInitialConfigStep();

  const {
    isPreventMissClickDialogOpen,
    preventMissClick,
    closeAnyway,
    keepOpen,
  } = usePreventMissClick(handleClose, isValidToCloseWithoutWarn);

  return (
    <form onSubmit={handleSubmit}>
      <FieldTitle
        title="Defina um nome"
        subtitle="Adicione um nome para essa estratégia de cobrança."
      >
        <AUInputWithRemainingChars
          inputProps={{ 'data-testid': 'strategy-name-input' } as any}
          chars={strategyConfig?.name?.length || 0}
          placeholder="Digite o nome da estratégia de cobrança"
          maxChars={30}
          value={strategyConfig?.name ?? ''}
          label={''}
          onChange={(e) =>
            handleChangeStrategyConfig({
              name: e.target.value.replace(/^\s+/, ''),
            })
          }
        />
      </FieldTitle>

      <FieldTitle
        title="Defina a(s) carteira(s)"
        subtitle="Adicione as carteiras que entrarão nessa estratégia de cobrança."
        mt={1.5}
      >
        {isFetchingWallets ? (
          <AUBoxSkeleton width="100%" height={40} />
        ) : (
          <>
            <AutocompleteCheckbox
              noOptionsText="Nenhuma carteira encontrada"
              optionsList={walletsListWithActives}
              filterBy={['name']}
              getOptionLabel={(option) => option.name}
              placeholder="Pesquisar carteiras"
              renderOption={(item) => {
                return (
                  <AutocompleteCheckboxItem
                    item={item}
                    onChangeItemStatus={handleChangeWalletStatus}
                    strongNameKey="name"
                    testId={`autocomplete-option-${item.id}`}
                  />
                );
              }}
              onChange={(wallet) =>
                wallet && handleChangeWalletStatus(wallet.id, !wallet.active)
              }
            />
            <AutocompleteTable
              list={selectedWallets}
              onChangeItemStatus={handleChangeWalletStatus}
              cardBoxProps={{
                mt: 1,
                mb: 1,
              }}
            >
              {(item) => <ListTitle>{item.name}</ListTitle>}
            </AutocompleteTable>
          </>
        )}
      </FieldTitle>

      {selectedWallets.length ? (
        <FieldTitle
          title="Inteligência artificial aliada à cobrança"
          subtitle="A estratégia será criada com a Cobrança inteligente ativa, possibilitando o aumento da sua recuperação, priorizando os devedores com maior probabilidade de pagamento."
          mt={selectedWallets?.length > 5 ? 3 : 4}
          pb={1}
        />
      ) : null}

      <StepperFooter
        handleBack={preventMissClick}
        backBtnText="CANCELAR"
        nextBtnProps={{ disabled: isSubmitDisabled }}
      />

      <PreventMissClickDialog
        open={isPreventMissClickDialogOpen}
        closeAnyway={closeAnyway}
        keepOpen={keepOpen}
      />
    </form>
  );
};

export default InitialConfigStep;
