import { createContext, useContext, useMemo, useState } from 'react';

interface DefaultDrawerContextValue {
  shouldSlideLeft: boolean;
  setShouldSlideLeft: (shouldSlideLeft: boolean) => void;
}

const DefaultDrawerContext = createContext<
  DefaultDrawerContextValue | undefined
>(undefined);

const DefaultDrawerProvider = ({ children }) => {
  const [shouldSlideLeft, setShouldSlideLeft] = useState(false);

  const providerValue = useMemo(
    () => ({
      shouldSlideLeft,
      setShouldSlideLeft,
    }),
    [shouldSlideLeft, setShouldSlideLeft]
  );

  return (
    <DefaultDrawerContext.Provider value={providerValue}>
      {children}
    </DefaultDrawerContext.Provider>
  );
};

function useDefaultDrawer() {
  const context = useContext(DefaultDrawerContext);
  if (context === undefined) {
    throw new Error(
      'useDefaultDrawer must be used within a DefaultDrawerProvider'
    );
  }
  return context;
}

export { DefaultDrawerProvider, useDefaultDrawer };
