import { TypeDoc } from '@features/debtors/types';
import { Pagination } from '@src/constants/types';
import { BilletRegister } from '@src/features/billet/types';

export interface LastEvent {
  date: string;
  event: string;
}

export interface Debt {
  id: string;
  idDebtor: string;
  debtorDoc: string;
  debtorName: string;
  value: number;
  initialValue: number;
  feeRate: number;
  fineRate: number;
  honoraryRate: number;
  finalDate: string;
  totalFractions: number;
  numberFraction: number;
  idGenericDebt: string;
  idStatus: number;
  lastEvent: LastEvent;
  payedAt?: string;
  paidValue?: number | null;
  firstCell: string;
  firstPhone?: string;
  selected?: boolean;
}

export interface DebtDetails extends Debt {
  idAgreement?: boolean;
  description: string;
  totalValue: number;
  feeTotalValue: number;
  fineTotalValue: number;
  idContract: string;
  idReference?: string;
  status?: number;
  debtor: any;
  negativated: boolean;
  expirationTime: boolean;
  billet?: BilletRegister | null;
  isPaymentMethodBlocked?: boolean;
}

export interface IDebtsFilters {
  page: string;
  size: string;
  debtorName?: string;
  typeDoc?: TypeDoc;
  doc?: string;
  phone?: string;
  minValueDebt?: string;
  maxValueDebt?: string;
  minFinalDateDebt?: string | null;
  maxFinalDateDebt?: string | null;
  minFinalDateEvent?: string | null;
  maxFinalDateEvent?: string | null;
  sortBy?: string;
  contract?: string;
  reference?: string;
  onlyAgreement: boolean | null;
  onlyNegativated: boolean | null;
  hasBillet: 'true' | 'false' | '';
}

export interface Status {
  id: number;
  cardDescription: string;
  chipDescription: string;
  borderColor: string;
  backgroundColor: string;
}

export type TabIndexes = 0 | 1 | 2 | 3;

export interface Summary {
  amountDebts: number;
  idStatus: number;
  initialValueDebts?: number;
  valueDebts: number;
}

export interface DebtInfoToCall {
  idDebt?: string;
  idDebtor?: string;
  phoneNumber?: string;
}

export interface DebtDetailStatus {
  id: number;
  name: string;
  description: string;
  color: string;
}

export interface DebtsState {
  list: Debt[];
  idStatusDetail?: number;
  detailStatus: DebtDetailStatus[];
  pagination: Pagination;
  madeInitialFetchDebts: boolean;
  debtIdToDelete?: string;
  debtIdToSeeMore?: string;
  debtIdToAddEvent?: string;
  debtIdsToConfirm?: string[];
  debtIdsToCancel?: string[];
  debtIdsToConfirmWithBillet?: string[];
  debtInfoToCall?: DebtInfoToCall;
  debtIdToSendSMS?: string;
  debtIdToSendWhatsapp?: string;
  isFiltersOpen: boolean;
  isAllRegistersSelected: boolean;
  mapStatus: Record<string, Status>;
  mapSummary: Record<string, Summary>;
  madeInitialFetchSummary: boolean;
  initialTabIndexDetailsDrawer?: TabIndexes;
  refetchDebts: boolean;
  isGeneratingBilletBatch: boolean;
  hasActivePaymentMethod: boolean;
}

export enum DebtsSortBy {
  FinalDateAsc = 'finalDate-ASC',
  FinalDateDesc = 'finalDate-DESC',
  EventDateAsc = 'eventDate-ASC',
  EventDateDesc = 'eventDate-DESC',
  ValueAsc = 'value-ASC',
  ValueDesc = 'value-DESC',
  NbaPercentageAsc = 'nbaPercentage-ASC',
  NbaPercentageDesc = 'nbaPercentage-DESC',
}

export enum OverdueDebtsTimeFilter {
  BetweenThirtyDays = 1,
  MoreThanThirtyDays,
  MoreThanSixtyDays,
  MoreThanNinetyDays,
}
