import React, { useMemo } from 'react';
import { BUTooltip } from '@assertiva/business-ui';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';

import {
  UploadResume,
  UploadResumeBillet,
} from '@src/contexts/NotifyBatchUploadContext';

const StyledIcon = styled('div')({
  width: 33.33,
  height: 33.33,
  borderRadius: '50%',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const TotalIcon = () => (
  <StyledIcon style={{ background: '#3000AA', fontSize: '20px' }}>
    <FileIcon fontSize="inherit" />
  </StyledIcon>
);

const SentIcon = () => (
  <StyledIcon style={{ background: '#27AE60' }}>
    <CheckIcon fontSize="small" />
  </StyledIcon>
);

const AlertIcon = () => (
  <StyledIcon style={{ color: '#EB5757', fontSize: '40px' }}>
    <ErrorIcon style={{ width: '40px', height: '40px' }} />
  </StyledIcon>
);

const DownloadButton = styled(IconButton)(({ theme }) => ({
  background: `${theme.palette.primary.main} !important`,
  color: '#EDEDED',
  maxWidth: '40px',
  maxHeight: '40px',
  '&:disabled': {
    background: '#cfcfcf!important',
  },
}));

const GridIconContainer = styled(Grid)({
  position: 'relative',
  top: '-20px',
});

const generateCards = (isDelete: boolean, resume?: UploadResume) => [
  {
    title: isDelete ? 'Excluídas' : 'Importadas',
    total: resume ? resume.rowsSuccess : 0,
    icon: <SentIcon />,
  },
  {
    title: isDelete ? 'Não excluídas' : 'Não importadas',
    total: resume ? resume.rowsError : 0,
    icon: <AlertIcon />,
  },
  {
    title: 'Total',
    total: resume ? resume.rowsSuccess + resume.rowsError : 0,
    icon: <TotalIcon />,
  },
];

const generateCardsBillet = (resume?: UploadResumeBillet) => [
  {
    title: 'Gerados',
    total: resume ? resume.billetsSuccess : 0,
    icon: <SentIcon />,
  },
  {
    title: 'Não gerados',
    total: resume ? resume.billetsError : 0,
    icon: <AlertIcon />,
  },
  {
    title: 'Total',
    total: resume ? resume.billetsSuccess + resume.billetsError : 0,
    icon: <TotalIcon />,
  },
];

const StyledDialogContent = styled(DialogContent)({
  overflowY: 'visible',
});

const getResumesFlatList = (
  listUploadResume: UploadResume[],
  listDeleteResume: UploadResume[],
  listBilletResume: UploadResumeBillet[]
) => {
  return [listUploadResume, listDeleteResume, listBilletResume]
    .filter((list) => !_isEmpty(list))
    .flat();
};

const isBilletResume = (resume) => typeof resume?.billetsSuccess === 'number';

const getResumeType = (isDeleteResume: boolean, resume) => {
  if (isDeleteResume) return 'exclusão';
  if (isBilletResume(resume)) return 'geração de boletos';
  return 'importação';
};
interface Props {
  open: boolean;
  onClose(): void;
  listUploadResume: UploadResume[];
  listDeleteResume: UploadResume[];
  listBilletResume: UploadResumeBillet[];
}

const ResumeUploadDialog = ({
  open,
  onClose,
  listUploadResume,
  listDeleteResume,
  listBilletResume,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentFile, setCurrentFile] = React.useState(0);
  const openTooltip = Boolean(anchorEl);

  const isDeleteResume = !_isEmpty(listDeleteResume);

  const resumes = getResumesFlatList(
    listUploadResume,
    listDeleteResume,
    listBilletResume
  ) as any;

  const resume = resumes[currentFile];

  const resumeType = getResumeType(isDeleteResume, resume);

  const cards = useMemo(() => {
    if (isBilletResume(resume))
      return generateCardsBillet(resume as UploadResumeBillet);
    return generateCards(isDeleteResume, resume as UploadResume);
  }, [resume, isDeleteResume]);

  const handleClose = () => {
    setIsOpen(false);
    // wait modal animation finish to call onClose that resets state
    setTimeout(onClose, 500);
  };

  const showLabelTypeDownload = (s3KeyErrors) => {
    if (isDeleteResume) return 'Dívidas_Não_Excluídas';

    if (s3KeyErrors?.includes('debts')) return 'Dívidas_Não_Importadas';

    return 'Pagamentos_Não_Importados';
  };

  React.useEffect(() => {
    if (open) {
      setIsOpen(true);
      setCurrentFile(0);
    }
  }, [open]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Resumo da {resumeType}</DialogTitle>
      <StyledDialogContent>
        <Grid container direction="column" spacing={2}>
          <Box height={16} />
          <Grid container item spacing={1}>
            {cards.map((card) => (
              <Grid item key={card.title}>
                <Paper
                  elevation={2}
                  id={`summarizer-blocklist-${card.title}`}
                  data-testid={`summarizer-blocklist-${card.title}`}
                >
                  <Box style={{ minWidth: '130px' }} padding={1}>
                    <GridIconContainer item>{card.icon}</GridIconContainer>
                    <Grid container direction="column" alignItems="flex-end">
                      <Grid item>
                        <Typography variant="h6" style={{ fontWeight: 500 }}>
                          {card.total?.toLocaleString() || card.total}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          {card.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
          {resume?.s3KeyErrors ? (
            <>
              <Box height={16} />
              <Grid container item justifyContent="space-between" wrap="nowrap">
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography variant="subtitle1">
                    {!_isEmpty(resume) &&
                      showLabelTypeDownload(resume?.s3KeyErrors)}
                  </Typography>
                  <Box
                    marginLeft={0.5}
                    display="flex"
                    alignItems="center"
                    aria-owns={openTooltip ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                    onMouseLeave={() => setAnchorEl(null)}
                    data-testid="icon-tooltip"
                  >
                    <HelpOutlineOutlinedIcon
                      fontSize="small"
                      style={{ fontWeight: 'bold' }}
                    />
                    <BUTooltip
                      text={`Faça o download do arquivo com as dívidas não ${
                        isDeleteResume ? 'excluídas' : 'importadas'
                      } e tenha acesso aos motivos da não ${
                        isDeleteResume ? 'exclusão' : 'importação'
                      }.`}
                      open={openTooltip}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <DownloadButton
                    disabled={!resume?.s3KeyErrors}
                    aria-label="download das dívidas não importadas"
                    onClick={() => window.open(resume.s3KeyErrors)}
                    data-testid={`btn-download-failed-debts`}
                  >
                    <GetAppIcon />
                  </DownloadButton>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </StyledDialogContent>
      <DialogActions>
        <Box paddingBottom={2} paddingTop={4} paddingRight={2}>
          {resumes.length <= 1 ? (
            <Button onClick={handleClose} color="primary" variant="contained">
              OK
            </Button>
          ) : (
            <Box display="flex" gridColumnGap={18}>
              {currentFile > 0 && (
                <Button
                  onClick={() => setCurrentFile((prev) => prev - 1)}
                  color="primary"
                >
                  VOLTAR
                </Button>
              )}
              {currentFile === resumes.length - 1 ? (
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                >
                  OK
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setCurrentFile((prev) => prev + 1);
                  }}
                  color="primary"
                  variant="contained"
                >
                  PRÓXIMO
                </Button>
              )}
            </Box>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ResumeUploadDialog;
