import { createRef, useState, useEffect } from 'react';
import {
  BUPopoverChip,
  BUContentPopoverLinks,
  useContentPopoverLinks,
  useWhatsAppForm,
} from '@assertiva/business-ui';
import { AUPreventMissClick } from '@assertiva/assertiva-ui';
import { Nullish } from '@src/constants/types';
import {
  handleVariableFitsInMessage,
  isVariableInMessage,
} from '../messageHandlers';
import { LINK_KEY, defaultLinkVariable } from '../constants';
import { MessageVariable } from '../types';
import { useCursorPosition } from '../MessageField/CursorPositionContext';

const { PreventMissClickDialog, usePreventMissClick } = AUPreventMissClick;

type AnchorEl = Nullish<Element>;

interface Props {
  message: string;
  handleRemoveVariable: (variableKey: string) => void;
  handleAddVariable: (
    variableKey: string,
    variableLength: number,
    cursorPosition: number
  ) => void;
  remainingChars: number;
  link: MessageVariable;
  setLink: (link: MessageVariable) => void;
}

const variableKey = LINK_KEY;

/**
 * Variável de links, possibilita adicionar links na mensagem.
 *
 * Utiliza os componentes do business-ui para reaproveitar comportamento do SMS.
 *
 * Precisa ser passado na prop `messageVariables` do componente `MessageField` para acessar o `CursorPositionContext`
 */
const LinkVariable = ({
  message,
  handleRemoveVariable,
  handleAddVariable,
  remainingChars,
  link,
  setLink,
}: Props) => {
  const cursorPosition = useCursorPosition();
  const chipRef = createRef<any>();
  const [anchorEl, setAnchorEl] = useState<AnchorEl>(null);

  const togglePopover = (type: string, evt?: MouseEvent) =>
    setAnchorEl(evt ? (evt.currentTarget as Element) : null);

  const isUsed = isVariableInMessage({ message, variableKey });

  const handleAddLinkInMessage = (linkRegister) => {
    const url = linkRegister?.url;
    const length = url?.length;
    handleAddVariable(variableKey, length, cursorPosition);
    setLink({ ...link, url, length });
  };

  const handleDelete = () => {
    handleRemoveVariable(variableKey);
  };

  const handleClosePop = () => {
    togglePopover(variableKey);
    if (isUsed) {
      handleRemoveVariable(variableKey);
    }
  };

  const responseLinksHook = useContentPopoverLinks({
    handleClosePop,
    handleAddLinkInMessage,
    variableFitInMessage: (variableLength: number) =>
      handleVariableFitsInMessage(remainingChars, variableLength),
    isOpen: Boolean(anchorEl),
    onlyWhatsApp: true,
  });

  const responseWhatsAppHook = useWhatsAppForm({
    handleChangePopoverLinks: responseLinksHook.handleChangeState,
    isOpen: Boolean(anchorEl),
  });

  const isValidToClosePopLinks = () =>
    responseLinksHook.isValidToClosePop() &&
    responseWhatsAppHook.isValidToClosePop();

  const preventMissClickLinks = usePreventMissClick(
    responseLinksHook.handleClose,
    isValidToClosePopLinks
  );

  useEffect(() => {
    if (isUsed && !link.url) {
      togglePopover(variableKey, { currentTarget: chipRef.current } as any);
    }
  }, [isUsed, link, chipRef]);

  useEffect(() => {
    if (!isUsed && link.url) {
      setLink(defaultLinkVariable);
    }
  }, [isUsed, link, chipRef, setLink]);

  useEffect(() => {
    if (isUsed && link.url) {
      togglePopover(variableKey);
    }
  }, [isUsed, link]);

  return (
    <>
      <BUPopoverChip
        keyName={variableKey}
        label="WhatsApp"
        isUsed={isUsed}
        anchorEl={anchorEl}
        togglePopover={togglePopover as any}
        handleClosePopover={preventMissClickLinks.preventMissClick}
        contentPopover={
          <BUContentPopoverLinks
            responseLinksHook={responseLinksHook}
            responseWhatsAppHook={responseWhatsAppHook}
            preventMissClickLinks={preventMissClickLinks}
          />
        }
        chipId="link-chip"
        isSystemVariable
        onDelete={handleDelete}
        ref={chipRef}
      />

      <PreventMissClickDialog
        open={preventMissClickLinks.isPreventMissClickDialogOpen}
        closeAnyway={preventMissClickLinks.closeAnyway}
        keepOpen={preventMissClickLinks.keepOpen}
      />
    </>
  );
};

export default LinkVariable;
