import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core/styles';

type Props = {
  color?: 'disabled' | 'primary';
  size?: number;
};

export const StrategyIcon = ({ color, size = 24 }: Props) => {
  const theme = useTheme();

  let svgColor = theme.palette.grey[400];

  if (color === 'primary') {
    svgColor = theme.palette.primary.main;
  }

  if (color === undefined) {
    svgColor = 'inherit';
  }

  return (
    <SvgIcon viewBox="0 0 17 18" style={{ color: svgColor, fontSize: size }}>
      <path
        d="M14.9219 6.12876C13.9317 6.12876 13.3786 7.11212 13.6039 7.84281L11.1797 10.2739C10.9748 10.2124 10.6744 10.2124 10.4695 10.2739L8.72812 8.53253C8.9603 7.80184 8.40716 6.81165 7.41015 6.81165C6.41996 6.81165 5.85999 7.79501 6.09217 8.53253L2.97821 11.6397C2.24752 11.4143 1.26416 11.9674 1.26416 12.9576C1.26416 13.7088 1.87876 14.3234 2.62994 14.3234C3.62012 14.3234 4.17326 13.3401 3.94791 12.6094L7.05505 9.4954C7.25991 9.55686 7.56038 9.55686 7.76525 9.4954L9.50661 11.2368C9.27443 11.9674 9.82757 12.9576 10.8246 12.9576C11.8148 12.9576 12.3747 11.9743 12.1426 11.2368L14.5736 8.81251C15.3043 9.03786 16.2877 8.48472 16.2877 7.49454C16.2877 6.74336 15.6731 6.12876 14.9219 6.12876Z"
        fill="#00ADB3"
      />
      <path
        d="M10.8247 6.81165L11.4666 5.39807L12.8733 4.76299L11.4666 4.1279L10.8247 2.71432L10.1964 4.1279L8.776 4.76299L10.1964 5.39807L10.8247 6.81165Z"
        fill="#00ADB3"
      />
      <path
        d="M2.97138 8.17742L3.31282 6.81165L4.6786 6.4702L3.31282 6.12876L2.97138 4.76299L2.62994 6.12876L1.26416 6.4702L2.62994 6.81165L2.97138 8.17742Z"
        fill="#00ADB3"
      />
    </SvgIcon>
  );
};
