import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import URL_IMAGES from '@src/constants/images';
import { StyledCardText, StyledPaperCard } from './NBA.styles';
import NBAModal from './components/NBAModal';
import { useNBA } from './hooks/useNBA';

const NBA = () => {
  const {
    canPostNBA,
    cardText,
    disableNBAButton,
    isModalOpen,
    handleAnalyticsTracking,
    toggleIsModalOpen,
    tooltipTitle,
  } = useNBA();

  if (!canPostNBA) return null;

  return (
    <StyledPaperCard elevation={2}>
      <img
        src={URL_IMAGES.NBA_CARD_ICON}
        height={50}
        width={69}
        alt="nba"
        fetchPriority="high"
      />
      <Box flex={1}>
        <StyledCardText variant="body2">
          <span>Inteligência artificial aliada à cobrança:</span> {cardText}
        </StyledCardText>
      </Box>
      <Tooltip arrow title={tooltipTitle}>
        <span>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleAnalyticsTracking();
              toggleIsModalOpen();
            }}
            id="btn-start-now-nba"
            data-testid="btn-start-now-nba"
            disabled={disableNBAButton}
          >
            COMECE AGORA
          </Button>
        </span>
      </Tooltip>

      <NBAModal open={isModalOpen} handleClose={toggleIsModalOpen} />
    </StyledPaperCard>
  );
};

export default NBA;
