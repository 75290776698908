import WalletService from '@src/services/WalletService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStrategyDrawer } from '../../useStrategyDrawer';
import { useAppDispatch } from '@src/store/hooks';
import { nextStep } from '../../strategySlice';
import { AUNotifier, ErrorUtils } from '@assertiva/assertiva-ui';
import { WalletListItem } from '../../types';

export const useInitialConfigStep = () => {
  const [isFetchingWallets, setIsFetchingWallets] = useState(false);
  const [walletsList, setWalletsList] = useState<
    WalletListItem[] | undefined
  >();

  const {
    isOpen,
    strategyConfig,
    handleClose,
    isValidToCloseWithoutWarn,
    handleChangeStrategyConfig,
  } = useStrategyDrawer();

  const dispatch = useAppDispatch();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(nextStep());
  };

  const selectedIdWallets = strategyConfig?.idWallets;

  const selectedWallets = useMemo(
    () =>
      walletsList?.filter((wallet) => selectedIdWallets?.includes(wallet.id)) ||
      [],
    [walletsList, selectedIdWallets]
  );

  const walletsListWithActives = useMemo(
    () =>
      walletsList?.map((wallet) => ({
        ...wallet,
        active: selectedIdWallets?.includes(wallet.id),
      })) || [],
    [walletsList, selectedIdWallets]
  );

  const isSubmitDisabled = !strategyConfig?.name || !selectedIdWallets?.length;

  const handleFetchWallets = useCallback(async () => {
    try {
      setIsFetchingWallets(true);
      const res = await WalletService.getWalletsActive();
      const wallets = res.data.body;
      if (selectedIdWallets?.length) {
        setWalletsList(
          wallets.map((wallet) => ({
            ...wallet,
            active: selectedIdWallets?.includes(wallet.id),
          }))
        );
      } else {
        setWalletsList(wallets);
      }
    } catch (err) {
      console.error(err);
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      setIsFetchingWallets(false);
    }
  }, [selectedIdWallets]);

  const handleChangeWalletStatus = (id: number, active: boolean) => {
    const oldIdWallets = strategyConfig?.idWallets ?? [];
    handleChangeStrategyConfig({
      idWallets: active
        ? [...oldIdWallets, id]
        : oldIdWallets.filter((idWallet) => idWallet !== id),
    });
  };

  useEffect(() => {
    if (isOpen && !walletsList) {
      handleFetchWallets();
    }
  }, [isOpen, handleFetchWallets, walletsList]);

  return {
    handleFetchWallets,
    isFetchingWallets,
    handleClose,
    isValidToCloseWithoutWarn,
    handleChangeStrategyConfig,
    handleSubmit,
    isSubmitDisabled,
    strategyConfig,
    walletsListWithActives,
    selectedWallets,
    handleChangeWalletStatus,
  };
};
