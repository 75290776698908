import { createContext, useContext } from 'react';

const CursorPositionContext = createContext<number | undefined>(undefined);

const CursorPositionProvider = (props) => (
  <CursorPositionContext.Provider {...props} />
);

function useCursorPosition() {
  const context = useContext(CursorPositionContext);
  if (context === undefined) {
    throw new Error(
      'useCursorPosition must be used within a CursorPositionProvider'
    );
  }
  return context;
}

export { CursorPositionProvider, useCursorPosition };
