import { createContext, useContext } from 'react';

const LinearLoadingContext = createContext(false);

export const LinearLoadingProvider = (props) => (
  <LinearLoadingContext.Provider {...props} />
);

export function useLinearLoadingContext() {
  const context = useContext(LinearLoadingContext);
  if (context === undefined) {
    throw new Error(
      'useLinearLoadingContext must be used within a LinearLoadingProvider'
    );
  }
  return context;
}
