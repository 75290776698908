import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import _isEmpty from 'lodash/isEmpty';
import {
  AURegex,
  AUInputWithRemainingChars,
  AUZipCodeField,
  AUEmptyData,
  ValidationUtils,
} from '@assertiva/assertiva-ui';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import messages from '@src/constants/messages';
import { useAddressFields } from './useAddressFields';
import { City } from '@src/constants/types';
import { FORM_CONFIG } from './constants';
import { requiredLabel } from '@src/utils/functionUtils';

const REGEX_NOT_ALPHANUMERIC = /[^A-Z0-9a-Ź]+/g;

interface Props {
  hookForm: any;
  hidePublicPlaceType?: boolean;
  avoidFetchAddress?: boolean;
  maxCharsComplement?: number;
  maxCharsStreet?: number;
}

let lastZipCode = '';

const AddressFields = ({
  hookForm,
  hidePublicPlaceType,
  avoidFetchAddress,
  maxCharsComplement = 80,
  maxCharsStreet = 128,
}: Props) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = hookForm;

  const { statesList, citiesList, loaders, getCitiesList, fetchAddress } =
    useAddressFields(setValue);

  const state = watch(FORM_CONFIG.state.name);

  useEffect(() => {
    if (state) {
      getCitiesList(state);
    }
  }, [state, getCitiesList, setValue]);

  useEffect(() => {
    lastZipCode = '';
  }, []);

  return (
    <>
      <Grid item xs={12} sm={5}>
        <Controller
          name={FORM_CONFIG.zipCode.name}
          control={control}
          rules={{
            required: messages.ERRORS.REQUIRED_FIELD(FORM_CONFIG.zipCode.label),
            pattern: {
              value: AURegex.cep,
              message: messages.ERRORS.INVALID_FIELD(FORM_CONFIG.zipCode.label),
            },
          }}
          render={({ field: { ref, onChange, ...rest } }) => (
            <AUZipCodeField
              {...rest}
              onChange={(e) => {
                if (
                  ValidationUtils.isValidZipCode(e.target.value) &&
                  !avoidFetchAddress
                ) {
                  if (lastZipCode !== e.target.value) {
                    lastZipCode = e.target.value;
                    fetchAddress(e.target.value);
                  }
                }

                onChange(e);
              }}
              inputRef={ref}
              label={requiredLabel(FORM_CONFIG.zipCode.label)}
              fullWidth
              helperText={errors[FORM_CONFIG.zipCode.name]?.message}
              error={Boolean(errors[FORM_CONFIG.zipCode.name])}
              id="input-cep"
            />
          )}
        />
      </Grid>
      {!hidePublicPlaceType ? (
        <Grid item xs={12} sm={4}>
          <Controller
            name={FORM_CONFIG.publicPlaceType.name}
            control={control}
            rules={{
              required: messages.ERRORS.REQUIRED_FIELD(
                FORM_CONFIG.publicPlaceType.label
              ),
            }}
            render={({ field: { ref, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  inputRef={ref}
                  select
                  data-testid="input-publicPlaceType"
                  label={requiredLabel(FORM_CONFIG.publicPlaceType.label)}
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors[FORM_CONFIG.publicPlaceType.name])}
                  helperText={errors[FORM_CONFIG.publicPlaceType.name]?.message}
                >
                  <MenuItem value="" disabled>
                    {FORM_CONFIG.publicPlaceType.label}*
                  </MenuItem>
                  {[
                    {
                      description: 'Rua',
                      id: 0,
                    },
                    {
                      description: 'Avenida',
                      id: 1,
                    },
                  ].map((item) => (
                    <MenuItem key={item.id} value={item.description}>
                      {item.description}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Controller
          name={FORM_CONFIG.street.name}
          control={control}
          defaultValue=""
          rules={{
            required: messages.ERRORS.REQUIRED_FIELD(FORM_CONFIG.street.label),
            validate: (v) =>
              Boolean(v.replace(/\s/g, '')) ||
              messages.ERRORS.INVALID_FIELD(FORM_CONFIG.street.label),
            minLength: {
              value: 3,
              message: messages.ERRORS.INVALID_FIELD(FORM_CONFIG.street.label),
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <AUInputWithRemainingChars
              {...rest}
              inputRef={ref}
              label={requiredLabel(FORM_CONFIG.street.label)}
              chars={watch(FORM_CONFIG.street.name)?.length || 0}
              maxChars={maxCharsStreet}
              helperText={errors[FORM_CONFIG.street.name]?.message}
              error={Boolean(errors[FORM_CONFIG.street.name])}
              id="input-logradouro"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          name={FORM_CONFIG.number.name}
          control={control}
          rules={{
            required: messages.ERRORS.REQUIRED_FIELD(FORM_CONFIG.number.label),
          }}
          render={({ field: { ref, onChange, ...rest } }) => (
            <AUInputWithRemainingChars
              {...rest}
              onChange={(e) =>
                onChange(e.target.value.replace(REGEX_NOT_ALPHANUMERIC, ''))
              }
              inputRef={ref}
              maxChars={10}
              chars={watch(FORM_CONFIG.number.name)?.length || 0}
              label={requiredLabel(FORM_CONFIG.number.label)}
              fullWidth
              helperText={errors[FORM_CONFIG.number.name]?.message}
              error={Boolean(errors[FORM_CONFIG.number.name])}
              id="numero"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Controller
          name={FORM_CONFIG.complement.name}
          control={control}
          defaultValue=""
          render={({ field: { ref, ...rest } }) => (
            <AUInputWithRemainingChars
              {...rest}
              inputRef={ref}
              label={FORM_CONFIG.complement.label}
              chars={watch(FORM_CONFIG.complement.name)?.length || 0}
              maxChars={maxCharsComplement}
              id="input-complemento"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Controller
          name={FORM_CONFIG.neighborhood.name}
          control={control}
          defaultValue=""
          rules={{
            required: messages.ERRORS.REQUIRED_FIELD(
              FORM_CONFIG.neighborhood.label
            ),
            validate: (v) =>
              Boolean(v.replace(/\s/g, '')) ||
              messages.ERRORS.INVALID_FIELD(FORM_CONFIG.neighborhood.label),
            minLength: {
              value: 3,
              message: messages.ERRORS.INVALID_FIELD(
                FORM_CONFIG.neighborhood.label
              ),
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <AUInputWithRemainingChars
              {...rest}
              inputRef={ref}
              label={requiredLabel(FORM_CONFIG.neighborhood.label)}
              chars={watch(FORM_CONFIG.neighborhood.name)?.length || 0}
              maxChars={64}
              helperText={errors[FORM_CONFIG.neighborhood.name]?.message}
              error={Boolean(errors[FORM_CONFIG.neighborhood.name])}
              id="input-bairro"
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <Controller
          name={FORM_CONFIG.state.name}
          control={control}
          rules={{
            required: messages.ERRORS.REQUIRED_FIELD(FORM_CONFIG.state.label),
          }}
          defaultValue=""
          render={({ field: { ref, onChange, ...rest } }) => (
            <TextField
              {...rest}
              onChange={(e) => {
                onChange(e);
                setValue(FORM_CONFIG.city.name, undefined);
              }}
              inputRef={ref}
              select
              label={requiredLabel(FORM_CONFIG.state.label)}
              variant="outlined"
              fullWidth
              helperText={errors[FORM_CONFIG.state.name]?.message}
              error={Boolean(errors[FORM_CONFIG.state.name])}
              SelectProps={{
                MenuProps: {
                  style: {
                    maxHeight: 400,
                  },
                },
              }}
            >
              <MenuItem value="" disabled>
                UF*
              </MenuItem>
              {statesList.map((item) => (
                <MenuItem key={item.sigla} value={item.sigla}>
                  {item.sigla}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Controller
          name={FORM_CONFIG.city.name}
          control={control}
          defaultValue={{ nome: '' }}
          rules={{
            required: messages.ERRORS.REQUIRED_FIELD(FORM_CONFIG.city.label),
          }}
          render={({ field: { ref, onChange, value, ...rest } }) => (
            <Autocomplete
              {...rest}
              key={state}
              value={value}
              disabled={_isEmpty(citiesList) || loaders.cities}
              options={citiesList}
              disablePortal
              filterOptions={(options: City[], { inputValue }) =>
                options.filter((option) =>
                  option.nome.toLowerCase().includes(inputValue?.toLowerCase())
                )
              }
              getOptionLabel={(option) => option.nome}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={ref}
                  label={requiredLabel(FORM_CONFIG.city.label)}
                  fullWidth
                  helperText={errors[FORM_CONFIG.city.name]?.message}
                  error={Boolean(errors[FORM_CONFIG.city.name])}
                />
              )}
              noOptionsText={
                <AUEmptyData
                  subtitle="Nenhuma cidade encontrada."
                  spacing={1}
                  boxProps={{ pt: 2, pb: 3 }}
                />
              }
              ListboxProps={{
                style: {
                  maxHeight: 180,
                },
              }}
              onChange={(_, option) => onChange(option || undefined)}
              id="autocomplete-cidade"
              autoSelect
              autoHighlight
            />
          )}
        />
      </Grid>
    </>
  );
};

export default AddressFields;
