import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { StyledTypographyWrapper } from '@src/assets/styles/Typography.styles';
import { StyledCardText } from './MessageCard.styles';

interface Props {
  text: string | JSX.Element;
  label?: string | JSX.Element;
  message?: string;
  handleClick: () => void;
  disabled?: boolean;
  buttonId?: string;
  messageDataId?: string;
}

const MessageCard = ({
  text,
  label,
  buttonId,
  messageDataId,
  handleClick,
  disabled,
  message,
}: Props) => (
  <Box marginBottom={2}>
    {label && <p>{label}</p>}
    <Paper>
      <Box
        padding={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <StyledCardText>
          <StyledTypographyWrapper
            size={14}
            weight={400}
            color="#5A5A65"
            title={message || undefined}
            data-testid={messageDataId}
          >
            {message || text}
          </StyledTypographyWrapper>
        </StyledCardText>

        <Box minWidth={195}>
          <Button
            variant="outlined"
            color="primary"
            id={buttonId}
            data-testid="btn-add-edit-message"
            onClick={handleClick}
            disabled={disabled}
            fullWidth
          >
            {message ? 'EDITAR MENSAGEM' : 'ADICIONAR MENSAGEM'}
          </Button>
        </Box>
      </Box>
    </Paper>
  </Box>
);

export default MessageCard;
