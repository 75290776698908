import { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {
  AUNotifier,
  AUPreventMissClick,
  sendAnalytics,
} from '@assertiva/assertiva-ui';
import {
  BUWriteMessageSMS,
  DrawerSMSDispatch,
  BURouteSelectSMS,
  useDrawerSMS,
  SMSActions,
  SMSWriteMessageUtils,
  SMSTypes,
} from '@assertiva/business-ui';
import Title from '@src/features/trackConfig/components/Title';

import DefaultDrawer from '@components/DefaultDrawer';
import { ISMSConfig, TYPE_MESSAGE_ENUM } from '@src/features/trackConfig/types';
import { TYPE_MESSAGE_LABEL } from '@src/features/trackConfig/constants';
import { Nullish } from '@src/constants/types';
const { PreventMissClickDialog, usePreventMissClick } = AUPreventMissClick;

enum RouteTypes {
  Short = 1,
  Letter = 2,
}
interface Props {
  open: boolean;
  handleClose: () => void;
  typeMessage?: TYPE_MESSAGE_ENUM;
  smsConfig?: Nullish<ISMSConfig>;
  showBilletVariable: boolean;
  handleInsertConfig: (
    typeMessage: TYPE_MESSAGE_ENUM,
    smsConfig: ISMSConfig
  ) => void;
}

const WriteSmsDrawer = ({
  open,
  handleClose,
  typeMessage,
  smsConfig,
  showBilletVariable,
  handleInsertConfig,
}: Props) => {
  const { dispatch, state: stateDrawerSMS, getFinalMessage } = useDrawerSMS();

  const validateBillet = (message: string) => {
    if (
      showBilletVariable &&
      !SMSWriteMessageUtils.messageIncludesVariable(
        message,
        SMSTypes.DEBT_CODE_BILLET
      )
    ) {
      AUNotifier.error(
        `É obrigatório selecionar a variável "${SMSTypes.DEBT_CODE_BILLET_LABEL}" para esse modelo de mensagem`
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (smsConfig) {
      if (smsConfig.message) {
        dispatch(
          SMSActions.setMessage(
            smsConfig.messageWithoutParse || smsConfig.message
          )
        );
      }
      if (smsConfig.idRoute) {
        dispatch(SMSActions.setRouteType(smsConfig.idRoute));
      }
    }
  }, [smsConfig, dispatch]);

  const handleSubmit = () => {
    const message = getFinalMessage();

    if (!validateBillet(message)) return;

    const newSMSConfig: ISMSConfig = {
      message,
      idRoute: stateDrawerSMS.routeType,
      // TODO: Exemplo caso precise persistir variáveis da mensagem:
      // selectedLink: stateDrawerSMS.selectedLink || undefined,
      // selectedSender: stateDrawerSMS.selectedSender || undefined,
      // messageWithoutParse: stateDrawerSMS.message,
    };
    handleInsertConfig(typeMessage as TYPE_MESSAGE_ENUM, newSMSConfig);
    stateDrawerSMS.routeType === RouteTypes.Short
      ? sendAnalytics({
          page: 'configurar-cobranca-drawer-configuracao-de-sms',
          event: 'curto',
        })
      : sendAnalytics({
          page: 'configurar-cobranca-drawer-configuracao-de-sms',
          event: 'carta',
        });
    handleClose();
  };

  const isValidToCloseWithoutWarn = () => {
    const message = smsConfig?.messageWithoutParse || smsConfig?.message;
    if (
      smsConfig &&
      message === stateDrawerSMS.message &&
      smsConfig.idRoute === stateDrawerSMS.routeType
    ) {
      return true;
    }

    if (!smsConfig && stateDrawerSMS.message === '') {
      return true;
    }

    return false;
  };

  const {
    isPreventMissClickDialogOpen,
    preventMissClick,
    closeAnyway,
    keepOpen,
  } = usePreventMissClick(handleClose, isValidToCloseWithoutWarn);

  return (
    <DrawerSMSDispatch.Provider value={dispatch}>
      <DefaultDrawer
        open={open}
        onClose={preventMissClick}
        title={`Mensagem ${typeMessage ? TYPE_MESSAGE_LABEL[typeMessage] : ''}`}
      >
        <Box>
          <Title
            title="Escolher rota"
            description="Escolha uma rota para a mensagem"
          />
          <Box marginY={2} width={230}>
            <BURouteSelectSMS routeType={stateDrawerSMS.routeType} />
          </Box>
        </Box>
        <BUWriteMessageSMS
          selectedWallet={null}
          isDrawerSMSOpen
          routeType={stateDrawerSMS.routeType}
          executedBlurMessage={stateDrawerSMS.executedBlurMessage}
          phones={stateDrawerSMS.phones}
          hideStepNumber
          defaultMessage={
            smsConfig?.messageWithoutParse || smsConfig?.message || undefined
          }
          selectedSender={smsConfig?.selectedSender}
          selectedLink={smsConfig?.selectedLink}
          onlyWhatsApp
          removePaddingAlertInvalidChars
          recupereModels
          recupereOnlyVariables
          hideRouteSelect
          showRecupereCodeBillet={showBilletVariable}
        />

        <Box
          display="flex"
          justifyContent="flex-end"
          gridGap={24}
          marginTop={6}
        >
          <Button
            variant="text"
            color="primary"
            id="btn-cancelar"
            onClick={preventMissClick}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="btn-inserir"
            data-testid="btn-inserir"
            disabled={
              isValidToCloseWithoutWarn() || stateDrawerSMS.message === ''
            }
            onClick={handleSubmit}
          >
            INSERIR
          </Button>
        </Box>
      </DefaultDrawer>
      <PreventMissClickDialog
        open={isPreventMissClickDialogOpen}
        closeAnyway={closeAnyway}
        keepOpen={keepOpen}
      />
    </DrawerSMSDispatch.Provider>
  );
};
export default WriteSmsDrawer;
