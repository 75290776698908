import { MessageVariable, MessageVariables, IEmailLogo } from './types';

export const LINK_KEY = 'Link';
export const LINK_BILLET_KEY = 'LinkBoleto';
export const CODE_BILLE_KEY = 'CodigoBoleto';

export const defaultMessageVariables: MessageVariables = {
  Nome: {
    label: 'Nome',
    length: 12,
  },
  ValorInicial: {
    label: 'Valor Inicial',
    length: 12,
  },
  ValorAtual: {
    label: 'Valor Atual',
    length: 12,
  },
  Vencimento: {
    label: 'Vencimento',
    length: 10,
  },
  Observacoes: {
    label: 'Observações',
    length: 600,
  },
  [LINK_BILLET_KEY]: {
    label: 'Link do Boleto',
    length: 24,
  },
  [CODE_BILLE_KEY]: {
    label: 'Código do Boleto',
    length: 48,
  },
};

export const defaultLinkVariable: MessageVariable = {
  label: 'WhatsApp',
  length: 0,
  url: '',
};

export const limitChars = 3000;

export const defaultLogo: IEmailLogo = {
  id: null,
  idFileS3: null,
  name: null,
  type: null,
  fileSize: null,
};

export const VARIABLE_DONT_FIT = 'A variável escolhida não cabe na mensagem.';

export const EMAIL_PLACEHOLDERS = {
  SUBJECT: 'Escreva o assunto aqui',
  MESSAGE: 'Escreva sua mensagem aqui',
};

export const LOGO_TYPES_ACCEPT = '.jpg,.jpeg,.png';
export const MAX_LOGO_SIZE_IN_BYTES = 10000000;
