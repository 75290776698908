import { AUNotifier, ErrorUtils, AuthUtils } from '@assertiva/assertiva-ui';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  closeLinearLoading,
  showLinearLoading,
} from '@src/hooks/useLinearLoading';
import { RootStateType } from '@src/store';
import ConfigService from '@src/services/ConfigService';
import BadCreditService from '@src/services/BadCreditService';
import _isEmpty from 'lodash/isEmpty';

type listOperationTypes = {
  loaded: boolean;
  list: Record<string, any>[];
};
interface BadCreditConfigState {
  enabledConfirm: boolean;
  isOpenDrawerSettings: boolean;
  companyInfo: {
    status: string;
    data: Record<string, any>;
  };
  listOperationTypes: listOperationTypes;
  urlBadCreditModelFile: {
    status: string;
    url: string | undefined;
  };
}

const initialState: BadCreditConfigState = {
  enabledConfirm: false,
  isOpenDrawerSettings: false,
  companyInfo: {
    status: 'idle',
    data: {
      cnpj: '',
      name: '',
      socialName: '',
      operationType: 0,
      operationTypeDescription: '',
      tel1: '',
      tel2: '',
      email: '',
      site: '',
    },
  },
  listOperationTypes: {
    list: [],
    loaded: false,
  },
  urlBadCreditModelFile: {
    status: 'idle',
    url: '',
  },
};

export const fetchBadCreditModel = createAsyncThunk(
  'badCreditConfig/fetchBadCreditModel',
  async () => {
    const res = await ConfigService.getBadCreditDocCreditor();
    try {
      const {
        data: {
          body: { url },
        },
      } = res;
      return url;
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    }
  }
);

export const fetchListOperationType = createAsyncThunk(
  'badCreditConfig/fetchListOperationType',
  async () => {
    try {
      const response = await BadCreditService.getOperationTypeNegative();
      return response.data.body;
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    }
  }
);

export const fetchDataCompany = createAsyncThunk(
  'badCreditConfig/fetchDataCompany',
  async () => {
    showLinearLoading();
    try {
      const response = await ConfigService.getSettingsNegative();
      if (_isEmpty(response.data.body)) {
        return {
          ...response.data.body,
          cnpj: AuthUtils.getUser().company.document,
          name: AuthUtils.getUser().company.name,
        };
      } else {
        return response.data.body;
      }
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      closeLinearLoading();
    }
  }
);

export const badCreditConfigSlice = createSlice({
  name: 'badCreditConfig',
  initialState,
  reducers: {
    setInitialState: () => initialState,
    handleOpenDrawerCreditBad(state, action: PayloadAction<boolean>) {
      state.isOpenDrawerSettings = action.payload;
    },
    handleCompanyInfo(state, action: PayloadAction<Record<string, any>>) {
      state.companyInfo['data'] = action.payload;
    },
    handleListOperationType(state, action: any) {
      state.listOperationTypes = action.payload;
    },
    handleBadCreditDownloadModel(state, action: PayloadAction<string>) {
      state.urlBadCreditModelFile.url = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataCompany.pending, (state, action) => {
      state.companyInfo.status = 'loading';
    });
    builder.addCase(fetchDataCompany.fulfilled, (state, action) => {
      state.companyInfo.status = 'succeeded';
      state.companyInfo.data = { ...action.payload };
    });
    builder.addCase(fetchDataCompany.rejected, (state, action) => {
      state.companyInfo.status = 'failed';
    });
    builder.addCase(fetchListOperationType.fulfilled, (state, action) => {
      if (action.payload)
        state.listOperationTypes = { list: action.payload, loaded: true };
    });
    builder.addCase(fetchBadCreditModel.pending, (state, action) => {
      state.urlBadCreditModelFile.status = 'loading';
    });
    builder.addCase(fetchBadCreditModel.fulfilled, (state, action) => {
      state.urlBadCreditModelFile.status = 'succeeded';
      state.urlBadCreditModelFile.url = action.payload as any;
    });
  },
});

export const {
  handleOpenDrawerCreditBad,
  handleCompanyInfo,
  handleBadCreditDownloadModel,
} = badCreditConfigSlice.actions;

export const selectIsOpenDrawer = (state: RootStateType) =>
  state.badCreditConfig.isOpenDrawerSettings;
export const selectCompanyInfo = (state: RootStateType) =>
  state.badCreditConfig.companyInfo;
export const selectOperationType = (state: RootStateType) =>
  state.badCreditConfig.listOperationTypes;
export const selectUrlDownloadModel = (state: RootStateType) =>
  state.badCreditConfig.urlBadCreditModelFile;
export default badCreditConfigSlice.reducer;
