import _orderBy from 'lodash/orderBy';
import { AUCookiesUtils } from '@assertiva/assertiva-ui';
import { getOnlyPropertiesWithValue } from '@src/utils/functionUtils';
import { defaultWallet } from './constants';
import { NBAGroup, NBAStatus, WalletSelect } from './type';

export const WALLET_NAME_COOKIE = 'ASTVWALLET';

export const saveSelectedWallet = (wallet: WalletSelect) => {
  AUCookiesUtils.saveItem(WALLET_NAME_COOKIE, wallet);
};

export const getSelectedWallet = () => {
  const walletString = AUCookiesUtils.getItem(WALLET_NAME_COOKIE);
  if (walletString) {
    const wallet: WalletSelect = JSON.parse(walletString);
    return wallet;
  }
};

export const mountInitialWallet = () => {
  const selectWallet = getSelectedWallet();
  return selectWallet ?? defaultWallet;
};

export const isValidNbaGroup = (idGroupNba: number, nbaGroups?: NBAGroup[]) =>
  nbaGroups?.some((g) => Number(g.id) === idGroupNba);

export const isFetchingNBA = (nbaStatus?: NBAStatus | null) =>
  ![null, NBAStatus.Success, NBAStatus.Error].includes(nbaStatus as any);

export const orderedNBAGroups = (groups?: NBAGroup[]) => {
  if (groups) {
    return _orderBy(groups, ['order'], ['asc']);
  }
};

export const getOnlyValidFilters = (filters): Record<string, any> =>
  getOnlyPropertiesWithValue({
    page: filters?.page,
    size: filters?.size,
  });
