const REGEX_ALL_URLS_AND_EMAILS =
  /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*|(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gi;

export const findLinksInMessage = (message: string): string[] => {
  let match: string[] | null;
  const linksInMessage: string[] = [];
  do {
    match = REGEX_ALL_URLS_AND_EMAILS.exec(message);
    if (match) {
      linksInMessage.push(match[0]);
    }
  } while (match);

  return linksInMessage;
};
