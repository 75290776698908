import React, { InputHTMLAttributes } from 'react';

import {
  FileUploadContainer,
  FileField,
  FilePreviewContainer,
  PreviewList,
  StyledAUEmptyDataWrapper,
  StyledIconButton,
} from './FileUpload.styles';

import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import Button from '@material-ui/core/Button';

import URL_IMAGES from '@constants/images';
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from '@components/FileUpload/constants';

import { useFileUpload } from '@components/FileUpload/useFileUpload';

import AuxiliaryFilesAddDebt from '@components/FileUpload/Views/AuxiliaryFilesAddDebt';

import LinearProgress from '@material-ui/core/LinearProgress';

export enum TYPE_SCREEN {
  ADD,
  DETAIL,
}

export enum ACTION_UPLOAD {
  ADDED = 'added',
  REMOVED = 'removed',
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  btnUploadBatch?: boolean;
  items?: any;
  enableDelete?: boolean;
  typeScreen?: TYPE_SCREEN;
  modeImage?: boolean;
  readonly?: boolean;
  itemsProgress?: any;
  onUpdateFiles?: (
    files?: Array<File>,
    action?: ACTION_UPLOAD,
    currentFiles?: any
  ) => void;
  maxFileSizeInBytes?: number;
  shouldRenderPreview?: boolean;
  shouldHideUploadContainer?: boolean;
  fileUploadContainerProps?: any;
  filePreviewContainerProps?: any;
  hideCursorPointer?: boolean;
  emptyDataSubtitleStyles?: any;
  considerOnlyShouldShowPreview?: boolean;
}

const FileUpload = ({
  label = 'Clique aqui e faça upload de arquivos auxiliares de até 10mb.',
  btnUploadBatch = false,
  enableDelete = false,
  onUpdateFiles,
  items,
  itemsProgress,
  typeScreen = TYPE_SCREEN.ADD,
  readonly,
  modeImage = false,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  shouldRenderPreview,
  shouldHideUploadContainer,
  fileUploadContainerProps = {},
  filePreviewContainerProps = {},
  hideCursorPointer,
  emptyDataSubtitleStyles = {},
  considerOnlyShouldShowPreview,
  ...otherProps
}: Props) => {
  const {
    removeFile,
    getObjectURL,
    fileInputField,
    handleNewFileUpload,
    handleUploadBtnClick,
    renderPreview,
  } = useFileUpload({
    maxFileSizeInBytes,
    onUpdateFiles,
    items,
    otherProps,
  });

  const actionsButtons = (fileName) =>
    typeScreen === TYPE_SCREEN.ADD && {
      download: getObjectURL(fileName).name,
      href: getObjectURL(fileName).objectURL,
    };

  const memoizedItemsProgress = React.useMemo(() => {
    return itemsProgress;
  }, [itemsProgress]);

  const shouldRenderPreviewValidation = considerOnlyShouldShowPreview
    ? shouldRenderPreview
    : !btnUploadBatch || shouldRenderPreview;

  return (
    <>
      <FilePreviewContainer {...filePreviewContainerProps}>
        <PreviewList>
          {shouldRenderPreviewValidation &&
            renderPreview(({ file, fileName, isImageFile, index }) => (
              <React.Fragment key={index}>
                <AuxiliaryFilesAddDebt
                  file={file}
                  isImageFile={isImageFile}
                  isLoading={
                    !memoizedItemsProgress[index]?.progress ||
                    memoizedItemsProgress[index]?.progress < 100
                  }
                  hideCursorPointer={hideCursorPointer}
                  progress={
                    <>
                      {memoizedItemsProgress[index]?.progress < 100 && (
                        <LinearProgress
                          variant="determinate"
                          value={memoizedItemsProgress[index]?.progress}
                        />
                      )}
                      {!memoizedItemsProgress[index]?.progress && (
                        <LinearProgress />
                      )}
                    </>
                  }
                  key={fileName}
                  modeImage={modeImage}
                  index={index}
                  {...actionsButtons(fileName)}
                >
                  {!readonly ? (
                    <StyledIconButton
                      color="primary"
                      mode={modeImage}
                      disabled={!enableDelete}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        removeFile(fileName);
                      }}
                    >
                      {modeImage ? <ClearIcon /> : <DeleteOutlinedIcon />}
                    </StyledIconButton>
                  ) : (
                    <></>
                  )}
                </AuxiliaryFilesAddDebt>
              </React.Fragment>
            ))}
        </PreviewList>
      </FilePreviewContainer>
      {!readonly && !shouldHideUploadContainer && (
        <FileUploadContainer {...fileUploadContainerProps}>
          <StyledAUEmptyDataWrapper
            image={URL_IMAGES.UPLOAD_ICON}
            subtitle={label}
            emptyDataSubtitleStyles={emptyDataSubtitleStyles}
          />

          <FileField
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            title=""
            value=""
            {...otherProps}
          />
          {btnUploadBatch && (
            <>
              <Box height={9} />
              <Button
                variant="outlined"
                color="primary"
                onClick={handleUploadBtnClick}
                id="btn-upload-batch"
              >
                IMPORTAR
              </Button>
            </>
          )}
        </FileUploadContainer>
      )}
    </>
  );
};

export default React.memo(FileUpload);
