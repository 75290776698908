import { getSender } from '../utils';
import {
  StyledEmailBody,
  StyledEmailContainer,
  StyledEmailHeader,
  StyledLogoHeader,
} from './PreviewEmail.styles';

interface Props {
  logoHeader?: JSX.Element | null;
  logoBody?: JSX.Element;
}

const EmailTemplate: React.FC<Props> = ({ logoHeader, logoBody, children }) => (
  <StyledEmailContainer>
    <StyledEmailHeader>
      <StyledLogoHeader>{logoHeader}</StyledLogoHeader>

      <b
        style={{
          marginBottom: logoHeader ? '4px' : 0,
        }}
      >
        {getSender()}
      </b>
    </StyledEmailHeader>
    <StyledEmailBody>
      {children}
      {logoBody}
    </StyledEmailBody>
  </StyledEmailContainer>
);

export default EmailTemplate;
