import { styled } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
});

export const StyledTab = styled(Tab)({
  textTransform: 'uppercase',
  fontSize: '14px',
});
