import FileUpload, { TYPE_SCREEN } from '@src/components/FileUpload/FileUpload';
import { Nullish } from '@src/constants/types';
import { IEmailLogo } from '../types';
import { useLogo } from './useLogo';
import { LOGO_TYPES_ACCEPT, MAX_LOGO_SIZE_IN_BYTES } from '../constants';

interface Props {
  logo: Nullish<IEmailLogo>;
  setLogo: (logo: Nullish<IEmailLogo>) => void;
}

const Logo = ({ logo, setLogo }: Props) => {
  const {
    filesNotLoaded,
    uploadFiles,
    onGenerateS3PreSignedURL,
    isShowingPreviewFiles,
  } = useLogo({ setLogo });

  if (logo?.name) {
    return (
      <FileUpload
        items={{
          Logo: {
            type: '',
            name: logo?.name,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            handleClick: () => {},
            hideTooltip: true,
          },
        }}
        itemsProgress={[{ progress: 100 }]}
        typeScreen={TYPE_SCREEN.DETAIL}
        enableDelete
        hideCursorPointer
        shouldHideUploadContainer
        onUpdateFiles={onGenerateS3PreSignedURL}
        fileUploadContainerProps={{
          style: { padding: '16px 8px', marginBottom: 0, marginTop: '16px' },
        }}
        filePreviewContainerProps={{
          style: { marginBottom: 0, marginTop: !filesNotLoaded ? 12 : 0 },
        }}
      />
    );
  }

  return (
    <FileUpload
      accept={LOGO_TYPES_ACCEPT}
      enableDelete={filesNotLoaded}
      itemsProgress={uploadFiles}
      maxFileSizeInBytes={MAX_LOGO_SIZE_IN_BYTES}
      onUpdateFiles={onGenerateS3PreSignedURL}
      label="Clique aqui e faça upload do seu logo de até 10mb em formato jpeg, jpg ou png que será inserido ao fim da mensagem de e-mail"
      shouldRenderPreview={isShowingPreviewFiles}
      shouldHideUploadContainer={isShowingPreviewFiles}
      fileUploadContainerProps={{
        style: { padding: '16px 8px', marginBottom: 0, marginTop: '16px' },
      }}
      filePreviewContainerProps={{
        style: { marginBottom: 0, marginTop: !filesNotLoaded ? 12 : 0 },
      }}
      emptyDataSubtitleStyles={{
        width: '500px',
        fontSize: '12px',
      }}
      considerOnlyShouldShowPreview
    />
  );
};

export default Logo;
