import { styled, Theme } from '@material-ui/core/styles';

type StyledTypographyWrapperProps = {
  size?: number;
  weight?:
    | 'normal'
    | 'bold'
    | 100
    | 200
    | 300
    | 400
    | 500
    | 600
    | 700
    | 800
    | 900;
  letterSpacing?: number;
  lineHeight?: number;
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'inherit';
  color?: string;
  style?: any;
};

export const StyledTypographyWrapper = styled(
  ({ size, weight, letterSpacing, lineHeight, textAlign, color, ...rest }) => (
    <span {...rest} />
  )
)(
  ({
    size = 16,
    weight = 'normal',
    letterSpacing = 0.16,
    lineHeight = 16,
    textAlign = 'left',
    style,
    color = '#000000CC',
  }: StyledTypographyWrapperProps) => {
    return {
      fontSize: `${size}px`,
      fontWeight: weight,
      letterSpacing: `${letterSpacing}px`,
      lineHeight: `${lineHeight}px`,
      textAlign: textAlign,
      color: color,
      ...style,
    };
  }
);

interface StyledTextEllipsedProps {
  maxWidth?: number | string;
  theme: Theme;
}

export const StyledTextEllipsed = styled(({ maxWidth, ...rest }) => (
  <StyledTypographyWrapper {...rest} />
))(({ maxWidth = 300 }: StyledTextEllipsedProps) => ({
  maxWidth,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'block',
}));
