import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';

import {
  AULoadingManager,
  AUNotifier,
  ErrorUtils,
} from '@assertiva/assertiva-ui';

import { eraseAllFilters, setQueryParams } from '@src/components/filters';
import { SORT_BY } from '@src/features/debt/list/constants';
import { DebtsSortBy } from '@src/features/debt/list/types';
import WalletService from '@src/services/WalletService';
import { RootState } from '@src/store/rootReducer';
import { resetMadeInitialFetchDebtsPage } from '../debt/list/debtsSlice';
import {
  NBAGroupType,
  NBAStatus,
  WalletList,
  WalletSelect,
  WalletState,
} from './type';
import { isFetchingNBA, mountInitialWallet, orderedNBAGroups } from './utils';

export const initialState: WalletState = {
  table: {
    list: [],
    madeInitialFetch: false,
    pagination: { page: 0, rowsPerPage: 10, count: 0 },
  },
  drawer: {
    isOpen: false,
    hasPaymentMethods: false,
    idPaymentMethod: undefined,
    madeInitialFetchPayments: false,
  },
  walletIdToDisable: null,
  globalWallet: {
    value: mountInitialWallet(),
    shouldUpdateDependencies: false,
  },
  dashboard: {
    walletList: [],
    NBAGroupTypes: [],
  },
  summary: undefined,
  nbaStatus: undefined,
  isGlobalWalletInStrategy: undefined,
};

export const fetchDashboardWalletList = createAsyncThunk(
  'wallet/fetchDashboardWalletList',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;

      if (state.wallet.dashboard.walletList.length) {
        return state.wallet.dashboard.walletList;
      }

      const response = await WalletService.getWalletsDashboard();
      return response.data.body;
    } catch (err) {}
  }
);

export const refetchGlobalWalletSummary = createAsyncThunk(
  'wallet/refetchGlobalWalletSummary',
  async () => {
    try {
      const response = await WalletService.getWalletSummary();

      return response.data.body;
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
      throw new Error();
    }
  }
);

export const initialFetchGlobalWalletSummary = createAsyncThunk(
  'wallet/initialFetchGlobalWalletSummary',
  async (_, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;

      if (!_isEmpty(state.debts.mapSummary)) {
        dispatch(resetMadeInitialFetchDebtsPage());
      }

      if (!_isUndefined(state.wallet.nbaStatus)) {
        dispatch(updateState({ nbaStatus: undefined }));
      }

      if (state.wallet.summary) {
        dispatch(updateState({ summary: undefined }));
      }

      const response = await WalletService.getWalletSummary();

      const { body } = response.data;

      if (!body.nba) {
        dispatch(fetchWalletNBAStatus());
      }

      return body;
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
      throw new Error();
    }
  }
);

export const fetchWalletNBAStatus = createAsyncThunk(
  'wallet/fetchWalletNBAStatus',
  async (_, { dispatch }) => {
    try {
      const response = await WalletService.getNBAStatus();
      const { status } = response.data.body;

      if (status === NBAStatus.Success) {
        await dispatch(initialFetchGlobalWalletSummary());
        const { sortDirection, sortBy } =
          SORT_BY[DebtsSortBy.NbaPercentageDesc];
        setQueryParams({
          sortDirection,
          sortBy,
        });
      }

      if (isFetchingNBA(status)) {
        setTimeout(() => dispatch(fetchWalletNBAStatus()), 4000);
      }

      if (status === NBAStatus.Error) {
        await dispatch(refetchGlobalWalletSummary());
      }

      return status;
    } catch (err: any) {
      if (err?.response?.status !== 404)
        AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));

      throw new Error();
    }
  }
);

export const postWalletNBA = createAsyncThunk(
  'wallet/postWalletNBA',
  async (_, { dispatch }) => {
    try {
      AULoadingManager.show();
      await WalletService.postNBA();
      eraseAllFilters();
      const status = await dispatch(fetchWalletNBAStatus()).unwrap();
      if (status !== NBAStatus.Success) {
        dispatch(updateState({ summary: undefined }));
      }
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
      throw new Error();
    } finally {
      AULoadingManager.close();
    }
  }
);

export const undoWalletNBA = createAsyncThunk(
  'wallet/undoWalletNBA',
  async (_, { dispatch }) => {
    try {
      AULoadingManager.show();
      await WalletService.undoNBA();
      await dispatch(initialFetchGlobalWalletSummary());
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
      throw new Error();
    } finally {
      AULoadingManager.close();
    }
  }
);

export const fetchNBAGroupTypes = createAsyncThunk(
  'wallet/fetchNBAGroupTypes',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;

      if (state.wallet.dashboard.NBAGroupTypes.length) {
        return state.wallet.dashboard.NBAGroupTypes;
      }

      const response = await WalletService.getNBAGroupTypes();

      return response.data.body;
    } catch (err) {}
  }
);

export const fetchIsGlobalWalletInStrategy = createAsyncThunk(
  'wallet/fetchIsGlobalWalletInStrategy',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;

      const response = await WalletService.getIsWalletInStrategy(
        state.wallet.globalWallet.value.id
      );

      return response.data.body.success;
    } catch (err) {
      return false;
    }
  }
);

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    updateWalletList: (state, action: PayloadAction<Partial<WalletList>>) => {
      return {
        ...state,
        table: { ...state.table, ...action.payload },
      };
    },
    toggleWalletListItem: (state, action: PayloadAction<number>) => {
      const wallet = state.table.list.find(
        (wallet) => wallet.id === action.payload
      );
      if (wallet) wallet.active = !wallet.active;
    },
    updateSelectWallet: (state, action: PayloadAction<number | undefined>) => {
      if (action.payload) state.drawer.idSeletedWallet = action.payload;
      else state.drawer.idSeletedWallet = undefined;
    },
    toggleWalletDrawer: (state, action: PayloadAction<boolean>) => {
      state.drawer.isOpen = action.payload;
    },
    updateGlobalWallet: (state, action: PayloadAction<WalletSelect>) => {
      state.globalWallet.value = action.payload;
    },
    updateGlobalWalletStatus: (state, action: PayloadAction<boolean>) => {
      state.globalWallet.shouldUpdateDependencies = action.payload;
    },
    updateWalletIdToDisable: (state, action: PayloadAction<number | null>) => {
      state.walletIdToDisable = action.payload;
    },
    updateIdPaymentMethod: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.drawer.idPaymentMethod = action.payload;
    },
    updateHasPaymentMethods: (state, action: PayloadAction<boolean>) => {
      state.drawer.hasPaymentMethods = action.payload;
    },
    updateMadeInitialFetchPayments: (state, action: PayloadAction<boolean>) => {
      state.drawer.madeInitialFetchPayments = action.payload;
    },
    closeDrawer: (state) => {
      state.drawer.isOpen = false;
      state.drawer.idSeletedWallet = undefined;
      state.drawer.idPaymentMethod = undefined;
      state.drawer.hasPaymentMethods = false;
      state.drawer.madeInitialFetchPayments = false;
    },
    updateState: (state, action: PayloadAction<Partial<WalletState>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardWalletList.fulfilled, (state, action) => {
      state.dashboard.walletList = action.payload;
    });
    builder.addCase(fetchNBAGroupTypes.fulfilled, (state, action) => {
      state.dashboard.NBAGroupTypes = action.payload as NBAGroupType[];
    });
    builder.addCase(
      initialFetchGlobalWalletSummary.fulfilled,
      (state, action) => {
        state.summary = action.payload;

        if (action.payload?.nba) {
          state.nbaStatus = NBAStatus.Success;
        }
      }
    );
    builder.addCase(initialFetchGlobalWalletSummary.rejected, (state) => {
      state.nbaStatus = null;
      state.summary = null;
    });
    builder.addCase(fetchWalletNBAStatus.fulfilled, (state, action) => {
      state.nbaStatus = action.payload;
    });
    builder.addCase(fetchWalletNBAStatus.rejected, (state) => {
      state.nbaStatus = null;
    });
    builder.addCase(refetchGlobalWalletSummary.fulfilled, (state, action) => {
      state.summary = action.payload;
    });
    builder.addCase(
      fetchIsGlobalWalletInStrategy.fulfilled,
      (state, action) => {
        state.isGlobalWalletInStrategy = action.payload;
      }
    );
    builder.addCase(fetchIsGlobalWalletInStrategy.rejected, (state) => {
      state.isGlobalWalletInStrategy = false;
    });
  },
});

export const {
  updateWalletList,
  updateSelectWallet,
  toggleWalletListItem,
  toggleWalletDrawer,
  updateGlobalWallet,
  updateGlobalWalletStatus,
  updateWalletIdToDisable,
  updateIdPaymentMethod,
  updateHasPaymentMethods,
  updateMadeInitialFetchPayments,
  closeDrawer,
  updateState,
} = walletSlice.actions;

export const selectWalletTable = (state: RootState) => state.wallet.table;

export const selectWalletId = (state: RootState) =>
  state.wallet.drawer.idSeletedWallet;

export const wasMadeInitialFetch = (state: RootState) =>
  state.wallet.table.madeInitialFetch;

export const selectWalletList = (state: RootState) => state.wallet.table.list;

export const selectWalletPagination = (state: RootState) =>
  state.wallet.table.pagination;

export const isWalletDrawerOpen = (state: RootState) =>
  state.wallet.drawer.isOpen;

export const isWalleDrawerCreating = (state: RootState) =>
  !state.wallet.drawer.idSeletedWallet;

export const seletWalletSelected = (state: RootState) => {
  return state.wallet.table.list.find(
    (wallet) => wallet.id === state.wallet.drawer.idSeletedWallet
  );
};

export const selectGlobalWallet = (state: RootState) =>
  state.wallet.globalWallet.value;

export const selectGlobalWalletName = (state: RootState) =>
  state.wallet.globalWallet.value.name;

export const selectGlobalWalletId = (state: RootState) =>
  state.wallet.globalWallet.value.id;

export const selectGlobalWalletDeependenciesStatus = (state: RootState) =>
  state.wallet.globalWallet.shouldUpdateDependencies;

export const selectWalletIdToDisable = (state: RootState) =>
  state.wallet.walletIdToDisable;

export const selectIdPaymentMehtod = (state: RootState) =>
  state.wallet.drawer.idPaymentMethod;

export const selectHasPaymentMethods = (state: RootState) =>
  state.wallet.drawer.hasPaymentMethods;

export const selectMadeInitialFetchPayments = (state: RootState) =>
  state.wallet.drawer.madeInitialFetchPayments;

export const selectDashboardWalletList = (state: RootState) =>
  state.wallet.dashboard.walletList;

export const selectNBAStatus = (state: RootState) => state.wallet.nbaStatus;

export const selectCanPostNBA = (state: RootState) =>
  _isNull(state.wallet.nbaStatus) || state.wallet.nbaStatus === NBAStatus.Error;

export const selectIsFetchingNBA = (state: RootState) =>
  isFetchingNBA(state.wallet.nbaStatus);

export const selectHasNBA = (state: RootState) =>
  Boolean(state.wallet.summary?.nba);

export const selectWalletSummary = (state: RootState) => state.wallet.summary;

export const selectNBAGroups = (state: RootState) =>
  orderedNBAGroups(state.wallet.summary?.nba?.groups);

export const selectHasNBAGroups = (state: RootState) =>
  Boolean(state.wallet.summary?.nba?.groups?.length);

export const selectNBACreatedAt = (state: RootState) =>
  state.wallet.summary?.nba?.createdAt;

export const selectNBAGroupTypes = (state: RootState) =>
  state.wallet.dashboard.NBAGroupTypes;

export const selectIsGlobalWalletInStrategy = (state: RootState) =>
  state.wallet.isGlobalWalletInStrategy ||
  state.wallet.isGlobalWalletInStrategy === undefined;

export default walletSlice.reducer;
