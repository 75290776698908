import { StyledLogoCard } from './UploadLogo.styles';
import { UploadLogoParams, useUploadLogo } from './useUploadLogo';
import UploadLogoCard from './UploadLogoCard';
import ChangeLogoCard from './ChangeLogoCard';
import { FileField } from '@src/components/FileUpload/FileUpload.styles';

export const DEFAULT_HELPER_TEXT =
  'Faça upload do seu logo de até 10mb em formato jpeg, jpg ou png que aparecerá do boleto';

type UploadLogoProps = UploadLogoParams & {
  helperText?: JSX.Element | string;
};

const UploadLogo = ({
  helperText = DEFAULT_HELPER_TEXT,
  ...props
}: UploadLogoProps) => {
  const {
    imgSrc,
    loadedImg,
    fileInputRef,
    handleClickUpload,
    handleUpload,
    handleRemoveLogo,
  } = useUploadLogo(props);
  const showUploadLogoCard = !imgSrc && !props.idFileLogo;

  return (
    <StyledLogoCard>
      {showUploadLogoCard ? (
        <UploadLogoCard
          handleClickUpload={handleClickUpload}
          helperText={helperText}
        />
      ) : (
        <ChangeLogoCard
          handleChangeLogo={handleClickUpload}
          handleRemoveLogo={handleRemoveLogo}
          imgSrc={imgSrc}
          loadedImg={loadedImg}
          helperText={helperText}
        />
      )}

      <FileField
        type="file"
        ref={fileInputRef}
        onChange={handleUpload}
        accept=".jpg,.jpeg,.png"
        data-testid="file-input"
        title=""
        style={{
          display: showUploadLogoCard ? 'block' : 'none',
        }}
      />
    </StyledLogoCard>
  );
};

export default UploadLogo;
