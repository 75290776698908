import { GetResponse, SuccessResponse } from '@src/constants/types';
import {
  NBAGroupType,
  NBAStatus,
  WalletActive,
  WalletSummary,
} from '@src/features/wallet/type';
import BaseService from './BaseService';

class WalletService extends BaseService {
  getNBAGroupTypes() {
    return this.axiosInstance.get<GetResponse<NBAGroupType[]>>(
      `${this.BASE_URL}/nba/group-types`
    );
  }

  getWallets(params) {
    return this.axiosInstance.get(`${this.BASE_URL}/wallet`, { params });
  }

  putWalletStatus(id: number, active: boolean) {
    return this.axiosInstance.put(`${this.BASE_URL}/wallet/${id}/active`, {
      active,
    });
  }

  postWallet(body) {
    return this.axiosInstance.post(`${this.BASE_URL}/wallet`, body);
  }

  putWallet(id, body) {
    return this.axiosInstance.put(`${this.BASE_URL}/wallet/${id}`, body);
  }

  getWalletsAvailable() {
    return this.axiosInstance.get(`${this.BASE_URL}/wallet/available`, {});
  }

  getWalletsDashboard() {
    return this.axiosInstance.get(`${this.BASE_URL}/wallet/dashboard`);
  }

  getWalletsActive() {
    return this.axiosInstance.get<GetResponse<WalletActive[]>>(
      `${this.BASE_URL}/wallet/active`
    );
  }

  getWalletSummary() {
    return this.axiosInstance.get<GetResponse<WalletSummary>>(
      `${this.BASE_URL}/wallet/summary`
    );
  }

  /** Fazer uma análise NBA da carteira atual, presente nos headers */
  postNBA() {
    return this.axiosInstance.post(`${this.BASE_URL}/nba`);
  }

  /** Verificar o status da análise NBA da carteira atual, presente nos headers */
  getNBAStatus() {
    return this.axiosInstance.get<GetResponse<{ status: NBAStatus | null }>>(
      `${this.BASE_URL}/nba/status`
    );
  }

  undoNBA() {
    return this.axiosInstance.delete(`${this.BASE_URL}/nba`);
  }

  getIsWalletInStrategy(idWallet) {
    return this.axiosInstance.get<GetResponse<SuccessResponse>>(
      `${this.BASE_URL}/strategy/wallet/${idWallet}`
    );
  }
}

export default new WalletService();
