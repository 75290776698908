import { WebphoneCampaignStatus } from './types';

export const isFetchingWebphoneCampaign = (
  status?: WebphoneCampaignStatus | null
) =>
  ![
    null,
    WebphoneCampaignStatus.Success,
    WebphoneCampaignStatus.Error,
  ].includes(status as any);
