import { useStrategyDrawer } from '../../useStrategyDrawer';
import { useAppDispatch } from '@src/store/hooks';
import { nextStep, prevStep } from '../../strategySlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterOption } from '../../types';
import StrategyService from '@src/services/StrategyService';
import { AUNotifier, ErrorUtils, StringUtils } from '@assertiva/assertiva-ui';
import DebtsService from '@src/services/DebtsService';
import { Status } from '@src/features/debt/list/types';
import { EXPIRED_ID_STATUS } from '@src/features/debt/list/constants';

interface FeaturesStepState {
  isFetchingData: boolean;
  agreementFilterOptions: FilterOption[];
  negativeFilterOptions: FilterOption[];
  agingFilterOptions: FilterOption[];
  debtStatus: Status[];
  reloadMinField: boolean;
  reloadMaxField: boolean;
}

const initialState: FeaturesStepState = {
  isFetchingData: false,
  agreementFilterOptions: [],
  negativeFilterOptions: [],
  agingFilterOptions: [],
  debtStatus: [],
  reloadMinField: false,
  reloadMaxField: false,
};

export const useFeaturesStep = () => {
  const [state, setState] = useState(initialState);

  const handleState = useCallback(
    (changes: Partial<FeaturesStepState>) =>
      setState((state) => {
        return { ...state, ...changes };
      }),
    []
  );

  const { isOpen, strategyConfig, handleChangeStrategyConfig, drawerMode } =
    useStrategyDrawer();

  const dispatch = useAppDispatch();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!strategyConfig?.idAgreementOption) {
      handleChangeStrategyConfig({
        idAgreementOption: state.agreementFilterOptions?.[0]?.id,
      });
    }
    if (!strategyConfig?.idNegativeOption) {
      handleChangeStrategyConfig({
        idNegativeOption: state.negativeFilterOptions?.[0]?.id,
      });
    }
    dispatch(nextStep());
  };

  const handleBack = () => dispatch(prevStep());

  const selectedIdStatus = useMemo(
    () => strategyConfig?.idStatus || [],
    [strategyConfig?.idStatus]
  );
  const selectedIdAging = useMemo(
    () => strategyConfig?.idAgingOptions || [],
    [strategyConfig?.idAgingOptions]
  );

  const getStatusTooltip = (statusId: number): string | undefined => {
    const STATUS_TOOLTIP = {
      1: 'São as dívidas cadastradas que ainda não estão em processo de cobrança pois tem data antes do vencimento (cobrança preventiva)',
      2: 'Dívidas que estão entre 1 e 10 dias antes do vencimento (cobrança preventiva)',
      3: 'Nesse status ficam todas as dívidas que vencem no dia atual',
      10: 'Dívidas com pagamento confirmado ou que tiveram o acordo realizado e iniciou-se um novo processo de cobrança',
    };

    return STATUS_TOOLTIP[statusId];
  };

  const statusListWithActives = useMemo(
    () =>
      state.debtStatus.map((status) => ({
        ...status,
        active: selectedIdStatus?.includes(status.id),
        tooltip: getStatusTooltip(status.id),
      })),
    [state.debtStatus, selectedIdStatus]
  );

  const agingListWithActives = useMemo(
    () =>
      state.agingFilterOptions.map((aging) => ({
        ...aging,
        active: selectedIdAging?.includes(aging.id),
      })),
    [state.agingFilterOptions, selectedIdAging]
  );

  const minValue = strategyConfig?.minValue;
  const maxValue = strategyConfig?.maxValue;

  const isInvalidRange = useMemo(() => {
    const minValueNumber = StringUtils.formatCurrencyToFloatPtBr(`${minValue}`);
    const maxValueNumber = StringUtils.formatCurrencyToFloatPtBr(`${maxValue}`);

    return Boolean(
      minValueNumber && maxValueNumber && minValueNumber > maxValueNumber
    );
  }, [minValue, maxValue]);

  const isSubmitDisabled = useMemo(() => {
    return !selectedIdStatus?.length || isInvalidRange;
  }, [selectedIdStatus, isInvalidRange]);

  const fetchAllOptions = useCallback(async () => {
    try {
      handleState({ isFetchingData: true });
      const [
        resAgreementOptions,
        resNegativeOptions,
        resAgingOptions,
        resDebtStatus,
      ] = await Promise.all([
        StrategyService.getAgreementFilterOptions(),
        StrategyService.getNegativeFilterOptions(),
        StrategyService.getAgingFilterOptions(),
        DebtsService.getDebtStatus(),
      ]);

      handleState({
        agreementFilterOptions: resAgreementOptions.data.body,
        negativeFilterOptions: resNegativeOptions.data.body,
        agingFilterOptions: resAgingOptions.data.body,
        debtStatus: resDebtStatus.data.body,
      });
    } catch (err) {
      console.error(err);
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
    } finally {
      handleState({ isFetchingData: false });
    }
  }, [handleState]);

  useEffect(() => {
    if (isOpen && !state.agreementFilterOptions?.length) {
      fetchAllOptions();
    }
  }, [isOpen, state.agreementFilterOptions, fetchAllOptions]);

  const handleSelectStatus = (id: number, active: boolean) => {
    if (id === EXPIRED_ID_STATUS && !active) {
      handleChangeStrategyConfig({
        idAgingOptions: [],
      });
    }
    handleChangeStrategyConfig({
      idStatus: active
        ? [...selectedIdStatus, id]
        : selectedIdStatus.filter((idStatus) => idStatus !== id),
    });
  };

  const handleSelectAging = (id: number, active: boolean) => {
    handleChangeStrategyConfig({
      idAgingOptions: active
        ? [...selectedIdAging, id]
        : selectedIdAging.filter((idStatus) => idStatus !== id),
    });
  };

  return {
    handleChangeStrategyConfig,
    handleSubmit,
    isSubmitDisabled,
    strategyConfig,
    handleBack,
    drawerMode,
    isInvalidRange,
    state,
    handleState,
    statusListWithActives,
    handleSelectStatus,
    agingListWithActives,
    handleSelectAging,
  };
};
