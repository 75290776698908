import { styled } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

export const StyledAlert = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.primary.light,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledAlertLink = styled(Link)({
  cursor: 'pointer',
  textDecoration: 'underline',
});
