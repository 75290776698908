import { styled } from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';
import {
  AUPreventMissClick,
  AUCurrencyField,
  AURegex,
  AURegexField,
  AUHelperTextRemainingChars,
  StringUtils,
} from '@assertiva/assertiva-ui';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-hook-form';
import messages from '@src/constants/messages';
import { StepThreeFormValues } from '../../../types';
import FormField from './FormField';
import CurrencyHelperText from './CurrencyHelperText';
import FileUpload from '@src/components/FileUpload/FileUpload';
import { FILE_TYPES_ACCEPT, MAX_FILE_SIZE_IN_BYTES } from '../constants';
import { useStepThree } from './useStepThree';

const { PreventMissClickDialog, usePreventMissClick } = AUPreventMissClick;

const StyledCurrencyField = styled(AUCurrencyField)({
  '& input::placeholder': {
    textAlign: 'left',
  },
});

const StepThree = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<StepThreeFormValues>();
  const {
    handlePrevStep,
    handleSave,
    filesNotLoaded,
    uploadFiles,
    onGenerateS3PreSignedURL,
    isShowingPreviewFiles,
  } = useStepThree();

  const formFields = watch();

  const hasNotTouchedForm = () => Object.values(formFields).every(_isEmpty);

  const {
    isPreventMissClickDialogOpen,
    preventMissClick,
    closeAnyway,
    keepOpen,
  } = usePreventMissClick(handlePrevStep, hasNotTouchedForm);

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)} data-testid="form-step-3">
        <Grid container spacing={2}>
          <FormField
            {...{
              control,
              watch,
              errors,
              title:
                'Produtos e/ou serviços prestados que serão cobrados via boletos:',
              name: 'descriptionJob',
              placeholder: 'Digite os produtos e/ou serviços*',
              maxChars: 160,
              rules: {
                required: messages.ERRORS.REQUIRED_FIELD('Este campo'),
              },
            }}
          />

          <FormField
            {...{
              control,
              title: 'Faturamento mensal da empresa:',
              name: 'monthlyBilling',
              rules: {
                required: messages.ERRORS.REQUIRED_FIELD('Este campo'),
                validate: (value) =>
                  StringUtils.formatCurrencyToFloatPtBr(value) > 0 ||
                  messages.ERRORS.INVALID_FIELD('Valor'),
              },
              render: ({ field: { ref, ...rest } }) => (
                <StyledCurrencyField
                  {...rest}
                  inputRef={ref}
                  fullWidth
                  placeholder="Digite o valor do faturamento mensal da empresa*"
                  helperText={
                    <CurrencyHelperText
                      helperText={errors?.monthlyBilling?.message}
                      maxChars={10}
                      inputValue={watch('monthlyBilling')?.toString() || ''}
                    />
                  }
                  inputProps={{
                    maxLength: 15,
                  }}
                  error={Boolean(errors?.monthlyBilling)}
                  value={rest.value || undefined}
                  onChangeEvent={(target) =>
                    setValue('monthlyBilling', target.value)
                  }
                />
              ),
            }}
          />

          <FormField
            {...{
              control,
              watch,
              errors,
              title: 'Site ou rede social da empresa (se possuir):',
              name: 'site',
              placeholder: 'Digite o link aqui',
              maxChars: 255,
              rules: {
                pattern: {
                  value: AURegex.allUrls,
                  message: messages.ERRORS.INVALID_FIELD('Link'),
                },
              },
            }}
          />

          <FormField
            {...{
              control,
              title:
                'Quantidade aproximada de boletos que será emitida mensalmente:',
              name: 'numberMonthlyBills',
              rules: {
                required: messages.ERRORS.REQUIRED_FIELD('Este campo'),
                validate: (value) =>
                  Number(value) > 0 || messages.ERRORS.INVALID_FIELD('Valor'),
              },
              render: ({ field: { ref, ...rest } }) => (
                <AURegexField
                  {...(rest as any)}
                  regexNotAllowed={AURegex.notNumbers}
                  removesExcessSpace={true}
                  inputRef={ref}
                  fullWidth
                  placeholder="Digite a quantidade de boletos (somente números)*"
                  helperText={
                    <AUHelperTextRemainingChars
                      maxChars={10}
                      helperText={errors?.numberMonthlyBills?.message}
                      chars={
                        watch('numberMonthlyBills')?.toString()?.length || 0
                      }
                    />
                  }
                  error={Boolean(errors?.numberMonthlyBills)}
                  inputProps={{ maxLength: 10 }}
                />
              ),
            }}
          />

          <FormField
            {...{
              control,
              title: 'Ticket médio por emissão:',
              name: 'averageTicketPerBills',
              rules: {
                required: messages.ERRORS.REQUIRED_FIELD('Este campo'),
                validate: (value) =>
                  StringUtils.formatCurrencyToFloatPtBr(value) > 0 ||
                  messages.ERRORS.INVALID_FIELD('Valor'),
              },
              render: ({ field: { ref, ...rest } }) => (
                <StyledCurrencyField
                  {...rest}
                  inputRef={ref}
                  fullWidth
                  placeholder="Digite o ticket médio*"
                  helperText={
                    <CurrencyHelperText
                      helperText={errors?.averageTicketPerBills?.message}
                      maxChars={10}
                      inputValue={
                        watch('averageTicketPerBills')?.toString() || ''
                      }
                    />
                  }
                  inputProps={{
                    maxLength: 15,
                  }}
                  error={Boolean(errors?.averageTicketPerBills)}
                  value={rest.value || undefined}
                  onChangeEvent={(target) =>
                    setValue('averageTicketPerBills', target.value)
                  }
                />
              ),
            }}
          />

          <Grid item xs={12}>
            <Box marginBottom={isShowingPreviewFiles ? 1 : -2}>
              <Typography variant="subtitle1">
                Faça o upload do contrato social atualizado
              </Typography>
            </Box>
            <FileUpload
              btnUploadBatch
              accept={FILE_TYPES_ACCEPT}
              enableDelete={filesNotLoaded}
              itemsProgress={uploadFiles}
              maxFileSizeInBytes={MAX_FILE_SIZE_IN_BYTES}
              onUpdateFiles={onGenerateS3PreSignedURL}
              label="Arraste e solte um arquivo aqui ou clique para subir um novo arquivo de até 10mb"
              shouldRenderPreview={isShowingPreviewFiles}
              shouldHideUploadContainer={isShowingPreviewFiles}
            />
          </Grid>
        </Grid>
        <Box height={32} />
        <Grid item container justify="flex-end" spacing={3}>
          <Grid item>
            <Button
              onClick={preventMissClick}
              color="primary"
              id="btn-cancelar"
              data-testid="btn-cancel"
            >
              VOLTAR
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              id="btn-salvar"
              disabled={hasNotTouchedForm() || !filesNotLoaded}
              type="submit"
            >
              SALVAR
            </Button>
          </Grid>
        </Grid>
      </form>
      <PreventMissClickDialog
        open={isPreventMissClickDialogOpen}
        closeAnyway={closeAnyway}
        keepOpen={keepOpen}
        closeAnywayButtonText="VOLTAR MESMO ASSIM"
      />
    </>
  );
};

export default StepThree;
