import Typography from '@material-ui/core/Typography';
import { StyledStatusChip } from '@src/components/DefaultTable/DefaultTable.styles';
import { StatusContentContainer } from './ExplainerBanners.styles';

export interface IStatusExplainer {
  statusName: string;
  statusDescription: string;
  chipColors: {
    backgroundColor: string;
    textColor: string;
  };
  showBottonLine?: boolean;
}

const StatusExplainer = ({
  statusName,
  statusDescription,
  chipColors,
  showBottonLine,
}: IStatusExplainer) => (
  <StatusContentContainer>
    <StyledStatusChip
      size="small"
      label={statusName}
      backgroundColor={chipColors.backgroundColor}
      textColor={chipColors.textColor}
    />
    <Typography>{statusDescription}</Typography>
    {showBottonLine && <hr />}
  </StatusContentContainer>
);

export default StatusExplainer;
