import React, { useState, createContext, useCallback } from 'react';

export interface DrawerSMSDialogs {
  sentWithSuccess: boolean;
}

export interface State {
  isOpen: boolean;
  selectedNumbersToSendSMS: string[];
  dialogs: DrawerSMSDialogs;
  lastCampaignCreated: string;
}

interface IDrawerSMSContext {
  state: State;
  handleState: (name: keyof State, value: any) => void;
  sendSMSWithSelectedNumbers: (numbers: string[]) => void;
  setInitialState: () => void;
  handleDrawerSMS: () => void;
  handleOnSendSMS: (payload) => void;
  toggleSuccessDialog: () => void;
}

export const initialState: State = {
  isOpen: false,
  selectedNumbersToSendSMS: [],
  dialogs: {
    sentWithSuccess: false,
  },
  lastCampaignCreated: '',
};

const DrawerSMSContext = createContext<IDrawerSMSContext>({
  state: initialState,
  handleState: (name: keyof State, value: any) => null,
  setInitialState: () => null,
  sendSMSWithSelectedNumbers: (numbers: string[]) => null,
  handleDrawerSMS: () => null,
  handleOnSendSMS: (payload) => null,
  toggleSuccessDialog: () => null,
});

interface Props {
  initialState?: State;
}

const DrawerSMSProvider: React.FC<Props> = ({
  children,
  initialState: initialStateProps,
}) => {
  const [state, setState] = useState<State>(initialStateProps || initialState);

  const handleState = useCallback((key: keyof State, value: any) => {
    setState((prev) => ({ ...prev, [key]: value }));
  }, []);

  const setInitialState = useCallback(() => {
    setState(initialState);
  }, []);

  const handleDrawerSMS = () => {
    setState((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
  };

  const toggleSuccessDialog = () => {
    setState((prev) => ({
      ...prev,
      dialogs: {
        sentWithSuccess: !prev.dialogs.sentWithSuccess,
      },
    }));
  };

  const sendSMSWithSelectedNumbers = (numbers: string[]) => {
    setState((prev) => ({
      ...prev,
      selectedNumbersToSendSMS: numbers,
      isOpen: true,
    }));
  };

  const handleOnSendSMS = (payload) => {
    const newState = state;
    newState.selectedNumbersToSendSMS = [];
    newState.lastCampaignCreated = payload;
    newState.dialogs = {
      ...state.dialogs,
      sentWithSuccess: true,
    };

    setState((prev) => ({ ...prev, ...newState }));
  };

  return (
    <DrawerSMSContext.Provider
      value={{
        state,
        handleState,
        setInitialState,
        sendSMSWithSelectedNumbers,
        handleDrawerSMS,
        handleOnSendSMS,
        toggleSuccessDialog,
      }}
    >
      {children}
    </DrawerSMSContext.Provider>
  );
};

function useDrawerSMSContext() {
  const context = React.useContext(DrawerSMSContext);
  if (context === undefined) {
    throw new Error(
      'useDrawerSMSContext must be used within a DrawerSMSContext'
    );
  }
  return context;
}

export { DrawerSMSProvider, useDrawerSMSContext };
