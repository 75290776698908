import Select from '@material-ui/core/Select';
import { styled } from '@material-ui/core/styles';

export const StyledSelect = styled(Select)(({ theme }) => ({
  boxShadow:
    '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px  1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
  border: '1px solid #FFFFFF',
  background: 'white',
  borderRadius: 4,
  minHeight: '32px',

  '& .MuiSelect-select.MuiSelect-select': {
    paddingLeft: 16,
    paddingTop: 8,
  },
}));
