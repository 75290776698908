import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserStrategy } from '../../strategyDebtSlice';

export const useStrategyInfo = () => {
  const { userStrategy, madeInitialFetch } = useSelector(selectUserStrategy);

  const userHasStrategy = useMemo(() => userStrategy !== null, [userStrategy]);

  return {
    userStrategy,
    userHasStrategy,
    madeInitialFetch,
  };
};
