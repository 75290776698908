import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Box, { BoxProps } from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { AUCookiesUtils, AuthUtils } from '@assertiva/assertiva-ui';
import { StyledAlert } from './Alert.styles';
import { EVENTS } from '@src/constants/analytics';
import { sendInternalAnalytics } from '@src/utils/internalAnalytics';

const variants = {
  primary: {
    backgroundColor: '#F3E4F8',
  },
  warning: {
    backgroundColor: '#F9DF94',
  },
};

interface Props {
  cookieName: string;
  id?: string;
  icon?: React.JSX.Element;
  variant?: keyof typeof variants;
}

const Alert = ({
  cookieName,
  id = '',
  icon,
  children,
  variant = 'primary',
  ...props
}: React.PropsWithChildren<Props & BoxProps>) => {
  const [close, setClose] = useState(true);

  const handleClose = () => {
    setClose(true);
    AUCookiesUtils.saveItem(cookieName, AuthUtils.getToken());
    sendInternalAnalytics(EVENTS.ALERTS.CLOSE_ALERT(id, alertMessage));
  };

  const alertMessage = typeof children === 'string' ? children : undefined;

  useEffect(() => {
    const cookieClose = AUCookiesUtils.getItem(cookieName);
    if (!cookieClose || cookieClose !== AuthUtils.getToken()) {
      setClose(false);
    }
  }, [cookieName]);

  useEffect(() => {
    if (!close) {
      sendInternalAnalytics(EVENTS.ALERTS.SEE_ALERT(id, alertMessage));
    }
  }, [id, alertMessage, close]);

  if (close) return null;

  const alertId = id ? `-${id}` : '';

  return (
    <StyledAlert
      data-testid="alert-container"
      {...props}
      style={{ ...variants[variant], ...props?.style }}
    >
      <Box display="flex" alignItems="center" gridGap={16}>
        {icon}
        <Typography variant="body2" color="textPrimary">
          {children}
        </Typography>
      </Box>
      <Box minWidth={120}>
        <Button
          color={variant === 'primary' ? 'primary' : 'default'}
          onClick={handleClose}
          id={`btn-fechar-alerta${alertId}`}
          fullWidth
          startIcon={<CloseIcon />}
        >
          FECHAR
        </Button>
      </Box>
    </StyledAlert>
  );
};

export default Alert;
