import { KILO_BYTES_PER_BYTE } from '@components/FileUpload/constants';
import { IGeneratePreSignedURLRequest } from '@constants/interfaces';
import DebtsService from '@src/services/DebtsService';
import S3Service from '@src/services/S3Service';

export const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

export const convertBytesToKB = (bytes) =>
  Math.round(bytes / KILO_BYTES_PER_BYTE);

export const getUrlPreSignedByFile = (file: File) => {
  const getItemsFile: IGeneratePreSignedURLRequest = {
    name: file.name,
    contentType: file.type,
    fileSize: file.size,
  };
  return getItemsFile;
};

export const handleImagesToServiceS3 = async (
  files,
  checkUpload,
  idsFileS3,
  cb
) => {
  files.map(async (item) => {
    const file: File = item;
    const getItemsFile: IGeneratePreSignedURLRequest = {
      name: file.name,
      contentType: file.type,
      fileSize: file.size,
    };

    const response = await DebtsService.postAttachmentsFilesDebt(getItemsFile);

    const {
      data: {
        body: { idFileS3, url: preSignedURL },
      },
    } = response;

    await S3Service.uploadFileByPreSignedURL({ preSignedURL, file }, (data) => {
      console.log(data);
    });

    checkUpload++;
    idsFileS3.push(idFileS3);

    if (checkUpload === files.length) {
      cb();
    }
  });
};
