import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DrawerProps } from '@material-ui/core/Drawer';
import DefaultDrawer from '@components/DefaultDrawer';

type Props = DrawerProps & {
  description?: string | JSX.Element;
  title?: string;
};

export const FilterDrawer: React.FC<Props> = ({
  description = 'Utilize as opções abaixo para exibir apenas as informações desejadas.',
  children,
  title = 'Filtros',
  ...props
}) => (
  <DefaultDrawer
    title={title}
    containerBoxProps={{ width: '350px', minWidth: '350px' }}
    contentBoxProps={{ paddingY: 0, paddingX: 0 }}
    cardBoxProps={{ paddingTop: 4 }}
    data-testid="filters-drawer"
    {...props}
  >
    <Box marginBottom={2}>
      {typeof description === 'string' ? (
        <Typography variant="caption">{description}</Typography>
      ) : (
        description
      )}
    </Box>
    {children}
  </DefaultDrawer>
);
