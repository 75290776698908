import Box from '@material-ui/core/Box';
import { BUBoxTitle } from '@assertiva/business-ui';

const Title = (props: React.ComponentProps<typeof BUBoxTitle>) => (
  <Box marginBottom={2}>
    <BUBoxTitle variant="medium" variantDesc="small" {...props} />
  </Box>
);

export default Title;
