import { useEffect, useState, useCallback } from 'react';
import { City, State } from '@src/constants/types';
import AddressService from '@src/services/AddressService';
import { AULoadingManager } from '@assertiva/assertiva-ui';
import { FORM_CONFIG } from './constants';

interface Loaders {
  states: boolean;
  cities: boolean;
}

export function useAddressFields(setValue?: (key: string, value: any) => void) {
  const [statesList, setStatesList] = useState<State[]>([]);
  const [citiesList, setCitiesList] = useState<City[]>([]);
  const [loaders, setLoaders] = useState<Loaders>({
    states: true,
    cities: false,
  });

  const handleLoaders = (changes: Partial<Loaders>) =>
    setLoaders((prevState) => ({ ...prevState, ...changes }));

  const getCitiesList = useCallback(async (uf: string) => {
    try {
      handleLoaders({ cities: true });
      const res = await AddressService.getCities(uf);
      setCitiesList(res.data);
    } finally {
      handleLoaders({ cities: false });
    }
  }, []);

  const fetchAddress = async (zipCode: string) => {
    AULoadingManager.show();

    try {
      const response = await AddressService.getAddress(zipCode);
      const data = response.data;

      const {
        bairro: neighborhood,
        localidade: city,
        logradouro: street,
        uf: state,
      } = data;

      const normalizedAddressFields = { neighborhood, city, street, state };

      if (setValue) {
        Object.keys(normalizedAddressFields)
          .filter((key) => key !== FORM_CONFIG.city.name)
          .forEach((key) => setValue(key, normalizedAddressFields[key]));
        setValue(FORM_CONFIG.city.name, {
          nome: normalizedAddressFields[FORM_CONFIG.city.name],
        });
      }
      return normalizedAddressFields;
    } finally {
      AULoadingManager.close();
    }
  };

  useEffect(() => {
    const getStatesList = async () => {
      try {
        const res = await AddressService.getStates();
        setStatesList(res.data);
      } finally {
        handleLoaders({ states: false });
      }
    };

    getStatesList();
  }, []);

  return {
    statesList,
    citiesList,
    loaders,
    getCitiesList,
    fetchAddress,
  };
}
