import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

export const STEPS = [
  {
    label: 'Dados bancários',
    component: <StepOne />,
  },
  {
    label: 'Dados de contato e localização',
    component: <StepTwo />,
  },
  {
    label: 'Dados de validação',
    component: <StepThree />,
  },
];

export const FILE_TYPES_ACCEPT = '.jpg,.jpeg,.png,.pdf';
export const MAX_FILE_SIZE_IN_BYTES = 10000000;
