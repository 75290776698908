import { styled, Theme } from '@material-ui/core/styles';
import Chip, { ChipProps } from '@material-ui/core/Chip';

export const StyledTableContainer = styled('div')(({ theme }) => ({
  marginTop: `-${theme.spacing(2)}px`,
  width: '100%',

  '& .table-row:hover': {
    '& .hidden-action-display': {
      display: 'block',
    },
  },

  '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },

  // Alterando classe aqui pq no assertiva-ui está mudando só a opacidade
  '& .hidden-action-display': {
    display: 'none',
  },

  '& .hidden-cell': {
    display: 'none',
  },
}));

type StyledStatusChipProps = ChipProps & {
  textColor: string;
  backgroundColor: string;
  theme: Theme;
};

export const StyledStatusChip = styled(
  ({ textColor, backgroundColor, ...rest }) => <Chip {...rest} />
)(({ textColor, backgroundColor }: StyledStatusChipProps) => ({
  color: textColor,
  backgroundColor,
  textTransform: 'capitalize',
  '& .MuiChip-icon': {
    color: textColor,
  },
  cursor: 'inherit',
}));
