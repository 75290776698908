import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  AUMainContainer,
  AUNavBar,
  AUNotificationHub,
  AUProductTrailSuggestion,
  AUSideBar,
  AUValidateAccessProduct,
  AuthUtils,
  Envs,
  PRODUCTS,
  PRODUCT_IDS,
  AUBreadcrumbs,
} from '@assertiva/assertiva-ui';
import {
  useWebphoneDispatch,
  useWebphoneInit,
  BUDrawerSMS,
} from '@assertiva/business-ui';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { CALL_WEBPHONE, EDIT_PROFILE } from '@constants/permissions';

import IMAGES from '@constants/images';
import { useLinearLoading } from '@src/hooks/useLinearLoading';
import routes from '@src/routes';
import Box from '@material-ui/core/Box';
import { LinearLoadingProvider } from '@src/contexts/LinearLoadingContext';
import TrackConfigDrawer from '@src/features/trackConfig/TrackConfigDrawer';
import comparators from './constants/comparators';
import { useDrawerSMSContext } from './contexts/DrawerSMSContext';
import WalletSelect from './features/wallet/components/WalletSelect';
import { hasPermissionToSeeWalletSelection } from './features/wallet/constants';
import NBA from './features/nba';
import { getCommonBreadcrumbItems } from './utils/functionUtils';
import { StyledAppLayout } from './AppLayout.styles';
import { useStrategyInfo } from './features/strategyDebt/components/StrategyInfo/useStrategyInfo';
import { useStrategyFeatureFlag } from './hooks/useStrategyFeatureFlag';

// used to add a action on click in sidebar item, not using in this moment
// function getRoutesWithOpenChargeSettingsAction(action) {
//   return routes.map((route) => {
//     if (route.label === comparators.LABEL_SIMPLIFICA_ROUTE) {
//       return {
//         ...route,
//         nested: route.nested.map((nestedRoute) => {
//           if (nestedRoute.label === comparators.LABEL_CHARGE_SETTINGS_ROUTE) {
//             return { ...nestedRoute, action };
//           }

//           return nestedRoute;
//         }),
//       };
//     }
//     return route;
//   });
// }

const ROUTES_TO_START_WEBPHONE = [
  comparators.PATH_DEBTS_ROUTE,
  comparators.PATH_DEBTORS_ROUTE,
  comparators.PATH_DASHBOARD,
];

const AppLayout = (props) => {
  const { children, width, path } = props;
  const isMobile = isWidthDown('sm', width);
  const history = useHistory();
  const userInfo = AuthUtils.getUser();
  const [openSidebar, setOpenSidebar] = useState(!isMobile);

  const handleSidebar = () => setOpenSidebar(!openSidebar);

  const isLinearLoadingOpen = useLinearLoading();

  const isDebtsPage = window.location.pathname === comparators.PATH_DEBTS_ROUTE;

  const { userHasStrategy, madeInitialFetch } = useStrategyInfo();

  const { shouldShowStrategy } = useStrategyFeatureFlag();

  const {
    state: drawerSMSState,
    handleDrawerSMS,
    handleOnSendSMS,
  } = useDrawerSMSContext();

  const dispatch = useWebphoneDispatch();

  useWebphoneInit(
    AuthUtils.getUser().hasPermission(CALL_WEBPHONE) &&
      ROUTES_TO_START_WEBPHONE.includes(props.path),
    dispatch
  );

  return (
    <StyledAppLayout className="app-layout">
      <AUNavBar
        handleClickIconButton={handleSidebar}
        logo={IMAGES.WHITE_LOGO}
        loading={isLinearLoadingOpen}
        showEditUserProfileOption={AuthUtils.getUser().hasPermission(
          ...EDIT_PROFILE
        )}
        onClickEditUserProfileOption={() =>
          window.open(
            `${Envs().PAINEL_URL}/gestao-da-conta/editar/perfil`,
            '_self'
          )
        }
        objectMenuList={[]}
        handleClickLogoIcon={() => history.push('/inicio')}
      />
      <AUSideBar
        routes={routes({ userInfo, shouldShowStrategy })}
        path={path}
        open={openSidebar}
        onClose={handleSidebar}
        onOpen={handleSidebar}
        variant={isMobile ? 'temporary' : 'permanent'}
      />
      <LinearLoadingProvider value={isLinearLoadingOpen}>
        <TrackConfigDrawer />
        <BUDrawerSMS
          isDrawerSMSOpen={drawerSMSState.isOpen}
          selectedNumbersToSendSMS={drawerSMSState.selectedNumbersToSendSMS}
          handleDrawerSMS={handleDrawerSMS}
          onSend={(campaignId) => handleOnSendSMS(campaignId)}
          origin={PRODUCTS.RECUPERE}
        />
        <AUMainContainer isSideBarOpen={openSidebar} maxWidth="1326px">
          <AUValidateAccessProduct productId={PRODUCT_IDS.RECUPERE}>
            <AUNotificationHub
              isSideBarOpen={openSidebar}
              productId={PRODUCT_IDS.RECUPERE}
            />
            <Box
              display="flex"
              alignItems={isDebtsPage ? 'start' : 'center'}
              justifyContent="space-between"
              gridGap={16}
              mb={isDebtsPage ? 2 : 0}
            >
              {hasPermissionToSeeWalletSelection(
                userHasStrategy,
                madeInitialFetch
              ) ? (
                <>
                  <Box flex="1">
                    <Box mb={2}>
                      <WalletSelect />
                    </Box>

                    {isDebtsPage ? (
                      <AUBreadcrumbs
                        items={[
                          ...getCommonBreadcrumbItems(),
                          { label: 'Cobrança' },
                        ]}
                      />
                    ) : null}
                  </Box>
                </>
              ) : (
                <div />
              )}
              {isDebtsPage && madeInitialFetch && !userHasStrategy ? (
                <NBA />
              ) : (
                <AUProductTrailSuggestion />
              )}
            </Box>
            <div>{children}</div>
          </AUValidateAccessProduct>
        </AUMainContainer>
      </LinearLoadingProvider>
    </StyledAppLayout>
  );
};

export default withWidth()(AppLayout);
