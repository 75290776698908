import { Nullish, Pagination } from '@src/constants/types';
import { WalletActive } from '../wallet/type';

export type StrategyId = number;

export type StrategyListItem = {
  id: StrategyId;
  name: string;
  isActive: boolean;
};

export type StrategyConfig = {
  name: string;
  idUsers: number[];
  idWallets: number[];
  idStatus: number[];
  idAgingOptions: number[];
  minValue: Nullish<number | string>;
  maxValue: Nullish<number | string>;
  idAgreementOption: number;
  idNegativeOption: number;
  id?: StrategyId;
};

export type StrategyDetailed = StrategyListItem & StrategyConfig;

export type StrategyListDTO = {
  list: StrategyListItem[];
  count: number;
  size: number;
  page: number;
};

export enum DrawerModes {
  Edit = 'Edit',
  Add = 'Add',
  Closed = 'Closed',
}

export enum StrategyDrawerSteps {
  InitialConfig,
  Features,
  Users,
}

interface ActiveControl {
  active?: boolean;
}

export type WalletListItem = WalletActive & ActiveControl;

export interface UserAvailable {
  id: number;
  name: string;
  email: string;
}

export type UserListItem = UserAvailable & ActiveControl;

export interface FilterOption {
  id: number;
  name: string;
  description: string;
}

export interface StrategyState {
  list: StrategyListItem[];
  pagination: Pagination;
  isLoadingTable: boolean;
  drawerMode: DrawerModes;
  strategyDrawerStep: StrategyDrawerSteps;
  strategyConfig?: Partial<StrategyConfig>;
  hasChangedStrategyConfig: boolean;
  hasAvailableUsers?: boolean;
  lastCreatedStrategy?: StrategyListItem;
}
