import { SIMPLIFICA_EMAIL_AUTOMATICO } from './permissions';
import { ChannelsTrackConfig } from '@src/features/trackConfig/types';

const comparators = {
  LABEL_SIMPLIFICA_ROUTE: 'Recupere',
  PATH_DASHBOARD: '/dashboard',
  PATH_APPOINTMENTS: '/agendamentos',
  LABEL_DASHBOARD: 'Painel de gestão',
  LABEL_CHARGE_SETTINGS_ROUTE: 'Configurações',
  LABEL_DEBTS_ROUTE: 'Cobrança',
  PATH_DEBTS_ROUTE: '/cobranca',
  LABEL_DEBTORS_ROUTE: 'Clientes',
  PATH_DEBTORS_ROUTE: '/clientes',
  LABEL_WALLET_ROUTE: 'Carteiras',
  PATH_WALLET_ROUTE: '/carteiras',
  PATH_STRATEGY_ROUTE: '/estrategias',
  LABEL_STRATEGY_ROUTE: 'Estratégias',
  LABEL_USER_SETTINGS_ROUTE: 'Usuários',
  LABLE_REPORT: 'Relatórios',
  LABEL_SENDS_CHANNEL_OPTIONS: {
    [ChannelsTrackConfig.Sms]: 'SMS',
    [ChannelsTrackConfig.Email]: 'E-mail',
    // 2: 'Telefone',
  },
  NAME_SENDS_CHANNEL_OPTIONS: {
    [ChannelsTrackConfig.Sms]: 'Sms',
    [ChannelsTrackConfig.Email]: 'Email',
    // 2: 'Phone',
  },
  PERMISSION_SENDS_CHANNEL_OPTIONS: {
    [ChannelsTrackConfig.Sms]: null,
    [ChannelsTrackConfig.Email]: SIMPLIFICA_EMAIL_AUTOMATICO,
    // 2: null,
  },
  DEFAULT_ACTION_PROPS_DIALOG: {
    display: 'flex',
    gridGap: 24,
    padding: 2,
    paddingTop: 1,
  },
  DEFAULT_YEARS_PAST_FUTURE: 100,
  DEFAULT_INITIAL_PAGINATION: { page: 0, rowsPerPage: 10, count: 0 },
  INITIAL_PAGINATION_HISTORIC: { page: 0, rowsPerPage: 5, count: 0 },
  BLACK_INFO_COLOR: '#212121',
};

export default comparators;
