import { useState, useEffect } from 'react';
import { BoxProps } from '@material-ui/core/Box';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Alert from './Alert';
import ConfigService from '@src/services/ConfigService';

interface AlertItem {
  id: number;
  message: string;
}

const CLOSE_ALERT_COOKIE = 'close-warning-alert';

const WarningAlerts = (props: BoxProps) => {
  const [alerts, setAlerts] = useState<AlertItem[]>([]);

  useEffect(() => {
    ConfigService.getAlerts()
      .then((res) => {
        const alertsList = res.data?.body;
        if (alertsList?.length) {
          setAlerts(alertsList);
        }
      })
      .catch(() => {
        // do nothing in case of error
      });
  }, []);

  if (!alerts.length) return null;

  return (
    <>
      {alerts.map((alert) => {
        return (
          <Alert
            key={alert.id}
            cookieName={`${CLOSE_ALERT_COOKIE}-${alert.id}`}
            id={`${alert.id}`}
            icon={<ReportProblemOutlinedIcon data-testid="alert-icon" />}
            variant="warning"
            {...props}
          >
            {alert.message}
          </Alert>
        );
      })}
    </>
  );
};

export default WarningAlerts;
