import { AuthUtils } from '@assertiva/assertiva-ui';

const handleWhiteSpace = (value?: string) => (value ? ` ${value}` : '');

const getPathname = () => window.location.pathname.substring(1).toUpperCase();

const resolveEventLabel = (label?: string) => {
  if (!label) return undefined;

  if (label.length > 100) return `${label.substring(0, 97)}...`;

  return label;
};

export const EVENTS = {
  PAYMENTS: {
    CLICK_ADD_ACCOUNT: (step = '') => ({
      eventCategory: '[FORMA DE RECEBIMENTO]',
      eventAction: '[CLICAR]',
      eventLabel: `ADICIONAR FORMA${handleWhiteSpace(step)}`,
    }),
    CLICK_BILLET: {
      eventCategory: '[BOLETO]',
      eventAction: '[CLICAR]',
      eventLabel: 'BOLETO',
    },
    CLICK_SEE_BILLET: (type = '') => ({
      eventCategory: `[BOLETO${handleWhiteSpace(type)}]`,
      eventAction: '[CLICAR]',
      eventLabel: `VISUALIZAR BOLETO${handleWhiteSpace(type)}`,
    }),
    CLICK_GENERATE_BILLET: (type = '') => ({
      eventCategory: `[BOLETO${handleWhiteSpace(type)}]`,
      eventAction: '[CLICAR]',
      eventLabel: `GERAR BOLETO${handleWhiteSpace(type)}`,
    }),
    CLICK_ADD_ADDRESS_BILLET: {
      eventCategory: '[BOLETO]',
      eventAction: '[CLICAR]',
      eventLabel: 'ADICIONAR ENDEREÇO BOLETO',
    },
  },
  ALERTS: {
    SEE_ALERT: (id: string, message?: string) => ({
      eventCategory: `[${getPathname()} ALERTA ${id}]`,
      eventAction: '[VISUALIZAR]',
      eventLabel: resolveEventLabel(message),
    }),
    CLOSE_ALERT: (id: string, message?: string) => ({
      eventCategory: `[${getPathname()} ALERTA ${id}]`,
      eventAction: '[FECHAR]',
      eventLabel: resolveEventLabel(message),
    }),
  },
  FAKE_DOOR_NBA: () => ({
    eventCategory: `[FAKE DOOR NBA]`,
    eventAction: '[CLICAR]',
    eventLabel: AuthUtils.getUser().getEmail(),
  }),
  IMPORT_FILES: {
    DELETE_LAST_IMPORT_DEBTS: {
      eventCategory: '[EXCLUIR IMPORTACAO]',
      eventAction: '[CLICAR]',
    },
  },
  AGREEMENTS: {
    CONFIRM_UNDO: {
      eventCategory: '[DESFAZER ACORDO]',
      eventAction: '[CONFIRMAR]',
    },
  },
};
