import {
  AUConfirmDialog,
  sendAnalytics,
  StringUtils,
} from '@assertiva/assertiva-ui';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  StyledTab,
  StyledTabs,
} from '@src/components/SimpleTabs/SimpleTabs.styles';
import comparators from '@src/constants/comparators';
import Title from '@src/features/trackConfig/components/Title';
import { selectGlobalWallet } from '@src/features/wallet/walletSlice';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTrackConfigContext } from '../context';
import {
  ChannelsTrackConfig,
  DefineMessagesTabs,
  TYPE_MESSAGE_ENUM,
} from '../types';
import { areAllOptionsCorrect, resolveHasPermission } from '../utils';
import MessageCard from './components/MessageCard';
import WriteEmailDrawer from './components/WriteEmailDrawer';
import WriteSmsDrawer from './components/WriteSmsDrawer';
import { useDefineMessages } from './useDefineMessages';

const tabFixedContent = (
  label: string,
  showBilletMessage: boolean,
  hasPermission?: boolean
) => ({
  title: `Definição de mensagens do ${label}`,
  description: `Defina as mensagens que serão enviadas via ${label}`,
  hasPermission,
  label,
  cards: {
    [TYPE_MESSAGE_ENUM.preventiveOptions]: {
      title: 'Preventiva',
      tooltip: 'Dívidas que podem ser recuperadas antes do vencimento',
      text: showBilletMessage ? (
        <span>
          Modelo 1 &#40;obrigatório&#41;:
          <br />
          Mensagem {label.toLowerCase()} para clientes <b>sem boleto</b>
        </span>
      ) : (
        `Adicione a mensagem que será enviada via ${label.toLowerCase()} nos dias antes do vencimento`
      ),
      buttonId: 'add-edit-message-preventive',
      isBilletMessage: false,
      label: showBilletMessage && (
        <span>
          <b>Mensagem sem boleto:</b>
        </span>
      ),
    },
    [TYPE_MESSAGE_ENUM.expirationDay]: {
      title: 'Dia do vencimento',
      text: showBilletMessage ? (
        <span>
          Modelo 1 &#40;obrigatório&#41;:
          <br />
          Mensagem {label.toLowerCase()} para clientes <b>sem boleto</b>
        </span>
      ) : (
        `Adicione a mensagem que será enviada via ${label.toLowerCase()} no dia do vencimento`
      ),
      buttonId: 'add-edit-message-expiration-day',
      isBilletMessage: false,
      label: showBilletMessage && (
        <span>
          <b>Mensagem sem boleto:</b>
        </span>
      ),
    },
    [TYPE_MESSAGE_ENUM.reactiveOption]: {
      title: 'Reativa',
      tooltip: 'Dívidas que precisam ser recuperadas após vencimento',
      text: showBilletMessage ? (
        <span>
          Modelo 1 &#40;obrigatório&#41;:
          <br />
          Mensagem {label.toLowerCase()} para clientes <b>sem boleto</b>
        </span>
      ) : (
        `Adicione a mensagem que será enviada via ${label.toLowerCase()} todos os dias depois do vencimento`
      ),
      buttonId: 'add-edit-message-reactive',
      isBilletMessage: false,
      label: showBilletMessage && (
        <span>
          <b>Mensagem sem boleto:</b>
        </span>
      ),
    },
  },
});

const DefineMessages = () => {
  const {
    state: { typeMessageToDefine, isSubmitDialogOpen },
    handleOpenDrawer,
    handleCloseDrawer,
    handleState,
  } = useDefineMessages();
  const {
    state: stateTrackConfig,
    goBack,
    optionsData,
    handleSubmit,
    handleState: handleStateTrack,
    handleInsertConfig,
  } = useTrackConfigContext();
  const selectedWallet = useSelector(selectGlobalWallet);
  const [showBilletVariableOnDrawer, setShowBilletVariableOnDrawer] =
    useState<boolean>(false);
  const { defineMessagesTab, hasBillet: showBilletMessage } = stateTrackConfig;
  const isWriteSmsDrawerOpen = Boolean(
    typeMessageToDefine && defineMessagesTab === DefineMessagesTabs.Sms
  );
  const isWriteEmailDrawerOpen = Boolean(
    typeMessageToDefine && defineMessagesTab === DefineMessagesTabs.Email
  );

  const tabs = {
    [DefineMessagesTabs.Sms]: tabFixedContent(
      'SMS',
      showBilletMessage,
      resolveHasPermission(
        comparators.PERMISSION_SENDS_CHANNEL_OPTIONS[ChannelsTrackConfig.Sms]
      )
    ),
    [DefineMessagesTabs.Email]: tabFixedContent(
      'E-mail',
      showBilletMessage,
      resolveHasPermission(
        comparators.PERMISSION_SENDS_CHANNEL_OPTIONS[ChannelsTrackConfig.Email]
      )
    ),
  };

  const tab = tabs[defineMessagesTab];

  const getEmailConfig = () => {
    if (typeMessageToDefine) {
      if (showBilletVariableOnDrawer) {
        return stateTrackConfig[typeMessageToDefine].emailBilletConfig;
      }
      return stateTrackConfig[typeMessageToDefine].emailConfig;
    }
    return null;
  };

  const getSMSConfig = () => {
    if (typeMessageToDefine) {
      if (showBilletVariableOnDrawer) {
        return stateTrackConfig[typeMessageToDefine].smsBilletConfig;
      }
      return stateTrackConfig[typeMessageToDefine].smsConfig;
    }
    return null;
  };

  return (
    <>
      <StyledTabs
        indicatorColor="primary"
        textColor="primary"
        value={defineMessagesTab}
        onChange={(_, newActiveTab) =>
          handleStateTrack({ defineMessagesTab: newActiveTab })
        }
        variant="fullWidth"
        style={{ marginTop: '-16px' }}
      >
        {Object.keys(tabs).map((key) => (
          <StyledTab
            key={key}
            style={{ pointerEvents: 'auto' }}
            label={
              <Tooltip
                title={
                  tabs[key].hasPermission
                    ? ''
                    : `Você não possui permissão para configurar ${tabs[key].label}.`
                }
                arrow
              >
                <div>{key}</div>
              </Tooltip>
            }
            value={key}
            id={`${StringUtils.formatToLowerCaseHyphen(key)}-tab`}
            data-testid={`${StringUtils.formatToLowerCaseHyphen(key)}-tab`}
            disabled={!tabs[key].hasPermission}
          />
        ))}
      </StyledTabs>

      <Box paddingTop={3}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Title title={tab.title} description={tab.description} />
          </Grid>
          {Object.keys(tab.cards).map((key) => {
            const card = tab.cards[key];
            return (
              <Grid key={key.toString()} item>
                <Title title={card.title} tooltip={card.tooltip} />
                <MessageCard
                  text={card.text}
                  buttonId={card.buttonId}
                  handleClick={() => {
                    setShowBilletVariableOnDrawer(false);
                    handleOpenDrawer(key as TYPE_MESSAGE_ENUM);
                  }}
                  message={
                    optionsData[defineMessagesTab][key]['default'].message
                  }
                  disabled={
                    !optionsData[defineMessagesTab][key]['default'].selected
                  }
                  label={card.label}
                />
                {showBilletMessage && (
                  <MessageCard
                    text={
                      <span>
                        Modelo 2:
                        <br />
                        Mensagem {defineMessagesTab.toLowerCase()} para clientes{' '}
                        <b>com boleto</b>
                      </span>
                    }
                    label={
                      <span>
                        <b>Mensagem com boleto:</b>
                      </span>
                    }
                    buttonId={card.buttonId}
                    handleClick={() => {
                      setShowBilletVariableOnDrawer(true);
                      handleOpenDrawer(key as TYPE_MESSAGE_ENUM);
                    }}
                    message={
                      optionsData[defineMessagesTab][key]['withBillet'].message
                    }
                    disabled={
                      !optionsData[defineMessagesTab][key]['withBillet']
                        .selected
                    }
                    messageDataId="billet-message"
                  />
                )}
              </Grid>
            );
          })}
          <Grid container item justifyContent="flex-end" spacing={3}>
            <Grid item>
              <Button
                variant="text"
                color="primary"
                id="btn-voltar"
                onClick={goBack}
              >
                VOLTAR
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!areAllOptionsCorrect(optionsData, showBilletMessage)}
                id="btn-salvar"
                onClick={() => {
                  sendAnalytics({
                    page: 'configurar-cobranca-drawer-configuracao-de-sms',
                    event: 'salvar',
                  });
                  handleState({ isSubmitDialogOpen: true });
                }}
              >
                SALVAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <WriteSmsDrawer
        open={isWriteSmsDrawerOpen}
        typeMessage={typeMessageToDefine}
        handleClose={handleCloseDrawer}
        smsConfig={getSMSConfig()}
        showBilletVariable={showBilletVariableOnDrawer}
        handleInsertConfig={(typeMessage, smsConfig) =>
          handleInsertConfig(
            typeMessage,
            showBilletVariableOnDrawer ? 'smsBilletConfig' : 'smsConfig',
            smsConfig
          )
        }
      />
      <WriteEmailDrawer
        open={isWriteEmailDrawerOpen}
        typeMessage={typeMessageToDefine}
        handleClose={handleCloseDrawer}
        emailConfig={getEmailConfig()}
        showBilletVariable={showBilletVariableOnDrawer}
        handleInsertConfig={(typeMessage, emailConfig) =>
          handleInsertConfig(
            typeMessage,
            showBilletVariableOnDrawer ? 'emailBilletConfig' : 'emailConfig',
            emailConfig
          )
        }
      />
      <AUConfirmDialog
        title="Confirmar alteração de configurações"
        subtitle={`As alterações serão aplicadas a
              <span style="font-weight: 600">
                carteira ${selectedWallet.name}
              </span> e a régua de cobrança será executada com essas alterações <span style="font-weight: 600">a partir de amanhã.</span> Certifique-se de que está na carteira correta antes de prosseguir.`}
        open={Boolean(isSubmitDialogOpen)}
        onConfirm={() => {
          handleState({ isSubmitDialogOpen: false });
          handleSubmit();
        }}
        onDeny={() => handleState({ isSubmitDialogOpen: false })}
        confirmButtonText="Salvar alterações"
        denyButtonText="Cancelar"
        actionsWrapperProps={comparators.DEFAULT_ACTION_PROPS_DIALOG}
        data-testid="dialog-confirm-save"
      />
    </>
  );
};

export default DefineMessages;
