import Box from '@material-ui/core/Box';

function handleCountString(
  number: number,
  singular: string,
  plural: string,
  none: string
): string {
  if (number > 1) {
    return plural;
  }
  if (number > 0) {
    return singular;
  }
  return none;
}

const getNumberChars = (inputValue: string) => {
  if (!inputValue) return 0;

  return inputValue.replace('R$', '').replace(/\./g, '').trim().length;
};

export interface CurrencyHelperTextProps {
  helperText?: string;
  maxChars: number;
  inputValue: string;
}

const CurrencyHelperText = ({
  helperText = '',
  maxChars,
  inputValue,
}: CurrencyHelperTextProps) => {
  const remainingChars = maxChars - getNumberChars(inputValue);

  return (
    <Box
      component="span"
      justifyContent="space-between"
      alignItems="center"
      display="flex"
    >
      <span>{helperText}</span>
      <span>
        {handleCountString(
          remainingChars,
          '1 caracter restante',
          `${remainingChars} caracteres restantes`,
          'Nenhum caracter restante'
        )}
      </span>
    </Box>
  );
};

export default CurrencyHelperText;
