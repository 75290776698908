import { StringUtils, ValidationUtils } from '@assertiva/assertiva-ui';

import moment from 'moment';
import { sendInternalAnalytics } from './internalAnalytics';

export const emptyObjectArray = (length: number) =>
  Array.from({ length }).map((_, index) => ({ key: index }));

export const lowercase = (str: string) => str.toLowerCase();

export const uppercase = (str: string) => str.toUpperCase();

export const isEmptyString = (str: any) => !str || str?.trim()?.length === 0;

export const getOnlyPropertiesWithValue = (obj: Record<string, any>) => {
  const finalObj = {};

  for (const key in obj) {
    if (obj[key] || obj[key] === false) {
      finalObj[key] = obj[key];
    }
  }

  return finalObj;
};

export const getOnlyDefaultValidFilters = (filters): Record<string, any> =>
  getOnlyPropertiesWithValue({
    page: filters?.page,
    size: filters?.size,
  });

export const objectsAreEqual = (
  firstObj: Record<string, any>,
  secondObj: Record<string, any>
) =>
  JSON.stringify(getOnlyPropertiesWithValue(firstObj)) ===
  JSON.stringify(getOnlyPropertiesWithValue(secondObj));

interface OptionsDate {
  defaultValue?: any;
  format?: string;
}

export const resolveStringToMoment = (
  date: any,
  options: OptionsDate = { defaultValue: undefined, format: 'DD/MM/YYYY' }
) => {
  if (!date) return options.defaultValue;
  return moment(date, options.format);
};

export const resolveMomentToString = (
  date: any,
  options: OptionsDate = { defaultValue: undefined, format: 'DD/MM/YYYY' }
) => {
  if (!date) return options.defaultValue;
  return moment(date).format(options.format);
};

export const addPropToObjectArray = (
  array: any[],
  props: Record<string, any>
) => array.map((v) => ({ ...v, ...props }));

export const removePropFromObjectArray = (array: any[], propName: string) =>
  array.map((value) => {
    const copy = value;
    delete copy[propName];
    return copy;
  });

export const getCommonBreadcrumbItems = () => {
  return [{ label: 'Assertiva Recupere', link: '/inicio' }];
};

export const downloadFile = (filePath) => {
  const link = document.createElement('a');
  link.href = filePath;
  link.target = '_blank';
  link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  link.click();
};

export const getDiffFromNowInMinutes = (startDate) => {
  const now = moment();
  const min = now.diff(startDate, 'minutes') % 60 || 1;

  return `${min} minuto(s)`;
};

export const openWhatsAppWeb = (phoneNumber?: string) => {
  if (!phoneNumber) return;

  window.open(
    `https://wa.me/55${StringUtils.returnOnlyNumber(phoneNumber)}`,
    '_blank'
  );

  sendInternalAnalytics({
    eventCategory: '[WHATSAPP]',
    eventAction: '[CLICAR]',
    eventLabel: 'sem modelo',
  });
};

export const dateIsBeforeCompareDate = (
  date: moment.Moment,
  compareDate: moment.Moment
) => {
  const momentDate = moment(date);
  const momentCompareDate = moment(compareDate);
  if (momentDate.isValid() && momentCompareDate.isValid()) {
    return momentDate.startOf('day').isBefore(momentCompareDate.startOf('day'));
  }
};

export const dateIsAfterCompareDate = (
  date: moment.Moment,
  compareDate: moment.Moment
) => {
  if (date.isValid() && compareDate.isValid())
    return moment(date).isAfter(moment(compareDate));
};

export const requiredLabel = (label: string) => `${label}*`;

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const resolveTooltipSendSMS = (number) => {
  if (!ValidationUtils.isValidPhone(number, 'mobile')) {
    return 'Não é possível enviar SMS para telefone fixo';
  }

  return 'Enviar SMS';
};

export const getByDate = (date: string) => {
  return String(date).split(' ')[0];
};

export const getByHour = (date: string) => {
  return String(date).split(' ')[1].slice(0, -3);
};

export const preloadImage = (src: string, callback: () => void) => {
  const img = new Image();
  img.src = src;
  img.crossOrigin = 'anonymous';
  img.onload = callback;
};
