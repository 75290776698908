import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import EventIcon from '@material-ui/icons/Event';
import Box from '@material-ui/core/Box';

import { useTrackConfigContext } from '../../context';
import { hoursRegex, INVALID_HOUR_MESSAGE } from '../../constants';
import { resolveMomentToString } from '@src/utils/functionUtils';

import Title from '@src/features/trackConfig/components/Title';
import ChannelLabel from '../../components/ChannelLabel';
import { StyledRulesFields } from './rulesFields/RulesFields.styles';
import ChannelCheckboxes from './ChannelCheckboxes';
import Grid from '@material-ui/core/Grid';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { TYPE_MESSAGE_ENUM } from '../../types';

const rule = TYPE_MESSAGE_ENUM.expirationDay;

const ExpirationDayForm = () => {
  const {
    state: { expirationDay },
  } = useTrackConfigContext();
  const {
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setValue(rule, expirationDay);
  }, [expirationDay, setValue]);

  useEffect(() => {
    resetField(`${rule}.hour` as '', { defaultValue: expirationDay.hour });
  }, [expirationDay.hour, resetField]);

  const isSmsWatch = useWatch({
    control,
    name: `${rule}.isSms` as '',
    defaultValue: expirationDay.isSms,
  });

  const isEmailWatch = useWatch({
    control,
    name: `${rule}.isEmail` as '',
    defaultValue: expirationDay.isEmail,
  });

  const isSmsOrEmail = isSmsWatch || isEmailWatch;

  return (
    <Grid container direction="column">
      <Grid container item wrap="nowrap" alignItems="center">
        <Grid item xs={6}>
          <Title
            title="Dia do vencimento"
            description="Hora da cobrança no dia do vencimento"
          />
        </Grid>
        <Grid item>
          <ChannelLabel />
        </Grid>
      </Grid>
      <Grid
        container
        item
        wrap="nowrap"
        spacing={2}
        data-testid="rules-fields-expirationDay"
      >
        <Grid container item xs={6} spacing={1}>
          <Grid item xs={5}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <StyledRulesFields>
                <Controller
                  name={`${rule}.hour` as ''}
                  control={control}
                  rules={{
                    validate: (value) =>
                      hoursRegex.test(
                        resolveMomentToString(value, { format: 'HH:mm' })
                      ) ||
                      !value ||
                      INVALID_HOUR_MESSAGE,
                    required: isSmsOrEmail,
                  }}
                  defaultValue={expirationDay.hour}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <KeyboardTimePicker
                      value={value ? moment(value, 'HH:mm') : null}
                      onChange={(date) => {
                        if (date) {
                          onChange(date);
                        } else onChange(null);
                      }}
                      onBlur={onBlur}
                      onAccept={(date) => {
                        if (date) {
                          setValue(`${rule}.hour` as '', date, {
                            shouldTouch: true,
                          });
                        }
                      }}
                      fullWidth
                      clearable
                      clearLabel="LIMPAR"
                      cancelLabel="CANCELAR"
                      id={`select-hour-${rule}`}
                      label="Hora*"
                      invalidDateMessage="Hora inválida"
                      ampm={false}
                      size="small"
                      helperText={(errors?.[rule] as any)?.hour?.message}
                      error={Boolean((errors?.[rule] as any)?.hour)}
                      keyboardIcon={<EventIcon color={'primary'} />}
                    />
                  )}
                />
              </StyledRulesFields>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={7} />
        </Grid>

        <Grid item>
          <Box display="flex">
            <ChannelCheckboxes rule={rule} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExpirationDayForm;
