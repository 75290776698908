import { GlobalDrawersProvider } from '@src/contexts/GlobalDrawersContext';
import { EnrichScoreBatchProvider } from '@src/contexts/EnrichScoreBatchContext';
import { NotifyBatchUploadProvider } from '@src/contexts/NotifyBatchUploadContext';
import { combineComponents } from '@src/contexts/combineComponents';
import { DrawerSMSProvider } from '@src/contexts/DrawerSMSContext';

const providers = [
  EnrichScoreBatchProvider,
  GlobalDrawersProvider,
  NotifyBatchUploadProvider,
  DrawerSMSProvider,
];

export const AppContextProvider = combineComponents(...providers);
