import {
  STATUS_BATCH_FILE,
  STATUS_PROCESS_BATCH_FILE,
  TYPE_FEATURE,
  useNotifyBatchUploadContext,
} from '@src/contexts/NotifyBatchUploadContext';
import DebtsService from '@src/services/DebtsService';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';
import { AUNotifier, AuthUtils } from '@assertiva/assertiva-ui';
import { useAppDispatch } from '@src/store/hooks';
import { updateDebts } from '@src/features/debt/list/debtsSlice';
import { useRef } from 'react';
import { setStrategyDebtState } from '@src/features/strategyDebt/strategyDebtSlice';
import { useStrategyInfo } from '@src/features/strategyDebt/components/StrategyInfo/useStrategyInfo';
import { refetchAllStrategyRequests } from '@src/features/strategyDebt/utils';

export const GENERATE_BILLET_BATCH_NAME = 'Geração em andamento...';

const uploadTypeConfig: Record<
  TYPE_FEATURE,
  {
    getStatus: (id) => Promise<any>;
    getResponse: () => Promise<any>;
    statusType: string;
    name?: string;
  }
> = {
  [TYPE_FEATURE.addDebt]: {
    getStatus: DebtsService.getStatusBatchDebts,
    getResponse: DebtsService.getPendingProcessDebts,
    statusType: 'addDebt',
  },
  [TYPE_FEATURE.confirmPayment]: {
    getStatus: DebtsService.getStatusBatchPayments,
    getResponse: DebtsService.getPendingProcessPayments,
    statusType: 'confirmPayment',
  },
  [TYPE_FEATURE.billetBatch]: {
    getStatus: DebtsService.getBilletBatchGenerationStatus,
    getResponse: DebtsService.getHasBilletBatchPendingProcess,
    statusType: 'billetBatch',
    name: GENERATE_BILLET_BATCH_NAME,
  },
};

export function useNotifyFileBatch() {
  const { state, handleState, closeNotifyBatchUpload } =
    useNotifyBatchUploadContext();

  const { userHasStrategy } = useStrategyInfo();

  const isAlreadyFetchingBilletStatus = useRef(false);

  const { isExpanded, statusUpload, uploadingFiles } = state;

  const toggleExpanded = () => handleState('isExpanded', !isExpanded);

  const dispatch = useAppDispatch();

  const statusFile = (typeFeature: TYPE_FEATURE) => {
    if (typeFeature === TYPE_FEATURE.billetBatch) return '';

    const { statusType } = uploadTypeConfig[typeFeature];

    if (statusUpload[statusType].status === STATUS_BATCH_FILE.starting) {
      return ' Iniciando...';
    }

    if (statusUpload[statusType].status === STATUS_BATCH_FILE.uploading) {
      return ' Enviando...';
    }

    if (statusUpload[statusType].status === STATUS_BATCH_FILE.processing) {
      return ' Processando...';
    }
  };

  const fetchPendingProcess = async (typeFeature: TYPE_FEATURE) => {
    try {
      const uploadConfig = uploadTypeConfig[typeFeature];

      const getStatusBatch = async (idFileS3) =>
        await uploadConfig?.getStatus?.(idFileS3);

      const handleErrorMessage = (name?: string) => {
        if (typeFeature === TYPE_FEATURE.billetBatch)
          return `Erro ao gerar boletos. Tente novamente.`;
        return `Erro ao fazer importação ${name}. Tente novamente.`;
      };

      const statusType = uploadConfig?.statusType;

      const billetBatchInProgress = uploadingFiles.find(
        (value) => value.type === TYPE_FEATURE.billetBatch
      );

      let response;

      if (typeFeature === TYPE_FEATURE.billetBatch && billetBatchInProgress) {
        response = { data: { body: [billetBatchInProgress] } };
      } else {
        response = await uploadConfig?.getResponse();
      }

      const {
        data: { body },
      } = response;

      if (!_isEmpty(body)) {
        if (
          isAlreadyFetchingBilletStatus.current &&
          typeFeature === TYPE_FEATURE.billetBatch
        )
          return;

        isAlreadyFetchingBilletStatus.current = true;

        const isDifferentUser =
          body[0]?.idUser && body[0]?.idUser !== AuthUtils.getUser().getId();

        statusUpload[statusType].status = STATUS_BATCH_FILE.processing;
        handleState('statusUpload', statusUpload);

        const id = body[0]?.idFileS3 ?? body[0]?.idImport;

        const objFile = {
          ...body[0],
          idFileS3: id,
          name: uploadConfig?.name ?? body[0].name,
          type: typeFeature,
          status: 0,
        };

        const pollingRequestStatus = async () => {
          getStatusBatch(id)
            .then(function (responseGetStatus) {
              const {
                data: { body: bodyResume },
              } = responseGetStatus;

              if (
                bodyResume.status === STATUS_PROCESS_BATCH_FILE.WAITING ||
                bodyResume.status === STATUS_PROCESS_BATCH_FILE.PROCESSING ||
                bodyResume.status === STATUS_PROCESS_BATCH_FILE.QUEUED
              ) {
                const key = setTimeout(pollingRequestStatus, 5000);
                handleState('timeoutProcessKey', key);

                if (typeFeature === TYPE_FEATURE.billetBatch) {
                  dispatch(updateDebts({ isGeneratingBilletBatch: true }));
                }

                return;
              }

              closeNotifyBatchUpload(typeFeature);
              handleState('rerenderDebtsFetch', true);
              dispatch(updateDebts({ isGeneratingBilletBatch: false }));

              if (userHasStrategy) {
                dispatch(
                  setStrategyDebtState({ isGeneratingBilletBatch: false })
                );
                refetchAllStrategyRequests(dispatch);
              }

              isAlreadyFetchingBilletStatus.current = false;

              if (
                bodyResume.status === STATUS_PROCESS_BATCH_FILE.ERROR &&
                !bodyResume.s3KeyErrors
              ) {
                AUNotifier.error(handleErrorMessage(body[0]?.name));
                return;
              }

              if (
                typeFeature === TYPE_FEATURE.billetBatch &&
                !isDifferentUser
              ) {
                handleState('listBilletResume', [bodyResume]);
                return;
              }

              handleState('uploadResume', bodyResume);
            })
            .catch(function (error) {
              AUNotifier.error(handleErrorMessage(body[0]?.name));
              console.error(error);
              closeNotifyBatchUpload(typeFeature);
              dispatch(updateDebts({ isGeneratingBilletBatch: false }));

              if (userHasStrategy) {
                dispatch(
                  setStrategyDebtState({ isGeneratingBilletBatch: false })
                );
              }
            });
        };

        await pollingRequestStatus();

        // caso tenha idUser e não seja um upload do usuário não mostra nada
        if (isDifferentUser) {
          return null;
        }

        handleState('isNotifierBatchUploadOpen', true);
        return {
          ...objFile,
        };
      }

      if (_isEmpty(body) && typeFeature === TYPE_FEATURE.billetBatch) {
        dispatch(updateDebts({ isGeneratingBilletBatch: false }));

        if (userHasStrategy) {
          dispatch(setStrategyDebtState({ isGeneratingBilletBatch: false }));
        }
      }

      return null;
    } catch (e) {
      console.error('Error useNotifyFileBatch request', e);
    }
  };

  const fetchAllPendingProcesses = (
    types = [
      TYPE_FEATURE.addDebt,
      TYPE_FEATURE.confirmPayment,
      TYPE_FEATURE.billetBatch,
    ]
  ) => {
    setTimeout(() => {
      const checkPending = async () => {
        const responses = await Promise.all(types.map(fetchPendingProcess));

        handleState(
          'uploadingFiles',
          _uniqBy([...uploadingFiles, ...responses].filter(Boolean), 'idFileS3')
        );
      };

      checkPending();
      handleState('isExpanded', true);
    }, 5000);
  };

  const isOnlyGeneratingBillets =
    uploadingFiles.length === 1 &&
    uploadingFiles[0].type === TYPE_FEATURE.billetBatch;

  const onCloseResumeUploadDialog = () => {
    handleState('uploadResume', undefined);
    handleState('listUploadResume', []);
    handleState('listDeleteResume', []);
    handleState('listBilletResume', []);
  };

  return {
    fetchPendingProcess,
    fetchAllPendingProcesses,
    statusFile,
    toggleExpanded,
    onCloseResumeUploadDialog,
    isOnlyGeneratingBillets,
  };
}
