import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core/styles';

type Props = {
  color?: 'disabled' | 'primary';
  size?: number;
};

export const WalletIcon = ({ color, size = 24 }: Props) => {
  const theme = useTheme();

  let svgColor = theme.palette.grey[400];

  if (color === 'primary') {
    svgColor = theme.palette.primary.main;
  }

  if (color === undefined) {
    svgColor = 'inherit';
  }

  return (
    <SvgIcon viewBox="0 0 24 14" style={{ color: svgColor, fontSize: size }}>
      <path d="M17.3334 13.6667H8.16675V7.83337H17.3334V13.6667ZM6.50008 13.6667H0.666748V7.83337H6.50008V13.6667ZM17.3334 6.16671H0.666748V0.333374H17.3334V6.16671Z" />
    </SvgIcon>
  );
};
