import { City, Nullish, State } from '@src/constants/types';

export enum AccountStatus {
  PENDING = 1,
  APROVED = 2,
  REPROVED = 3,
  BLOCKED = 4,
}

export enum AccountType {
  CORRENTE = 1,
  POUPANCA = 2,
}

export const ACCOUNT_TYPE = {
  [AccountType.CORRENTE]: 'Corrente',
  [AccountType.POUPANCA]: 'Poupança',
};

export interface AccountStatusContent {
  code: number;
  description: string;
  name: string;
}

export interface Bank {
  code: number;
  name: string;
  fullName: string;
}

export interface StepOneFormValues {
  doc: string;
  companyName: string;
  tradingName: string;
  bankAccount: string;
  bankAccountDigit: string;
  bankAgency: string;
  bankNumber: string | number;
  bank: Bank;
  bankAccountType: string | number;
}

export interface StepTwoFormValues {
  phone: string;
  email: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: City;
  state: State;
  default: boolean;
  idFileLogo: TIdFileLogo;
}

export interface StepThreeFormValues {
  descriptionJob: string;
  monthlyBilling: string | number;
  site?: string;
  numberMonthlyBills: number;
  averageTicketPerBills: string | number;
  idFileSocialContract: string;
}

export type AddAcccountFormValues = StepOneFormValues &
  StepTwoFormValues &
  StepThreeFormValues;

export interface PaymentAccount {
  id: string;
  doc: string;
  docType: string;
  companyName: string;
  tradingName: string;
  bankAccount: string;
  bankAgency: string;
  bankNumber: number;
  bankAccountType: any;
  areaCode: any;
  phone: string;
  email: string;
  zipCode: number;
  address: string;
  addressNumber: string;
  addressComplement: string;
  neighborhood: string;
  city: string;
  state: string;
  default: boolean;
  active?: boolean;
  status: number;
  createdAt: Date;
  descriptionJob: string;
  monthlyBilling: number;
  site?: string;
  numberMonthlyBills: number;
  averageTicketPerBills: number;
  idFileSocialContract: string;
  idFileLogo: TIdFileLogo;
}

export interface SocialContract {
  fileSize: number;
  idFileS3: string;
  name: string;
  type: string;
  url: string;
}

export interface ITermsOfUse {
  id: string | null;
  accepted: boolean | null;
  html: string | null;
  urlPdf: string | null;
}

export type TIdFileLogo = string | null | undefined;

export enum EditDrawerName {
  MonetaryRestatement = 'monetary-restatement',
  Agreement = 'agreement',
  Default = 'default',
}

export interface FormValues {
  debtFeeRate: number;
  debtFineRate: number;
  agreementFeeRate: number;
  agreementFineRate: number;
  agreementMaxDiscount: string;
  agreementHonorary: number;
  monetaryRestatementIdIndex: Nullish<number | ''>;
  monetaryRestatementIdPeriod: Nullish<number | ''>;
  monetaryRestatementIgnoreNegativeValue: Nullish<boolean>;
}

export interface IMonetaryRestatementConfig {
  idIndex: Nullish<number>;
  idPeriod: Nullish<number>;
  ignoreNegativeValue: Nullish<boolean>;
}

export interface IMonetaryRestatementIndex {
  id: number;
  name: string;
  startDateDescription: string;
}

export interface IMonetaryRestatementPeriod {
  id: number;
  name: string;
}

export interface IMonetaryRestatementLastYear {
  month: string;
  monthValue: string;
  yearValue: string;
}
