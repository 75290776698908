import { combineReducers } from '@reduxjs/toolkit';

import badCreditReducer from '@features/bad-credit/badCreditSlice';
import badCreditConfigReducer from '@features/settings/tabs/bad-credit/badCreditConfigSlice';
import debtsTableReducer from '@features/debt/list/components/DebtsTable/debtsTableSlice';
import financialReducer from '@src/features/settings/tabs/financial/financialSlice';
import wallerReducer from '@features/wallet/walletSlice';
import debtsReducer from '@src/features/debt/list/debtsSlice';
import billetReducer from '@src/features/billet/billetSlice';
import blocklistReducer from '@src/features/settings/tabs/blocklist/blocklistSlice';
import webphoneCampaignsReducer from '@src/features/webphoneCampaigns/webphoneCampaignsSlice';
import strategyReducer from '@src/features/strategy/strategySlice';
import strategyDebtReducer from '@src/features/strategyDebt/strategyDebtSlice';

const rootReducer = combineReducers({
  badCredit: badCreditReducer,
  badCreditConfig: badCreditConfigReducer,
  debtsTable: debtsTableReducer,
  financial: financialReducer,
  wallet: wallerReducer,
  debts: debtsReducer,
  billet: billetReducer,
  blocklist: blocklistReducer,
  webphoneCampaigns: webphoneCampaignsReducer,
  strategy: strategyReducer,
  strategyDebt: strategyDebtReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
