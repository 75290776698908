import { AUPreventMissClick } from '@assertiva/assertiva-ui';

import DefaultDrawer from '@components/DefaultDrawer';
import { useTrackConfig } from './useTrackConfig';
import { TrackConfigProvider, useTrackConfigContext } from './context';
import TriggerRules from './triggerRules/TriggerRules';
import DefineMessages from './defineMessages';
import { State, Step } from './types';

const { PreventMissClickDialog, usePreventMissClick } = AUPreventMissClick;

const TrackConfigDrawer = () => {
  const {
    state: { step },
    handleCloseDrawer,
    canCloseDrawerWithoutWarn,
    isDrawerOpen,
  } = useTrackConfigContext();

  const {
    isPreventMissClickDialogOpen,
    preventMissClick,
    closeAnyway,
    keepOpen,
  } = usePreventMissClick(handleCloseDrawer, () => canCloseDrawerWithoutWarn);

  return (
    <>
      <DefaultDrawer
        open={isDrawerOpen}
        onClose={preventMissClick}
        title="Configurar cobrança"
        data-testid="track-config-drawer"
      >
        {step === Step.triggerRules ? (
          <TriggerRules handleCloseDrawer={preventMissClick} />
        ) : (
          <DefineMessages />
        )}
      </DefaultDrawer>
      <PreventMissClickDialog
        open={isPreventMissClickDialogOpen}
        closeAnyway={closeAnyway}
        keepOpen={keepOpen}
      />
    </>
  );
};

interface Props {
  initialState?: State;
}

const TrackConfigWrapper = ({ initialState }: Props) => {
  const value = useTrackConfig(initialState);

  return (
    <TrackConfigProvider value={value}>
      <TrackConfigDrawer />
    </TrackConfigProvider>
  );
};

export default TrackConfigWrapper;
